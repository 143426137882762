import React, { Component } from 'react';
import PropTypes from 'prop-types';

import scrollBehavior from '../../../../utils/scrollBehavior';
import MerchantSurvey from '../Sections/MerchantSurvey/MerchantSurvey';
import MerchantAppPartnerAgentInfo from '../Sections/MerchantSurvey/MerchantAppPartnerAgentInfo';
import AppOptions from '../Sections/AppOptions/AppOptions';
import ApplicationFiles from '../Sections/ApplicationFiles/ApplicationFiles';

export class Page4 extends Component {

    static propTypes = {
        invalidInputs: PropTypes.any,
        runValidation: PropTypes.func,
        runDocUploadValidation: PropTypes.func,
        showMissingUpload: PropTypes.bool
    };

    componentDidMount() {
        const application = document.getElementById('merchant-application');
        application.scrollIntoView(scrollBehavior());
    }

    render() {
        const { invalidInputs } = this.props;

        return (
            <div>
                <MerchantAppPartnerAgentInfo invalidInputs={invalidInputs} runValidation={this.props.runValidation} />
                <MerchantSurvey invalidInputs={invalidInputs} runValidation={this.props.runValidation} />
                <AppOptions invalidInputs={invalidInputs} runValidation={this.props.runValidation} />
                <ApplicationFiles invalidInputs={invalidInputs} runValidation={this.props.runValidation}
                    runDocUploadValidation={this.props.runDocUploadValidation}
                    showMissingUpload={this.props.showMissingUpload}
                />
            </div>
        );
    }
}

export default Page4;
