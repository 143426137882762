import React from 'react';
import { connect } from 'react-redux';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';

import _ from 'lodash';
import { MERCHANT_APP } from '../../../../../actions/sectionsActions';
import {
    isRadioChecked
} from '../../../../../utils/form';
import CheckboxContainer from '../../../../Shared/CheckboxContainer/CheckboxContainer';
import { getMerchantAppData } from '../../../../../reducers/sectionsReducer';

export class AppOptions extends BaseSection {
    sectionName = MERCHANT_APP;

    state = {
        checkCredit: this.props.MerchantAppData.checkCredit,
        hasPersonalGuarantor: this.props.MerchantAppData.hasPersonalGuarantor
    };

    constructor(props) {
        super(props);

        this.state = {
            checkCredit: this.props.MerchantAppData.checkCredit,
            hasPersonalGuarantor: this.props.MerchantAppData.hasPersonalGuarantor
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.MerchantAppData, this.props.MerchantAppData)) {
            this.setState({
                checkCredit: this.props.MerchantAppData.checkCredit,
                hasPersonalGuarantor: this.props.MerchantAppData.hasPersonalGuarantor
            });
        }
    }

    render() {
        const { checkCredit, hasPersonalGuarantor } = this.state;

        return (
            <Section className="app-options" heading="App Options" >
                <Group>
                    <div id="checkCredit" className="form-input-wrapper">
                        <div className="item-wrapper"  >
                            <CheckboxContainer name="checkCredit" isRequired={false}>
                                <Row>
                                    <label className="label">
                                        Credit Check Enabled?
                                    </label>
                                </Row>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="checkCredit"
                                            value="1"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(1, checkCredit ? 1 : 0)}
                                        />
                                        <label>Yes</label>
                                    </Row>
                                </div>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="checkCredit"
                                            value="0"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(0, checkCredit ? 1 : 0)}
                                        />
                                        <label>No</label>
                                    </Row>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                </Group>
                <Group>
                    <div id="hasPersonalGuarantor" className="form-input-wrapper">
                        <div className="item-wrapper"  >
                            <CheckboxContainer name="hasPersonalGuarantor" isRequired={false}>
                                <Row>
                                    <label className="label">
                                        Personal Guarantor Signature Required?
                                    </label>
                                </Row>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="hasPersonalGuarantor"
                                            value="1"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(1, hasPersonalGuarantor ? 1 : 0)}
                                        />
                                        <label>Yes</label>
                                    </Row>
                                </div>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="hasPersonalGuarantor"
                                            value="0"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(0, hasPersonalGuarantor ? 1 : 0)}
                                        />
                                        <label>No</label>
                                    </Row>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                </Group>
            </Section >
        );
    }
}

export const mapStateToProps = (state) => ({
    MerchantAppData: getMerchantAppData(state)
});

export default connect(mapStateToProps)(AppOptions);
