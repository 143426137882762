export const IS_USER_AN_AGENT = 'IS_USER_AN_AGENT';
export const SET_IS_MERCHANT_AUTHORIZED = 'SET_IS_MERCHANT_AUTHORIZED';

export const isUserAnAgent = (isUserAnAgent) => ({
    type: IS_USER_AN_AGENT,
    isUserAnAgent
});

export const setIsMerchantAuthorized = (isMerchantAuthorized) => ({
    type: SET_IS_MERCHANT_AUTHORIZED,
    isMerchantAuthorized
});

