import React from 'react';
import { connect } from 'react-redux';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextArea from '../../../../Shared/TextArea/TextArea';

import './MerchantSurvey.scss';
import _ from 'lodash';
import { MERCHANT_SURVEY } from '../../../../../actions/sectionsActions';
import {
    getIsValid,
    getErrorMessage,
    shouldBeRequired
} from '../../../../../utils/form';
import { getIsoRepData, getMerchantSiteSurvey } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class MerchantSurvey extends BaseSection {
    sectionName = MERCHANT_SURVEY;

    state = {
        locationOtherDescription: this.props.merchantSurvey.locationOtherDescription,
        notes: this.props.merchantSurvey.notes,
        isUserAnAgent: this.props.isUserAnAgent
    };

    constructor(props) {
        super(props);

        this.state = {
            ...props.merchantSurvey
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.merchantSurvey, this.props.merchantSurvey)) {
            this.setState({
                ...this.props.merchantSurvey
            });
        }
    }

    render() {
        const { merchantSurvey, invalidInputs } = this.props;
        const { notes } = this.state;
        if (merchantSurvey.useFulfillmentHouse === 0 && merchantSurvey.fulfillmentHouseInspected) {
            this.props.merchantSurvey.fulfillmentHouseInspected = null;
        }

        return (
            <Section className="merchant-site-survey" heading="Agent Notes" >
                <Group>
                    <div className="form-input-wrapper" id="notes" >
                        <div className="item-wrapper">
                            <Row>
                                <div className="start">
                                    <label className="label">Notes:
                                        <span className="optional-input">{shouldBeRequired('notes', merchantSurvey.hide) ? '' : 'Optional'} </span>
                                    </label>
                                </div>
                            </Row>
                            <TextArea
                                type="text"
                                name="notes"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={notes}
                                required={false}
                                valid={getIsValid('notes', invalidInputs)}
                                errorMessage={getErrorMessage('notes', invalidInputs)}
                            />
                        </div>
                    </div>
                </Group >
            </Section >
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantSurvey: getMerchantSiteSurvey(state),
    isoRepData: getIsoRepData(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(MerchantSurvey);
