import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './RateBubble.scss';

class RateBubble extends Component {
    static defaultProps = {
        label: '',
        hidden: false
    };

    static propTypes = {
        label: PropTypes.string,
        hidden: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool
        ]),
        children: PropTypes.any
    };

    render() {
        const { label, hidden, children } = this.props;
        const className = hidden ? 'rate-bubble-container hidden' : 'rate-bubble-container';

        return (
            <div className={className}>
                <div className="rate-bubble">
                    {children}
                </div>
                <div className="rate-bubble-label">{label}</div>
            </div>
        );
    }
}

export default RateBubble;