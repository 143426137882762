import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import { createSelector } from 'reselect';
import {
    FETCH_DIRECT_SALES_CONFIG_FAILURE,
    FETCH_DIRECT_SALES_CONFIG_LOAD,
    FETCH_DIRECT_SALES_CONFIG_SUCCESS
} from './createDirectSalesAppActions';
import _ from 'lodash';

export default createFetchReducer({
    loadActionType: FETCH_DIRECT_SALES_CONFIG_LOAD,
    failureActionType: FETCH_DIRECT_SALES_CONFIG_FAILURE,
    successActionType: FETCH_DIRECT_SALES_CONFIG_SUCCESS
});

const { getData } = createBaseSelectors('createDirectSalesAppReducer');

export const getAgents = createSelector(
    getData,
    (data) => _.get(data, 'agents')
);

export const getPartners = createSelector(
    getData,
    (data) => _.get(data, 'partners')
);