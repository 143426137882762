import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mixpanel from '../../../utils/mixpanel';
import { connect } from 'react-redux';
import './TextInput.scss';
import { getMerchantAppMerchantHash } from '../../../reducers/sectionsReducer';

export class TextInput extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.object
        ]),
        placeHolder: PropTypes.string,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        handleFocus: PropTypes.func,
        disabled: PropTypes.bool,
        type: PropTypes.string,
        required: PropTypes.bool,
        valid: PropTypes.bool,
        errorMessage: PropTypes.string,
        readOnly: PropTypes.bool,
        minimum: PropTypes.number,
        maximum: PropTypes.number,
        icon: PropTypes.string,
        merchantHash: PropTypes.string,
        dataId: PropTypes.string,
        pattern: PropTypes.string,
        maxLength: PropTypes.number,
        id: PropTypes.string
    };

    static defaultProps = {
        placeHolder: '',
        disabled: false,
        type: 'text',
        required: false,
        valid: true,
        errorMessage: '',
        minimum: null,
        maximum: null,
        dataId: null,
        pattern: null,
        maxLength: null
    };

    formatInput = (inputType, value, emptyvalue) => {
        if (!value || value.trim().length === 0) {
            return emptyvalue;
        }
        switch (inputType) {
            case 'money':
                return `${Number(value).toFixed(2)}`;
            case 'percent':
                return `${Number(value).toFixed(2)}`;
            case 'decimal':
                return `${Number(value).toFixed(2)}`;
            default:
                return value;
        }
    };

    keyPressed = (e) => {
        if (e.key === 'Enter') {
            this.props.handleBlur(e);
        }
    }

    renderInput = () => {
        const {
            name, value, placeHolder, handleChange, disabled, type, required, readOnly, handleFocus,
            icon, minimum, maximum, dataId, pattern, maxLength, id
        } = this.props;

        let theClassName = `text-input ${icon ? icon : ''}`;
        if (disabled) {
            theClassName = 'text-input-no-border';
        }

        if (readOnly) {
            return <div className="read-only-input">
                <span>{type === 'money' ? '$' : ''}</span>
                {this.formatInput(type, value, 0)}
                <span>{type === 'percent' ? '%' : ''}</span>
            </div>;
        } else {
            return <div>
                <span>{type === 'money' ? '$' : ''}</span>
                <input
                    id={id}
                    className={theClassName}
                    type={type}
                    placeholder={placeHolder}
                    name={name}
                    value={value ? value : ''}
                    onChange={handleChange}
                    onBlur={this.onBlur}
                    disabled={disabled}
                    required={value ? true : required}
                    onFocus={handleFocus}
                    min={minimum}
                    max={maximum}
                    maxLength={maxLength}
                    pattern={pattern}
                    data-id={dataId}
                    onKeyPress={this.keyPressed}
                />
                <span>{type === 'percent' ? '%' : ''}</span>
            </div>;
        }
    }

    onBlur = (e) => {
        this.props.handleBlur(e);
        mixpanel.track(`${this.props.merchantHash}--${this.props.name}`);
    };

    render() {
        const { valid, errorMessage } = this.props;
        const className = `text-input-container ${!valid ? 'text-input-error' : ''}`;

        return (
            <span className={className} >
                {this.renderInput()}
                {!valid && <span className="text-input-error-message">{errorMessage}</span>}
            </span>
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantHash: getMerchantAppMerchantHash(state)
});

export default connect(mapStateToProps)(TextInput);

