import config from '../../../../../config/config';
import { getLocalStorageItem } from '../../../../../utils/localStorage';

export const MARK_MERCHANT_APP_COMPLETE_LOAD = 'MARK_MERCHANT_APP_COMPLETE_LOAD';
export const MARK_MERCHANT_APP_COMPLETE_FAILURE = 'MARK_MERCHANT_APP_COMPLETE_FAILURE';
export const MARK_MERCHANT_APP_COMPLETE_SUCCESS = 'MARK_MERCHANT_APP_COMPLETE_SUCCESS';

export const GET_DOCUSIGN_IFRAME_URL_LOAD = 'GET_DOCUSIGN_IFRAME_URL_LOAD';
export const GET_DOCUSIGN_IFRAME_URL_FAILURE = 'GET_DOCUSIGN_IFRAME_URL_FAILURE';
export const GET_DOCUSIGN_IFRAME_URL_SUCCESS = 'GET_DOCUSIGN_IFRAME_URL_SUCCESS';

export const markMerchantAppComplete = (merchantAppId) => {
    return {
        loadActionTypeOrCreator: MARK_MERCHANT_APP_COMPLETE_LOAD,
        failureActionTypeOrCreator: MARK_MERCHANT_APP_COMPLETE_FAILURE,
        successActionTypeOrCreator: MARK_MERCHANT_APP_COMPLETE_SUCCESS,
        fetchParams: {
            body: {
                id: merchantAppId,
                status: 'Complete'
            },
            method: 'PUT',
            url: `${config.cforceServiceUrl}/merchant/appDetails/MerchantApp/${merchantAppId}`
        }
    };
};

export const fetchDocusignIframeUrl = (merchantAppId) => {
    return {
        loadActionTypeOrCreator: GET_DOCUSIGN_IFRAME_URL_LOAD,
        failureActionTypeOrCreator: GET_DOCUSIGN_IFRAME_URL_FAILURE,
        successActionTypeOrCreator: GET_DOCUSIGN_IFRAME_URL_SUCCESS,
        fetchParams: {
            body: {
                merchantAppID: merchantAppId,
                merchantToken: getLocalStorageItem('merchant-token')
            },
            method: 'POST',
            url: `${config.cforceServiceUrl}/docusign`
        }
    };
};