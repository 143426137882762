import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import _ from 'lodash';

import './Application.scss';

import Page1 from './Page/Page1';
import Page2 from './Page/Page2';
import Page3 from './Page/Page3';
import Page4 from './Page/Page4';
import KycPart1 from './Page/kycPart1';
import KycPart2 from './Page/kycPart2';
import EmailAppLink from './Page/EmailAppLink';
import DocumentUpload from './Page/DocumentUpload';
import DocuSign from './Page/DocuSign';
import PageNumberBubble from '../../Shared/PageNumberBubble/PageNumberBubble';
import Page3a from './Page/Page3a';
import { getIsUserAnAgent } from '../../../reducers/applicationReducer';
import {
    getCardNotPresentMotoPercentage,
    getCardNotPresentEcommercePercentage,
    getCreditCardInfo,
    getIs1099App,
    getIsDirectSalesApp,
    getIsMarketTypeLodging
} from '../../../reducers/sectionsReducer';
import { validateEmail, validateRequired } from '@nexio/ui-validation';

export class Application extends React.Component {
    static propTypes = {
        isUserAnAgent: PropTypes.bool,
        dispatch: PropTypes.func,
        creditCardInfo: PropTypes.shape(),
        handleModalClose: PropTypes.func,
        cardNotPresentMotoPercentage: PropTypes.string,
        cardNotPresentEcommercePercentage: PropTypes.string,
        isMarketTypeLodging: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
        is1099App: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
        isDirectSalesApp: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ])
    };

    static defaultProps = {
        isUserAnAgent: false
    };

    constructor(props) {
        super(props);
        this.state = {
            allFieldsValid: false,
            currentPage: 0,
            invalidInputs: {},
            hasSubmitOnce: false,
            forceAgentRequired: false,
            missingRequiredDoc: false,
            showMissingUpload: false
        };
        this.formRef = React.createRef();
    }

    incrementPage = () => {
        this.setState((prevState) => {
            return {
                currentPage: prevState.currentPage + 1,
                hasSubmitOnce: false
            };
        });
    };

    decrementPage = () => {
        this.setState((prevState) => {
            if (prevState.currentPage > 0) {
                return {
                    currentPage: prevState.currentPage - 1
                };
            }
        }, () => {
            this.handlePreviousPageValidation();
        });
    };

    showInvalidInputsNoToast = (allElements) => {
        const invalidInputs = Object.entries(allElements)
            .reduce((acc, [key, value]) => {
                if (!value.valid) {
                    acc[key] = value;
                }

                return acc;
            }, {});
        this.setState({
            invalidInputs
        });
    };

    showInvalidInputs = (allElements) => {
        const invalidInputs = Object.entries(allElements)
            .reduce((acc, [key, value]) => {
                if (!value.valid) {
                    acc[key] = value;
                }

                return acc;
            }, {});
        this.setState({
            invalidInputs
        });
        const elementNames = Object.keys(invalidInputs);
        if (elementNames.length) {
            const firstError = document.getElementById(elementNames[0]);
            if (firstError) {
                firstError.scrollIntoView({ behavior: 'smooth' });
                toast.error('Please correct form errors');
            }
        }
    };

    validateAllInputs(formElements) {
        return formElements
            .filter((element) => element.name)
            .map((input) => {
                return {
                    name: input.dataset.id || input.name,
                    type: input.type,
                    typeMismatch: input.validity.typeMismatch,
                    value: input.value,
                    validationMessage: this.getValidationMessage(input, formElements),
                    valid: this.getValid(input, formElements)
                };
            })
            .reduce((acc, input) => {
                acc[input.name] = {
                    name: input.name,
                    value: input.value,
                    valid: input.valid,
                    validationMessage: input.validationMessage,
                    typeMismatch: input.typeMismatch
                };

                return acc;
            }, {});
    }

    getValidationMessage = (input, formElements) => {
        if (this.determineWhichPageToShow() === 'Page3' && input.name === 'email'){
            return this.getOwnerEmailValidation(input, formElements).validationMessage;
        } else if (input.name === 'zip' || input.name === 'physicalAddressZip' || input.name === 'legalAddressZip') {
            return this.zipCodeValidation(input).validationMessage;
        } else {
            return input.validationMessage;
        }
    };

    getValid = (input, formElements) => {
        if (this.determineWhichPageToShow() === 'Page3' && input.name === 'email'){
            return this.getOwnerEmailValidation(input, formElements).isValid;
        } else if (input.name === 'zip' || input.name === 'physicalAddressZip' || input.name === 'legalAddressZip') {
            return this.zipCodeValidation(input).isValid;
        } else {
            return this.isValidByType(
                input.type,
                input.value,
                input.type !== 'fieldset'
                    ? input.checkValidity()
                    : input.dataset.value,
                input.required
            );
        }
    };

    getOwnerEmailValidation = (input, formElements) => {
        const isValid = { isValid: true };

        isValid.validationMessage = input.required ? validateRequired(input.value) : undefined;
        if (!isValid.validationMessage){
            isValid.validationMessage = validateEmail(input.value);
        }
        if (isValid.validationMessage) {
            isValid.isValid = false;

            return isValid;
        }

        const emails = [];
        formElements.forEach((formElement) => {
            if (formElement.name === 'email'){
                if (_.indexOf(emails, formElement.value) !== -1){
                    isValid.isValid = false;
                    isValid.validationMessage = 'duplicate emails found';
                } else {
                    emails.push(formElement.value);
                }
            }
        });

        return isValid;
    };

    zipCodeValidation = (input) => {
        const isValid = {};

        isValid.validationMessage = input.required ? validateRequired(input.value) : undefined;
        if (isValid.validationMessage){
            isValid.isValid = false;

            return isValid;
        }

        const length = input.value.length;

        if (length === 5 || (length === 10 && input.value[5] === '-') || this.props.isUserAnAgent) {
            isValid.isValid = true;
        } else {
            isValid.isValid = false;
            isValid.validationMessage = 'Zip Code is not valid';
        }

        return isValid;
    };

    isValidByType = (type, value, valid, required) => {
        if (this.props.isUserAnAgent && this.determineWhichPageToShow() !== 'Page4') {
            return true;
        }
        switch (type) {
            case 'radio':
                return !!value.length;
            case 'text':
            case 'number':
            case 'email':
            case 'checkbox':
            case 'url':
            case 'hidden':
            case 'date':
            case 'textarea':
                return valid;
            case 'fieldset':
                return valid === 'true';
            case 'select-one':
                return !required || value.length > 0;
            default:
                return true;
        }
    };

    checkIfAllFieldsValid = (inputs) => Object.keys(inputs)
        .map((key) => inputs[key])
        .every((input) => input.valid);

    handleSubmit = (e) => {
        e.preventDefault();
        const formElements = e.target.elements;
        const elements = [...formElements];
        const transformedElements = this.validateAllInputs(elements);

        let allFieldsValid = this.checkIfAllFieldsValid(transformedElements);
        this.setState({
            allFieldsValid,
            hasSubmitOnce: true
        }, () => {
            if (!this.state.allFieldsValid) {
                this.showInvalidInputs(transformedElements);
            } else if (this.state.missingRequiredDoc) {
                this.showMissingUpload();
            } else {
                this.incrementPage();
            }
        });
    };

    handlePreviousPageValidation() {
        // hack to wait until the checkbox container validates its inputs first
        setTimeout(() => {
            const elements = [...this.formRef.current.elements];
            const transformedElements = this.validateAllInputs(elements);
            this.showInvalidInputs(transformedElements);
        }, 0);
    }

    showHideButton(shouldHide) {
        if (shouldHide) {
            return { display: 'none' };
        }

        return {};
    }

    runValidation() {
        if (this.state.hasSubmitOnce) {
            const elements = [...this.formRef.current.elements];
            const transformedElements = this.validateAllInputs(elements);
            this.showInvalidInputsNoToast(transformedElements);
        }
    }

    runDocUploadValidation = (required, isMissing) => {
        if (required && isMissing) {
            this.setState((prevState) => {
                if (!prevState.missingRequiredDoc) {
                    return {
                        missingRequiredDoc: true
                    };
                }
            });
        } else {
            this.setState((prevState) => {
                if (prevState.missingRequiredDoc) {
                    return {
                        missingRequiredDoc: false
                    };
                }
            });
        }
    }

    showMissingUpload = () => {
        this.setState((prevState) => {
            if (!prevState.showMissingUpload) {
                return {
                    showMissingUpload: true
                };
            }
        });
    }

    handlePageChange(currentPage, newPage) {
        if (newPage > currentPage + 1) {
            //only allow going forward one page at a time
            newPage = currentPage + 1;
        }

        if (newPage > currentPage) {
            const elements = [...this.formRef.current.elements];
            const transformedElements = this.validateAllInputs(elements);
            let allFieldsValid = this.checkIfAllFieldsValid(transformedElements);
            this.setState({
                allFieldsValid,
                hasSubmitOnce: true
            }, () => {
                if (!this.state.allFieldsValid) {
                    this.showInvalidInputs(transformedElements);
                } else if (this.state.missingRequiredDoc) {
                    this.showMissingUpload();
                } else {
                    this.setState({
                        currentPage: newPage,
                        hasSubmitOnce: false
                    });
                }
            });
        } else {
            this.setState({ currentPage: newPage });
        }
    }

    renderPageBubbles() {
        const { currentPage } = this.state;
        const pages = this.getPages();

        return pages.map((_, index) => {
            const active = index === currentPage;

            return (
                <PageNumberBubble
                    key={`page${index + 1}`}
                    number={index}
                    currentPage={currentPage}
                    totalPages={pages.length}
                    active={active}
                    onClick={() => this.handlePageChange(currentPage, index)}
                />
            );
        });
    }

    getPages = () => {
        const { isUserAnAgent, cardNotPresentMotoPercentage, cardNotPresentEcommercePercentage, isMarketTypeLodging, is1099App, isDirectSalesApp } = this.props;

        const pages = ['Page1', 'Page2', 'Page3'];

        const cardNotPresentPercentage = cardNotPresentMotoPercentage + cardNotPresentEcommercePercentage;

        if (isUserAnAgent) {
            pages.push('Page4');
            pages.push('EmailAppLink');
        } else {
            if (((cardNotPresentPercentage && cardNotPresentPercentage >= 50 && !isMarketTypeLodging) ||  is1099App) && !isDirectSalesApp) {
                pages.push('Page3a');
            }
            if (isDirectSalesApp) {
                pages.push('KycPart1');
                pages.push('KycPart2');
            }
            pages.push('DocumentUpload');
            pages.push('DocuSign');
        }

        return pages;
    };

    determineWhichPageToShow = () => {
        const { currentPage } = this.state;

        return this.getPages()[currentPage];
    };

    renderPages() {
        const { handleModalClose } = this.props;

        const { invalidInputs } = this.state;
        const pageToShow = this.determineWhichPageToShow();

        switch (pageToShow) {
            case 'Page1':
                return <Page1 invalidInputs={invalidInputs} runValidation={() => this.runValidation('Page1')}/>;
            case 'Page2':
                return <Page2 invalidInputs={invalidInputs} runValidation={() => this.runValidation('Page2')}/>;
            case 'Page3':
                return <Page3 invalidInputs={invalidInputs} runValidation={() => this.runValidation('Page3')}/>;
            case 'Page4':
                return <Page4 invalidInputs={invalidInputs} handleModalClose={handleModalClose}
                    runValidation={() => this.runValidation('Page4')}
                    runDocUploadValidation={this.runDocUploadValidation}
                    showMissingUpload={this.state.showMissingUpload} />;
            case 'EmailAppLink':
                return <EmailAppLink invalidInputs={invalidInputs} handleModalClose={handleModalClose} runValidation={() => this.runValidation('EmailAppLink')}/>;
            case 'Page3a':
                return <Page3a invalidInputs={invalidInputs} runValidation={() => this.runValidation('Page3a')}/>;
            case 'KycPart1':
                return <KycPart1 invalidInputs={invalidInputs} runValidation={() => this.runValidation('KycPart1')}/>;
            case 'KycPart2':
                return <KycPart2 invalidInputs={invalidInputs} runValidation={() => this.runValidation('KycPart2')} />;
            case 'DocumentUpload':
                return <DocumentUpload invalidInputs={invalidInputs}
                    runValidation={() => this.runValidation('DocumentUpload')}
                    runDocUploadValidation={this.runDocUploadValidation}
                    showMissingUpload={this.state.showMissingUpload} />;
            default:
                return null;
        }
    }

    render() {
        const { currentPage } = this.state;
        const pageToShow = this.determineWhichPageToShow();
        const pages = this.getPages();

        if (pageToShow === 'DocuSign'){
            return <DocuSign className="docusign-iframe" totalPages={pages.length} />;
        } else {
            const isEmailAppLinkPage = pageToShow === 'EmailAppLinkPage';

            const FormOrDiv = isEmailAppLinkPage ? 'div' : 'form';
            const formProps = isEmailAppLinkPage ? {} : { onSubmit: this.handleSubmit, noValidate: true };

            return (
                <div className="application" id="merchant-application">
                    <div className="page-num-wrapper">
                        <div className="page-num">
                            {this.renderPageBubbles()}
                        </div>
                    </div>
                    <FormOrDiv ref={this.formRef} {...formProps}>
                        {this.renderPages()}
                        <div className="button-row">
                            <button type="button" style={this.showHideButton(currentPage === 0)} onClick={this.decrementPage}>Previous</button>
                            <button type="submit" style={this.showHideButton(pageToShow === 'DocuSign' || pageToShow === 'EmailAppLink')}>Next</button>
                        </div>
                    </FormOrDiv>
                </div>
            );
        }
    }
}

export const mapStateToProps = (state) => ({
    is1099App: getIs1099App(state),
    isDirectSalesApp: getIsDirectSalesApp(state),
    isUserAnAgent: getIsUserAnAgent(state),
    creditCardInfo: getCreditCardInfo(state),
    cardNotPresentMotoPercentage: getCardNotPresentMotoPercentage(state),
    cardNotPresentEcommercePercentage: getCardNotPresentEcommercePercentage(state),
    isMarketTypeLodging: getIsMarketTypeLodging(state)
});

export default connect(mapStateToProps)(Application);
