import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import {
    FETCH_APPLICATION_DATA_LOAD,
    FETCH_APPLICATION_DATA_FAILURE,
    FETCH_APPLICATION_DATA_SUCCESS
} from '../actions/fetchApplicationData';

export default createFetchReducer({
    loadActionType: FETCH_APPLICATION_DATA_LOAD,
    failureActionType: FETCH_APPLICATION_DATA_FAILURE,
    successActionType: FETCH_APPLICATION_DATA_SUCCESS
});

const baseSelectors = createBaseSelectors('fetchApplicationDataReducer');

export const getIsFetchApplicationDataLoading = baseSelectors.getIsLoading;