import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EmvioValidatedForm, EmvioValidatedInput } from '@nexio/emvio-form-components';
import Select from '../../Shared/Select/Select';
import './ConfigSection.scss';
import BaseSection from './Sections/BaseSection';

import { pricingTypeOptions, optBlueOptions } from './StaticData/staticData';
import _ from 'lodash';
import {
    getIsoRepData,
    getIsvUserData,
    getIsvUserName, getMerchantAppContact,
    getMerchantAppOptBlue, getMerchantAppPartner,
    getMerchantAppPartnerName,
    getMerchantAppPricingType
} from '../../../reducers/sectionsReducer';

export class ConfigSection extends BaseSection {
    constructor(props) {
        super(props);
        this.state = {
            isvUserData: {},
            pricingType: props.merchantAppPricingType,
            optBlue: props.merchantAppOptBlue
        };
    }

    static propTypes = {
        isvUserData: PropTypes.shape(),
        isvUserName: PropTypes.string,
        isoRepData: PropTypes.shape(),
        merchantAppContact: PropTypes.string,
        merchantAppOptBlue: PropTypes.number,
        MerchantAppPartner: PropTypes.shape(),
        merchantAppPartnerName: PropTypes.string,
        merchantAppPricingType: PropTypes.string
    };

    handleSelectChangeAndSave = async (e) => {
        if (e.persist) {
            e.persist();
        }
        this.setState({ [e.target.name]: e.target.value });

        let section;
        let valueToSave;

        switch (e.target.name) {
            case 'optBlue':
                section = 'MerchantApp';
                valueToSave = _.toInteger(e.target.value);
                break;
            case 'pricingType':
                section = 'MerchantAppPartner';
                valueToSave = e.target.value;
                break;
            default:
                // should not happen
                break;
        }
        await this.saveAndCheckDoneSpecific(section, e.target.name, valueToSave);
    }

    renderRep() {
        const { isvUserName, isoRepData, MerchantAppPartner } = this.props;
        if (isvUserName) {
            return <EmvioValidatedInput
                labelText="ISV Rep"
                type="text"
                className="right readonly"
                disabled={true}
                value={this.props.isvUserData.Name}
                name="ISVRep"
                onChange={() => { }}
            />;
        } else {
            const isoName = `${MerchantAppPartner.agentID} - ${isoRepData.Name ? isoRepData.Name : ''}`;

            return <EmvioValidatedInput
                labelText="Rep Code"
                type="text"
                className="right readonly"
                disabled={true}
                value={isoName}
                name="ISVRep"
                onChange={() => { }}
            />;
        }
    }

    renderName() {
        const { merchantAppPartnerName, isvUserName } = this.props;
        if (isvUserName) {
            return <EmvioValidatedInput
                labelText="Partner Name"
                type="text"
                className="right readonly"
                disabled={true}
                value={merchantAppPartnerName}
                name="PartnerName"
                onChange={() => { }}
            />;
        } else {
            return <EmvioValidatedInput
                labelText="Application Preset"
                type="text"
                className="right readonly"
                disabled={true}
                value={merchantAppPartnerName}
                name="PartnerName"
                onChange={() => { }}
            />;
        }
    }

    render() {
        const { merchantAppContact } = this.props;

        return (
            <div className="options">
                <EmvioValidatedForm onSubmit={() => { }}>
                    <div className="options-row">
                        <div className="cell readonly">
                            {this.renderName()}
                        </div>
                        <div className="cell readonly">
                            {this.renderRep()}
                        </div>
                        <div className="cell readonly">
                            <EmvioValidatedInput
                                labelText="Contact"
                                type="text"
                                className="right readonly"
                                disabled={true}
                                value={merchantAppContact}
                                name="Contact"
                                onChange={() => { }}
                            />
                        </div>
                    </div>
                    <div className="options-row">
                        <div className="cell select-cell">
                            <label htmlFor="pricingType" className="left">
                                * Pricing Type
                            </label>
                            <Select
                                className="pricing-type-select"
                                id="pricingType"
                                name="pricingType"
                                handleChange={(e) => this.handleSelectChangeAndSave(e)}
                                value={this.state.pricingType}
                                options={pricingTypeOptions}
                                removeBlank
                            />
                        </div>
                        <div className="cell select-cell">
                            <label htmlFor="optBlue" className="left">
                                * OptBlue
                            </label>
                            <Select
                                id="optBlue"
                                name="optBlue"
                                handleChange={(e) => this.handleSelectChangeAndSave(e)}
                                value={this.state.optBlue}
                                options={optBlueOptions}
                                required={true}
                                removeBlank
                            />
                        </div>
                    </div>
                </EmvioValidatedForm>
            </div>
        );
    }
}

export const mapStateToProps = (state) => ({
    isvUserData: getIsvUserData(state),
    isoRepData: getIsoRepData(state),
    MerchantAppPartner: getMerchantAppPartner(state),
    isvUserName: getIsvUserName(state),
    merchantAppContact: getMerchantAppContact(state),
    merchantAppPartnerName: getMerchantAppPartnerName(state),
    merchantAppOptBlue: getMerchantAppOptBlue(state),
    merchantAppPricingType: getMerchantAppPricingType(state)
});

export default connect(mapStateToProps)(ConfigSection);
