import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SendApplinkEmail from '../Sections/SendAppLinkEmail/SendAppLinkEmail';

export class EmailAppLink extends Component{
    static propTypes = {
        invalidInputs: PropTypes.any,
        handleModalClose: PropTypes.any
    };

    render() {
        const { invalidInputs, handleModalClose } = this.props;

        return (
            <div>
                <SendApplinkEmail invalidInputs={invalidInputs}  handleModalClose={handleModalClose}/>
            </div>
        );
    }
}

export default EmailAppLink;