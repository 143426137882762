import React, { Component } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import './shared.scss';
import App from './App/App';
import store from './store';

class ConnectedApp extends Component {
    render() {
        return (
            <Provider store={store}>
                <App/>
            </Provider>
        );
    }
}

ReactDOM.render(<ConnectedApp />, document.getElementById('root'));
