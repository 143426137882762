import React from 'react';
import './Actions.scss';

//eslint-disable-next-line react/prop-types
const Actions = ({ handleLinkClick, icon }) => (
    <div className="online-app-actions">
        <button className="online-app-button" type="button" onClick={handleLinkClick}>
            <span className={icon} />
        </button>
    </div>
);

export default Actions;