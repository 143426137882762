import { getUrlConfig } from '@nexio/cforce-config';

const config = {
    local: {
        cforceDashboardBaseUrl: 'http://localhost:3000',
        cforceServiceUrl: 'https://api.nexiopaydev.com/onlineapp/v1/app',
        cforce_userid: 142974, //develop
        // eslint-disable-next-line max-len
        token: '',
        mixpanel: {
            token: '5d0a619fcbeef54e6b70a4fe9507ca35'
        },
        triAppPartnerId: 379
    },
    test: {
        mixpanel: {
            token: 'test'
        },
        triAppPartnerId: 379
    },
    development: {
        cforceDashboardBaseUrl: 'https://onlineapp.nexiopaydev.com',
        mixpanel: {
            token: '5d0a619fcbeef54e6b70a4fe9507ca35'
        },
        triAppPartnerId: 379
    },
    staging: {
        cforceDashboardBaseUrl: 'https://onlineapp.nexiopaystaging.com',
        mixpanel: {
            token: '5d0a619fcbeef54e6b70a4fe9507ca35'
        },
        triAppPartnerId: 379
    },
    production: {
        cforceDashboardBaseUrl: 'https://onlineapp.cmsonline.com',
        mixpanel: {
            token: 'e127b641a8437a12b05a15eb92ea715d'
        },
        triAppPartnerId: 379
    }
};

export default Object.assign(
    {},
    getUrlConfig(process.env.BUILD_ENV),
    config[process.env.BUILD_ENV]
);
