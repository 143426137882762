import React, { Component } from 'react';
import mixpanel from '../../../utils/mixpanel';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './Select.scss';
import { getMerchantAppMerchantHash } from '../../../reducers/sectionsReducer';

export class Select extends Component {
    static propTypes = {
        id: PropTypes.string,
        options: PropTypes.array,
        name: PropTypes.string,
        handleChange: PropTypes.func,
        value: PropTypes.string,
        required: PropTypes.bool,
        valid: PropTypes.bool,
        errorMessage: PropTypes.string,
        merchantHash: PropTypes.string,
        dataId: PropTypes.string,
        type: PropTypes.string
    };

    static defaultProps = {
        options: [],
        required: false,
        valid: true,
        errorMessage: '',
        dataId: null
    };

    renderOptions() {
        return this.props.options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
        ));
    }

    onChange = (e) => {
        this.props.handleChange(e);
        mixpanel.track(`${this.props.merchantHash}--${this.props.name}`);
    };

    render() {
        const { name, value, required, valid, errorMessage, dataId, id } = this.props;
        const className = `selector ${!valid ? 'text-input-error' : ''}`;

        return (
            <div className="selector">
                <select
                    name={name}
                    className={className}
                    onChange={this.onChange}
                    value={value ? value : ''}
                    required={value ? true : required}
                    data-id={dataId}
                    id={id}
                    type={this.props.type || null}
                >
                    <option key="" value="" />
                    {this.renderOptions()}
                </select>
                {!valid && <span className="text-input-error-message">{errorMessage}</span>}
            </div>
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantHash: getMerchantAppMerchantHash(state)
});

export default connect(mapStateToProps)(Select);
