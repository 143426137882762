import React, { Component } from 'react';
import config from '../../../config/config';
import Modal from '../../Shared/Modal/Modal';
import PropTypes from 'prop-types';
import './LinkModal.scss';

class LinkModal extends Component {
    static propTypes = {
        isLinkOpen: PropTypes.bool,
        merchantAppData: PropTypes.any,
        handleCancel: PropTypes.func
    };

    static defaultProps = {
        merchantAppData: {}
    }

    shouldRenderApplicationLink = () => {
        const { is1099App, agentEmail, agentName } = this.props.merchantAppData;

        return !is1099App || !!(agentEmail && agentName);
    }

    renderMissingRequiredFieldsMessage = () => {
        return (
            <div className="partner-row">
                <label className="label">Link</label>
                <div id="missing-1099-fields-message" className="app-link">
                    Missing agent email and/or agent name
                </div>
            </div>
        );
    }

    renderAppLinks = () => {
        let appLink = `${config.cforceDashboardBaseUrl}${this.props.merchantAppData.applicationLink}`;

        return <fragment>
            <div className="partner-row">
                <label className="label">Link</label>
                <div id="application-link" className="app-link">
                    <a href={appLink} target="_new">{appLink}</a>
                </div>
            </div>
        </fragment>;
    }

    render() {
        const { partnerName, contact, token } = this.props.merchantAppData;

        return (
            <Modal
                title="APP LINK"
                isOpen={this.props.isLinkOpen}
                handleClose={this.props.handleCancel}
                className="app-link"
            >
                <div className="link-modal">
                    <div className="partner-row">
                        <label className="label">Partner</label>
                        <div className="spacer">
                            {partnerName}
                        </div>
                    </div>

                    <div className="partner-row">
                        <label className="label">Contact</label>
                        <div className="spacer">
                            {contact}
                        </div>
                    </div>
                    {this.shouldRenderApplicationLink() ? this.renderAppLinks() : this.renderMissingRequiredFieldsMessage()}
                    <div className="partner-row">
                        <label className="label">Token</label>
                        <div className="spacer">
                            {token}
                        </div>
                    </div>

                </div>
            </Modal>
        );
    }
}

export default LinkModal;
