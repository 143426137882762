import config from '../config/config';

export const FETCH_FORM_FIELDS_LOAD = 'FETCH_FORM_FIELDS_LOAD';
export const FETCH_FORM_FIELDS_FAILURE = 'FETCH_FORM_FIELDS_FAILURE';
export const FETCH_FORM_FIELDS_SUCCESS = 'FETCH_FORM_FIELDS_SUCCESS';

export const fetchFormFields = () => {
    return {
        fetchParams: { url: `${config.cforceServiceUrl}/formFields` },
        loadActionTypeOrCreator: FETCH_FORM_FIELDS_LOAD,
        failureActionTypeOrCreator: FETCH_FORM_FIELDS_FAILURE,
        successActionTypeOrCreator: FETCH_FORM_FIELDS_SUCCESS
    };
};
