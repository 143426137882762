import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DocuSignIframe from '../Sections/DocuSign/DocuSignIframe';
import { setLocalStorageItem } from '../../../../utils/localStorage';

export const DocuSign = ({
    className,
    totalPages
}) => {
    useEffect(() => {
        setLocalStorageItem('totalPages', totalPages);
    }, []);

    return (
        <div>
            <DocuSignIframe className={className} />
        </div>
    );
};

DocuSign.propTypes = {
    className: PropTypes.string,
    merchantAppID: PropTypes.any,
    totalPages: PropTypes.number
};

export default DocuSign;