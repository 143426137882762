import React from 'react';
import { connect } from 'react-redux';

import './AmexFees.scss';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import TextInput from '../../../../Shared/TextInput/TextInput';
import { AMEX_FEES } from '../../../../../actions/sectionsActions';
import {
    getErrorMessage,
    getIsValid
} from '../../../../../utils/form';
import _ from 'lodash';
import Group from '../../../../Shared/Group/Group';
import { getAmexFees, getMerchantAppData, getMerchantAppPartner } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class AmexFees extends BaseSection {
    sectionName = AMEX_FEES;

    constructor(props) {
        super(props);

        this.state = {
            qualifiedErr: this.props.amexFees.qualifiedErr,
            nonQualifiedErr: this.props.amexFees.nonQualifiedErr,
            qualifiedRateTiered: this.props.amexFees.qualifiedRateTiered,
            midQualifiedRateTiered: this.props.amexFees.midQualifiedRateTiered,
            nonQualifiedRateTiered: this.props.amexFees.nonQualifiedRateTiered,
            interchangeRateIC: this.props.amexFees.interchangeRateIC,
            isUserAnAgent: this.props.isUserAnAgent
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.amexFees, this.props.amexFees)) {
            this.setState({
                qualifiedErr: this.props.amexFees.qualifiedErr,
                nonQualifiedErr: this.props.amexFees.nonQualifiedErr,
                transactionFeeErr: this.props.amexFees.transactionFeeErr,
                qualifiedRateTiered: this.props.amexFees.qualifiedRateTiered,
                midQualifiedRateTiered: this.props.amexFees.midQualifiedRateTiered,
                transactionFeeTiered: this.props.amexFees.transactionFeeTiered,
                nonQualifiedRateTiered: this.props.amexFees.nonQualifiedRateTiered,
                transactionFeeIC: this.props.amexFees.transactionFeeIC,
                interchangeRateIC: this.props.amexFees.interchangeRateIC
            });
        }
    }
    renderOption(option) {
        const { invalidInputs } = this.props;
        const {
            qualifiedErr, nonQualifiedErr,
            qualifiedRateTiered, midQualifiedRateTiered,
            nonQualifiedRateTiered, interchangeRateIC
        } = this.state;
        if (option === 0) {
            return (
                <div></div>
            );
        } else if (option === 1) { //err
            return (
                <Group>
                    <div className="form-input-wrapper" >
                        <div className="multi-item-wrapper">
                            <div className="qualified-err" id="qualifiedErr">
                                <div className="start">
                                    <label className="label">Qualified Rate</label>
                                </div>
                                <TextInput
                                    name="qualifiedErr"
                                    type="percent"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={qualifiedErr}
                                    required={true}
                                    valid={getIsValid('qualifiedErr', invalidInputs)}
                                    errorMessage={getErrorMessage('qualifiedErr', invalidInputs)}
                                    handleFocus={this.handleFocus}
                                    readOnly={!this.state.isUserAnAgent}
                                    placeHolder="0.00"
                                />
                            </div>

                            <div className="non-qualified-err" id="nonQualifiedErr" >
                                <div className="start">
                                    <label className="label">Non-Qualified Rate</label>
                                </div>
                                <TextInput
                                    name="nonQualifiedErr"
                                    type="percent"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={nonQualifiedErr}
                                    required={true}
                                    valid={getIsValid('nonQualifiedErr', invalidInputs)}
                                    errorMessage={getErrorMessage('nonQualifiedErr', invalidInputs)}
                                    handleFocus={this.handleFocus}
                                    readOnly={!this.state.isUserAnAgent}
                                    placeHolder="0.00"
                                />
                            </div>
                        </div>
                    </div>
                </Group>
            );
        } else if (option === 3) { //interchage
            return (
                <Group>
                    <div className="form-input-wrapper" >
                        <div className="multi-item-wrapper">
                            <div className="interchange-rate-ic" id="interchangeRateIC">
                                <div className="start">
                                    <label className="label">Interchange Rate</label>
                                </div>
                                <TextInput
                                    name="interchangeRateIC"
                                    type="percent"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={interchangeRateIC}
                                    required={true}
                                    valid={getIsValid('interchangeRateIC', invalidInputs)}
                                    errorMessage={getErrorMessage('interchangeRateIC', invalidInputs)}
                                    handleFocus={this.handleFocus}
                                    readOnly={!this.state.isUserAnAgent}
                                    placeHolder="0.00"
                                />
                            </div>
                        </div>
                    </div>
                </Group>
            );
        } else if (option === 2) { //tier
            return (
                <Group>
                    <div className="form-input-wrapper" >
                        <div className="multi-item-wrapper">
                            <div className="qualified-rate-tiered" id="qualifiedRateTiered">
                                <div className="start">
                                    <label className="label">Qualified Rate</label>
                                </div>
                                <TextInput
                                    name="qualifiedRateTiered"
                                    type="percent"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={qualifiedRateTiered}
                                    required={true}
                                    valid={getIsValid('qualifiedRateTiered', invalidInputs)}
                                    errorMessage={getErrorMessage('qualifiedRateTiered', invalidInputs)}
                                    handleFocus={this.handleFocus}
                                    readOnly={!this.state.isUserAnAgent}
                                    placeHolder="0.00"
                                />
                            </div>

                            <div className="mid-qualified-rate-tiered" id="midQualifiedRateTiered" >
                                <div className="start">
                                    <label className="label">Mid-Qualified Rate</label>
                                </div>
                                <TextInput
                                    name="midQualifiedRateTiered"
                                    type="percent"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={midQualifiedRateTiered}
                                    required={true}
                                    valid={getIsValid('midQualifiedRateTiered', invalidInputs)}
                                    errorMessage={getErrorMessage('midQualifiedRateTiered', invalidInputs)}
                                    handleFocus={this.handleFocus}
                                    readOnly={!this.state.isUserAnAgent}
                                    placeHolder="0.00"
                                />
                            </div>

                            <div className="nonQualified-rate-tiered" id="nonQualifiedRateTiered" >
                                <div className="start">
                                    <label className="label">Non-Qualified Rate</label>
                                </div>
                                <TextInput
                                    name="nonQualifiedRateTiered"
                                    type="percent"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={nonQualifiedRateTiered}
                                    required={true}
                                    valid={getIsValid('nonQualifiedRateTiered', invalidInputs)}
                                    errorMessage={getErrorMessage('nonQualifiedRateTiered', invalidInputs)}
                                    handleFocus={this.handleFocus}
                                    readOnly={!this.state.isUserAnAgent}
                                    placeHolder="0.00"
                                />
                            </div>
                        </div>
                    </div>
                </Group>

            );
        } else {
            return null;
        }
    }
    render() {
        const { merchantAppData } = this.props;
        if (merchantAppData.optBlue === 0){
            return (
                <div></div>
            );
        } else {
            return (
                <Section heading="Amex Pricing" className="amex-pricing-section">
                    {this.renderOption(merchantAppData.optBlue)}
                </Section>
            );
        }
    }
}

export const mapStateToProps = (state) => ({
    amexFees: getAmexFees(state),
    merchantAppPartner: getMerchantAppPartner(state),
    merchantAppData: getMerchantAppData(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(AmexFees);