import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import Application from './Application';
import TextInput from '../../Shared/TextInput/TextInput';
import Row from '../../Shared/Row/Row';
import { toast } from 'react-toastify';

import { fetchApplicationMerchant } from '../../../actions/fetchApplicationMerchant';

import './MerchantApplicationWrapper.scss';
import PropTypes from 'prop-types';
import { getIsFetchApplicationMerchantLoading } from '../../../reducers/fetchApplicationMerchantReducer';
import { getMerchantAppId } from '../../../reducers/sectionsReducer';
import { getIsMerchantAuthorized } from '../../../reducers/applicationReducer';

export class MerchantApplicationWrapper extends Component {
    state = {
        merchantToken: '',
        pagetoDisplay: 'token'
    };
    static propTypes = {
        isLoading: PropTypes.bool,
        location: PropTypes.any,
        merchantAppId: PropTypes.number,
        isMerchantAuthorized: PropTypes.any,
        redirectToBankInfo: PropTypes.bool,
        dispatch: PropTypes.any,
        match: PropTypes.any
    };

    static defaultProps = {
        match: {
            params: {
                partner: null,
                merchant: null
            }
        }
    };

    handleChange = (e) => {
        e.persist();
        this.setState({
            merchantToken: e.target.value
        });
    };

    handleSubmit = async () => {
        const { dispatch, match } = this.props;
        const cleanedUpToken = this.state.merchantToken.replace(/\s/g, '');
        try {
            await dispatch(fetchApplicationMerchant(cleanedUpToken, match.params.partner, match.params.merchant));
        } catch (err) {
            toast('Invalid Code, try again....', { type: 'error' });
        }
    };

    renderApplication() {
        return (
            <div className="merchant-application-container">
                <Application />
            </div>
        );
    }

    renderTokenScreen() {
        const { merchantToken } = this.state;

        return (
            <Fragment>
                <div className="merchant-landing-page">
                    <div className="token-container">
                        <Row justify="center">
                            <div className="welcome-message">
                                <Row justify="center">
                                    Welcome!
                                </Row>
                                <Row justify="center">
                                    Please enter access code to proceed to the application.
                                </Row>
                            </div>
                        </Row>
                        <Row justify="center">
                            <label>
                                Code
                            </label>
                        </Row>
                        <Row justify="center">
                            <TextInput
                                name="merchantToken"
                                handleChange={this.handleChange}
                                handleBlur={() => { }}
                                value={merchantToken}
                            />
                        </Row>
                        <Row justify="center">
                            <button
                                disabled={this.props.isLoading}
                                className="merchant-submit-button"
                                onClick={this.handleSubmit}
                            >
                                {this.props.isLoading ? 'Loading...' : 'Submit'}
                            </button>
                        </Row>
                    </div>
                    <Row justify="space-evenly">
                        <div className="badge bbb" />
                        <div className="badge pci" />
                        <div className="badge ink" />
                    </Row>
                </div>
            </Fragment>
        );
    }

    redirectToBankInfo() {
        return <Redirect to={`/docusignCB/${this.props.merchantAppId}?t=${this.state.merchantToken}&s=primary_signer&event=signing_complete`}/>;
    }

    render() {
        const { isMerchantAuthorized, redirectToBankInfo } = this.props;

        if (!isMerchantAuthorized){
            return this.renderTokenScreen();
        } else {
            if (redirectToBankInfo){
                return this.redirectToBankInfo();
            } else {
                return this.renderApplication();
            }
        }
    }
}

export const mapStateToProps = (state) => ({
    merchantAppId: getMerchantAppId(state),
    isLoading: getIsFetchApplicationMerchantLoading(state),
    isMerchantAuthorized: getIsMerchantAuthorized(state)
});

export default connect(mapStateToProps)(MerchantApplicationWrapper);