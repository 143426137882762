import {
    applyMiddleware,
    compose,
    createStore
} from 'redux';
import { tokenMiddleware } from './middleware/tokenMiddleware';
import fetchMiddleware from './middleware/fetchMiddleware';

import { rootReducer } from './reducers/rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [tokenMiddleware, fetchMiddleware];
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
);

export default store;
