import React from 'react';
import { connect } from 'react-redux';

import './Fees.scss';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';

import { FEES } from '../../../../../actions/sectionsActions';
import {
    getIsValid, getErrorMessage, shouldShowHelp
} from '../../../../../utils/form';
import _ from 'lodash';
import {
    getFees,
    getIs1099App,
    getIsDirectSalesApp,
    getMerchantAppPartner
} from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

let timeout;

export class Fees extends BaseSection {
    sectionName = FEES;

    constructor(props) {
        super(props);

        this.state = {
            chargebackFee: props.fees.chargebackFee,
            edcAVSFee: props.fees.edcAVSFee,
            retrievalFee: props.fees.retrievalFee,
            voiceAuthFee: props.fees.voiceAuthFee,
            insufficientFundsFee: props.fees.insufficientFundsFee,
            terminalAuthFee: props.fees.terminalAuthFee,
            downgradeFee: props.fees.downgradeFee,
            accountSetupFee: props.fees.accountSetupFee,
            annualMembershipFee: props.fees.annualMembershipFee,
            monthlyStatementFee: props.fees.monthlyStatementFee,
            monthlyDebitAccessFee: props.fees.monthlyDebitAccessFee,
            monthlyRegulatoryFee: props.fees.monthlyRegulatoryFee,
            onlineReportingAccessFee: props.fees.onlineReportingAccessFee,
            annualPciComplianceFee: props.fees.annualPciComplianceFee,
            helpDeskFee: props.fees.helpDeskFee,
            minimumFee: props.fees.minimumFee,
            batchFee: props.fees.batchFee,
            pinDebitRate: props.fees.pinDebitRate,
            pinDebitRatePerItem: props.fees.pinDebitRatePerItem,
            errSignatureDebitFee: props.fees.errSignatureDebitFee,
            tieredSignatureDebitFee: props.fees.tieredSignatureDebitFee,
            pricingType: props.merchantAppPartner.pricingType,
            isUserAnAgent: props.isUserAnAgent,
            hideToolTip: false,
            is1099App: props.is1099App,
            isDirectSalesApp: props.isDirectSalesApp,
            monthlyBreachInsuranceFee: props.fees.monthlyBreachInsuranceFee
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.fees, this.props.fees)) {
            this.setState({
                chargebackFee: this.props.fees.chargebackFee,
                edcAVSFee: this.props.fees.edcAVSFee,
                retrievalFee: this.props.fees.retrievalFee,
                voiceAuthFee: this.props.fees.voiceAuthFee,
                insufficientFundsFee: this.props.fees.insufficientFundsFee,
                terminalAuthFee: this.props.fees.terminalAuthFee,
                downgradeFee: this.props.fees.downgradeFee,
                accountSetupFee: this.props.fees.accountSetupFee,
                annualMembershipFee: this.props.fees.annualMembershipFee,
                monthlyStatementFee: this.props.fees.monthlyStatementFee,
                monthlyDebitAccessFee: this.props.fees.monthlyDebitAccessFee,
                monthlyRegulatoryFee: this.props.fees.monthlyRegulatoryFee,
                onlineReportingAccessFee: this.props.fees.onlineReportingAccessFee,
                annualPciComplianceFee: this.props.fees.annualPciComplianceFee,
                helpDeskFee: this.props.fees.helpDeskFee,
                minimumFee: this.props.fees.minimumFee,
                authorizationFee: this.props.fees.authorizationFee,
                batchFee: this.props.fees.batchFee,
                pinDebitRate: this.props.fees.pinDebitRate,
                pinDebitRatePerItem: this.props.fees.pinDebitRatePerItem,
                errSignatureDebitFee: this.props.fees.errSignatureDebitFee,
                tieredSignatureDebitFee: this.props.fees.tieredSignatureDebitFee,
                pricingType: this.props.merchantAppPartner.pricingType,
                monthlyBreachInsuranceFee: this.props.fees.monthlyBreachInsuranceFee
            });
        }
    }

    onToggleOpen(id) {
        clearTimeout(timeout);

        this.setState({
            currentFocused: id,
            hideToolTip: true
        }, () => {
            timeout = setTimeout(() => {
                this.setState({
                    currentFocused: null,
                    hideToolTip: false
                });
            }, 20000);
        }
        );

    }

    render() {
        const { invalidInputs } = this.props;
        const {
            chargebackFee, edcAVSFee, retrievalFee, insufficientFundsFee, helpDeskFee,
            accountSetupFee, annualMembershipFee, monthlyStatementFee, monthlyDebitAccessFee, monthlyRegulatoryFee, onlineReportingAccessFee,
            annualPciComplianceFee, isUserAnAgent, minimumFee, batchFee, pinDebitRate, pinDebitRatePerItem, pricingType, errSignatureDebitFee, tieredSignatureDebitFee,
            monthlyBreachInsuranceFee
        } = this.state;

        return (
            <Section heading="Fees (Only if Applicable)">
                <div >
                    <div className="form-input-wrapper">
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="chargebackFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={chargebackFee}
                                        valid={getIsValid('chargebackFee', invalidInputs)}
                                        errorMessage={getErrorMessage('chargebackFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('chargebackFee')}>
                                <Row>
                                    <label className="label">
                                        Chargeback Fee (per occurrence)
                                    </label>
                                </Row>
                                <div className="arrow-container" style={shouldShowHelp('chargebackFee', this.state.currentFocused)} >
                                    <div className="arrow-box" id="chargebackFee">Customers dispute charges to their credit card usually when goods or services are not delivered within the
                                        specified time frame, goods received are damaged, or the purchase was not authorized by the credit card holder.</div>
                                </div>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="edcAVSFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={edcAVSFee}
                                        valid={getIsValid('edcAVSFee', invalidInputs)}
                                        errorMessage={getErrorMessage('edcAVSFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('edcAVSFee')}>
                                <Row>
                                    <label className="label">
                                        EDC AVS Fee (per occurrence)
                                    </label>
                                </Row>
                                <div className="arrow-container" style={shouldShowHelp('edcAVSFee', this.state.currentFocused)}>
                                    <div className="arrow-box" id="edcAVSFee">Electronic Data Capture/ Address Verification Service: Checks to see that the billing address given by the customer matches the credit card.</div>
                                </div>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="retrievalFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={retrievalFee}
                                        valid={getIsValid('retrievalFee', invalidInputs)}
                                        errorMessage={getErrorMessage('retrievalFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('retrievalFee')}>
                                <Row>
                                    <label className="label">
                                        Retrieval Fee (per occurrence)
                                    </label>
                                </Row>
                                <div className="arrow-container" style={shouldShowHelp('retrievalFee', this.state.currentFocused)} >
                                    <div className="arrow-box" id="retrievalFee">A retrieval fee is charged when a customer or the customer&apos;s issuing bank requests a copy of a sales draft in order to substantiate a transaction.</div>
                                </div>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="insufficientFundsFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={insufficientFundsFee}
                                        valid={getIsValid('insufficientFundsFee', invalidInputs)}
                                        errorMessage={getErrorMessage('insufficientFundsFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('insufficientFundsFee')}>
                                <Row>
                                    <label className="label">
                                        Non-Sufficient Funds Fee (per occurrence)
                                    </label>
                                </Row>
                                <div className="arrow-container" style={shouldShowHelp('insufficientFundsFee', this.state.currentFocused)} >
                                    <div className="arrow-box" id="insufficientFundsFee">A type of ACH return indicating that the merchant&apos;s bank account does not have
                                        sufficient funds to cover the specific processing fees deducted by the processor.</div>
                                </div>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="helpDeskFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={helpDeskFee}
                                        valid={getIsValid('helpDeskFee', invalidInputs)}
                                        errorMessage={getErrorMessage('helpDeskFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('helpDeskFee')}>
                                <Row>
                                    <label className="label">
                                        Help Desk Fee (per occurrence)
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="accountSetupFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={accountSetupFee}
                                        valid={getIsValid('accountSetupFee', invalidInputs)}
                                        errorMessage={getErrorMessage('accountSetupFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('accountSetupFee')}>
                                <Row>
                                    <label className="label">
                                        Account Setup Fee
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="annualMembershipFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={annualMembershipFee}
                                        valid={getIsValid('annualMembershipFee', invalidInputs)}
                                        errorMessage={getErrorMessage('annualMembershipFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('annualMembershipFee')}>
                                <Row>
                                    <label className="label">
                                        Annual Membership Fee
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="monthlyStatementFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={monthlyStatementFee}
                                        valid={getIsValid('monthlyStatementFee', invalidInputs)}
                                        errorMessage={getErrorMessage('monthlyStatementFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('monthlyStatementFee')}>
                                <Row>
                                    <label className="label">
                                        Monthly Account Service Fee
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="monthlyDebitAccessFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={monthlyDebitAccessFee}
                                        valid={getIsValid('monthlyDebitAccessFee', invalidInputs)}
                                        errorMessage={getErrorMessage('monthlyDebitAccessFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('monthlyDebitAccessFee')}>
                                <Row>
                                    <label className="label">
                                        Monthly Debit Access Fee
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="monthlyRegulatoryFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={monthlyRegulatoryFee}
                                        valid={getIsValid('monthlyRegulatoryFee', invalidInputs)}
                                        errorMessage={getErrorMessage('monthlyRegulatoryFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('monthlyRegulatoryFee')}>
                                <Row>
                                    <label className="label">
                                        Monthly Regulatory Fee
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="onlineReportingAccessFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={onlineReportingAccessFee}
                                        valid={getIsValid('onlineReportingAccessFee', invalidInputs)}
                                        errorMessage={getErrorMessage('onlineReportingAccessFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('onlineReportingAccessFee')}>
                                <Row>
                                    <label className="label">
                                        Monthly Online Reporting Access Fee
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="annualPciComplianceFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={annualPciComplianceFee}
                                        valid={getIsValid('annualPciComplianceFee', invalidInputs)}
                                        errorMessage={getErrorMessage('annualPciComplianceFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('annualPciComplianceFee')}>
                                <Row>
                                    <label className="label">
                                        Annual PCI Compliance Fee
                                    </label>
                                </Row>
                                <div className="arrow-container" style={shouldShowHelp('annualPciComplianceFee', this.state.currentFocused)} >
                                    <div className="arrow-box" id="annualPciComplianceFee">Free for the first year.</div>
                                </div>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="minimumFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={minimumFee}
                                        valid={getIsValid('minimumFee', invalidInputs)}
                                        errorMessage={getErrorMessage('minimumFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('minimumFee')}>
                                <Row>
                                    <label className="label">
                                        Monthly Minimum Fee
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="batchFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={batchFee}
                                        valid={getIsValid('batchFee', invalidInputs)}
                                        errorMessage={getErrorMessage('batchFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                        required
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('batchFee')}>
                                <Row>
                                    <label className="label">
                                        Batch Fee (per occurence)
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="pinDebitRate"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={pinDebitRate}
                                        valid={getIsValid('pinDebitRate', invalidInputs)}
                                        errorMessage={getErrorMessage('pinDebitRate', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('pinDebitRate')}>
                                <Row>
                                    <label className="label">
                                        Pin Debit Rate
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="pinDebitRatePerItem"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={pinDebitRatePerItem}
                                        valid={getIsValid('pinDebitRatePerItem', invalidInputs)}
                                        errorMessage={getErrorMessage('pinDebitRatePerItem', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('pinDebitRatePerItem')}>
                                <Row>
                                    <label className="label">
                                        Pin Debit Per Item
                                    </label>
                                </Row>
                            </div>
                        </div>
                        <div className="fee-input-row">
                            <div className="fee-amount">
                                <Row>
                                    <span>$</span>
                                    <TextInput
                                        type="number"
                                        name="monthlyBreachInsuranceFee"
                                        placeHolder="0.00"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={monthlyBreachInsuranceFee}
                                        valid={getIsValid('monthlyBreachInsuranceFee', invalidInputs)}
                                        errorMessage={getErrorMessage('monthlyBreachInsuranceFee', invalidInputs)}
                                        disabled={!isUserAnAgent}
                                    />
                                </Row>
                            </div>
                            <div className="fee-description" onMouseOver={() => this.onToggleOpen('monthlyBreachInsuranceFee')}>
                                <Row>
                                    <label className="label">
                                        Monthly Breach Insurance Fee
                                    </label>
                                </Row>
                            </div>
                        </div>
                        { pricingType === 'ERR' ?
                            <div className="fee-input-row">
                                <div className="fee-amount">
                                    <Row>
                                        <span>$</span>
                                        <TextInput
                                            type="number"
                                            name="errSignatureDebitFee"
                                            placeHolder="0.00"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={errSignatureDebitFee}
                                            valid={getIsValid('errSignatureDebitFee', invalidInputs)}
                                            errorMessage={getErrorMessage('errSignatureDebitFee', invalidInputs)}
                                            disabled={!isUserAnAgent}
                                        />
                                    </Row>
                                </div>
                                <div className="fee-description" onMouseOver={() => this.onToggleOpen('errSignatureDebitFee')}>
                                    <Row>
                                        <label className="label">
                                            ERR Signature Debit Rate
                                        </label>
                                    </Row>
                                </div>
                            </div>
                            : null
                        }
                        { pricingType === 'Tiered' ?
                            <div className="fee-input-row">
                                <div className="fee-amount">
                                    <Row>
                                        <span>$</span>
                                        <TextInput
                                            type="number"
                                            name="tieredSignatureDebitFee"
                                            placeHolder="0.00"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={tieredSignatureDebitFee}
                                            valid={getIsValid('tieredSignatureDebitFee', invalidInputs)}
                                            errorMessage={getErrorMessage('tieredSignatureDebitFee', invalidInputs)}
                                            disabled={!isUserAnAgent}
                                        />
                                    </Row>
                                </div>
                                <div className="fee-description" onMouseOver={() => this.onToggleOpen('tieredSignatureDebitFee')}>
                                    <Row>
                                        <label className="label">
                                            Tiered Signature Debit Rate
                                        </label>
                                    </Row>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </Section>
        );
    }
}

export const mapStateToProps = (state) => ({
    fees: getFees(state),
    isUserAnAgent: getIsUserAnAgent(state),
    merchantAppPartner: getMerchantAppPartner(state),
    is1099App: getIs1099App(state),
    isDirectSalesApp: getIsDirectSalesApp(state)
});

export default connect(mapStateToProps)(Fees);
