import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EmvioValidatedForm, EmvioValidatedInput } from '@nexio/emvio-form-components';
import './ConfigSection.scss';

import { optBlueOptions } from './StaticData/staticData';
import _ from 'lodash';
import {
    getIsoRepData,
    getIsvUserData,
    getIsvUserName, getMerchantAppContact,
    getMerchantAppOptBlue, getMerchantAppPartner,
    getMerchantAppPartnerName,
    getMerchantAppPricingType
} from '../../../reducers/sectionsReducer';

export class ConfigSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isvUserData: {}
        };
    }

    static propTypes = {
        isvUserData: PropTypes.shape(),
        isvUserName: PropTypes.string,
        isoRepData: PropTypes.shape(),
        merchantAppContact: PropTypes.string,
        merchantAppOptBlue: PropTypes.number,
        MerchantAppPartner: PropTypes.shape(),
        merchantAppPartnerName: PropTypes.string,
        merchantAppPricingType: PropTypes.string
    };

    renderRep() {
        const { isvUserName, isoRepData, MerchantAppPartner } = this.props;
        if (isvUserName) {
            return <EmvioValidatedInput
                labelText="ISV Rep"
                type="text"
                className="right readonly"
                disabled={true}
                value={this.props.isvUserData.Name}
                name="ISVRep"
                onChange={() => { }}
            />;
        } else {
            const isoName = `${MerchantAppPartner.agentID} - ${isoRepData.Name ? isoRepData.Name : ''}`;

            return <EmvioValidatedInput
                labelText="Rep Code"
                type="text"
                className="right readonly"
                disabled={true}
                value={isoName}
                name="ISVRep"
                onChange={() => { }}
            />;
        }
    }

    renderName() {
        const { merchantAppPartnerName, isvUserName } = this.props;
        if (isvUserName) {
            return <EmvioValidatedInput
                labelText="Partner Name"
                type="text"
                className="right readonly"
                disabled={true}
                value={merchantAppPartnerName}
                name="PartnerName"
                onChange={() => { }}
            />;
        } else {
            return <EmvioValidatedInput
                labelText="Application Preset"
                type="text"
                className="right readonly"
                disabled={true}
                value={merchantAppPartnerName}
                name="PartnerName"
                onChange={() => { }}
            />;
        }
    }

    render() {
        const { merchantAppPricingType, merchantAppOptBlue, merchantAppContact } = this.props;

        return (
            <div className="options">
                <EmvioValidatedForm onSubmit={() => { }}>
                    <div className="options-row">
                        <div className="cell readonly">
                            {this.renderName()}
                        </div>
                        <div className="cell readonly">
                            <EmvioValidatedInput
                                labelText="Pricing Type"
                                type="text"
                                className="right readonly"
                                disabled={true}
                                value={merchantAppPricingType}
                                name="PricingType"
                                onChange={() => { }}
                            />
                        </div>
                    </div>
                    <div className="options-row">
                        <div className="cell readonly">
                            {this.renderRep()}
                        </div>
                        <div className="cell readonly">
                            <EmvioValidatedInput
                                labelText="OptBlue"
                                type="text"
                                className="right readonly"
                                disabled={true}
                                name="OptBlue"
                                onChange={() => { }}
                                validations={['required']}
                                value={optBlueOptions[_.findIndex(optBlueOptions, { value: parseInt(merchantAppOptBlue) })].label}
                            />
                        </div>
                    </div>
                    <div className="options-row">
                        <div className="cell readonly">
                            <EmvioValidatedInput
                                labelText="Contact"
                                type="text"
                                className="right readonly"
                                disabled={true}
                                value={merchantAppContact}
                                name="Contact"
                                onChange={() => { }}
                            />
                        </div>
                    </div>
                </EmvioValidatedForm>
            </div>
        );
    }
}

export const mapStateToProps = (state) => ({
    isvUserData: getIsvUserData(state),
    isoRepData: getIsoRepData(state),
    MerchantAppPartner: getMerchantAppPartner(state),
    isvUserName: getIsvUserName(state),
    merchantAppContact: getMerchantAppContact(state),
    merchantAppPartnerName: getMerchantAppPartnerName(state),
    merchantAppOptBlue: getMerchantAppOptBlue(state),
    merchantAppPricingType: getMerchantAppPricingType(state)
});

export default connect(mapStateToProps)(ConfigSection);
