import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './MerchantApplicationLandingPage.scss';
import { createAppFromLandingPage } from '../../../actions/createPartnerAppActions';
import { fetchApplicationMerchant } from '../../../actions/fetchApplicationMerchant';
import {
    getMerchantHash,
    getToken,
    getPartnerHash,
    getCreatePartnerAppErrorMessage,
    getCreatePartnerAppIsLoading
} from '../../../reducers/createPartnerAppReducer';
import {
    EmvioLoadingSpinner as LoadingSpinner
} from '@nexio/emvio-util-ui';

export const MerchantApplicationLandingPage = ({
    history,
    match
}) => {
    const dispatch = useDispatch();

    const token = useSelector(getToken);
    const partnerHash = useSelector(getPartnerHash);
    const merchantHash = useSelector(getMerchantHash);
    const message = useSelector(getCreatePartnerAppErrorMessage);
    const isLoading = useSelector(getCreatePartnerAppIsLoading);

    useEffect(() => {
        dispatch(createAppFromLandingPage(match.params.guid));
    }, []);

    useEffect (() => {
        async function setup() {
            if (partnerHash && merchantHash){
                await dispatch(fetchApplicationMerchant(token, partnerHash, merchantHash));
                history.replace(`/par/${partnerHash}/app/${merchantHash}`);
            }
        }
        setup();
    }, [partnerHash, merchantHash]);

    return isLoading
        ? <LoadingSpinner />
        : (
            <div id="merchant-landing-page">
                {message}
            </div>
        );
};

MerchantApplicationLandingPage.propTypes = {
    history: PropTypes.shape(),
    match: PropTypes.shape()
};

export default MerchantApplicationLandingPage;