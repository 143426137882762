import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import {
    POST_BANK_DATA_LOAD,
    POST_BANK_DATA_FAILURE,
    POST_BANK_DATA_SUCCESS,
    VALIDATE_BANK_DATA_LOAD,
    VALIDATE_BANK_DATA_FAILURE,
    VALIDATE_BANK_DATA_SUCCESS
} from './docusignCBActions';

export const postBankDataReducer = createFetchReducer({
    loadActionType: POST_BANK_DATA_LOAD,
    failureActionType: POST_BANK_DATA_FAILURE,
    successActionType: POST_BANK_DATA_SUCCESS
});

const postBankDataBaseSelectors = createBaseSelectors('postBankDataReducer');

export const getIsPostBankDataLoading = postBankDataBaseSelectors.getIsLoading;

export const validateBankDataReducer = createFetchReducer({
    loadActionType: VALIDATE_BANK_DATA_LOAD,
    failureActionType: VALIDATE_BANK_DATA_FAILURE,
    successActionType: VALIDATE_BANK_DATA_SUCCESS
});

const validateBankBaseSelectors = createBaseSelectors('validateBankDataReducer');

export const getIsValidateBankDataLoading = validateBankBaseSelectors.getIsLoading;
