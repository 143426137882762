import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './MerchantProfile.scss';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import Select from '../../../../Shared/Select/Select';

import { MERCHANT_PROFILE } from '../../../../../actions/sectionsActions';
import { merchantProfile } from '../../../../../utils/dataShapes';
import {
    isChecked,
    getErrorMessage,
    getIsValid,
    isRadioChecked,
    shouldBeRequired,
    shouldHideElement,
    shouldNotBeRequired,
    isValidDate,
    dateTime,
    shouldShowHelp
} from '../../../../../utils/form';
import { typesOfOwnership } from '../../StaticData/staticData';
import CheckboxContainer from '../../../../Shared/CheckboxContainer/CheckboxContainer';
import _ from 'lodash';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';
import {
    getMerchantProfile,
    getIs1099App,
    getIsDirectSalesApp
} from '../../../../../reducers/sectionsReducer';

export class MerchantProfile extends BaseSection {
    sectionName = MERCHANT_PROFILE;

    static propTypes = {
        merchantProfile: PropTypes.shape(merchantProfile),
        is1099App: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
        isDirectSalesApp: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ])
    };

    constructor(props) {
        super(props);

        this.state = {
            businessStartDate: props.merchantProfile.businessStartDate,
            typesOfGoodsServices: props.merchantProfile.typesOfGoodsServices,
            federalTaxId: props.merchantProfile.federalTaxId,
            durationOfBenefitsInWeeks: props.merchantProfile.durationOfBenefitsInWeeks,
            prepaidsalesPercentage: props.merchantProfile.prepaidsalesPercentage,
            avgPrepaidSalesPercentage: props.merchantProfile.avgPrepaidSalesPercentage,
            isUserAnAgent: props.isUserAnAgent,
            ownershipType: props.merchantProfile.ownershipType,
            acceptCard: props.merchantProfile.acceptCard,
            acceptPrepayment: props.merchantProfile.acceptPrepayment,
            isDirectSales: props.merchantProfile.isDirectSales,
            additionalBenefitsOffered: props.merchantProfile.additionalBenefitsOffered,
            marketTypeCashAdvance: props.merchantProfile.marketTypeCashAdvance,
            marketTypeECommerce: props.merchantProfile.marketTypeECommerce,
            marketTypeEmergingMarket: props.merchantProfile.marketTypeEmergingMarket,
            marketTypeLodging: props.merchantProfile.marketTypeLodging,
            marketTypeMOTO: props.merchantProfile.marketTypeMOTO,
            marketTypeOther: props.merchantProfile.marketTypeOther,
            marketTypePublicSector: props.merchantProfile.marketTypePublicSector,
            marketTypeRestaurant: props.merchantProfile.marketTypeRestaurant,
            marketTypeRetail: props.merchantProfile.marketTypeRetail,
            marketTypeSupermarket: props.merchantProfile.marketTypeSupermarket
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.merchantProfile, this.props.merchantProfile)) {
            this.setState({
                businessStartDate: this.props.merchantProfile.businessStartDate,
                typesOfGoodsServices: this.props.merchantProfile.typesOfGoodsServices,
                federalTaxId: this.props.merchantProfile.federalTaxId,
                durationOfBenefitsInWeeks: this.props.merchantProfile.durationOfBenefitsInWeeks,
                prepaidsalesPercentage: this.props.merchantProfile.prepaidsalesPercentage,
                avgPrepaidSalesPercentage: this.props.merchantProfile.avgPrepaidSalesPercentage,
                marketTypeRetail: this.props.merchantProfile.marketTypeRetail,
                marketTypeRestaurant: this.props.merchantProfile.marketTypeRestaurant,
                marketTypeSupermarket: this.props.merchantProfile.marketTypeSupermarket,
                marketTypeLodging: this.props.merchantProfile.marketTypeLodging,
                marketTypeMOTO: this.props.merchantProfile.marketTypeMOTO,
                marketTypeECommerce: this.props.merchantProfile.marketTypeECommerce,
                marketTypeEmergingMarket: this.props.merchantProfile.marketTypeEmergingMarket,
                marketTypePublicSector: this.props.merchantProfile.marketTypePublicSector,
                marketTypeCashAdvance: this.props.merchantProfile.marketTypeCashAdvance,
                marketTypeOther: this.props.merchantProfile.marketTypeOther,
                acceptCard: this.props.merchantProfile.acceptCard,
                isDirectSales: this.props.merchantProfile.isDirectSales,
                additionalBenefitsOffered: this.props.merchantProfile.additionalBenefitsOffered,
                acceptPrepayment: this.props.merchantProfile.acceptPrepayment,
                ownershipType: this.props.merchantProfile.ownershipType
            });
        }
    }

    render() {
        const { merchantProfile, invalidInputs, is1099App, isDirectSalesApp } = this.props;
        const {
            businessStartDate,
            typesOfGoodsServices,
            federalTaxId,
            durationOfBenefitsInWeeks,
            avgPrepaidSalesPercentage,
            prepaidsalesPercentage
        } = this.state;

        if (merchantProfile.acceptPrepayment === 0 && avgPrepaidSalesPercentage) {
            this.props.merchantProfile.avgPrepaidSalesPercentage = null;
        }

        if (merchantProfile.acceptPrepayment === 0 && prepaidsalesPercentage) {
            this.props.merchantProfile.prepaidsalesPercentage = null;
        }

        if (merchantProfile.additionalBenefitsOffered === 0 && durationOfBenefitsInWeeks) {
            this.props.merchantProfile.durationOfBenefitsInWeeks = null;
        }

        return (
            <Section heading="Merchant Profile" className="merchant-profile">
                <Group>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('ownershipType', this.state.currentFocused)} >
                            Please choose how your business is filed with the state and the IRS. (Please refer to your state and IRS documents if you are not sure.)
                        </div>
                        <div className="item-wrapper" id="ownershipType" style={shouldHideElement('ownershipType', merchantProfile.hide)}>
                            <Row>
                                <label className="label">Type of Ownership</label>
                            </Row>
                            <Select
                                name="ownershipType"
                                handleChange={this.handleSelectChange}
                                value={this.state.ownershipType}
                                options={typesOfOwnership}
                                required={shouldNotBeRequired('ownershipType', merchantProfile.notRequired)}
                                valid={getIsValid('ownershipType', invalidInputs)}
                                errorMessage={getErrorMessage('ownershipType', invalidInputs)}
                            />
                        </div>
                    </div>

                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('marketType', this.state.currentFocused)}>
                            Retail is for card-present face-to-face transactions,
                            MOTO is for transactions that take place over mail or phone,
                            Ecommerce is for transactions that take place through a shopping cart on your website,
                            “Other” is only for recurring transactions or transactions where the card was once present but is no longer present, also known as “Other Non-Mag.”
                        </div>
                        <div className="item-wrapper" id="marketType">
                            <Row>
                                <label className="label">Market Type <span className="optional-input">{shouldBeRequired('marketType', merchantProfile.required) ? '' : 'Optional'} </span>
                                </label>
                            </Row>
                            <div>
                                <div>
                                    <CheckboxContainer name="marketType">
                                        <div className="half-width-div">
                                            <span className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="marketTypeRetail"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={isChecked(this.state.marketTypeRetail)}
                                                />
                                            </span>
                                            <span className="checkbox-label-container">
                                                <label>Retail (face-to-face)</label>
                                            </span>
                                        </div>
                                        <div className="half-width-div">
                                            <span className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="marketTypeRestaurant"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={isChecked(this.state.marketTypeRestaurant)}
                                                />
                                            </span>
                                            <span className="checkbox-label-container">
                                                <label>Restaurant</label>
                                            </span>
                                        </div>
                                        <div className="half-width-div">
                                            <span className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="marketTypeLodging"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={isChecked(this.state.marketTypeLodging)}
                                                />
                                            </span>
                                            <span className="checkbox-label-container">
                                                <label>Lodging</label>
                                            </span>
                                        </div>
                                        <div className="half-width-div">
                                            <span className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="marketTypeMOTO"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={isChecked(this.state.marketTypeMOTO)}
                                                />
                                            </span>
                                            <span className="checkbox-label-container">
                                                <label title="Mail Order / Telephone Order">MO/TO</label>
                                            </span>
                                        </div>
                                        <div className="half-width-div">
                                            <span className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="marketTypeECommerce"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={isChecked(this.state.marketTypeECommerce)}
                                                />
                                            </span>
                                            <span className="checkbox-label-container">
                                                <label>E-Commerce</label>
                                            </span>
                                        </div>
                                        <div className="half-width-div">
                                            <span className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="marketTypePublicSector"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={isChecked(this.state.marketTypePublicSector)}
                                                />
                                            </span>
                                            <span className="checkbox-label-container">
                                                <label>Public Sector</label>
                                            </span>
                                        </div>
                                        <div className="half-width-div">
                                            <span className="checkbox-container">
                                                <input
                                                    type="checkbox"
                                                    name="marketTypeOther"
                                                    onChange={this.handleCheckboxChange}
                                                    checked={isChecked(this.state.marketTypeOther)}
                                                />
                                            </span>
                                            <span className="checkbox-label-container">
                                                <label>Other</label>
                                            </span>
                                        </div>
                                    </CheckboxContainer>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-input-wrapper">
                        <div className="item-wrapper" id="businessStartDate" style={shouldHideElement('businessStartDate', merchantProfile.hide)}>
                            <Row>
                                <label className="label">
                                    Business Start Date
                                </label>
                            </Row>
                            <TextInput
                                id="businessStartDate"
                                type="text"
                                name="businessStartDate"
                                placeHolder="YYYY-MM-DD"
                                pattern="\d{4}-\d{2}-\d{2}"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur(isValidDate)}
                                value={dateTime(businessStartDate)}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('businessStartDate', merchantProfile.notRequired)}
                                valid={getIsValid('businessStartDate', invalidInputs)}
                                errorMessage={getErrorMessage('businessStartDate', invalidInputs)}
                                handleFocus={this.handleFocus}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('typesOfGoodsServices', this.state.currentFocused)} >Brief description of exactly what you will be processing credit cards for.</div>
                        <div className="item-wrapper" id="typesOfGoodsServices" style={shouldHideElement('typesOfGoodsServices', merchantProfile.hide)}>
                            <Row>
                                <label className="label">
                                    Type of Goods or Services Sold
                                </label>
                            </Row>
                            <TextInput
                                name="typesOfGoodsServices"
                                handleChange={this.handleShortTextChange(40)}
                                handleBlur={this.handleTextBlur()}
                                value={typesOfGoodsServices}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('typesOfGoodsServices', merchantProfile.notRequired)}
                                valid={getIsValid('typesOfGoodsServices', invalidInputs)}
                                errorMessage={getErrorMessage('typesOfGoodsServices', invalidInputs)}
                                handleFocus={this.handleFocus}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('federalTaxId', this.state.currentFocused)} >The Tax ID (or EIN) associated with your business. It should be 9 digits.</div>
                        <div className="item-wrapper" id="federalTaxId" style={shouldHideElement('federalTaxId', merchantProfile.hide)}>
                            <Row>
                                <label className="label">
                                    Federal Tax ID (EIN)
                                </label>
                            </Row>
                            <TextInput
                                type="text"
                                pattern="\d{9}"
                                name="federalTaxId"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={federalTaxId}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('federalTaxId', merchantProfile.notRequired)}
                                valid={this.state.isUserAnAgent ? true : getIsValid('federalTaxId', invalidInputs)}
                                errorMessage={getErrorMessage('federalTaxId', invalidInputs)}
                                handleFocus={this.handleFocus}
                                maxLength={9}
                            />
                        </div>
                    </div>
                </Group>
                <Group>
                    <div id="acceptCard" className="form-input-wrapper">
                        <div className="item-wrapper"  >
                            <CheckboxContainer name="acceptCard" isRequired={ this.state.isUserAnAgent ? false : shouldNotBeRequired('acceptCard', merchantProfile.notRequired)}>
                                <Row>
                                    <label className="label">
                                        Do you currently accept credit cards and can you provide processing statements?
                                    </label>
                                </Row>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="acceptCard"
                                            value="0"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(0, this.state.acceptCard)}
                                        />
                                        <label>No</label>
                                    </Row>
                                </div>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="acceptCard"
                                            value="1"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(1, this.state.acceptCard)}
                                        />
                                        <label>Yes</label>
                                    </Row>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                </Group>
                <Group>
                    <div id="additionalBenefitsOffered" className="form-input-wrapper">
                        <div className="item-wrapper"  >
                            <CheckboxContainer name="acceptCard" isRequired={ this.state.isUserAnAgent ? false : shouldNotBeRequired('additionalBenefitsOffered', merchantProfile.notRequired)}>
                                <Row>
                                    <label className="label">
                                        Do you offer warranties, dues, subscriptions, memberships, or extended services?
                                    </label>
                                </Row>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="additionalBenefitsOffered"
                                            value="0"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(0, this.state.additionalBenefitsOffered)}
                                        />
                                        <label>No</label>
                                    </Row>
                                </div>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="additionalBenefitsOffered"
                                            value="1"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(1, this.state.additionalBenefitsOffered)}
                                        />
                                        <label>Yes</label>
                                    </Row>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                </Group>
                <Group>
                    <div id="acceptPrepayment" className="form-input-wrapper">
                        <div className="item-wrapper"  >
                            <CheckboxContainer name="acceptCard" isRequired={ this.state.isUserAnAgent ? false : shouldNotBeRequired('acceptPrepayment', merchantProfile.notRequired)}>
                                <Row>
                                    <label className="label">
                                        Do you accept prepayment (or a deposit) for your goods/ services?
                                        <span className="optional-input">{shouldNotBeRequired('acceptPrepayment', merchantProfile.notRequired) ? '' : 'Optional'} </span>

                                    </label>
                                </Row>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="acceptPrepayment"
                                            value="0"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(0, this.state.acceptPrepayment)}
                                        />
                                        <label>No</label>
                                    </Row>
                                </div>
                                <div className="half-width-div">
                                    <Row>
                                        <input
                                            type="radio"
                                            name="acceptPrepayment"
                                            value="1"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(1, this.state.acceptPrepayment)}
                                        />
                                        <label>Yes</label>
                                    </Row>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                </Group>
                {is1099App || isDirectSalesApp ? (
                    <Group>
                        <div id="isDirectSales" className="form-input-wrapper">
                            <div className="item-wrapper"  >
                                <CheckboxContainer name="isDirectSales" isRequired={ this.state.isUserAnAgent ? false : shouldNotBeRequired('isDirectSales', merchantProfile.notRequired)}>
                                    <Row>
                                        <label className="label">
                                            Is your business a Direct Sales or Multi-level Marketing (MLM) business?
                                            <span className="optional-input">{shouldNotBeRequired('isDirectSales', merchantProfile.notRequired) ? '' : 'Optional'} </span>
                                        </label>
                                    </Row>
                                    <div className="half-width-div">
                                        <Row>
                                            <input
                                                type="radio"
                                                name="isDirectSales"
                                                value="0"
                                                onChange={this.handleRadioChange}
                                                checked={isRadioChecked(0, this.state.isDirectSales)}
                                            />
                                            <label>No</label>
                                        </Row>
                                    </div>
                                    <div className="half-width-div">
                                        <Row>
                                            <input
                                                type="radio"
                                                name="isDirectSales"
                                                value="1"
                                                onChange={this.handleRadioChange}
                                                checked={isRadioChecked(1, this.state.isDirectSales)}
                                            />
                                            <label>Yes</label>
                                        </Row>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                    </Group>
                ) : null }
            </Section>
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantProfile: getMerchantProfile(state),
    isUserAnAgent: getIsUserAnAgent(state),
    is1099App: getIs1099App(state),
    isDirectSalesApp: getIsDirectSalesApp(state)
});

export default connect(mapStateToProps)(MerchantProfile);
