import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { KNOW_YOUR_CUSTOMER } from '../../../../actions/sectionsActions';
import { getIsUserAnAgent } from '../../../../reducers/applicationReducer';
import { getKnowYourCustomer } from '../../../../reducers/sectionsReducer';
import { getErrorMessage, getIsValid, isRadioChecked, shouldNotBeRequired } from '../../../../utils/form';
import scrollBehavior from '../../../../utils/scrollBehavior';
import CheckboxContainer from '../../../Shared/CheckboxContainer/CheckboxContainer';

import Row from '../../../Shared/Row/Row';
import Section from '../../../Shared/Section/Section';
import TextInput from '../../../Shared/TextInput/TextInput';
import BaseSection from '../Sections/BaseSection';
import Select from '../../../Shared/Select/Select';
import Group from '../../../Shared/Group/Group';

export class KycPart2 extends BaseSection {
    sectionName = KNOW_YOUR_CUSTOMER;

    static propTypes = {
        invalidInputs: PropTypes.any,
        isUserAnAgent: PropTypes.bool,
        runValidation: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            doesMonitorConsumerBoards: props.kyc.doesMonitorConsumerBoards,
            hasPeakPaymentSeason: props.kyc.hasPeakPaymentSeason,
            peakPaymentSeasonTimes: props.kyc.peakPaymentSeasonTimes,
            doesRecurringBilling: props.kyc.doesRecurringBilling,
            recurringBillingInfo: props.kyc.recurringBillingInfo,
            timeFromChargeToShipment: props.kyc.timeFromChargeToShipment,
            doesOfferTrial: props.kyc.doesOfferTrial,
            trialOfferInfo: props.kyc.trialOfferInfo,
            ccCollectionForMotoOrders: props.kyc.ccCollectionForMotoOrders,
            whoHandlesRefunds: props.kyc.whoHandlesRefunds,
            hasRestockingFeesOnRefunds: props.kyc.hasRestockingFeesOnRefunds,
            whoHandlesProductFulfillment: props.kyc.whoHandlesProductFulfillment,
            doesRequireOngoingDistributorPurchases: props.kyc.doesRequireOngoingDistributorPurchases,
            doesProvideDistributorTraining: props.kyc.doesProvideDistributorTraining,
            canPurchaseProductFromPhysicalLocation: props.kyc.canPurchaseProductFromPhysicalLocation,
            lengthOfDistributorContract: props.kyc.lengthOfDistributorContract,
            howIsDistributorPaperworkProvided: props.kyc.howIsDistributorPaperworkProvided,
            isUserAnAgent: props.isUserAnAgent
        };
    }

    componentDidMount() {
        const application = document.getElementById('merchant-application');
        application.scrollIntoView(scrollBehavior());
    }

    render() {
        const { kyc, invalidInputs } = this.props;

        return (
            <Section heading="Operational">
                <Group>
                    <div className="form-input-wrapper" id="doesMonitorConsumerBoards">
                        <div className="item-wrapper">
                            <Row>
                                <label>Do you monitor consumer complaint boards, Better Business Bureau, and CFPB, and respond to complaints?</label>
                            </Row>
                            <CheckboxContainer name="doesMonitorConsumerBoards" isRequired={true}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesMonitorConsumerBoards"
                                        onChange={this.handleBooleanRadioChange}
                                        value={false}
                                        checked={isRadioChecked(false, this.state.doesMonitorConsumerBoards)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesMonitorConsumerBoards"
                                        onChange={this.handleBooleanRadioChange}
                                        value={true}
                                        checked={isRadioChecked(true, this.state.doesMonitorConsumerBoards)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>

                    <div className="form-input-wrapper" id="hasPeakPaymentSeason">
                        <div className="item-wrapper">
                            <Row>
                                <label>Do you have a peak payment season or period?</label>
                            </Row>
                            <CheckboxContainer name="hasPeakPaymentSeason" isRequired={true}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="hasPeakPaymentSeason"
                                        onChange={this.handleBooleanRadioChange}
                                        value={false}
                                        checked={isRadioChecked(false, this.state.hasPeakPaymentSeason)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="hasPeakPaymentSeason"
                                        onChange={this.handleBooleanRadioChange}
                                        value={true}
                                        checked={isRadioChecked(true, this.state.hasPeakPaymentSeason)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    { this.state.hasPeakPaymentSeason ?
                        <div className="form-input-wrapper" id="peakPaymentSeasonTimes">
                            <div className="item-wrapper">
                                <Row>
                                    <label>Explain the peak time(s).</label>
                                </Row>
                                <TextInput
                                    name="peakPaymentSeasonTimes"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={this.state.peakPaymentSeasonTimes}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('peakPaymentSeasonTimes', kyc.notRequired)}
                                    valid={getIsValid('peakPaymentSeasonTimes', invalidInputs)}
                                    errorMessage={getErrorMessage('peakPaymentSeasonTimes', invalidInputs)}/>
                            </div>
                        </div> : null
                    }
                    <div className="form-input-wrapper" id="doesRecurringBilling">
                        <div className="item-wrapper">
                            <Row>
                                <label>Do you offer recurring billing on any of your products/services?</label>
                            </Row>
                            <CheckboxContainer name="doesRecurringBilling" isRequired={true}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesRecurringBilling"
                                        onChange={this.handleBooleanRadioChange}
                                        value={false}
                                        checked={isRadioChecked(false, this.state.doesRecurringBilling)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesRecurringBilling"
                                        onChange={this.handleBooleanRadioChange}
                                        value={true}
                                        checked={isRadioChecked(true, this.state.doesRecurringBilling)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    { this.state.doesRecurringBilling ?
                        <div className="form-input-wrapper" id="recurringBillingInfo">
                            <div className="item-wrapper">
                                <Row>
                                    <label>How often is the customer charged and is the recurring billing auto-renewed at the year mark?</label>
                                </Row>
                                <TextInput
                                    name="recurringBillingInfo"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={this.state.recurringBillingInfo}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('recurringBillingInfo', kyc.notRequired)}
                                    valid={getIsValid('recurringBillingInfo', invalidInputs)}
                                    errorMessage={getErrorMessage('recurringBillingInfo', invalidInputs)}
                                />
                            </div>
                        </div> : null
                    }
                    <div className="form-input-wrapper" id="timeFromChargeToShipment">
                        <div className="item-wrapper">
                            <Row>
                                <label>How far in advance will charges be made prior to shipment or fulfillment?</label>
                            </Row>
                            <TextInput
                                name="timeFromChargeToShipment"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={this.state.timeFromChargeToShipment}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('timeFromChargeToShipment', kyc.notRequired)}
                                valid={getIsValid('timeFromChargeToShipment', invalidInputs)}
                                errorMessage={getErrorMessage('timeFromChargeToShipment', invalidInputs)}/>
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="doesOfferTrial">
                        <div className="item-wrapper">
                            <Row>
                                <label>Is there a trial offer attached to the product/service?</label>
                            </Row>
                            <CheckboxContainer name="doesOfferTrial" isRequired={true}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesOfferTrial"
                                        onChange={this.handleBooleanRadioChange}
                                        value={false}
                                        checked={isRadioChecked(false, this.state.doesOfferTrial)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesOfferTrial"
                                        onChange={this.handleBooleanRadioChange}
                                        value={true}
                                        checked={isRadioChecked(true, this.state.doesOfferTrial)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    { this.state.doesOfferTrial ?
                        <div className="form-input-wrapper" id="trialOfferInfo">
                            <div className="item-wrapper">
                                <Row>
                                    <label>What are the details of the trial offer?</label>
                                </Row>
                                <TextInput
                                    name="trialOfferInfo"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={this.state.trialOfferInfo}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('trialOfferInfo', kyc.notRequired)}
                                    valid={getIsValid('trialOfferInfo', invalidInputs)}
                                    errorMessage={getErrorMessage('trialOfferInfo', invalidInputs)}
                                />
                            </div>
                        </div> : null
                    }
                    <div className="form-input-wrapper" id="ccCollectionForMotoOrders">
                        <div className="item-wrapper">
                            <Row>
                                <label>For mail/telephone orders, who collects the credit card information and runs the transaction?</label>
                            </Row>
                            <TextInput
                                name="ccCollectionForMotoOrders"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={this.state.ccCollectionForMotoOrders}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('ccCollectionForMotoOrders', kyc.notRequired)}
                                valid={getIsValid('ccCollectionForMotoOrders', invalidInputs)}
                                errorMessage={getErrorMessage('ccCollectionForMotoOrders', invalidInputs)}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="whoHandlesRefunds">
                        <div className="item-wrapper">
                            <Row>
                                <label>Who is responsible for issuing refunds?</label>
                            </Row>
                            <TextInput
                                name="whoHandlesRefunds"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={this.state.whoHandlesRefunds}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('whoHandlesRefunds', kyc.notRequired)}
                                valid={getIsValid('whoHandlesRefunds', invalidInputs)}
                                errorMessage={getErrorMessage('whoHandlesRefunds', invalidInputs)}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="hasRestockingFeesOnRefunds">
                        <div className="item-wrapper">
                            <Row>
                                <label>Do you charge restocking fees on refunds?</label>
                            </Row>
                            <CheckboxContainer name="hasRestockingFeesOnRefunds" isRequired={true}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="hasRestockingFeesOnRefunds"
                                        onChange={this.handleBooleanRadioChange}
                                        value={false}
                                        checked={isRadioChecked(false, this.state.hasRestockingFeesOnRefunds)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="hasRestockingFeesOnRefunds"
                                        onChange={this.handleBooleanRadioChange}
                                        value={true}
                                        checked={isRadioChecked(true, this.state.hasRestockingFeesOnRefunds)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="whoHandlesProductFulfillment">
                        <div className="item-wrapper">
                            <div>
                                <label>Who manages product fulfillment?</label>
                            </div>
                            <Select
                                name="whoHandlesProductFulfillment"
                                handleChange={this.handleSelectChange}
                                value={ this.state.whoHandlesProductFulfillment }
                                options={[
                                    { value: 'Distributors buy and resell the product.', label: 'Distributors buy and resell the product.' },
                                    { value: 'The company manages the product fulfillment.', label: 'The company manages the product fulfillment.' }
                                ]}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('whoHandlesProductFulfillment', kyc.notRequired)}
                                valid={getIsValid('whoHandlesProductFulfillment', invalidInputs)}
                                errorMessage={getErrorMessage('whoHandlesProductFulfillment', invalidInputs)}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="doesRequireOngoingDistributorPurchases">
                        <div className="item-wrapper">
                            <Row>
                                <label>Are distributors required to make an initial or ongoing purchase of inventory?</label>
                            </Row>
                            <CheckboxContainer name="doesRequireOngoingDistributorPurchases" isRequired={true}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesRequireOngoingDistributorPurchases"
                                        onChange={this.handleBooleanRadioChange}
                                        value={false}
                                        checked={isRadioChecked(false, this.state.doesRequireOngoingDistributorPurchases)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesRequireOngoingDistributorPurchases"
                                        onChange={this.handleBooleanRadioChange}
                                        value={true}
                                        checked={isRadioChecked(true, this.state.doesRequireOngoingDistributorPurchases)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="doesProvideDistributorTraining">
                        <div className="item-wrapper">
                            <Row>
                                <label>Is training offered before a distributor can sell the products/services?</label>
                            </Row>
                            <CheckboxContainer name="doesProvideDistributorTraining" isRequired={true}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesProvideDistributorTraining"
                                        onChange={this.handleBooleanRadioChange}
                                        value={false}
                                        checked={isRadioChecked(false, this.state.doesProvideDistributorTraining)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="doesProvideDistributorTraining"
                                        onChange={this.handleBooleanRadioChange}
                                        value={true}
                                        checked={isRadioChecked(true, this.state.doesProvideDistributorTraining)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="canPurchaseProductFromPhysicalLocation">
                        <div className="item-wrapper">
                            <Row>
                                <label>Are there physical locations for distributors to buy products?</label>
                            </Row>
                            <CheckboxContainer name="canPurchaseProductFromPhysicalLocation" isRequired={true}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="canPurchaseProductFromPhysicalLocation"
                                        onChange={this.handleBooleanRadioChange}
                                        value={false}
                                        checked={isRadioChecked(false, this.state.canPurchaseProductFromPhysicalLocation)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="canPurchaseProductFromPhysicalLocation"
                                        onChange={this.handleBooleanRadioChange}
                                        value={true}
                                        checked={isRadioChecked(true, this.state.canPurchaseProductFromPhysicalLocation)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="lengthOfDistributorContract" >
                        <div className="item-wrapper">
                            <Row>
                                <label>What is the length of a distributor contract?</label>
                            </Row>
                            <TextInput
                                name="lengthOfDistributorContract"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={this.state.lengthOfDistributorContract}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('lengthOfDistributorContract', kyc.notRequired)}
                                valid={getIsValid('lengthOfDistributorContract', invalidInputs)}
                                errorMessage={getErrorMessage('lengthOfDistributorContract', invalidInputs)}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="howIsDistributorPaperworkProvided">
                        <div className="item-wrapper">
                            <Row>
                                <label>How do you provide the following to your distributors: compensation structure/plan, distributor agreement, and policies & procedures?</label>
                            </Row>
                            <TextInput
                                name="howIsDistributorPaperworkProvided"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={this.state.howIsDistributorPaperworkProvided}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('howIsDistributorPaperworkProvided', kyc.notRequired)}
                                valid={getIsValid('howIsDistributorPaperworkProvided', invalidInputs)}
                                errorMessage={getErrorMessage('howIsDistributorPaperworkProvided', invalidInputs)}
                            />
                        </div>
                    </div>
                </Group>
            </Section>
        );
    }
}

export const mapStateToProps = (state) => ({
    kyc: getKnowYourCustomer(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(KycPart2);
