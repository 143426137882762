import config from '../config/config';

export const CREATE_PARTNER_APP_LOAD = 'CREATE_PARTNER_APP_LOAD';
export const CREATE_PARTNER_APP_SUCCESS = 'CREATE_PARTNER_APP_SUCCESS';
export const CREATE_PARTNER_APP_FAILURE = 'CREATE_PARTNER_APP_FAILURE';

export const createAppFromLandingPage = (guid) => {
    return {
        fetchParams: {
            body: {
                guid
            },
            method: 'POST',
            url: `${config.cforceServiceUrl}/partner/createAppFromLandingPage`
        },
        loadActionTypeOrCreator: CREATE_PARTNER_APP_LOAD,
        failureActionTypeOrCreator: CREATE_PARTNER_APP_FAILURE,
        successActionTypeOrCreator: CREATE_PARTNER_APP_SUCCESS
    };
};

