import React from 'react';
import { connect } from 'react-redux';

import './CardCompliance.scss';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import {
    conditionalShow,
    dateTime,
    getErrorMessage,
    getIsValid,
    isRadioChecked,
    isValidDate,
    shouldHideElement,
    shouldNotBeRequired,
    shouldShowHelp
} from '../../../../../utils/form';
import { CARD_COMPLIANCE } from '../../../../../actions/sectionsActions';
import CheckboxContainer from '../../../../Shared/CheckboxContainer/CheckboxContainer';
import _ from 'lodash';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';
import { getCardDataStorageComplianceInfo } from '../../../../../reducers/sectionsReducer';

export class CardCompliance extends BaseSection {
    sectionName = CARD_COMPLIANCE;

    constructor(props) {
        super(props);

        this.state = {
            otherServiceProviderName: props.cardCompliance.otherServiceProviderName,
            posServiceProvider: props.cardCompliance.posServiceProvider,
            selfAssessmentQuestionnaire: props.cardCompliance.selfAssessmentQuestionnaire,
            serviceProviderName: props.cardCompliance.serviceProviderName,
            softwareVersion: props.cardCompliance.softwareVersion,
            dateOfCompromise: props.cardCompliance.dateOfCompromise,
            dateOfCompliance: props.cardCompliance.dateOfCompliance,
            qualifiedSecurityAssessorName: props.cardCompliance.qualifiedSecurityAssessorName,
            dateOfLastScan: props.cardCompliance.dateOfLastScan,
            cardDataMerchantsLocationOnly: props.cardCompliance.cardDataMerchantsLocationOnly,
            cardDataOtherServiceProvider: props.cardCompliance.cardDataOtherServiceProvider,
            cardDataBothMerchantAndServiceProviders: props.cardCompliance.cardDataBothMerchantAndServiceProviders,
            cardDataMerchantHeadquarterCorpOfficeOnly: props.cardCompliance.cardDataMerchantHeadquarterCorpOfficeOnly,
            cardDataPrimaryServiceProvider: props.cardCompliance.cardDataPrimaryServiceProvider,
            cardDataAllApply: props.cardCompliance.cardDataAllApply,
            isUserAnAgent: props.isUserAnAgent,
            accDataCompromise: props.cardCompliance.accDataCompromise,
            pciDSSCompliance: props.cardCompliance.pciDSSCompliance,
            dialUpOrTTC: props.cardCompliance.dialUpOrTTC,
            dealWithFCN: props.cardCompliance.dealWithFCN,
            useOtherServiceProvider: props.cardCompliance.useOtherServiceProvider
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.cardCompliance, this.props.cardCompliance)) {
            this.setState({
                otherServiceProviderName: this.props.cardCompliance.otherServiceProviderName,
                posServiceProvider: this.props.cardCompliance.posServiceProvider,
                selfAssessmentQuestionnaire: this.props.cardCompliance.selfAssessmentQuestionnaire,
                serviceProviderName: this.props.cardCompliance.serviceProviderName,
                softwareVersion: this.props.cardCompliance.softwareVersion,
                dateOfCompromise: this.props.cardCompliance.dateOfCompromise,
                dateOfCompliance: this.props.cardCompliance.dateOfCompliance,
                qualifiedSecurityAssessorName: this.props.cardCompliance.qualifiedSecurityAssessorName,
                dateOfLastScan: this.props.cardCompliance.dateOfLastScan,
                cardDataMerchantsLocationOnly: this.props.cardCompliance.cardDataMerchantsLocationOnly,
                cardDataOtherServiceProvider: this.props.cardCompliance.cardDataOtherServiceProvider,
                cardDataBothMerchantAndServiceProviders: this.props.cardCompliance.cardDataBothMerchantAndServiceProviders,
                cardDataMerchantHeadquarterCorpOfficeOnly: this.props.cardCompliance.cardDataMerchantHeadquarterCorpOfficeOnly,
                cardDataPrimaryServiceProvider: this.props.cardCompliance.cardDataPrimaryServiceProvider,
                cardDataAllApply: this.props.cardCompliance.cardDataAllApply,
                isUserAnAgent: this.props.isUserAnAgent,
                accDataCompromise: this.props.cardCompliance.accDataCompromise,
                pciDSSCompliance: this.props.cardCompliance.pciDSSCompliance,
                dialUpOrTTC: this.props.cardCompliance.dialUpOrTTC,
                dealWithFCN: this.props.cardCompliance.dealWithFCN,
                useOtherServiceProvider: this.props.cardCompliance.useOtherServiceProvider
            });
        }
    }

    render() {
        const { cardCompliance, invalidInputs } = this.props;
        const {
            otherServiceProviderName, dateOfCompromise,
            dateOfCompliance, qualifiedSecurityAssessorName, dateOfLastScan, selfAssessmentQuestionnaire,
            cardDataMerchantsLocationOnly, cardDataOtherServiceProvider, cardDataBothMerchantAndServiceProviders,
            cardDataMerchantHeadquarterCorpOfficeOnly, cardDataPrimaryServiceProvider, cardDataAllApply
        } = this.state;

        if (cardCompliance.accDataCompromise === 0 && dateOfCompromise) {
            this.props.cardCompliance.dateOfCompromise = null;
        }

        if (cardCompliance.pciDSSCompliance === 0 && dateOfCompliance) {
            this.props.cardCompliance.dateOfCompliance = null;
        }

        if (cardCompliance.pciDSSCompliance === 0 && qualifiedSecurityAssessorName) {
            this.props.cardCompliance.qualifiedSecurityAssessorName = null;
        }

        if (cardCompliance.pciDSSCompliance === 0 && selfAssessmentQuestionnaire) {
            this.props.cardCompliance.selfAssessmentQuestionnaire = null;
        }

        if (cardCompliance.pciDSSCompliance === 0 && dateOfLastScan) {
            this.props.cardCompliance.dateOfLastScan = null;
        }

        if (cardCompliance.dealWithFCN === 0 && (cardDataMerchantsLocationOnly || cardDataOtherServiceProvider
            || cardDataBothMerchantAndServiceProviders || cardDataMerchantHeadquarterCorpOfficeOnly || cardDataPrimaryServiceProvider
            || cardDataAllApply)) {
            this.props.cardCompliance.cardDataMerchantsLocationOnly = null;
            this.props.cardCompliance.cardDataOtherServiceProvider = null;
            this.props.cardCompliance.cardDataBothMerchantAndServiceProviders = null;
            this.props.cardCompliance.cardDataMerchantHeadquarterCorpOfficeOnly = null;
            this.props.cardCompliance.cardDataPrimaryServiceProvider = null;
            this.props.cardCompliance.cardDataAllApply = null;
        }

        if (cardCompliance.useOtherServiceProvider === 0 && otherServiceProviderName) {
            this.props.cardCompliance.otherServiceProviderName = null;
        }

        return (
            <Section heading="Cardholder Compliance" className="card-holder-compliance">
                <h3>Card Holder Data Storage Compliance &amp; Service Provider</h3>
                <p> PCI DSS and card association rules prohibit storage of track data under any circumstances.
                    If you or your POS system pass, transmit, store or receive full cardholder data, then the POS
                    software must be PA DSS (Payment Application Data Security Standard) compliant or you (merchant)
                    must validate PCI DSS compliance. If you use a payment gateway, they must be PCI DSS compliant.
                </p>
                <Group>
                    <div className="form-input-wrapper" id="accDataCompromise">
                        <div className="item-wrapper">
                            <Row>
                                <div className="start">
                                    <label className="label">
                                        Have you ever experienced an Account Data Compromise (ADC)?
                                    </label>
                                </div>
                            </Row>
                            <CheckboxContainer name="accDataCompromise" isRequired={this.state.isUserAnAgent ? false : shouldNotBeRequired('accDataCompromise', cardCompliance.notRequired)}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="accDataCompromise"
                                        onChange={this.handleRadioChange}
                                        value="0"
                                        checked={isRadioChecked(0, this.state.accDataCompromise)}
                                        onFocus={this.handleFocus}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="accDataCompromise"
                                        onChange={this.handleRadioChange}
                                        value="1"
                                        checked={isRadioChecked(1, this.state.accDataCompromise)}
                                        onFocus={this.handleFocus}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    <div className="form-input-wrapper" style={conditionalShow(cardCompliance.accDataCompromise === 1)}>
                        <div className="item-wrapper">
                            <Row>
                                <div className="start">
                                    <label className="label">Date of Compromise:</label>
                                </div>
                            </Row>
                            <div>
                                <div id="dateOfCompromise" style={shouldHideElement('dateOfCompromise', cardCompliance.hide)}>
                                    <TextInput
                                        type="text"
                                        name="dateOfCompromise"
                                        placeHolder="YYYY-MM-DD"
                                        pattern="\d{4}-\d{2}-\d{2}"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur(isValidDate)}
                                        value={dateTime(dateOfCompromise)}
                                        required={!!(!this.state.isUserAnAgent && cardCompliance.accDataCompromise)}
                                        valid={getIsValid('dateOfCompromise', invalidInputs)}
                                        errorMessage={getErrorMessage('dateOfCompromise', invalidInputs)}
                                        handleFocus={this.handleFocus}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="pciDSSCompliance">
                        <div className="item-wrapper">
                            <Row>
                                <div className="start">
                                    <label className="label">
                                        Have you validated Payment Card Industry Data Security Standard (PCI DSS) compliance?
                                    </label>
                                </div>
                            </Row>
                            <CheckboxContainer name="pciDSSCompliance" isRequired={this.state.isUserAnAgent ? false : shouldNotBeRequired('pciDSSCompliance', cardCompliance.notRequired)}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="pciDSSCompliance"
                                        value="0"
                                        onChange={this.handleRadioChange}
                                        checked={isRadioChecked(0, this.state.pciDSSCompliance)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="pciDSSCompliance"
                                        value="1"
                                        onChange={this.handleRadioChange}
                                        checked={isRadioChecked(1, this.state.pciDSSCompliance)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    <div className="dof-wrapper" style={conditionalShow(cardCompliance.pciDSSCompliance === 1)}>
                        <div className="form-input-wrapper">
                            <div className="item-wrapper">
                                <div id="dateOfLastScan" style={shouldHideElement('dateOfLastScan', cardCompliance.hide)}>
                                    <Row>
                                        <div className="start">
                                            <label className="label">
                                                Date of last validation
                                            </label>
                                        </div>
                                    </Row>
                                    <TextInput
                                        type="text"
                                        name="dateOfLastScan"
                                        placeHolder="YYYY-MM-DD"
                                        pattern="\d{4}-\d{2}-\d{2}"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={dateTime(dateOfLastScan)}
                                        required={!!(!this.state.isUserAnAgent && cardCompliance.pciDSSCompliance)}
                                        valid={getIsValid('dateOfLastScan', invalidInputs)}
                                        errorMessage={getErrorMessage('dateOfLastScan', invalidInputs)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Group>
                <Group>
                    <div className="form-input-wrapper">
                        <div className="item-wrapper">
                            <Row>
                                <div className="start">
                                    <label className="label">
                                        Do you or your Service Provider(s) receive, pass, transmit or store the Full Cardholder Number (FCN)?
                                    </label>
                                </div>
                                <span data-toggle="tooltip" title="" data-original-title="Full Cardholder Number" />
                            </Row>
                            <CheckboxContainer name="dealWithFCN" isRequired={this.state.isUserAnAgent ? false : shouldNotBeRequired('dealWithFCN', cardCompliance.notRequired)}>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="dealWithFCN"
                                        value="0"
                                        onChange={this.handleRadioChange}
                                        checked={isRadioChecked(0, this.state.dealWithFCN)}
                                    />
                                    <label>No</label>
                                </div>
                                <div className="half-width-div">
                                    <input
                                        type="radio"
                                        name="dealWithFCN"
                                        value="1"
                                        onChange={this.handleRadioChange}
                                        checked={isRadioChecked(1, this.state.dealWithFCN)}
                                    />
                                    <label>Yes</label>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                </Group>
                <Group>
                    <div>
                        <div className="form-input-wrapper">
                            <div id="useOtherServiceProvider" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do your transactions process through a third-party service provider (i.e., web hosting companies, gateways, software, etc.)?
                                        </label>
                                    </div>
                                </Row>
                                <div className="arrow-box" style={shouldShowHelp('useOtherProvider', this.state.currentFocused)}> Web Hosting Companies, Gateways, Corporate Office </div>
                                <CheckboxContainer name="useOtherServiceProvider" isRequired={this.state.isUserAnAgent ? false : shouldNotBeRequired('useOtherServiceProvider', cardCompliance.notRequired)}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="useOtherServiceProvider"
                                            value="0"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(0, this.state.useOtherServiceProvider)}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="useOtherServiceProvider"
                                            value="1"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(1, this.state.useOtherServiceProvider)}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(cardCompliance.useOtherServiceProvider === 1)}>
                            <div className="item-wrapper" id="otherServiceProviderName" style={shouldHideElement('otherServiceProviderName', cardCompliance.hide)}>
                                <Row>
                                    <div className="start">
                                        <label className="label">What is the name of the service provider(s)?</label>
                                    </div>
                                </Row>
                                <TextInput
                                    name="otherServiceProviderName"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={otherServiceProviderName}
                                    required={!!(!this.state.isUserAnAgent && cardCompliance.useOtherServiceProvider)}
                                    valid={getIsValid('otherServiceProviderName', invalidInputs)}
                                    errorMessage={getErrorMessage('otherServiceProviderName', invalidInputs)}
                                    placeHolder="Other Service Provider"
                                />
                            </div>
                        </div>
                    </div>
                </Group>
            </Section>
        );
    }
}

export const mapStateToProps = (state) => ({
    cardCompliance: getCardDataStorageComplianceInfo(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(CardCompliance);
