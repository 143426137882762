import { mappers } from '@nexio/cforce-config';

export const tabList = [
    { header: 'All' },
    { header: 'Incomplete' },
    { header: 'Sent' },
    { header: 'Filled Out' }
];

export const typesOfOwnership = [
    { value: '1', label: 'Sole Proprietor' },
    { value: '2', label: 'Partnership' },
    { value: '3', label: 'LLC' },
    { value: '4', label: 'Professional Association' },
    { value: '5', label: 'Corporation' },
    { value: '6', label: 'Tax Exempt (501C)' }
];

export const pricingTypes = [
    { value: mappers.pricingTypes.err, label: 'ERR' },
    { value: mappers.pricingTypes.tiered, label: 'Tiered' },
    { value: mappers.pricingTypes.interchange, label: 'Interchange' }
];

export const optBlueOptions = [
    { value: mappers.optBlueOptions.none, label: 'None' },
    { value: mappers.optBlueOptions.err, label: 'ERR' },
    { value: mappers.optBlueOptions.tiered, label: 'Tiered' },
    { value: mappers.optBlueOptions.interchange, label: 'Interchange' }
];

export const cforceDepartments = mappers.cforceDepartments;

export const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
];

export const identificationTypes = [
    { value: 'Drv Lic', label: 'Drivers License' },
    { value: 'State ID', label: 'State ID' },
    { value: 'Passport', label: 'Passport' }
];

export const jobTitleTypes = [
    { value: 'CEO', label: 'CEO' },
    { value: 'CFO', label: 'CFO' },
    { value: 'Chairman', label: 'Chairman' },
    { value: 'Co-Owner', label: 'Co-Owner' },
    { value: 'Comptroller', label: 'Comptroller' },
    { value: 'Controller', label: 'Controller' },
    { value: 'Director', label: 'Director' },
    { value: 'General Manager', label: 'General Manager' },
    { value: 'Owner', label: 'Owner' },
    { value: 'Partner', label: 'Partner' },
    { value: 'President', label: 'President' },
    { value: 'Treasurer', label: 'Treasurer' },
    { value: 'Vice President', label: 'Vice President' }
];
