import React from 'react';
import { connect } from 'react-redux';

import Section from '../../../../Shared/Section/Section';
import BaseSection from '../BaseSection';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import { MERCHANT_PROFILE_ANALYSIS } from '../../../../../actions/sectionsActions';
import _ from 'lodash';
import {
    conditionalShow,
    getIsValid,
    isRadioChecked
} from '../../../../../utils/form';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';
import { getMPAInfo } from '../../../../../reducers/sectionsReducer';
import CheckboxContainer from '../../../../Shared/CheckboxContainer/CheckboxContainer';

import './MerchantProfileAnalysis.scss';

export class MerchantProfileAnalysis extends BaseSection {
    sectionName = MERCHANT_PROFILE_ANALYSIS;

    state = {
        productDetails: this.props.MPAInfo.productDetails,
        advertising: this.props.MPAInfo.advertising,
        inventoryStored: this.props.MPAInfo.inventoryStored,
        dropShipping: this.props.MPAInfo.dropShipping,
        chargingCustomerFor: this.props.MPAInfo.chargingCustomerFor,
        chargesAdvance: this.props.MPAInfo.chargesAdvance,
        explainBilling: this.props.MPAInfo.explainBilling,
        cancellationPolicy: this.props.MPAInfo.cancellationPolicy,
        refundPolicy: this.props.MPAInfo.refundPolicy,
        motoAccount: this.props.MPAInfo.motoAccount,
        customerRefund: this.props.MPAInfo.customerRefund,
        questionsContactNumber: this.props.MPAInfo.questionsContactNumber,
        isUserAnAgent: this.props.isUserAnAgent,
        creditCardPaymentSystem: this.props.MPAInfo.creditCardPaymentSystem,
        ownerNames: this.props.MPAInfo.ownerNames,
        travelInsurance: this.props.MPAInfo.travelInsurance,
        arcNumbers: this.props.MPAInfo.arcNumbers,
        thirdPartyVendors: this.props.MPAInfo.thirdPartyVendors,
        licensedStates: this.props.MPAInfo.licensedStates,
        travelServicesCompany: this.props.MPAInfo.travelServicesCompany,
        debtCollection: this.props.MPAInfo.debtCollection,
        debtIndustries: this.props.MPAInfo.debtIndustries,
        chargeCustomer: this.props.MPAInfo.chargeCustomer,
        attorneyOnStaff: this.props.MPAInfo.attorneyOnStaff,
        discloseLength: this.props.MPAInfo.discloseLength,
        debtConsolidation: this.props.MPAInfo.debtConsolidation,
        bankruptcyPaperwork: this.props.MPAInfo.bankruptcyPaperwork,
        discloseInterestRates: this.props.MPAInfo.discloseInterestRates,
        loanOriginated: this.props.MPAInfo.loanOriginated,
        filingPaperwork: this.props.MPAInfo.filingPaperwork,
        travelCompany: this.props.MPAInfo.travelCompany,
        offerCollectionServices: this.props.MPAInfo.offerCollectionServices,
        offerCreditRepairServices: this.props.MPAInfo.offerCreditRepairServices,
        offerLendingServices: this.props.MPAInfo.offerLendingServices,
        offerDocumentPreparationServices: this.props.MPAInfo.offerDocumentPreparationServices,
        version: this.props.MPAInfo.version,
        telemarketing: this.props.MPAInfo.telemarketing,
        nutraceuticalProducts: this.props.MPAInfo.nutraceuticalProducts,
        counselingPrograms: this.props.MPAInfo.counselingPrograms,
        telemarketingSalesRule: this.props.MPAInfo.telemarketingSalesRule
    };

    componentDidUpdate(prevProps, prevState, snapShot) {
        if (!_.isEqual(prevProps.MPAInfo, this.props.MPAInfo)) {
            this.setState({
                productDetails: this.props.MPAInfo.productDetails,
                advertising: this.props.MPAInfo.advertising,
                inventoryStored: this.props.MPAInfo.inventoryStored,
                dropShipping: this.props.MPAInfo.dropShipping,
                chargingCustomerFor: this.props.MPAInfo.chargingCustomerFor,
                chargesAdvance: this.props.MPAInfo.chargesAdvance,
                explainBilling: this.props.MPAInfo.explainBilling,
                cancellationPolicy: this.props.MPAInfo.cancellationPolicy,
                refundPolicy: this.props.MPAInfo.refundPolicy,
                motoAccount: this.props.MPAInfo.motoAccount,
                customerRefund: this.props.MPAInfo.customerRefund,
                questionsContactNumber: this.props.MPAInfo.questionsContactNumber,
                isUserAnAgent: this.props.isUserAnAgent,
                creditCardPaymentSystem: this.props.MPAInfo.creditCardPaymentSystem,
                ownerNames: this.props.MPAInfo.ownerNames,
                travelInsurance: this.props.MPAInfo.travelInsurance,
                arcNumbers: this.props.MPAInfo.arcNumbers,
                thirdPartyVendors: this.props.MPAInfo.thirdPartyVendors,
                licensedStates: this.props.MPAInfo.licensedStates,
                travelServicesCompany: this.props.MPAInfo.travelServicesCompany,
                debtCollection: this.props.MPAInfo.debtCollection,
                debtIndustries: this.props.MPAInfo.debtIndustries,
                chargeCustomer: this.props.MPAInfo.chargeCustomer,
                attorneyOnStaff: this.props.MPAInfo.attorneyOnStaff,
                discloseLength: this.props.MPAInfo.discloseLength,
                debtConsolidation: this.props.MPAInfo.debtConsolidation,
                bankruptcyPaperwork: this.props.MPAInfo.bankruptcyPaperwork,
                discloseInterestRates: this.props.MPAInfo.discloseInterestRates,
                loanOriginated: this.props.MPAInfo.loanOriginated,
                filingPaperwork: this.props.MPAInfo.filingPaperwork,
                travelCompany: this.props.MPAInfo.travelCompany,
                offerCollectionServices: this.props.MPAInfo.offerCollectionServices,
                offerCreditRepairServices: this.props.MPAInfo.offerCreditRepairServices,
                offerLendingServices: this.props.MPAInfo.offerLendingServices,
                offerDocumentPreparationServices: this.props.MPAInfo.offerDocumentPreparationServices,
                version: this.props.MPAInfo.version,
                telemarketing: this.props.MPAInfo.telemarketing,
                nutraceuticalProducts: this.props.MPAInfo.nutraceuticalProducts,
                counselingPrograms: this.props.MPAInfo.counselingPrograms,
                telemarketingSalesRule: this.props.MPAInfo.telemarketingSalesRule
            });
        }
    }

    render() {

        const { MPAInfo, invalidInputs } = this.props;

        const { productDetails, advertising, inventoryStored, chargesAdvance,
            explainBilling, cancellationPolicy, refundPolicy, questionsContactNumber,
            creditCardPaymentSystem, ownerNames, travelInsurance, arcNumbers,
            thirdPartyVendors, licensedStates, travelServicesCompany, debtCollection, debtIndustries,
            chargeCustomer, attorneyOnStaff, discloseLength, debtConsolidation, bankruptcyPaperwork, discloseInterestRates,
            loanOriginated, filingPaperwork, version, telemarketing, nutraceuticalProducts, counselingPrograms, telemarketingSalesRule } = this.state;

        if (MPAInfo.travelCompany === 0 && (travelInsurance || arcNumbers || thirdPartyVendors || licensedStates || travelServicesCompany)) {
            this.props.MPAInfo.travelInsurance = null;
            this.props.MPAInfo.arcNumbers = null;
            this.props.MPAInfo.thirdPartyVendors = null;
            this.props.MPAInfo.licensedStates = null;
            this.props.MPAInfo.travelServicesCompany = null;
        }

        if (MPAInfo.offerCollectionServices === 0 && (debtCollection || debtIndustries)) {
            this.props.MPAInfo.debtCollection = null;
            this.props.MPAInfo.debtIndustries = null;
        }

        if (MPAInfo.offerCreditRepairServices === 0 && (chargeCustomer || attorneyOnStaff || discloseLength || debtConsolidation || bankruptcyPaperwork || telemarketingSalesRule)) {
            this.props.MPAInfo.chargeCustomer = null;
            this.props.MPAInfo.attorneyOnStaff = null;
            this.props.MPAInfo.discloseLength = null;
            this.props.MPAInfo.debtConsolidation = null;
            this.props.MPAInfo.bankruptcyPaperwork = null;
            this.props.MPAInfo.telemarketingSalesRule = null;
        }

        if (MPAInfo.offerLendingServices === 0 && (discloseInterestRates || loanOriginated)) {
            this.props.MPAInfo.discloseInterestRates = null;
            this.props.MPAInfo.loanOriginated = null;
        }

        if (MPAInfo.offerDocumentPreparationServices === 0 && (filingPaperwork || counselingPrograms)) {
            this.props.MPAInfo.filingPaperwork = null;
            this.props.MPAInfo.counselingPrograms = null;
        }

        if (version === 3) {
            return (
                <Section heading="Merchant Profile Analysis">
                    <Group>
                        <div className="form-input-wrapper">
                            <div id="productDetails" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe in detail the products or services you are selling, including price points.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="productDetails"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={productDetails}
                                        valid={getIsValid('productDetails', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="advertising" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Explain how and where you advertise your products or services. Please supply examples of any
                                            brochures, catalogs, web pages, or any other item used for marketing. If not applicable, put N/A.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="advertising"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={advertising}
                                        valid={getIsValid('advertising', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="telemarketing" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you use telemarketing (outbound or inbound – please specify)? If not, describe how consumers enroll in the
                                            service(s) or purchase the product(s). Please supply all call scripts used with consumers by emailing them to your agent, if applicable.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="telemarketing"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={telemarketing}
                                        valid={getIsValid('telemarketing', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="inventoryStored" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe where inventory is stored and how you fill your orders, if applicable. Please provide the name, address, contact
                                            person, phone number, and email address for any fulfillment company (aka drop shipper).
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="inventoryStored"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={inventoryStored}
                                        valid={getIsValid('inventoryStored', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="nutraceuticalProducts" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            If you are selling nutraceutical products/ ingestible supplements, please provide the manufacturer’s name, address, and
                                            phone number.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="nutraceuticalProducts"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={nutraceuticalProducts}
                                        valid={getIsValid('nutraceuticalProducts', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="explainBilling" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            How often are customers billed (i.e., one-time fee or monthly)? Do you offer auto-ship enrollment?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="explainBilling"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={explainBilling}
                                        valid={getIsValid('explainBilling', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="chargesAdvance" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            How far in advance are charges/bookings/reservations/etc. made before fulfillment/shipping?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="chargesAdvance"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={chargesAdvance}
                                        valid={getIsValid('chargesAdvance', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="creditCardPaymentSystem" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            What system do you use to enter credit card payments for processing? (shopping cart, gateway, point-of-sale software, etc.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="creditCardPaymentSystem"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={creditCardPaymentSystem}
                                        valid={getIsValid('creditCardPaymentSystem', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="cancellationPolicy" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe in detail your cancellation policy and how you advise customers of this policy or email a copy of the policy to your agent.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="cancellationPolicy"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={cancellationPolicy}
                                        valid={getIsValid('cancellationPolicy', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="refundPolicy" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe in detail your refund policy and how long customers are eligible to receive a refund or email a copy of the policy to your agent.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="refundPolicy"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={refundPolicy}
                                        valid={getIsValid('refundPolicy', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="ownerNames" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Please list any individual who owns, directly or indirectly, 25% or more of the equity interests of the legal entity.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="ownerNames"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={ownerNames}
                                        valid={getIsValid('ownerNames', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-input-wrapper" id="travelCompany">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Are you a travel company? (Including Timeshare Related Businesses)
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="travelCompany" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="travelCompany"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.travelCompany)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="travelCompany"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.travelCompany)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer travel insurance? If so, who is the insurance provider?
                                            (Please email a copy of the contract with the insurance provider to your agent.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="travelInsurance">
                                        <TextInput
                                            name="travelInsurance"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={travelInsurance}
                                            valid={getIsValid('travelInsurance', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Please provide ARC/ASTA/IATAN/CLIA/USTOA numbers, if applicable.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="arcNumbers">
                                        <TextInput
                                            name="arcNumbers"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={arcNumbers}
                                            valid={getIsValid('arcNumbers', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you use any additional third-party vendors that result in additional fees? (resort fees, attorneys, etc.) How are these fees disclosed?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="thirdPartyVendors">
                                        <TextInput
                                            name="thirdPartyVendors"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={thirdPartyVendors}
                                            valid={getIsValid('thirdPartyVendors', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            In what states are you licensed to sell travel services?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="licensedStates">
                                        <TextInput
                                            name="licensedStates"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={licensedStates}
                                            valid={getIsValid('licensedStates', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            What is the name of the company that fulfills travel services?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="travelServicesCompany">
                                        <TextInput
                                            name="travelServicesCompany"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={travelServicesCompany}
                                            valid={getIsValid('travelServicesCompany', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="offerCollectionServices">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer collections services?
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="offerCollectionServices" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerCollectionServices"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.offerCollectionServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerCollectionServices"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.offerCollectionServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCollectionServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            What type of debt do you collect? (first party, third party, etc.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="debtCollection">
                                        <TextInput
                                            name="debtCollection"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={debtCollection}
                                            valid={getIsValid('debtCollection', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCollectionServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCollectionServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            From what industries do you collect? (credit card, medical, etc.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="debtIndustries">
                                        <TextInput
                                            name="debtIndustries"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={debtIndustries}
                                            valid={getIsValid('debtIndustries', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCollectionServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="offerCreditRepairServices">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer credit repair services?
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="offerCreditRepairServices" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerCreditRepairServices"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.offerCreditRepairServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerCreditRepairServices"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.offerCreditRepairServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe all fees charged to customers; the purpose of each fee, when that fee is assessed, and why that fee is
                                            permissible under the Credit Repair Organizations Act (CROA).
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="feesCharged">
                                        <TextInput
                                            name="chargeCustomer"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={chargeCustomer}
                                            valid={getIsValid('chargeCustomer', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            If you use telemarking (outbound or inbound), describe your telemarketing practices and affirm that you
                                            comply with the Telemarketing Sales Rule (TSR).
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="telemarketingSalesRule">
                                        <TextInput
                                            name="telemarketingSalesRule"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={telemarketingSalesRule}
                                            valid={getIsValid('telemarketingSalesRule', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Please email a copy of each unique consumer-facing contract or agreement currently in use to your agent, including
                                            attachments, addenda, or exhibits.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="discloseLength">
                                        <TextInput
                                            name="discloseLength"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={discloseLength}
                                            valid={getIsValid('discloseLength', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you have an attorney on staff?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="attorneyOnStaff">
                                        <TextInput
                                            name="attorneyOnStaff"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={attorneyOnStaff}
                                            valid={getIsValid('attorneyOnStaff', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="offerLendingServices">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer lending services?
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="offerLendingServices" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerLendingServices"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.offerLendingServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerLendingServices"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.offerLendingServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerLendingServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            How do you disclose interest rates? (displayed in-store, etc.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="discloseInterestRates">
                                        <TextInput
                                            name="discloseInterestRates"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={discloseInterestRates}
                                            valid={getIsValid('discloseInterestRates', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerLendingServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerLendingServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Where is the loan originated (on-line or in-person)?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="loanOriginated">
                                        <TextInput
                                            name="loanOriginated"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={loanOriginated}
                                            valid={getIsValid('loanOriginated', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerLendingServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="offerDocumentPreparationServices">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer document preparation services or financial consulting?
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="offerDocumentPreparationServices" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerDocumentPreparationServices"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.offerDocumentPreparationServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerDocumentPreparationServices"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.offerDocumentPreparationServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerDocumentPreparationServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Are you filing paperwork on the consumer’s behalf to consolidate debt?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="filingPaperwork">
                                        <TextInput
                                            name="filingPaperwork"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={filingPaperwork}
                                            valid={getIsValid('filingPaperwork', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerDocumentPreparationServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerDocumentPreparationServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe any debt relief, repayment, restructure, modification, or counseling programs you offer or assist the
                                            consumer in applying on their behalf.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="counselingPrograms">
                                        <TextInput
                                            name="counselingPrograms"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={counselingPrograms}
                                            valid={getIsValid('counselingPrograms', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerDocumentPreparationServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="questionsContactNumber" className="item-wrapper">
                                <Row>
                                    <div>
                                        <label className="label">
                                            Should we have any questions on any of your answers, what number should we use to contact you?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        type="text"
                                        name="questionsContactNumber"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={questionsContactNumber}
                                        valid={getIsValid('questionsContactNumber', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                    </Group>
                </Section >
            );
        } else {
            return (
                <Section heading="Merchant Profile Analysis">
                    <Group>
                        <div className="form-input-wrapper">
                            <div id="productDetails" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe in detail the products or services you are selling, including price points.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="productDetails"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={productDetails}
                                        valid={getIsValid('productDetails', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="advertising" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Explain how and where you advertise your products or services. Please supply examples of any
                                            brochures, catalogs, web pages, or any other item used for marketing. If not applicable, put N/A.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="advertising"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={advertising}
                                        valid={getIsValid('advertising', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="inventoryStored" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe where inventory is stored and how you fill your orders, if applicable. Please provide name,
                                            address, and phone number for manufacturer, fulfillment company, and/or drop-shipper.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="inventoryStored"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={inventoryStored}
                                        valid={getIsValid('inventoryStored', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="explainBilling" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            How often are customers billed (i.e., one-time fee or monthly)? Do you offer auto-ship enrollment?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="explainBilling"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={explainBilling}
                                        valid={getIsValid('explainBilling', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="chargesAdvance" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            How far in advance are charges/bookings/reservations/etc. made before fulfillment/shipping?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="chargesAdvance"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={chargesAdvance}
                                        valid={getIsValid('chargesAdvance', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="creditCardPaymentSystem" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            What system do you use to enter credit card payments for processing? (shopping cart, gateway, point-of-sale software, etc.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="creditCardPaymentSystem"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={creditCardPaymentSystem}
                                        valid={getIsValid('creditCardPaymentSystem', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="cancellationPolicy" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe in detail your cancellation policy and how you advise customers of this policy or email a copy of the policy to your agent.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="cancellationPolicy"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={cancellationPolicy}
                                        valid={getIsValid('cancellationPolicy', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="refundPolicy" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Describe in detail your refund policy and how long customers are eligible to receive a refund or email a copy of the policy to your agent.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="refundPolicy"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={refundPolicy}
                                        valid={getIsValid('refundPolicy', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="ownerNames" className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Please list any individual who owns, directly or indirectly, 25% or more of the equity interests of the legal entity.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        name="ownerNames"
                                        type="text"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={ownerNames}
                                        valid={getIsValid('ownerNames', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-input-wrapper" id="travelCompany">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Are you a travel company? (Including Timeshare Related Businesses)
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="travelCompany" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="travelCompany"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.travelCompany)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="travelCompany"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.travelCompany)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer travel insurance? If so, who is the insurance provider?
                                            (Please email a copy of the contract with the insurance provider to your agent.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="travelInsurance">
                                        <TextInput
                                            name="travelInsurance"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={travelInsurance}
                                            valid={getIsValid('travelInsurance', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Please provide ARC/ASTA/IATAN/CLIA/USTOA numbers, if applicable.
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="arcNumbers">
                                        <TextInput
                                            name="arcNumbers"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={arcNumbers}
                                            valid={getIsValid('arcNumbers', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you use any additional third-party vendors that result in additional fees? (resort fees, attorneys, etc.) How are these fees disclosed?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="thirdPartyVendors">
                                        <TextInput
                                            name="thirdPartyVendors"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={thirdPartyVendors}
                                            valid={getIsValid('thirdPartyVendors', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            In what states are you licensed to sell travel services?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="licensedStates">
                                        <TextInput
                                            name="licensedStates"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={licensedStates}
                                            valid={getIsValid('licensedStates', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.travelCompany === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            What is the name of the company that fulfills travel services?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="travelServicesCompany">
                                        <TextInput
                                            name="travelServicesCompany"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={travelServicesCompany}
                                            valid={getIsValid('travelServicesCompany', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.travelCompany === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="offerCollectionServices">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer collections services?
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="offerCollectionServices" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerCollectionServices"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.offerCollectionServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerCollectionServices"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.offerCollectionServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCollectionServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            What type of debt do you collect? (first party, third party, etc.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="debtCollection">
                                        <TextInput
                                            name="debtCollection"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={debtCollection}
                                            valid={getIsValid('debtCollection', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCollectionServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCollectionServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            From what industries do you collect? (credit card, medical, etc.)
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="debtIndustries">
                                        <TextInput
                                            name="debtIndustries"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={debtIndustries}
                                            valid={getIsValid('debtIndustries', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCollectionServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="offerCreditRepairServices">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer credit repair services?
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="offerCreditRepairServices" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerCreditRepairServices"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.offerCreditRepairServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerCreditRepairServices"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.offerCreditRepairServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you charge the customer before or after the completion of the service?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="chargeCustomer">
                                        <TextInput
                                            name="chargeCustomer"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={chargeCustomer}
                                            valid={getIsValid('chargeCustomer', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you have an attorney on staff?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="attorneyOnStaff">
                                        <TextInput
                                            name="attorneyOnStaff"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={attorneyOnStaff}
                                            valid={getIsValid('attorneyOnStaff', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            How do you disclose the length of time required for credit to be repaired to the customer?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="discloseLength">
                                        <TextInput
                                            name="discloseLength"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={discloseLength}
                                            valid={getIsValid('discloseLength', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer debt consolidation?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="debtConsolidation">
                                        <TextInput
                                            name="debtConsolidation"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={debtConsolidation}
                                            valid={getIsValid('debtConsolidation', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerCreditRepairServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you file bankruptcy paperwork on the customer’s behalf?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="bankruptcyPaperwork">
                                        <TextInput
                                            name="bankruptcyPaperwork"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={bankruptcyPaperwork}
                                            valid={getIsValid('bankruptcyPaperwork', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerCreditRepairServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="offerLendingServices">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer lending services?
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="offerLendingServices" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerLendingServices"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.offerLendingServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerLendingServices"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.offerLendingServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerLendingServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            How do you disclose interest rates? Is it displayed in-store?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="discloseInterestRates">
                                        <TextInput
                                            name="discloseInterestRates"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={discloseInterestRates}
                                            valid={getIsValid('discloseInterestRates', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerLendingServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerLendingServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Where is the loan originated (on-line or in-person)?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="loanOriginated">
                                        <TextInput
                                            name="loanOriginated"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={loanOriginated}
                                            valid={getIsValid('loanOriginated', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerLendingServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="offerDocumentPreparationServices">
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Do you offer document preparation services?
                                        </label>
                                    </div>
                                </Row>
                                <CheckboxContainer name="offerDocumentPreparationServices" isRequired={!this.state.isUserAnAgent}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerDocumentPreparationServices"
                                            onChange={this.handleRadioChange}
                                            value="0"
                                            checked={isRadioChecked(0, this.state.offerDocumentPreparationServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="offerDocumentPreparationServices"
                                            onChange={this.handleRadioChange}
                                            value="1"
                                            checked={isRadioChecked(1, this.state.offerDocumentPreparationServices)}
                                            handleFocus={this.handleFocus}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" style={conditionalShow(this.state.offerDocumentPreparationServices === 1)}>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Are you filing paperwork on the customer’s behalf for debt consolidation/debt relief programs?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <div id="filingPaperwork">
                                        <TextInput
                                            name="filingPaperwork"
                                            type="text"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={filingPaperwork}
                                            valid={getIsValid('filingPaperwork', invalidInputs)}
                                            required={!this.state.isUserAnAgent && this.state.offerDocumentPreparationServices === 1}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div id="questionsContactNumber" className="item-wrapper">
                                <Row>
                                    <div>
                                        <label className="label">
                                            Should we have any questions on any of your answers, what number should we use to contact you?
                                        </label>
                                    </div>
                                </Row>
                                <div>
                                    <TextInput
                                        type="text"
                                        name="questionsContactNumber"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={questionsContactNumber}
                                        valid={getIsValid('questionsContactNumber', invalidInputs)}
                                        required={!this.state.isUserAnAgent}
                                    />
                                </div>
                            </div>
                        </div>
                    </Group>
                </Section >
            );
        }
    }
}

export const mapStateToProps = (state) => ({
    MPAInfo: getMPAInfo(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(MerchantProfileAnalysis);
