import React from 'react';
import {
    Route,
    Switch
} from 'react-router-dom';

import OnlineApp from '../OnlineApp/AppList/AppList';
import docusignCB from '../OnlineApp/Application/Sections/DocusignCB/DocusignCB';
import MerchantApplication from '../OnlineApp/Application/MerchantApplicationWrapper';
import MerchantApplicationLandingPage from '../OnlineApp/Application/MerchantApplicationLandingPage';
import NextSigner from  '../OnlineApp/Application/Sections/NextSigner/NextSigner';

const Routes = () => (
    <Switch>
        <Route exact={true} path="/" component={OnlineApp} />
        <Route exact={true} path="/docusignCB/:merchantAppId" component={docusignCB} />
        <Route exact={true} path="/nextSigner" component={NextSigner} />
        <Route exact={true} path="/agentSigner" component={NextSigner} />
        <Route exact={true} path="/underWriterSigner" component={NextSigner} />
        <Route
            path="/par/:partner/app/:merchant/bankinfo"
            render={(props) => <MerchantApplication {...props} redirectToBankInfo />}
        />
        <Route path="/par/:partner/app/:merchant/" component={MerchantApplication} />
        <Route path="/par/:guid" component={MerchantApplicationLandingPage} />
    </Switch>
);

export default Routes;
