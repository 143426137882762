import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import './Modal.scss';

class Modal extends Component {

    state = {
        closeType: ''
    };

    static propTypes = {
        title: PropTypes.string,
        isOpen: PropTypes.bool,
        handleClose: PropTypes.func,
        modalStyle: PropTypes.shape({ top: PropTypes.string }),
        children: PropTypes.any,
        className: PropTypes.string
    };

    static defaultProps = {
        title: '',
        isOpen: false,
        handleClose: () => {},
        modalStyle: {
            top: '2px',
            bottom: 'auto',
            left: 'auto',
            right: 'auto'
        }
    };

    constructor(props) {
        super(props);
        if (process.env.NODE_ENV !== 'test') {
            ReactModal.setAppElement('#root');
        }
    }

    render() {
        const { title, children, isOpen, handleClose, modalStyle, className } = this.props;

        return (
            <ReactModal
                className={className}
                isOpen={isOpen}
                onRequestClose={handleClose}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={400}
                style={{ content: modalStyle }}
            >
                <div className="modal-title">
                    <span>{title}</span>
                    <div className="modal-close">
                        <span className="icon-close" onClick={handleClose} />
                    </div>
                </div>
                <div className="modal-body">
                    {children}
                </div>
            </ReactModal>
        );
    }
}

export default Modal;
