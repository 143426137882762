import React from 'react';
import _ from 'lodash';
import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import { BANK_DATA } from '../../../../../actions/sectionsActions';
import './BankData.scss';
import { validateBankData } from '../DocusignCB/docusignCBActions';
import { getBankData, getBusinessInfo } from '../../../../../reducers/sectionsReducer';
import { connect } from 'react-redux';
import { getIsPostBankDataLoading, getIsValidateBankDataLoading } from '../DocusignCB/docusignCBReducer';
import {
    getIsValid,
    shouldShowHelp
} from '../../../../../utils/form';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';
import {
    EmvioLoadingSpinner as LoadingSpinner
} from '@nexio/emvio-util-ui';

const invalidGiactResponseCodes = [-1, 0, 1, 2, 20, null];

export class BankData extends BaseSection {
    sectionName = BANK_DATA;

    constructor(props) {
        super(props);

        this.state = {
            accountNumber: props.bankData.accountNumber || '',
            routingNumber: props.bankData.routingNumber || '',
            routingNumberValidated: '',
            repeatAccountNumber: props.bankData.accountNumber || null,
            disableButton: true,
            errorMessage: '',
            bankInfoValidated: false,
            currentFocused: 'fred'
        };
    }

    componentDidMount() {
        if (!_.includes(invalidGiactResponseCodes, this.props.bankData.giactResponseCode)) {
            if (!this.disableButton(this.state.accountNumber, this.state.repeatAccountNumber, this.state.routingNumber)) {
                this.setState({
                    bankInfoValidated: true,
                    routingNumberValidated: this.state.routingNumber
                });
            }
        }
    }

    onClick = async () => {
        this.setState({
            errorMessage: ''
        });

        const validBankInfo = await this.validateViaGiact();
        this.saveAndCheckDone('giactResponseCode', validBankInfo.AccountResponseCode);
        if (!validBankInfo.valid) {
            this.setState({
                errorMessage: validBankInfo.message
            });

        } else {
            this.setState({
                routingNumberValidated: this.state.routingNumber
            });
        }
    }

    handleInputChange = (e) => {
        e.persist();
        this.setState({
            [e.target.name]: e.target.value,
            routingNumberValidated: ''
        });
    }

    validateViaGiact = async () => {

        if (this.state.accountNumber === this.state.repeatAccountNumber && this.state.routingNumber.length === 9) {
            return this.props.dispatch(validateBankData({
                routingNumber: this.state.routingNumber,
                accountNumber: this.state.accountNumber,
                merchantAppId: this.props.bankData.merchantAppId
            }));
        } else {
            return false;
        }
    }

    disableButton = (accountNumber, repeatAccountNumber, routingNumber) => {
        if ((accountNumber === repeatAccountNumber) && routingNumber.length === 9) {
            return false;
        } else {
            return true;
        }
    }

    handleNumberChange = (e) => {
        e.persist();
        this.setState({
            [e.target.name]: _.parseInt(e.target.value) || 0
        });
    };

    render() {
        const { invalidInputs } = this.props;
        const { accountNumber, routingNumber, repeatAccountNumber, routingNumberValidated } = this.state;
        const validateDisabled = this.disableButton(accountNumber, repeatAccountNumber, routingNumber);

        return (
            <div>
                <Section className="bank-data-section" heading="Business Checking Account Information">
                    <div className="bank-info-section">
                        <br />Please provide your business checking account and routing numbers below. We will use this information for the following purposes:<br /><br />
                        1. Processing Payment Fees: We will deduct any applicable fees directly from your provided bank account.<br /><br />
                        2. Deposits: All funds from your sales transactions will be deposited into your provided bank account.<br /><br />
                        By providing your bank account and routing numbers, you explicitly authorize us to use this information as outlined above.
                    </div>
                    <Group>
                        <div className="form-input-wrapper">
                            <div className="arrow-box" style={shouldShowHelp('accountNumber', this.state.currentFocused)}>Account Number. Must match Repeat Account Number.</div>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Account Number
                                        </label>
                                    </div>
                                </Row>
                                <TextInput
                                    type="number"
                                    name="accountNumber"
                                    handleChange={this.handleInputChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={accountNumber}
                                    required={true}
                                    handleFocus={this.handleFocus}
                                    placeHolder="4488555500"
                                    valid={getIsValid('accountNumber', invalidInputs)}
                                />
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div className="arrow-box" style={shouldShowHelp('repeatAccountNumber', this.state.currentFocused)}>Repeat Account Number. Must match Account Number.</div>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Repeat Account Number
                                        </label>
                                    </div>
                                </Row>
                                <TextInput
                                    type="number"
                                    name="repeatAccountNumber"
                                    handleChange={this.handleInputChange}
                                    value={repeatAccountNumber}
                                    required={true}
                                    handleFocus={this.handleFocus}
                                    placeHolder="4488555500"
                                    valid={getIsValid('repeatAccountNumber', invalidInputs)}
                                    handleBlur={() => { }}
                                />
                            </div>
                        </div>
                        <div className="form-input-wrapper">
                            <div className="arrow-box" style={shouldShowHelp('routingNumber', this.state.currentFocused)}>Routing Number. Must be 9 digits long.</div>
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">
                                            Routing Number
                                        </label>
                                    </div>
                                </Row>
                                <TextInput
                                    type="number"
                                    name="routingNumber"
                                    handleChange={this.handleInputChange}
                                    value={routingNumber}
                                    required={true}
                                    handleFocus={this.handleFocus}
                                    placeHolder="000222333"
                                    valid={getIsValid('routingNumber', invalidInputs)}
                                    handleBlur={this.handleTextBlur()}
                                    maxLength="9"
                                />
                                <span className="routing-number-validated">
                                    <TextInput
                                        type="number"
                                        name="routingNumberValidated"
                                        value={routingNumberValidated}
                                        required={true}
                                        valid={this.state.bankInfoValidated}
                                    />
                                </span>
                            </div>
                        </div>
                    </Group>
                    <div>
                        {this.props ?
                            (
                                <div className="button-row">
                                    <span>Click button below to validate bank information before proceeding</span>
                                    <button
                                        type="button"
                                        className={`${validateDisabled ? 'button-disabled' : 'button-enabled'}`}
                                        disabled={validateDisabled}
                                        onClick={() => this.onClick()} >
                                        { this.props.isValidateBankDataLoading ? <span className="validating-spinner">Validating <LoadingSpinner size="small" absolute/> </span> : 'Validate Bank Info' }
                                    </button>
                                    <div className="validation-message">
                                        {this.props.invalidInputs.routingNumberValidated && !this.state.routingNumberValidated && !this.state.errorMessage ? (
                                            <span className="validation-error">Checking account information has not been validated.</span>
                                        ) : null
                                        }
                                        {this.state.errorMessage && !this.state.routingNumberValidated ? (
                                            <span className="validation-error">{this.state.errorMessage}.</span>
                                        ) : null
                                        }
                                        {this.state.routingNumberValidated ? (
                                            <span className="validation-success">Checking account information validated.</span>
                                        ) : null
                                        }
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </Section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isPostBankDataLoading: getIsPostBankDataLoading(state),
        isValidateBankDataLoading: getIsValidateBankDataLoading(state),
        bankData: getBankData(state),
        isUserAnAgent: getIsUserAnAgent(state),
        businessInfo: getBusinessInfo(state)
    };
};

export default connect(mapStateToProps)(BankData);