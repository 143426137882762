import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { KNOW_YOUR_CUSTOMER } from '../../../../actions/sectionsActions';
import { getKnowYourCustomer } from '../../../../reducers/sectionsReducer';
import { getErrorMessage, getIsValid, isRadioChecked, shouldNotBeRequired } from '../../../../utils/form';
import scrollBehavior from '../../../../utils/scrollBehavior';
import CheckboxContainer from '../../../Shared/CheckboxContainer/CheckboxContainer';
import Row from '../../../Shared/Row/Row';
import Section from '../../../Shared/Section/Section';
import Select from '../../../Shared/Select/Select';
import TextInput from '../../../Shared/TextInput/TextInput';
import BaseSection from '../Sections/BaseSection';
import { getIsUserAnAgent } from '../../../../reducers/applicationReducer';
import Group from '../../../Shared/Group/Group';

export class KycPart1 extends BaseSection {
    sectionName = KNOW_YOUR_CUSTOMER;

    static propTypes = {
        invalidInputs: PropTypes.any,
        isUserAnAgent: PropTypes.bool,
        runValidation: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            productsServicesOffered: props.kyc.productsServicesOffered,
            advertisementStrategy: props.kyc.advertisementStrategy,
            isWorkingWithAttorney: props.kyc.isWorkingWithAttorney,
            workingWithAttorneyWho: props.kyc.workingWithAttorneyWho,
            howIsFulfillmentHandled: props.kyc.howIsFulfillmentHandled,
            fulfillmentHouseInfo: props.kyc.fulfillmentHouseInfo,
            isDsaMember: props.kyc.isDsaMember,
            hasIndictmentInlast5Years: props.kyc.hasIndictmentInlast5Years,
            indictmentInLast5YearsInfo: props.kyc.indictmentInLast5YearsInfo,
            salesGrossRevenuePercentage: props.kyc.salesGrossRevenuePercentage,
            doesPayCommissionOnSales: props.kyc.doesPayCommissionOnSales,
            recruitingGrossRevenuePercentage: props.kyc.recruitingGrossRevenuePercentage,
            doesPayCommissionOnRecruiting: props.kyc.doesPayCommissionOnRecruiting,
            isStartupBeingFinanced: props.kyc.isStartupBeingFinanced,
            hasFiledForBankruptcy: props.kyc.hasFiledForBankruptcy,
            isUserAnAgent: props.isUserAnAgent
        };
    }

    componentDidMount() {
        const application = document.getElementById('merchant-application');
        application.scrollIntoView(scrollBehavior());
    }

    render() {
        const { kyc, invalidInputs } = this.props;

        return (
            <div>
                <Section heading="General">
                    <Group>
                        <div className="form-input-wrapper" id="productsServicesOffered">
                            <div className="item-wrapper">
                                <Row>
                                    <label>What are the products/services you offer to consumers?</label>
                                </Row>
                                <TextInput
                                    name="productsServicesOffered"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('productsServicesOffered', kyc.notRequired)}
                                    valid={getIsValid('productsServicesOffered', invalidInputs)}
                                    value={this.state.productsServicesOffered}
                                    errorMessage={getErrorMessage('productsServicesOffered', invalidInputs)}
                                />
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="advertisementStrategy">
                            <div className="item-wrapper">
                                <Row>
                                    <label>Explain how and where you advertise your products/services
                                        <br />(e.g., Google, Facebook, website, brochures, leads/referrals, etc.).</label>
                                </Row>
                                <TextInput
                                    name="advertisementStrategy"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('advertisementStrategy', kyc.notRequired)}
                                    valid={getIsValid('advertisementStrategy', invalidInputs)}
                                    value={this.state.advertisementStrategy}
                                    errorMessage={getErrorMessage('advertisementStrategy', invalidInputs)}
                                />
                            </div>
                        </div>
                    </Group>
                </Section>

                <Section heading="Reputational">
                    <Group>
                        <div className="form-input-wrapper" id="isWorkingWithAttorney">
                            <div className="item-wrapper">
                                <Row>
                                    <label>Are you currently working with or have you worked with a consultant or attorney regarding your business model?</label>
                                </Row>
                                <CheckboxContainer name="isWorkingWithAttorney" isRequired={true}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="isWorkingWithAttorney"
                                            onChange={this.handleBooleanRadioChange}
                                            value={false}
                                            checked={isRadioChecked(false, this.state.isWorkingWithAttorney)}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="isWorkingWithAttorney"
                                            onChange={this.handleBooleanRadioChange}
                                            value={true}
                                            checked={isRadioChecked(true, this.state.isWorkingWithAttorney)}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        {this.state.isWorkingWithAttorney ?
                            <div className="form-input-wrapper" id="workingWithAttorneyWho">
                                <div className="item-wrapper">
                                    <Row>
                                        <label>What is the name of the attorney?</label>
                                    </Row>
                                    <TextInput
                                        name="workingWithAttorneyWho"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('workingWithAttorneyWho', kyc.notRequired)}
                                        valid={getIsValid('workingWithAttorneyWho', invalidInputs)}
                                        value={this.state.workingWithAttorneyWho}
                                        errorMessage={getErrorMessage('workingWithAttorneyWho', invalidInputs)}
                                    />
                                </div>
                            </div> : null
                        }
                        <div className="form-input-wrapper" id="howIsFulfillmentHandled">
                            <div className="item-wrapper">
                                <Row>
                                    <label>How are goods shipped or services fulfilled (UPS, FedEx, 3rd party fulfillment,
                                        etc.)?</label>
                                </Row>
                                <TextInput
                                    name="howIsFulfillmentHandled"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('howIsFulfillmentHandled', kyc.notRequired)}
                                    valid={getIsValid('howIsFulfillmentHandled', invalidInputs)}
                                    value={this.state.howIsFulfillmentHandled}
                                    errorMessage={getErrorMessage('howIsFulfillmentHandled', invalidInputs)}
                                />
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="fulfillmentHouseInfo">
                            <div className="item-wrapper">
                                <Row>
                                    <label>Provide contact information for your fulfillment house: name, address, contact
                                        person, phone, & email.</label>
                                </Row>
                                <TextInput
                                    name="fulfillmentHouseInfo"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={this.state.fulfillmentHouseInfo}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('fulfillmentHouseInfo', kyc.notRequired)}
                                    valid={getIsValid('fulfillmentHouseInfo', invalidInputs)}
                                    errorMessage={getErrorMessage('fulfillmentHouseInfo', invalidInputs)}/>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="isDsaMember">
                            <div className="item-wrapper">
                                <Row>
                                    <label>Do you have a membership with the Direct Sellers Association (DSA)?</label>
                                </Row>
                                <CheckboxContainer name="isDsaMember" isRequired={true}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="isDsaMember"
                                            onChange={this.handleBooleanRadioChange}
                                            value={false}
                                            checked={isRadioChecked(false, this.state.isDsaMember)}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="isDsaMember"
                                            onChange={this.handleBooleanRadioChange}
                                            value={true}
                                            checked={isRadioChecked(true, this.state.isDsaMember)}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="hasIndictmentInlast5Years">
                            <div className="item-wrapper">
                                <Row>
                                    <label>In the last 5 years, has your company or executive team been accused or indicted
                                        of any criminal, civil (Ex. class action or individual lawsuits, etc.), or
                                        regulatory (Ex. FTC, DOJ, etc.) misconduct?</label>
                                </Row>
                                <CheckboxContainer name="hasIndictmentInlast5Years" isRequired={true}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="hasIndictmentInlast5Years"
                                            onChange={this.handleBooleanRadioChange}
                                            value={false}
                                            checked={isRadioChecked(false, this.state.hasIndictmentInlast5Years)}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="hasIndictmentInlast5Years"
                                            onChange={this.handleBooleanRadioChange}
                                            value={true}
                                            checked={isRadioChecked(true, this.state.hasIndictmentInlast5Years)}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        {this.state.hasIndictmentInlast5Years ?
                            <div className="form-input-wrapper" id="indictmentInLast5YearsInfo">
                                <div className="item-wrapper">
                                    <Row>
                                        <label>Are there any current orders imposing sanctions or regulatory actions? Please explain.</label>
                                    </Row>
                                    <TextInput
                                        name="indictmentInLast5YearsInfo"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('indictmentInLast5YearsInfo', kyc.notRequired)}
                                        valid={getIsValid('indictmentInLast5YearsInfo', invalidInputs)}
                                        errorMessage={getErrorMessage('indictmentInLast5YearsInfo', invalidInputs)}
                                        value={this.state.indictmentInLast5YearsInfo}/>
                                </div>
                            </div> : null
                        }
                    </Group>
                </Section>

                <Section heading="Financial">
                    <Group>
                        <div className="form-input-wrapper" id="salesGrossRevenuePercentage">
                            <div className="item-wrapper">
                                <Row>
                                    <label>What percentage of gross revenue is generated from product or service sales to consumers?</label>
                                </Row>
                                <TextInput
                                    type="number"
                                    name="salesGrossRevenuePercentage"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={this.state.salesGrossRevenuePercentage}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('salesGrossRevenuePercentage', kyc.notRequired)}
                                    valid={getIsValid('salesGrossRevenuePercentage', invalidInputs)}
                                    errorMessage={getErrorMessage('salesGrossRevenuePercentage', invalidInputs)}
                                />
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="doesPayCommissionOnSales">
                            <div className="item-wrapper">
                                <Row>
                                    <label>Do you pay commissions on revenue generated from product or service sales to consumers?</label>
                                </Row>
                                <CheckboxContainer name="doesPayCommissionOnSales" isRequired={true}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="doesPayCommissionOnSales"
                                            onChange={this.handleBooleanRadioChange}
                                            value={false}
                                            checked={isRadioChecked(false, this.state.doesPayCommissionOnSales)}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="doesPayCommissionOnSales"
                                            onChange={this.handleBooleanRadioChange}
                                            value={true}
                                            checked={isRadioChecked(true, this.state.doesPayCommissionOnSales)}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="recruitingGrossRevenuePercentage">
                            <div className="item-wrapper">
                                <Row>
                                    <label>What percentage of gross revenue is generated from recruitment?</label>
                                </Row>
                                <TextInput
                                    type="number"
                                    name="recruitingGrossRevenuePercentage"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={this.state.recruitingGrossRevenuePercentage}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('recruitingGrossRevenuePercentage', kyc.notRequired)}
                                    valid={getIsValid('recruitingGrossRevenuePercentage', invalidInputs)}
                                    errorMessage={getErrorMessage('recruitingGrossRevenuePercentage', invalidInputs)}/>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="doesPayCommissionOnRecruiting">
                            <div className="item-wrapper">
                                <Row>
                                    <label>Do you pay commissions on revenue generated from recruitment?</label>
                                </Row>
                                <CheckboxContainer name="doesPayCommissionOnRecruiting" isRequired={true}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="doesPayCommissionOnRecruiting"
                                            onChange={this.handleBooleanRadioChange}
                                            value={false}
                                            checked={isRadioChecked(false, this.state.doesPayCommissionOnRecruiting)}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="doesPayCommissionOnRecruiting"
                                            onChange={this.handleBooleanRadioChange}
                                            value={true}
                                            checked={isRadioChecked(true, this.state.doesPayCommissionOnRecruiting)}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="isStartupBeingFinanced">
                            <div className="item-wrapper">
                                <Row>
                                    <label>If your company is a startup, are you being financed?</label>
                                </Row>
                                <Select
                                    name="isStartupBeingFinanced"
                                    handleChange={this.handleSelectChange}
                                    value={ this.state.isStartupBeingFinanced }
                                    options={[
                                        { value: 'N/A', label: 'N/A' },
                                        { value: 'No', label: 'No' },
                                        { value: 'Yes', label: 'Yes' }
                                    ]}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('isStartupBeingFinanced', kyc.notRequired)}
                                    valid={getIsValid('isStartupBeingFinanced', invalidInputs)}
                                    errorMessage={getErrorMessage('isStartupBeingFinanced', invalidInputs)}
                                />
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="hasFiledForBankruptcy">
                            <div className="item-wrapper">
                                <Row>
                                    <label>Have you ever filed for bankruptcy?</label>
                                </Row>
                                <CheckboxContainer name="hasFiledForBankruptcy" isRequired={true}>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="hasFiledForBankruptcy"
                                            onChange={this.handleBooleanRadioChange}
                                            value={false}
                                            checked={isRadioChecked(false, this.state.hasFiledForBankruptcy)}
                                        />
                                        <label>No</label>
                                    </div>
                                    <div className="half-width-div">
                                        <input
                                            type="radio"
                                            name="hasFiledForBankruptcy"
                                            onChange={this.handleBooleanRadioChange}
                                            value={true}
                                            checked={isRadioChecked(true, this.state.hasFiledForBankruptcy)}
                                        />
                                        <label>Yes</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                    </Group>
                </Section>
            </div>
        );
    }
}

export const mapStateToProps = (state) => ({
    kyc: getKnowYourCustomer(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(KycPart1);
