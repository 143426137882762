
export function getLocalStorageItem(itemName) {
    try {
        return window.localStorage.getItem(itemName);
    } catch (err) {
        return undefined;
    }
}
export function setLocalStorageItem(itemName, itemValue) {
    try {
        return window.localStorage.setItem(itemName, itemValue);
    } catch (err) {
        return undefined;
    }
}