import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Owner.scss';
import { EmvioLoadingSpinner as LoadingSpinner } from '@nexio/emvio-util-ui';

import { states, identificationTypes, jobTitleTypes } from '../../StaticData/staticData';
import Section from '../../../../Shared/Section/Section';
import Row from '../../../../Shared/Row/Row';
import Select from '../../../../Shared/Select/Select';
import TextInput from '../../../../Shared/TextInput/TextInput';

import {
    getErrorMessage,
    getIsValid,
    shouldHideElement,
    shouldNotBeRequired,
    shouldShowHelp,
    conditionalShow
} from '../../../../../utils/form';
import { OWNER, saveOwnerData, createAdditionalOwnerData, deleteOwner } from '../../../../../actions/sectionsActions';
import Group from '../../../../Shared/Group/Group';
import BaseSection from '../BaseSection';
import PhoneInput from '../../../../Shared/TextInput/PhoneInput';
import SSNInput from '../../../../Shared/TextInput/SSNInput';
import moment from 'moment';
import { getIsRemoveOwnerLoading } from '../../../../../reducers/removeOwnerReducer';
import { getIsAddOwnerLoading } from '../../../../../reducers/addOwnerReducer';
import { getIsDirectSalesApp, getOwnerInfoData } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

let allowedDateFormats = ['MM/DD/YYYY', 'YYYY-MM-DD'];

export class Owner extends BaseSection {
    sectionName = OWNER;

    static defaultProps = {
        invalidInputs: {},
        ownersArray: [],
        dispatch: _.noop
    };

    static propTypes = {
        isAddOwnerLoading: PropTypes.bool,
        isRemoveOwnerLoading: PropTypes.bool,
        isUserAnAgent: PropTypes.bool,
        dispatch: PropTypes.func,
        saveOwner: PropTypes.func,
        ownersArray: PropTypes.array,
        owner: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            years: PropTypes.string,
            jobTitle: PropTypes.string,
            phone: PropTypes.string,
            homeAddress: PropTypes.string,
            date: PropTypes.string,
            city: PropTypes.string,
            zip: PropTypes.string,
            socialSecurity: PropTypes.string,
            percentageOfOwnership: PropTypes.string,
            identificationType: PropTypes.string,
            identificationNumber: PropTypes.string,
            identificationState: PropTypes.string,
            email: PropTypes.string,
            map: PropTypes.func
        }),
        ownerDivs: PropTypes.array,
        isDirectSalesApp: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool
        ])
    };

    constructor(props) {
        super(props);

        this.state = {
            showOwnerDiv: Array(props.ownersArray.length).fill(true),
            ownersArray: this.formatOwners(props.ownersArray),
            isDirectSalesApp: props.isDirectSalesApp,
            isUserAnAgent: props.isUserAnAgent
        };
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.ownersArray, this.props.ownersArray)) {
            this.setState({
                ownersArray: this.formatOwners(this.props.ownersArray),
                isDirectSalesApp: this.props.isDirectSalesApp
            });
        }
    }

    componentWillUnmount = async () => {
        // do nothing (each owner is saved on unmount so the ownerInfoData doesnt need to be saved)
    };

    formatOwners = (owners) => {
        return owners.map((owner) => {
            let birthday;

            if (owner.birthday && moment(owner.birthday, allowedDateFormats).isValid()) {
                birthday = moment(owner.birthday, allowedDateFormats).format('YYYY-MM-DD');
            } else {
                birthday = null;
            }

            return {
                ...owner,
                birthday
            };
        });
    };

    handleInputChange = (id, index) => (e) => {
        e.persist();
        this.setState((prevState) => {
            const newOwnersArray = [...prevState.ownersArray];
            newOwnersArray[index][e.target.name] = e.target.value;

            return {
                ...prevState,
                ownersArray: newOwnersArray
            };
        });
    };

    handleInputSelect = (id, index) => (e) => {
        e.persist();
        this.props.dispatch(saveOwnerData(index, e.target.name, e.target.value));
    };

    handleRadioInputChange = (id, index) => (e) => {
        this.props.dispatch(saveOwnerData(index, e.target.dataset.id, parseInt(e.target.value)));
    };

    handleInputBlur = (id, index) => (e) => {
        let value = e.target.value;
        if (e.target.name === 'birthday') {
            value = moment(value, allowedDateFormats).format('YYYY-MM-DD');
        }
        this.props.dispatch(saveOwnerData(index, e.target.name, value));
    };

    handleAddNewSigner = (index) => async () => {
        await this.props.dispatch(createAdditionalOwnerData(this.props.ownersArray[0].merchantAppId));
        const showOwnerDiv = this.state.showOwnerDiv;
        showOwnerDiv[index] = true;
        this.setState({
            ownersArray: this.props.ownersArray,
            showOwnerDiv
        });
    };

    isOwnerBirthdayValid = (owner) => {
        return !!(owner.birthday && moment(owner.birthday, allowedDateFormats).isValid());
    };

    showIcon(index) {
        return this.state.showOwnerDiv[index] ? ' icon-triangle-down' : 'icon-triangle-up';
    }

    showHide(index) {
        const showOwnerDiv = this.state.showOwnerDiv;
        showOwnerDiv[index] = !showOwnerDiv[index];
        this.setState({
            showOwnerDiv
        });
    }

    showThisOwnerSection(index) {
        let show = this.state.showOwnerDiv[index];
        if (typeof this.state.showOwnerDiv[index] === 'undefined') {
            show = true;
        }

        return show
            ? { display: 'block' }
            : { display: 'none' };
    }

    renderDeleteIcon(index, owner) {
        const { dispatch, isRemoveOwnerLoading } = this.props;
        if (index > 0) {
            return (
                <div className="delete-icon">
                    <span className="trash-container">
                        <span className="icon icon-trash trash-attributes" onClick={() => dispatch(deleteOwner(owner, index))} />
                    </span>
                    <div className="axios-loading">
                        {isRemoveOwnerLoading
                            ? <LoadingSpinner size="large" absolute />
                            : null
                        }
                    </div>
                </div>
            );
        }
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { invalidInputs, isAddOwnerLoading, isRemoveOwnerLoading } = this.props;
        const { ownersArray, isDirectSalesApp } = this.state;
        const ownerSections = ownersArray.map((owner, index) => {
            let additionalSig = <div></div>;
            if (index === ownersArray.length - 1) {
                let ownerOrSigner = isDirectSalesApp ? 'signer' : 'owner';
                additionalSig = <div className="form-input-wrapper">
                    <div className="item-wrapper item-wrapper-half">
                        <div className="add-new-signer" onClick={this.handleAddNewSigner(index)}>
                            <label className="label"><b>Who else owns 25% or more?</b>&nbsp;Add an additional {ownerOrSigner} on this account.</label>
                        </div>
                        <div className="axios-loading">
                            {isRemoveOwnerLoading || isAddOwnerLoading
                                ? <LoadingSpinner size="large" absolute />
                                : null
                            }
                        </div>
                    </div>
                </div>;
            }

            if (owner.identificationType === 'Passport' && owner.identificationState) {
                owner.identificationState = null;
            }

            return (
                <div key={owner.id} className="new-owner-div" >
                    We will need the information of <b>every individual who owns 25% or more</b> of the business.
                    <div>
                        <Row className="">
                            <h3 className="new-owner-h3">
                                {index === 0
                                    ? 'Owner Information'
                                    : 'Additional Owner Information'
                                }
                            </h3>
                            <span onClick={() => this.showHide(index)} className={`${this.showIcon(index)} icon-container small-icon`} />
                            {this.renderDeleteIcon(index, owner)}
                        </Row>
                        <div className="owner-info-wrapper" style={this.showThisOwnerSection(index)}>
                            {/*Full Name*/}
                            <div className="form-input-wrapper">
                                <div className="arrow-box" style={shouldShowHelp('name', this.state.currentFocused)}>
                                    Full Legal Name of the Signer
                                </div>
                                <div className="item-wrapper" id={`name_${owner.id}`} style={shouldHideElement('name', owner.hide)}>
                                    <Row>
                                        <label className="label">Full Name</label>
                                    </Row>
                                    <TextInput
                                        name="name"
                                        handleChange={this.handleInputChange(owner.id, index)}
                                        handleBlur={this.handleInputBlur(owner.id, index)}
                                        value={owner.name}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('name', owner.notRequired)}
                                        valid={getIsValid(`name_${owner.id}`, invalidInputs)}
                                        errorMessage={getErrorMessage(`name_${owner.id}`, invalidInputs)}
                                        placeHolder={'Full Legal Name of the Signer'}
                                        handleFocus={this.handleFocus}
                                        dataId={`name_${owner.id}`}
                                    />
                                </div>
                            </div>
                            {/*Job Title*/}
                            <div className="form-input-wrapper">
                                <div className="arrow-box" style={shouldShowHelp('jobTitle', this.state.currentFocused)}>
                                    Business title (Owner, President, CEO, etc). Not “Mr.” or “Mrs.”
                                </div>
                                <div className="item-wrapper" id={`jobTitle_${owner.id}`} style={shouldHideElement(`jobTitle_${owner.id}`, owner.hide)}>
                                    <Row>
                                        <label className="label">Job Title</label>
                                    </Row>
                                    <div style={{ marginTop: '20px', marginLeft: '9px' }}>
                                        <Select
                                            name="jobTitle"
                                            handleChange={this.handleInputSelect(owner.id, index)}
                                            options={jobTitleTypes}
                                            value={owner.jobTitle}
                                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('jobTitle', owner.notRequired)}
                                            valid={getIsValid(`jobTitle_${owner.id}`, invalidInputs)}
                                            errorMessage={getErrorMessage(`identificationType_${owner.id}`, invalidInputs)}
                                            dataId={`jobTitle_${owner.id}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*Phone and DOB*/}
                            <div className="form-input-wrapper">
                                <div className="arrow-box" style={shouldShowHelp('birthday', this.state.currentFocused)}>Birthday: YYYY-MM-DD</div>
                                <div className="multi-item-wrapper">
                                    <div id={`phone_${owner.id}`} className="half-width" style={shouldHideElement('phone', owner.hide)}>
                                        <Row>
                                            <label className="label">
                                                Personal Contact Phone
                                            </label>
                                        </Row>
                                        <PhoneInput
                                            phoneType="number"
                                            name="phone"
                                            handleChange={this.handleInputChange(owner.id, index)}
                                            handleBlur={this.handleInputBlur(owner.id, index)}
                                            value={owner.phone}
                                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('phone', owner.notRequired)}
                                            valid={getIsValid(`phone_${owner.id}`, invalidInputs)}
                                            errorMessage={getErrorMessage(`phone_${owner.id}`, invalidInputs)}
                                            dataId={`phone_${owner.id}`}
                                        />
                                    </div>
                                    <div id={`birthday_${owner.id}`} className="half-width" style={shouldHideElement('birthday', owner.hide)}>
                                        <Row>
                                            <label htmlFor={`dateOfBirth${owner.id}`} className="label">
                                                Date of Birth
                                            </label>
                                        </Row>
                                        <TextInput
                                            id={`dateOfBirth${owner.id}`}
                                            type="text"
                                            name="birthday"
                                            handleChange={this.handleInputChange(owner.id, index)}
                                            handleBlur={this.handleInputBlur(owner.id, index)}
                                            value={owner.birthday}
                                            placeHolder="YYYY-MM-DD"
                                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('birthday', owner.notRequired)}
                                            valid={this.isOwnerBirthdayValid(owner)}
                                            errorMessage={getErrorMessage(`birthday_${owner.id}`, invalidInputs)}
                                            handleFocus={this.handleFocus}
                                            dataId={`birthday_${owner.id}`}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*SSN*/}
                            <div className="form-input-wrapper">
                                <div className="arrow-box" style={shouldShowHelp('socialSecurity', this.state.currentFocused)}>
                                    Social Security Number
                                </div>
                                <div className="item-wrapper item-wrapper-half" id={`socialSecurity_${owner.id}`} style={shouldHideElement('socialSecurity', owner.hide)}>
                                    <Row>
                                        <label className="label">Social Security Number</label>
                                    </Row>
                                    <SSNInput
                                        name={'socialSecurity'}
                                        handleChange={this.handleInputChange(owner.id, index)}
                                        handleBlur={this.handleInputBlur(owner.id, index)}
                                        value={owner.socialSecurity ? owner.socialSecurity : ''}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('socialSecurity', owner.notRequired)}
                                        valid={getIsValid(`socialSecurity_${owner.id}`, invalidInputs)}
                                        errorMessage={getErrorMessage(`socialSecurity_${owner.id}`, invalidInputs)}
                                        dataId={`socialSecurity_${owner.id}`}
                                    />
                                </div>
                            </div>

                            {/* email */}
                            <div className="form-input-wrapper">
                                <div className="item-wrapper" id={`email_${owner.id}`}>
                                    <Row>
                                        <label className="label">
                                            Email
                                        </label>
                                    </Row>
                                    <TextInput
                                        icon="email"
                                        type="email"
                                        name="email"
                                        handleChange={this.handleInputChange(owner.id, index)}
                                        handleBlur={this.handleInputBlur(owner.id, index)}
                                        value={owner.email ? owner.email : ''}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('email', owner.required)}
                                        valid={getIsValid(`email_${owner.id}`, invalidInputs)}
                                        errorMessage={getErrorMessage(`email_${owner.id}`, invalidInputs)}
                                        placeHolder={'Email'}
                                        dataId={`email_${owner.id}`}
                                    />
                                </div>
                            </div>

                            {/*id typ*/}
                            <div className="form-input-wrapper">
                                <div className="arrow-box" style={shouldShowHelp('identificationType', this.state.currentFocused)}>
                                    Identification Type: ie Drivers License, Passport, Government ID
                                </div>
                                <div className="item-wrapper" id={`identificationType_${owner.id}`} style={shouldHideElement(`identificationType_${owner.id}`, owner.hide)}>
                                    <Row>
                                        <label className="label">Identification Type</label>
                                    </Row>
                                    <div style={{ marginTop: '20px', marginLeft: '9px' }}>
                                        <Select
                                            name="identificationType"
                                            handleChange={this.handleInputSelect(owner.id, index)}
                                            value={owner.identificationType}
                                            options={identificationTypes}
                                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('identificationType', owner.notRequired)}
                                            valid={getIsValid(`identificationType_${owner.id}`, invalidInputs)}
                                            errorMessage={getErrorMessage(`identificationType_${owner.id}`, invalidInputs)}
                                            dataId={`identificationType_${owner.id}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*id id num and id state*/}
                            <div className="form-input-wrapper">
                                <div className="arrow-box" style={shouldShowHelp('identificationNumber', this.state.currentFocused)}>
                                    The number associated with your choice of identification ie Drivers License Number, Passport Number
                                </div>
                                <div className="multi-item-wrapper">
                                    <div id={`identificationNumber_${owner.id}`} className="half-width" style={shouldHideElement('identificationNumber', owner.hide)}>
                                        <Row>
                                            <label className="label">
                                                Identification Number
                                            </label>
                                        </Row>
                                        <TextInput
                                            type="text"
                                            name="identificationNumber"
                                            maxLength={16}
                                            handleChange={this.handleInputChange(owner.id, index)}
                                            handleBlur={this.handleInputBlur(owner.id, index)}
                                            value={owner.identificationNumber}
                                            placeHolder="Identification Number"
                                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('identificationNumber', owner.required)}
                                            valid={getIsValid(`identificationNumber_${owner.id}`, invalidInputs)}
                                            errorMessage={getErrorMessage(`identificationNumber_${owner.id}`, invalidInputs)}
                                            dataId={`identificationNumber_${owner.id}`}
                                            handleFocus={this.handleFocus}
                                        />
                                    </div>
                                    <div id={`identificationState_${owner.id}`} className="half-width" style={shouldHideElement(`identificationState_${owner.id}`, owner.hide)}>
                                        <div id={`identificationState_${owner.id}-inner`} className="half-width" style={conditionalShow(owner.identificationType === 'State ID' || owner.identificationType === 'Drv Lic')}>
                                            <Row>
                                                <label className="label" style={{ marginBottom: '19px', marginLeft: '8px' }}>
                                                    Identification State
                                                </label>
                                            </Row>
                                            <div style={{ marginLeft: '15px' }}>
                                                <Select
                                                    name="identificationState"
                                                    handleChange={this.handleInputSelect(owner.id, index)}
                                                    value={owner.identificationState}
                                                    options={states}
                                                    required={owner.identificationType === 'State ID' || owner.identificationType === 'Drv Lic'}
                                                    valid={getIsValid('identificationState', invalidInputs)}
                                                    errorMessage={getErrorMessage('identificationState', invalidInputs)}
                                                    dataId={`identificationState_${owner.id}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*home address, city , state*/}
                            <div>
                                <Row>
                                    <label className="label">
                                        Home Address
                                    </label>
                                </Row>
                                <div className="form-input-wrapper">
                                    <div className="arrow-box" style={shouldShowHelp('homeAddress', this.state.currentFocused)}>
                                        Residential Address
                                    </div>
                                    <div className="item-wrapper" id={`homeAddress_${owner.id}`} style={shouldHideElement('homeAddress', owner.hide)}>
                                        <TextInput
                                            name="homeAddress"
                                            handleChange={this.handleInputChange(owner.id, index)}
                                            handleBlur={this.handleInputBlur(owner.id, index)}
                                            value={owner.homeAddress}
                                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('homeAddress', owner.notRequired)}
                                            valid={getIsValid(`homeAddress_${owner.id}`, invalidInputs)}
                                            errorMessage={getErrorMessage(`homeAddress_${owner.id}`, invalidInputs)}
                                            placeHolder={'Residential Address'}
                                            handleFocus={this.handleFocus}
                                            dataId={`homeAddress_${owner.id}`}
                                        />
                                    </div>
                                </div>
                                <div className="form-input-wrapper">
                                    <div className="arrow-box" style={shouldShowHelp('city', this.state.currentFocused)}>
                                        City
                                    </div>
                                    <div className="item-wrapper" id={`city_${owner.id}`} style={shouldHideElement('city', owner.hide)}>
                                        <TextInput
                                            name="city"
                                            handleChange={this.handleInputChange(owner.id, index)}
                                            handleBlur={this.handleInputBlur(owner.id, index)}
                                            value={owner.city}
                                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('city', owner.notRequired)}
                                            valid={getIsValid(`city_${owner.id}`, invalidInputs)}
                                            errorMessage={getErrorMessage(`city_${owner.id}`, invalidInputs)}
                                            placeHolder={'City'}
                                            handleFocus={this.handleFocus}
                                            dataId={`city_${owner.id}`}
                                        />
                                    </div>
                                </div>

                                <div className="form-input-wrapper">
                                    <div className="arrow-box" style={shouldShowHelp('state', this.state.currentFocused)}></div>
                                    <div className="multi-item-wrapper">
                                        <div id={`state_${owner.id}`} className="half-width state-select" style={shouldHideElement('state', owner.hide)}>
                                            <div style={{ marginTop: '20px', marginLeft: '9px' }}>
                                                <Select
                                                    name="state"
                                                    handleChange={this.handleInputSelect(owner.id, index)}
                                                    value={owner.state}
                                                    options={states}
                                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('state', owner.notRequired)}
                                                    valid={getIsValid(`state_${owner.id}`, invalidInputs)}
                                                    errorMessage={getErrorMessage(`state_${owner.id}`, invalidInputs)}
                                                    dataId={`state_${owner.id}`}
                                                />
                                            </div>
                                        </div>
                                        <div id={`zip_${owner.id}`} className="half-width" style={shouldHideElement('zip', owner.hide)}>
                                            <TextInput
                                                type="text"
                                                name="zip"
                                                handleChange={this.handleInputChange(owner.id, index)}
                                                handleBlur={this.handleInputBlur(owner.id, index)}
                                                value={owner.zip}
                                                placeHolder="Zip"
                                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('zip', owner.notRequired)}
                                                handleFocus={this.handleFocus}
                                                valid={getIsValid(`zip_${owner.id}`, invalidInputs)}
                                                errorMessage={getErrorMessage(`zip_${owner.id}`, invalidInputs)}
                                                dataId={`zip_${owner.id}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Share of ownership*/}
                            <div className="form-input-wrapper">
                                <div className="arrow-box" style={shouldShowHelp('percentageOfOwnership', this.state.currentFocused)}>
                                    Percentage Share of Ownership of the Business?
                                </div>
                                <div className="item-wrapper" id={`percentageOfOwnership_${owner.id}`} style={shouldHideElement('percentageOfOwnership', owner.hide)}>
                                    <Row>
                                        <label className="label">Share of Ownership</label>
                                    </Row>
                                    <TextInput
                                        type={'number'}
                                        name="percentageOfOwnership"
                                        handleChange={this.handleInputChange(owner.id, index)}
                                        handleBlur={this.handleInputBlur(owner.id, index)}
                                        value={owner.percentageOfOwnership}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('percentageOfOwnership', owner.notRequired)}
                                        valid={getIsValid(`percentageOfOwnership_${owner.id}`, invalidInputs)}
                                        errorMessage={getErrorMessage(`percentageOfOwnership_${owner.id}`, invalidInputs)}
                                        placeHolder={'100'}
                                        handleFocus={this.handleFocus}
                                        dataId={`percentageOfOwnership_${owner.id}`}
                                    />
                                </div>
                            </div>

                        </div>
                        <div>
                            {/*Additional Signer*/}
                            {additionalSig}
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <Section heading="Owner/Officer Personal Information">
                <Group>
                    {ownerSections}
                </Group>
            </Section>
        );
    }
}

export const mapStateToProps = (state) => ({
    ownersArray: getOwnerInfoData(state),
    isUserAnAgent: getIsUserAnAgent(state),
    isAddOwnerLoading: getIsAddOwnerLoading(state),
    isRemoveOwnerLoading: getIsRemoveOwnerLoading(state),
    isDirectSalesApp: getIsDirectSalesApp(state)
});

export default connect(mapStateToProps)(Owner);
