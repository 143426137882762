import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './PageNumberBubble.scss';

class PageNumberBubble extends Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        number: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
        onClick: PropTypes.func
    };

    render() {
        const { active, number, currentPage, totalPages } = this.props;
        const classNames = `page-number-bubble-position ${active ? 'active' : ''}`;
        const classNamesComplete = `${classNames} ${number < currentPage ? 'complete' : ''}`;

        const progressclassNames = `page-progress ${active ? 'active' : ''}`;
        const progressLastClassName = `${progressclassNames} ${number === totalPages - 1 ? 'last' : ''}`;
        const progressCompleteClassName = `${progressLastClassName} ${number < currentPage ? 'complete' : ''}`;

        return (
            <div className={`page-number-bubble-container ${number === totalPages ? 'last-container' : ''}`} onClick={this.props.onClick}>
                <div className={classNamesComplete}>
                    <div className="outer-oval">
                        <div className="bubble">
                            {number + 1}
                        </div>
                    </div>
                </div>
                <div className={progressCompleteClassName} />
            </div>
        );
    }
}

export default PageNumberBubble;
