import React from 'react';
import { connect } from 'react-redux';
import BaseSection from '../BaseSection';
import { EmvioLoadingSpinner as LoadingSpinner } from '@nexio/emvio-util-ui';
import { doesPropExistAndHasItChanged } from '@nexio/emvio-util-app';
import './FileUpload.scss';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { toastIfError } from '../../../../../utils/toast';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { APPLICATION_FILES } from '../../../../../actions/sectionsActions';
import {
    getMerchantAppData,
    isDeleteAppFileError,
    getDeleteAppFileErrorMessage,
    getIsDeleteAppFileLoading,
    getIsDeleteAppFileSuccessful,
    getUploadLinkError,
    getUploadLinkErrorMessage,
    getUploadLinkLoading,
    getUploadLinkSuccessful
} from '../../../../../reducers/sectionsReducer';
import { getFileUploadLink, deleteAppAttachment } from '../../../../../actions/sectionsActions';

export class FileUpload extends BaseSection {
    sectionName = APPLICATION_FILES;

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            existingFiles: _.get(this.props, 'value') ? JSON.parse(_.get(this.props, 'value')) : [],
            fileNames: [],
            fileLoading: false,
            fileError: false,
            uploadFail: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.deleteErrorMessage, this.props.deleteErrorMessage)) {
            this.setState({ fileLoading: false });
        }

        if (doesPropExistAndHasItChanged(prevProps, this.props, 'uploadLinkError')) {
            this.setState({ fileLoading: false });
            this.setState({ files: [] });
            toast(this.props.uploadLinkErrorMessage, { type: 'error', toastId: 'uploadError' });
        }

        toastIfError(prevProps, this.props, 'deleteErrorMessage');
    }

    onOpenFileDialog = () => {
        this.dropzoneRef.open();
    };

    saveFilesChange = () => {
        const newFileNames = _.map(this.state.files, (file) => file.name);

        const filesToSave = [...this.state.existingFiles, ...newFileNames];

        this.props.updateFiles(this.props.category, filesToSave);

    }

    uploadFile = async (file, name) => {
        this.props.fileIsLoading();

        const { message } = await this.props.dispatch(getFileUploadLink(this.props.MerchantAppData.id, name, this.props.category));

        const url = message;

        let result;
        try {
            result = await fetch(url, { method: 'PUT', body: file });

            if (!result.ok) {
                throw new Error(`Error response: ${result}`);
            }

        } catch (error) {
            this.setState({ fileError: true, fileLoading: false });
            toast('Error uploading file', { type: 'error' });
            this.setState({ files: [] });

            return error;
        }

        if (result) {
            await this.saveFilesChange();
            this.setState({ fileLoading: false });
            this.props.fileLoadingDone();
        }

        return result;
    };

    onDrop = async (acceptedFiles, rejectedFiles = []) => {
        this.setState({ fileLoading: true });

        rejectedFiles = rejectedFiles.map((file) => {
            file.invalid = true;

            return file;
        });

        await _.map(acceptedFiles, async (file) => {
            let fileUploadresult = await this.uploadFile(file, file.name);

            if (!fileUploadresult) {
                this.setState({ fileError: true });
                toast('Error uploading file', { type: 'error' });
            }
        });

        const filesClone = _.cloneDeep(this.state.files);

        const updatedFiles = [...filesClone, ...acceptedFiles];

        this.setState({
            files: updatedFiles
        });
    }

    removeFileAtIndex = async (indexToRemove, existingFile) => {
        this.setState({ fileLoading: true });
        let files;
        let fileName;

        if (existingFile) {
            fileName = this.state.existingFiles[indexToRemove];
            files = [...this.state.existingFiles];
        } else {
            fileName = this.state.files[indexToRemove].name;
            files = [...this.state.files];
        }

        _.remove(files, (val, index) => {
            return index === indexToRemove;
        });

        const deleteBody = {
            fileName,
            category: this.props.category
        };

        await this.props.dispatch(deleteAppAttachment(this.props.MerchantAppData.id, deleteBody));

        if (existingFile) {
            this.setState({
                existingFiles: files
            });
        } else {
            this.setState({
                files
            });
        }

        await this.saveFilesChange();

        this.setState({ fileLoading: false });
    }

    renderFile = (file, index) => {
        const fileNameClassNames = classNames('file-name', {
            'invalid': file.invalid
        });

        return (
            <div className="file-list-item" key={file.name + index}>
                <div className={fileNameClassNames}>{file.name || 'file'}</div>
                <button type="button" className="delete-item-button"
                    disabled={this.props.deleteLoading || this.state.fileLoading}
                    onClick={() => this.removeFileAtIndex(index, false)}
                >
                    {this.state.fileLoading ? (
                        <LoadingSpinner className="delete-spinner" size="small" relative />
                    ) : (
                        <div className="delete-icon"></div>
                    )}
                </button>
            </div>
        );
    }

    renderExistingFile = (file, index) => {
        const fileNameClassNames = classNames('file-name', {
            'invalid': file.invalid
        });

        return (
            <div className="file-list-item" key={file + index}>
                <div className={fileNameClassNames}>{file}</div>
                <button type="button" className="delete-item-button"
                    disabled={this.props.deleteLoading || this.state.fileLoading}
                    onClick={() => this.removeFileAtIndex(index, true)}
                >
                    {this.state.fileLoading ? (
                        <LoadingSpinner className="delete-spinner" size="small" relative />
                    ) : (
                        <div className="delete-icon"></div>
                    )}
                </button>

            </div>
        );
    }

    renderFileList = () => {
        const noFileText = this.state.files.length === 0 ? 'No file uploaded' : null;

        const renderedFiles = this.state.files.map((file, index) => {
            return this.renderFile(file, index);
        });

        const renderedExistingFiles = this.state.existingFiles.map((file, index) => {
            return this.renderExistingFile(file, index);
        });

        return (
            <div className="">
                <div className="no-file-text">{_.isEmpty(this.state.files) && _.isEmpty(this.state.existingFiles) ? noFileText : null}</div>
                {renderedFiles}
                {renderedExistingFiles}
            </div>
        );
    }

    render() {
        return (
            <Dropzone
                // eslint-disable-next-line max-len
                accept="image/jpeg, image/png, application/pdf, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/gif, image/tiff, image/bmp, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/svg+xml, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.apple.numbers, image/webp, application/rtf, application/vnd.oasis.opendocument.text"
                className="dropzone"
                name="files"
                disableClick
                onDrop={this.onDrop}
                ref={(node) => {
                    this.dropzoneRef = node;
                }}>
                <div className="upload-button" onClick={this.onOpenFileDialog}>
                    <div className="icon"></div>
                    <p>Upload Documents</p>
                </div>
                <div className="file-list">
                    {this.renderFileList()}
                </div>
            </Dropzone>
        );
    }
}

export const mapStateToProps = (state) => ({
    MerchantAppData: getMerchantAppData(state),
    deleteError: isDeleteAppFileError(state),
    deleteErrorMessage: getDeleteAppFileErrorMessage(state),
    deleteLoading: getIsDeleteAppFileLoading(state),
    deleteSuccessful: getIsDeleteAppFileSuccessful(state),
    IsGetUploadLinkSuccessful: getUploadLinkSuccessful(state),
    IsGetUploadLinkLoading: getUploadLinkLoading(state),
    uploadLinkErrorMessage: getUploadLinkErrorMessage(state),
    uploadLinkError: getUploadLinkError(state)
});

export default connect(mapStateToProps)(FileUpload);
