import React from 'react';
import _ from 'lodash';
import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import { BANK_DATA } from '../../../../../actions/sectionsActions';
import './DocusignCB.scss';
import { getLocalStorageItem } from '../../../../../utils/localStorage';
import { connect } from 'react-redux';
import { getIsPostBankDataLoading, getIsValidateBankDataLoading } from './docusignCBReducer';
import { getBankData, getMerchantAppData, getMerchantProfile } from '../../../../../reducers/sectionsReducer';

export class DocusignCB extends BaseSection {
    sectionName = BANK_DATA;

    static defaultProps = {
        dispatch: () => { }
    };

    state = {
        contactName: { value: '', valid: false },
        phoneNumber: { value: '', valid: false },
        nameOnCheck: { value: '', valid: false },
        accountNumber: { value: '', valid: false },
        routingNumber: { value: '', valid: false },
        repeatAccountNumber: { value: '', valid: false },
        bubbleCount: 4,
        tokenOK: false,
        disableButton: true,
        showFinalPage: false,
        errorMessage: ''
    };

    searchStringToObject(searchString) {
        const searchArrayParams = this.props.location.search.split(/[?&]+/);
        const retObj = {};
        searchArrayParams.forEach((param) => {
            if (param) {
                const pArray = _.split(param, '=');
                retObj[pArray[0]] = pArray[1];
            }
        });

        return retObj;
    }

    componentDidMount() {
        if (this.props.location.search) {

            const paramsObj = this.searchStringToObject(this.props.location.search);
            switch (paramsObj.event) {
                case 'signing_complete':
                    /** if i am in an iframe jump up to parent **/
                    if (window.location !== window.parent.location) {
                        window.parent.location = window.location;

                        return;
                    } else {
                        let pagetoDisplay = 'signing_complete';
                        if (paramsObj.s === 'primary_signer') {
                            pagetoDisplay = 'primary_signing_complete';
                        }
                        this.setState({
                            pagetoDisplay: pagetoDisplay
                        });
                    }
                    break;
                default:
                    this.setState({
                        pagetoDisplay: paramsObj.event
                    });
            }

            const tokenOK = getLocalStorageItem('merchant-token') === paramsObj.t;
            this.setState({
                tokenOK
            });
            const totalPages = getLocalStorageItem('totalPages') ? getLocalStorageItem('totalPages') : 4;
            this.setState({
                bubbleCount: parseInt(totalPages) + 1
            });
        }
    }

    renderNonPrimaryCompletePage() {
        return (
            <Section className="bank-data" heading="Information Received">
                <div>
                    <p>
                        Thank You! Your information has been received.
                    </p>
                </div>
            </Section>
        );
    }

    renderFinalPage() {
        return (
            <Section className="bank-data" heading="Information Received">
                <div>
                    <p>
                        Congratulations! Your information has been received.
                    </p>
                </div>
            </Section>
        );
    }

    renderGenericPage(action) {
        /** these are the actions from
         * https://developers.docusign.com/esign-rest-api/guides/features/embedding
         **/

        let heading = 'Information';
        let message = '';
        switch (action) {
            case 'cancel':
            case 'decline':
            case 'fax_pending':
            case 'id_check_failed':
                heading = 'Information Incomplete';
                message = 'The Document will need to be signed to continue. Please refer to the original Email to restart the signing process.';
                break;
            case 'exception':
                heading = 'Information Incomplete';
                message = 'There was an error processing this document. Please refer to the original Email to restart the signing process.';
                break;
            case 'session_timeout':
            case 'ttl_expired':
                heading = 'Session Expired';
                message = 'You session has expired , please refer back to your email and click on the link';
                break;
            case 'viewing_complete':
                heading = 'Complete';
                message = '';
                break;
            default:
        }

        return (
            <Section className="bank-data" heading={heading}>
                <div>
                    <p>{message}</p>
                </div>
            </Section>
        );
    }

    render() {
        if (this.state.tokenOK) {
            if (this.state.pagetoDisplay === 'primary_signing_complete') {
                return (
                    <div>
                        {this.renderFinalPage()}
                    </div>
                );
            } else if (this.state.pagetoDisplay === 'signing_complete') {
                return this.renderNonPrimaryCompletePage();
            } else {
                return this.renderGenericPage(this.state.pagetoDisplay);
            }
        } else {
            return (
                <div></div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isPostBankDataLoading: getIsPostBankDataLoading(state),
        isValidateBankDataLoading: getIsValidateBankDataLoading(state),
        bankData: getBankData(state),
        merchantAppData: getMerchantAppData(state),
        merchantProfile: getMerchantProfile(state)
    };
};

export default connect(mapStateToProps)(DocusignCB);