import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../Shared/Modal/Modal';
import Select from '../../../Shared/Select/Select';
import { fetchEnabledPartners } from '../../../../actions/partnerActions';
import {
    getEnabledPartners
} from '../../../../reducers/partnersReducer';
import {
    getFormFields,
    getFormSections
} from '../../../../reducers/formFieldsReducer';
import { fetchPartnerDefaults, savePartnerDefaults } from './PartnerDefaultsActions';
import { getIsLoadingPartnerDefaults, getPartnerDefaults } from './PartnerDefaultsReducer';
import { fetchFormFields } from '../../../../actions/formFieldsActions';
import './PartnerDefaults.scss';
import { isChecked } from '../../../../utils/form';

const PartnerDefaultsModal = ({ isOpen, handleCancel }) => {
    const dispatch = useDispatch();

    const partners = useSelector(getEnabledPartners);
    const formFields = useSelector(getFormFields);
    const sections = useSelector(getFormSections);

    const isLoadingPartnerDefaults = useSelector(getIsLoadingPartnerDefaults);
    const partnerDefaults = useSelector(getPartnerDefaults);

    const [currentPage, setCurrentPage] = useState('partnerSectionSelect');
    const [partnerId, setPartnerId] = useState();
    const [sectionId, setSectionId] = useState();
    const [currentPartnerDefaults, setCurrentPartnerDefaults] = useState([]);

    useEffect(() => {
        if (isOpen) {
            setCurrentPage('partnerSectionSelect');
            setSectionId('');
        }
    }, [isOpen]);

    useEffect(() => {
        if (_.isEmpty(partners)) {
            dispatch(fetchEnabledPartners());
        }

        if (_.isEmpty(formFields)) {
            dispatch(fetchFormFields());
        }
    });

    useEffect(() => {
        if (partnerId) {
            dispatch(fetchPartnerDefaults(partnerId));
        }

    }, [partnerId]);

    useEffect(() => {
        if (sectionId) {
            dispatch(fetchPartnerDefaults(partnerId));
        }

    }, [sectionId]);

    const handleFormClose = () => {
        handleCancel();
    };

    const handleNextOnClick = (e) => {
        e.preventDefault();

        setCurrentPage('partnerSectionDefaultsPage');
        setCurrentPartnerDefaults(partnerDefaults);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        await dispatch(savePartnerDefaults(currentPartnerDefaults));

        handleFormClose();
    };

    const getSectionList = () => {
        const defaultableSectionsList = [2, 3, 4, 8, 9, 11];

        return (sections || []).filter((section) => defaultableSectionsList.includes(section.id))
            .map((section) => ({
                label: _.startCase(section.name),
                value: section.id
            }));
    };

    const getPartnerList = () => {
        return (partners || []).map((partner) => ({
            label: partner.Name,
            value: partner.id
        }));
    };

    const getPartnerSectionInputDefaultValue = (formInputId) => {
        const { defaultValue } = currentPartnerDefaults.find((def) =>
            (def.partnerId === partnerId && def.formNameId === formInputId)
        ) || {};

        return defaultValue;
    };

    const updatePartnerDefault = (defaultValue, formNameId, partnerId) => {
        const newDefault = { partnerId, formNameId, sectionId, defaultValue };

        const unique = _.uniqBy([newDefault, ...currentPartnerDefaults], 'formNameId');

        setCurrentPartnerDefaults(unique);
    };

    const renderFormInput = (input) => {
        const defaultValue = getPartnerSectionInputDefaultValue(input.id);

        return (
            <div className="default-value" key={input.id}>
                <label className="default-label" htmlFor={input.formName}>{input.inputLabel || input.dbColumnName}</label>
                <input
                    type="text"
                    value={defaultValue}
                    name={input.formName}
                    placeholder={input.dbColumnName}
                    onChange={(e) => updatePartnerDefault(e.target.value, input.id, partnerId)}
                />
            </div>
        );
    };

    const renderMerchantAppDataFormInput = (input) => {
        let value = getPartnerSectionInputDefaultValue(input.id);

        let defaultValue;

        if (value === 'false' || value === false) {
            defaultValue = false;
        } else {
            defaultValue = true;
        }

        return (
            <div className="default-value" key={input.id}>
                <label className="default-label" htmlFor={input.formName}>{input.inputLabel || input.dbColumnName}</label>
                <input
                    type="checkbox"
                    value={defaultValue}
                    name={input.formName}
                    checked={isChecked(defaultValue)}
                    placeholder={input.dbColumnName}
                    onChange={() => updatePartnerDefault(`${!defaultValue}`, input.id, partnerId)}
                />
            </div>
        );
    };

    const renderPartnerSectionPage = () => {
        return (
            <div className="partner-section-select">
                <div className="partner-select dropdown">
                    <label htmlFor="partnerId">Partner Name</label>
                    <Select id="partnerId"
                        name="partnerId"
                        handleChange={(e) => setPartnerId(_.toNumber(e.target.value))}
                        value={partnerId}
                        options={getPartnerList()}
                        required={true}
                    />
                </div>
                <div className="section-select dropdown">
                    <label htmlFor="sectionId">Section</label>
                    <Select id="sectionId"
                        name="sectionId"
                        handleChange={(e) => setSectionId(_.toNumber(e.target.value))}
                        value={sectionId}
                        options={getSectionList()}
                        required={true}
                    />
                </div>
                <button onClick={handleNextOnClick} disabled={isLoadingPartnerDefaults || !partnerId || !sectionId} className="next-button">
                    Next
                </button>
            </div>
        );
    };

    const renderDefaultsPage = () => {
        const selectedSection = formFields.find((section) => section.sectionId === sectionId) || {};
        if (selectedSection) {
            if (selectedSection.sectionId === 11) {
                return (
                    <form className="partner-defaults" onSubmit={handleSubmit}>
                        {selectedSection.formNames.map(renderMerchantAppDataFormInput)}
                        <button disabled={isLoadingPartnerDefaults} className="save-button">
                            Save
                        </button>
                    </form>
                );
            } else {
                return (
                    <form className="partner-defaults" onSubmit={handleSubmit}>
                        {selectedSection.formNames.map(renderFormInput)}
                        <button disabled={isLoadingPartnerDefaults} className="save-button">
                            Save
                        </button>
                    </form>
                );
            }
        }
    };

    const partnerDefaultsModalClassName = classNames('partner-defaults-modal', {
        'partnerSectionDefaultsPage': currentPage === 'partnerSectionDefaultsPage',
        'partnerSectionSelect': currentPage === 'partnerSectionSelect'
    });

    return (
        <Modal
            title="Edit Partner Defaults"
            isOpen={isOpen}
            handleClose={handleFormClose}
            className={partnerDefaultsModalClassName} >
            {currentPage === 'partnerSectionSelect' ? renderPartnerSectionPage() : renderDefaultsPage()}
        </Modal>
    );
};

PartnerDefaultsModal.propTypes = {
    isOpen: PropTypes.bool,
    handleCancel: PropTypes.func.isRequired,
    formFields: PropTypes.array,
    partners: PropTypes.array,
    isLoadingPartnerDefaults: PropTypes.bool,
    partnerDefaults: PropTypes.array
};

export default PartnerDefaultsModal;
