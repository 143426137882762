import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mixpanel from '../../../utils/mixpanel';
import { connect } from 'react-redux';
import './SSNInput.scss';
import { getMerchantAppMerchantHash } from '../../../reducers/sectionsReducer';

export class SSNInput extends Component {
    constructor(props) {
        super(props);
        this.inputField1Focus = React.createRef();
        this.inputField2Focus = React.createRef();
        this.inputField3Focus = React.createRef();
    }

    state = {
        field1: '',
        field2: '',
        field3: '',
        value: this.props.value
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        placeHolder: PropTypes.string,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        type: PropTypes.string,
        required: PropTypes.bool,
        valid: PropTypes.bool,
        errorMessage: PropTypes.string,
        readOnly: PropTypes.bool,
        merchantHash: PropTypes.string,
        dataId: PropTypes.string
    };

    static defaultProps = {
        placeHolder: '',
        disabled: false,
        type: 'text',
        required: false,
        valid: true,
        errorMessage: '',
        dataId: null
    };

    componentDidUpdate(previousProps, previousStates) {
        const { value } = this.props;
        if (value && previousProps.value !== value && previousProps.name === this.props.name) {
            this.setState({
                field1: (value && value.length > 2) ? value.substring(0, 3) : '',
                field2: (value && value.length > 5) ? value.substring(3, 5) : '',
                field3: (value && value.length >= 9) ? value.substring(5, 9) : ''
            });
        }
    }

    componentDidMount() {
        const { value } = this.props;
        this.setState({
            field1: (value && value.length > 2) ? value.substring(0, 3) : '',
            field2: (value && value.length > 5) ? value.substring(3, 5) : '',
            field3: (value && value.length >= 9) ? value.substring(5, 9) : ''
        });
    }

    componentWillUnmount() {
        this.handleSSNBlur();
    }

    changeSSN = (event) => {
        event.persist();
        if (event.target.name === 'field1' && event.target.value.length === 3) {
            this.inputField2Focus.current.focus();
        }
        if (event.target.name === 'field2' && event.target.value.length === 2) {
            this.inputField3Focus.current.focus();
        }
        let setStateOK = true;
        switch (event.target.name) {
            case 'field1':
                setStateOK = event.target.value.length <= 3;
                break;
            case 'field2':
                setStateOK = event.target.value.length <= 2;
                break;
            case 'field3':
                setStateOK = event.target.value.length <= 4;
                break;
            default:
                //should not happen
                break;
        }
        if (setStateOK) {
            this.setState({
                [event.target.name]: event.target.value
            }, () => {
                if (this.isSSNValid()) {
                    this.props.handleChange({
                        persist: () => { },
                        target: {
                            name: this.props.name,
                            value: `${this.state.field1}${this.state.field2}${this.state.field3}`
                        }
                    });
                    mixpanel.track(`${this.props.merchantHash}--${this.props.name}`);
                }
            });
        }
    };

    /** this is to deal with html number fields. number fields allow the
     * character e and - at the beginning and in the middle
     **/
    keyPressed = (e) => {
        if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === '.') {
            e.preventDefault();
        }
    };

    handleSSNBlur = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.isSSNValid()) {
            this.props.handleBlur({
                target: {
                    name: this.props.name,
                    value: `${this.state.field1}${this.state.field2}${this.state.field3}`
                }
            });
            mixpanel.track(`${this.props.merchantHash}--${this.props.name}`);
        }
    };

    isSSNValid() {
        return (this.state.field1 && this.state.field1.length === 3 &&
            this.state.field2 && this.state.field2.length === 2 &&
            this.state.field3 && this.state.field3.length === 4) ||
            (this.state.field1 === '' && this.state.field2 === '' && this.state.field3 === '');
    }

    renderInput() {
        const { name, value, placeHolder, disabled, required, readOnly, dataId } = this.props;
        const { field1, field2, field3 } = this.state;
        if (readOnly) {
            return <div className="read-only-input" name={name}>
                {value}
            </div>;
        } else {
            return <div className="ssn-input-wrapper" >
                <input
                    required={true}
                    pattern="[0-9]{9}"
                    style={{ display: 'none' }}
                    value={`${field1}${field2}${field3}`}
                    name={this.props.name}
                    onChange={() => { }}
                    data-id={dataId}
                />
                <div className="ssn-input">
                    <span></span>
                    <input
                        className="ssn-input-field1"
                        type="number"
                        placeholder={placeHolder}
                        name={'field1'}
                        value={this.state.field1}
                        onChange={this.changeSSN}
                        onBlur={this.handleSSNBlur}
                        disabled={disabled}
                        required={required}
                        maxLength="3"
                        ref={this.inputField1Focus}
                        onKeyPress={this.keyPressed}
                    />
                    <span>-</span>
                    <input
                        className="ssn-input-field2"
                        type="number"
                        placeholder={placeHolder}
                        name={'field2'}
                        value={this.state.field2}
                        onChange={this.changeSSN}
                        onBlur={this.handleSSNBlur}
                        disabled={disabled}
                        required={required}
                        maxLength="2"
                        ref={this.inputField2Focus}
                        onKeyPress={this.keyPressed}
                    />
                    <span>-</span>
                    <input
                        className="ssn-input-field3"
                        type="number"
                        placeholder={placeHolder}
                        name={'field3'}
                        value={this.state.field3}
                        onChange={this.changeSSN}
                        onBlur={this.handleSSNBlur}
                        disabled={disabled}
                        required={required}
                        maxLength="4"
                        ref={this.inputField3Focus}
                        onKeyPress={this.keyPressed}
                    />
                </div>
            </div>;
        }
    }

    render() {
        const { valid, errorMessage } = this.props;
        const className = `text-input-container ssn-input-container ${!valid ? 'text-input-error' : ''}`;

        return (
            <span className={className}>
                {this.renderInput()}
                {!valid && <span className="text-input-error-message">{errorMessage}</span>}
            </span>
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantHash: getMerchantAppMerchantHash(state)
});

export default connect(mapStateToProps)(SSNInput);
