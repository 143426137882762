import {
    FETCH_BANK_DATA_SUCCESS,
    FETCH_BANK_DATA_FAILURE,
    FETCH_BANK_DATA_LOAD,
    FETCH_MERCHANT_APPS_SUCCESS,
    FETCH_MERCHANT_APPS_FAILURE,
    FETCH_MERCHANT_APPS_LOAD
} from '../actions/appListActions';
import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import { createSelector } from 'reselect';
import _ from 'lodash';

export const appListReducer = createFetchReducer({
    loadActionType: FETCH_MERCHANT_APPS_LOAD,
    failureActionType: FETCH_MERCHANT_APPS_FAILURE,
    successActionType: FETCH_MERCHANT_APPS_SUCCESS,
    shouldPersistData: true
});

const appListBaseSelectors = createBaseSelectors('appListReducer');

export const getIsAppListLoading = appListBaseSelectors.getIsLoading;
export const getAppList = createSelector(
    appListBaseSelectors.getData,
    (data) => _.get(data, '0')
);
export const getAppListCount = createSelector(
    appListBaseSelectors.getData,
    (data) => _.get(data, '1')
);
export const getAppListErrorMessage = appListBaseSelectors.getErrorMessage;

export const bankDataReducer = createFetchReducer({
    loadActionType: FETCH_BANK_DATA_LOAD,
    failureActionType: FETCH_BANK_DATA_FAILURE,
    successActionType: FETCH_BANK_DATA_SUCCESS
});

const bankDataBaseSelectors = createBaseSelectors('bankDataReducer');

export const getIsBankDataLoading = bankDataBaseSelectors.getIsLoading;
export const getBankData = createSelector(
    bankDataBaseSelectors.getData,
    (data) => _.get(data, 'bankData')
);
export const getBankDataError = bankDataBaseSelectors.getErrorMessage;