import config from '../../../../../config/config';

export const FETCH_DIRECT_SALES_CONFIG_LOAD = 'FETCH_DIRECT_SALES_CONFIG_LOAD';
export const FETCH_DIRECT_SALES_CONFIG_FAILURE = 'FETCH_DIRECT_SALES_CONFIG_FAILURE';
export const FETCH_DIRECT_SALES_CONFIG_SUCCESS = 'FETCH_DIRECT_SALES_CONFIG_SUCCESS';

export const getDirectSalesConfigData = () => {
    return {
        fetchParams: {
            url: `${config.cforceServiceUrl}/DirectSales`
        },
        loadActionTypeOrCreator: FETCH_DIRECT_SALES_CONFIG_LOAD,
        failureActionTypeOrCreator: FETCH_DIRECT_SALES_CONFIG_FAILURE,
        successActionTypeOrCreator: FETCH_DIRECT_SALES_CONFIG_SUCCESS
    };
};