import config from '../config/config';
import { storeApplicationSections } from './sectionsActions';
import { setLocalStorageItem } from '../utils/localStorage';
import { createSuccessActionCreator } from '@nexio/ui-redux';
import { setIsMerchantAuthorized } from './applicationActions';

export const FETCH_APPLICATION_MERCHANT_LOAD = 'FETCH_APPLICATION_MERCHANT_LOAD';
export const FETCH_APPLICATION_MERCHANT_FAILURE = 'FETCH_APPLICATION_MERCHANT_FAILURE';
export const FETCH_APPLICATION_MERCHANT_SUCCESS = 'FETCH_APPLICATION_MERCHANT_SUCCESS';

export const fetchApplicationMerchant = (merchantToken, partnerHash, merchantHash) => {
    return {
        fetchParams: {
            headers: {
                Authorization: merchantToken
            },
            url: `${config.cforceServiceUrl}/partner/${partnerHash}/merchant/${merchantHash}`
        },
        loadActionTypeOrCreator: FETCH_APPLICATION_MERCHANT_LOAD,
        failureActionTypeOrCreator: FETCH_APPLICATION_MERCHANT_FAILURE,
        successActionTypeOrCreator: (data) => ({ dispatch }) => {
            dispatch(storeApplicationSections(data.sections));

            setLocalStorageItem('merchant-token', merchantToken);
            dispatch(setIsMerchantAuthorized(true));
            dispatch(createSuccessActionCreator(FETCH_APPLICATION_MERCHANT_SUCCESS)(data));
        }
    };
};