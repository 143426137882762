import config from '../../../../../config/config';

export const SEND_APP_LINK_EMAIL_LOAD = 'SEND_APP_LINK_EMAIL_LOAD';
export const SEND_APP_LINK_EMAIL_FAILURE = 'SEND_APP_LINK_EMAIL_FAILURE';
export const SEND_APP_LINK_EMAIL_SUCCESS = 'SEND_APP_LINK_EMAIL_SUCCESS';

export const sendAppLinkEmail = ({
    agentName,
    agentPhone,
    agentEmail,
    link,
    token,
    emailBody,
    emailTo,
    merchantAppId
}) => {
    return {
        loadActionTypeOrCreator: SEND_APP_LINK_EMAIL_LOAD,
        failureActionTypeOrCreator: SEND_APP_LINK_EMAIL_FAILURE,
        successActionTypeOrCreator: SEND_APP_LINK_EMAIL_SUCCESS,
        fetchParams: {
            body: {
                agentName,
                agentPhone,
                agentEmail,
                link,
                token,
                emailBody,
                emailTo,
                merchantAppId
            },
            method: 'POST',
            url: `${config.cforceServiceUrl}/email`
        }
    };
};