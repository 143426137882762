import { Component } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { saveApplicationData } from '../../../../actions/sectionsActions';
import PropTypes from 'prop-types';

export class BaseSection extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        runValidation: PropTypes.func
    };

    defaultValidationFunction = () => ({
        isValid: true,
        message: ''
    });

    componentWillUnmount = async () => {
        await this.saveDataAndCheckDone(this.state);
    };

    handleRadioChange = async (e) => {
        this.setState({ [e.target.name]: parseInt(e.target.value) });
        await this.saveAndCheckDone(e.target.name, parseInt(e.target.value));

        this.props.runValidation();
    };

    handleBooleanRadioChange = async (e) => {
        let value = e.target.value === 'true';
        this.setState({ [e.target.name]: value });
        await this.saveAndCheckDone(e.target.name, value);

        this.props.runValidation();
    };

    handleSelectChange = async (e) => {
        this.setState({ [e.target.name]: e.target.value });
        await this.saveAndCheckDone(e.target.name, e.target.value);

        this.props.runValidation();
    };

    handleCheckboxChange = async (e) => {
        this.setState({ [e.target.name]: e.target.checked });
        await this.saveAndCheckDone(e.target.name, e.target.checked ? 1 : 0);

        this.props.runValidation();
    };

    handleFileInputChange = async (name, files) => {

        this.setState({ [name]: files });
        await this.saveAndCheckDone(name, files);

        this.props.runValidation();
    };

    handleShortTextChange = (numberOfCharacters) => (e) => {
        e.persist();
        const value = _.get(e, 'target.value') || '';
        this.setState({
            [e.target.name]: value.substring(0, numberOfCharacters)
        });
    };

    handleTextChange = (e) => {
        e.persist();
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleTextChangeWithTrim = (e) => {
        e.persist();
        this.setState({
            [e.target.name]: _.trim(e.target.value)
        });
    };

    handleTextBlur = (validationFunction = this.defaultValidationFunction) => async (e) => {
        const valueToSave = this.state[e.target.name];

        const { isValid, message } = validationFunction(valueToSave);
        if (isValid) {
            await this.saveAndCheckDone(e.target.name, valueToSave);
        } else {
            toast.error(message);
        }

        this.props.runValidation();
    };

    saveAndCheckDone = async (key, value) => {
        const { dispatch } = this.props;
        // when doing redux actions just post a message to parent window to reset the timer
        window.parent.postMessage({ refresh: true }, '*');
        await dispatch(saveApplicationData(this.sectionName, {
            [key]: value
        }));
    };

    saveDataAndCheckDone = async (data) => {
        const { dispatch } = this.props;
        // when doing redux actions just post a message to parent window to reset the timer
        window.parent.postMessage({ refresh: true }, '*');
        await dispatch(saveApplicationData(this.sectionName, data));
    };

    saveAndCheckDoneSpecific = async (section, key, value) => {
        const { dispatch } = this.props;
        window.parent.postMessage({ refresh: true }, '*');
        await dispatch(saveApplicationData(section, {
            [key]: value
        }));
    }

    handleFocus = (e) => {
        e.persist();
        this.setState({
            currentFocused: e.target.name
        });
    };

    render() {
        return null;
    }
}

export default BaseSection;
