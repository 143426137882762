import React from 'react';
import { connect } from 'react-redux';
import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import { MERCHANT_APP_PARTNER } from '../../../../../actions/sectionsActions';
import {
    getIsValid,
    getErrorMessage,
    isEmail
} from '../../../../../utils/form';
import _ from 'lodash';
import { getIs1099App, getMerchantAppPartner } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class MerchantAppPartnerAgentInfo extends BaseSection {
    sectionName = MERCHANT_APP_PARTNER;

    state = {
        agentName: '',
        agentEmail: ''
    }

    constructor(props) {
        super(props);

        this.state = {
            ...props.merchantAppPartner,
            is1099App: props.is1099App

        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.merchantAppPartner, this.props.merchantAppPartner)) {
            this.setState({
                ...this.props.merchantAppPartner
            });
        }
    }

    render() {
        const { invalidInputs, is1099App } = this.props;

        if (is1099App) {
            return (
                <Section className="merchant-app-partner" heading="Agent Info (To Be Completed by Sales Representative)" >
                    <Group>
                        <div className="form-input-wrapper" id="agentName" >
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">Agent Name: </label>
                                    </div>
                                </Row>
                                <TextInput
                                    name="agentName"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={this.state.agentName}
                                    required={is1099App === 1}
                                    valid={getIsValid('agentName', invalidInputs)}
                                    errorMessage={getErrorMessage('agentName', invalidInputs)}
                                />
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="agentEmail" >
                            <div className="item-wrapper">
                                <Row>
                                    <div className="start">
                                        <label className="label">Agent Email: </label>
                                    </div>
                                </Row>
                                <TextInput
                                    name="agentEmail"
                                    handleChange={this.handleTextChangeWithTrim}
                                    handleBlur={this.handleTextBlur(isEmail)}
                                    value={this.state.agentEmail}
                                    required={is1099App === 1}
                                    valid={getIsValid('agentEmail', invalidInputs)}
                                    errorMessage={getErrorMessage('agentEmail', invalidInputs)}
                                />
                            </div>
                        </div>
                    </Group>
                </Section>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
}

export const mapStateToProps = (state) => ({
    merchantAppPartner: getMerchantAppPartner(state),
    is1099App: getIs1099App(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(MerchantAppPartnerAgentInfo);
