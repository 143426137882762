import {
    IS_USER_AN_AGENT,
    SET_IS_MERCHANT_AUTHORIZED
} from '../actions/applicationActions';
import { createSelector } from 'reselect';
import _ from 'lodash';

const initialState = {
    isUserAnAgent: false,
    isMerchantAuthorized: false
};

export const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_USER_AN_AGENT:
            return { ...state, isUserAnAgent: action.isUserAnAgent };
        case SET_IS_MERCHANT_AUTHORIZED:
            return { ...state, isMerchantAuthorized: action.isMerchantAuthorized };
        default:
            return state;
    }
};

export const getApplicationSlice = createSelector(
    (state) => state,
    (state) => _.get(state, 'applicationReducer')
);

export const getIsMerchantAuthorized = createSelector(
    getApplicationSlice,
    (slice) => _.get(slice, 'isMerchantAuthorized')
);

export const getIsUserAnAgent = createSelector(
    getApplicationSlice,
    (slice) => _.get(slice, 'isUserAnAgent')
);
