import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './BusinessInfo.scss';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import PhoneInput from '../../../../Shared/TextInput/PhoneInput';
import Select from '../../../../Shared/Select/Select';
import {
    BUSINESS_INFO
} from '../../../../../actions/sectionsActions';
import { businessInfo } from '../../../../../utils/dataShapes';
import { states } from '../../StaticData/staticData';
import {
    shouldHideElement,
    shouldBeRequired,
    shouldNotBeRequired,
    getErrorMessage,
    isEmail,
    getIsValid,
    shouldShowHelp,
    isValidWebsite
} from '../../../../../utils/form';
import { toast } from 'react-toastify';
import { getBusinessInfo } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class BusinessInfo extends BaseSection {
    sectionName = BUSINESS_INFO;

    state = {
        dbaName: this.props.businessInfo.dbaName,
        legalName: this.props.businessInfo.legalName,
        businessPhone: this.props.businessInfo.businessPhone,
        physicalAddress: this.props.businessInfo.physicalAddress,
        physicalAddressCity: this.props.businessInfo.physicalAddressCity,
        physicalAddressState: this.props.businessInfo.physicalAddressState,
        physicalAddressZip: this.props.businessInfo.physicalAddressZip,
        legalAddress: this.props.businessInfo.legalAddress,
        legalAddressCity: this.props.businessInfo.legalAddressCity,
        legalAddressState: this.props.businessInfo.legalAddressState,
        legalAddressZip: this.props.businessInfo.legalAddressZip,
        dbaNumber: this.props.businessInfo.dbaNumber,
        webAddress: this.props.businessInfo.webAddress,
        customerServicePhone: this.props.businessInfo.customerServicePhone,
        fax: this.props.businessInfo.fax,
        email: this.props.businessInfo.email,
        contactName: this.props.businessInfo.contactName,
        isUserAnAgent: this.props.isUserAnAgent,
        currentFocused: 'fred',
        gettingData: true,
        sameAsPhysicalAddress: false
    };

    static propTypes = {
        businessInfo: PropTypes.shape(businessInfo)
    };

    handleSameAsCheckboxChange =  async (event) => {
        const stateObj = {
            sameAsPhysicalAddress: event.target.checked,
            legalAddress: event.target.checked ? this.state.physicalAddress : this.state.legalAddress,
            legalAddressCity: event.target.checked ? this.state.physicalAddressCity : this.state.legalAddressCity,
            legalAddressZip: event.target.checked ? this.state.physicalAddressZip : this.state.legalAddressZip,
            legalAddressState: event.target.checked ? this.state.physicalAddressState : this.state.legalAddressState
        };
        if (event.target.checked){
            const updateObj = {
                legalAddress: this.state.physicalAddress,
                legalAddressCity: this.state.physicalAddressCity,
                legalAddressState: this.state.physicalAddressState,
                legalAddressZip: this.state.physicalAddressZip
            };
            await this.saveDataAndCheckDone(updateObj);
        }
        this.setState(stateObj);
    };

    handleAddressSelectChange = async (e) => {
        this.setState({ [e.target.name]: e.target.value });

        const updateObj = {
            [e.target.name]: e.target.value
        };
        if (this.state.sameAsPhysicalAddress){
            const legalName = e.target.name.replace('physical', 'legal');
            updateObj[legalName] = e.target.value;
        }
        await this.saveDataAndCheckDone(updateObj);

        this.props.runValidation();
    };

    handleAddressTextBlur = (validationFunction = this.defaultValidationFunction) => async (e) => {
        const valueToSave = this.state[e.target.name];

        const { isValid, message } = validationFunction(valueToSave);
        if (isValid) {
            const updateObj = {
                [e.target.name]: valueToSave
            };
            if (this.state.sameAsPhysicalAddress){
                const legalName = e.target.name.replace('physical', 'legal');
                updateObj[legalName] = valueToSave;
            }
            await this.saveDataAndCheckDone(updateObj);
        } else {
            toast.error(message);
        }

        this.props.runValidation();
    };

    handleBlurForBankDataSave = async (e) => {
        if (e.persist) {
            e.persist();
        }

        const valueToSave = this.state[e.target.name];

        await this.saveAndCheckDone(e.target.name, valueToSave);

        let key;

        switch (e.target.name) {
            case 'legalName':
                key = 'nameOnCheck';
                break;
            case 'businessPhone':
                key = 'phoneNumber';
                break;
            case 'contactName':
                key = 'contactName';
                break;
            default:
                key = e.target.name;
        }

        await this.saveAndCheckDoneSpecific('BankData', key, valueToSave);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { sameAsPhysicalAddress } = this.state;

        if (!_.isEqual(prevProps.businessInfo, this.props.businessInfo)) {
            const updateObj = {
                dbaName: this.props.businessInfo.dbaName,
                legalName: this.props.businessInfo.legalName,
                businessPhone: this.props.businessInfo.businessPhone,
                physicalAddress: this.props.businessInfo.physicalAddress,
                physicalAddressCity: this.props.businessInfo.physicalAddressCity,
                physicalAddressState: this.props.businessInfo.physicalAddressState,
                physicalAddressZip: this.props.businessInfo.physicalAddressZip,
                legalAddress: sameAsPhysicalAddress ? this.state.physicalAddress : this.props.businessInfo.legalAddress,
                legalAddressCity: sameAsPhysicalAddress ? this.state.physicalAddressCity : this.props.businessInfo.legalAddressCity,
                legalAddressZip: sameAsPhysicalAddress ? this.state.physicalAddressZip : this.props.businessInfo.legalAddressZip,
                legalAddressState: sameAsPhysicalAddress ? this.state.physicalAddressState : this.props.businessInfo.legalAddressState,
                dbaNumber: this.props.businessInfo.dbaNumber,
                webAddress: this.props.businessInfo.webAddress,
                customerServicePhone: this.props.businessInfo.customerServicePhone,
                fax: this.props.businessInfo.fax,
                email: this.props.businessInfo.email,
                contactName: this.props.businessInfo.contactName,
                isUserAnAgent: this.props.isUserAnAgent,
                gettingDate: false
            };
            this.setState(updateObj);
            if (sameAsPhysicalAddress){
                this.state.legalAddress = this.state.physicalAddress;
                this.state.legalAddressCity = this.state.physicalAddressCity;
                this.state.legalAddressState = this.state.physicalAddressState;
                this.state.legalAddressZip = this.state.physicalAddressZip;
            }
        }
    }

    renderLegalAddress(){
        const { businessInfo, invalidInputs } = this.props;
        const { legalAddress, legalAddressCity, legalAddressState, legalAddressZip, sameAsPhysicalAddress } = this.state;

        if (sameAsPhysicalAddress){
            return <Group>
                <div className="form-input-wrapper">
                    <Row>
                        <label className="label">
                            Legal Address
                        </label>
                        <div className="same-as-checkbox">
                            <input
                                type="checkbox"
                                name="sameas"
                                checked={sameAsPhysicalAddress}
                                onChange={this.handleSameAsCheckboxChange}
                            />
                            <label>Same as Physical Address</label>
                        </div>
                    </Row>
                </div>
            </Group>;
        } else {
            return <Group>
                <div className="form-input-wrapper">
                    <div className="arrow-box" style={shouldShowHelp('legalAddress', this.state.currentFocused)}>Mailing address. Typically this will be in the state where your business is filed.</div>
                    <div className="item-wrapper" id="legalAddress" style={shouldHideElement('legalAddress', businessInfo.hide)}>
                        <Row>
                            <label className="label">
                                Legal Address
                            </label>
                            <div className="same-as-checkbox">
                                <input
                                    type="checkbox"
                                    name="sameas"
                                    value={sameAsPhysicalAddress}
                                    onChange={this.handleSameAsCheckboxChange}
                                />
                                <label>Same as Physical Address</label>
                            </div>
                        </Row>
                        <TextInput
                            name="legalAddress"
                            handleChange={this.handleTextChange}
                            handleBlur={this.handleTextBlur()}
                            value={legalAddress}
                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('legalAddress', businessInfo.notRequired)}
                            valid={getIsValid('legalAddress', invalidInputs)}
                            errorMessage={getErrorMessage('legalAddress', invalidInputs)}
                            placeHolder={'Legal Address'}
                            handleFocus={this.handleFocus}
                        />
                    </div>
                </div>
                <div className="form-input-wrapper">
                    <div className="arrow-box" style={shouldShowHelp('legalAddressCity', this.state.currentFocused)}>Legal Address City</div>
                    <div className="item-wrapper" id="legalAddressCity" style={shouldHideElement('legalAddressCity', businessInfo.hide)}>
                        <div>
                            <label className="label">City</label>
                        </div>
                        <TextInput
                            name="legalAddressCity"
                            handleChange={this.handleTextChange}
                            handleBlur={this.handleTextBlur()}
                            value={legalAddressCity}
                            required={this.state.isUserAnAgent ? false : shouldNotBeRequired('legalAddressCity', businessInfo.notRequired)}
                            valid={getIsValid('legalAddressCity', invalidInputs)}
                            errorMessage={getErrorMessage('physicalAddressZip', invalidInputs)}
                            placeHolder={'City'}
                            handleFocus={this.handleFocus}
                        />
                    </div>
                </div>
                <div className="form-input-wrapper">
                    <div className="multi-item-wrapper">
                        <div id="legalAddressState" className="half-width state-selector" style={shouldHideElement('legalAddressState', businessInfo.hide)}>
                            <div>
                                <label className="label" style={{ marginBottom: '20px' }}>State</label>
                            </div>
                            <Select
                                name="legalAddressState"
                                handleChange={this.handleSelectChange}
                                value={ legalAddressState }
                                options={states}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('legalAddressState', businessInfo.notRequired)}
                                valid={getIsValid('legalAddressState', invalidInputs)}
                                errorMessage={getErrorMessage('legalAddressState', invalidInputs)}
                            />
                        </div>

                        <div className="item-wrapper half-width"
                            id="legalAddressZip"
                            style={shouldHideElement('legalAddressZip', businessInfo.hide)}>
                            <div>
                                <label className="label" style={{ marginLeft: '10px' }}>Zip</label>
                            </div>
                            <TextInput
                                name="legalAddressZip"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={legalAddressZip}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('legalAddressZip', businessInfo.notRequired)}
                                valid={getIsValid('legalAddressZip', invalidInputs)}
                                errorMessage={getErrorMessage('legalAddressZip', invalidInputs)}
                                placeHolder={'Zip'}
                            />
                        </div>

                    </div>
                </div>
            </Group>;

        }
    }

    render() {
        const { businessInfo, invalidInputs } = this.props;
        const {
            dbaName, legalName, businessPhone, physicalAddress, physicalAddressCity, physicalAddressState,
            physicalAddressZip, dbaNumber, webAddress, contactName, customerServicePhone, fax, email
        } = this.state;

        return (
            <Section heading="Business Information">
                <Group>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('dbaName', this.state.currentFocused)}>
                            The name of your business that you are “Doing Business As.” You may need a DBA filing. Also known as Fictitious Name, Assumed Name, Trade Name, etc. This will display on your customer’s credit card or bank statement.
                        </div>
                        <div className="item-wrapper" id="dbaName" style={shouldHideElement('dbaName', businessInfo.hide)}>
                            <Row>
                                <label className="label">
                                    Merchant’s DBA Name
                                </label>
                            </Row>
                            <TextInput
                                name="dbaName"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={dbaName}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('dbaName', businessInfo.notRequired)}
                                valid={getIsValid('dbaName', invalidInputs)}
                                errorMessage={getErrorMessage('dbaName', invalidInputs)}
                                placeHolder={'Doing Business As'}
                                handleFocus={this.handleFocus}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('legalName', this.state.currentFocused)} >
                            The legal name of your business, exactly how you filed with your state and the IRS. Please include any suffixes such as LLC, Inc, Corp, A Professional Corporation, etc. For sole props, this will be your full personal name.
                        </div>
                        <div className="item-wrapper" id="legalName" style={shouldHideElement('legalName', businessInfo.hide)}>
                            <Row>
                                <label className="label">
                                     Merchant’s Legal Name
                                </label>
                            </Row>
                            <TextInput
                                name="legalName"
                                handleChange={this.handleTextChange}
                                handleBlur={(e) => this.handleBlurForBankDataSave(e)}
                                value={legalName}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('legalName', businessInfo.notRequired)}
                                valid={getIsValid('legalName', invalidInputs)}
                                errorMessage={getErrorMessage('legalName', invalidInputs)}
                                placeHolder={'The Business\' Legal Name'}
                                handleFocus={this.handleFocus}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="multi-item-wrapper">
                            <div id="businessPhone" className="half-width" style={shouldHideElement('businessPhone', businessInfo.hide)}>
                                <Row>
                                    <label className="label">
                                        DBA phone
                                    </label>
                                </Row>
                                <PhoneInput
                                    phoneType="number"
                                    name="businessPhone"
                                    handleChange={this.handleTextChange}
                                    handleBlur={(e) => this.handleBlurForBankDataSave(e)}
                                    value={businessPhone}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('businessPhone', businessInfo.notRequired)}
                                    valid={getIsValid('businessPhone', invalidInputs)}
                                    errorMessage={getErrorMessage('businessPhone', invalidInputs)}
                                />
                            </div>
                            <div id="dbaNumber" className="half-width" style={shouldHideElement('dbaNumber', businessInfo.hide)}>
                                <Row>
                                    <label className="label">
                                        Corporate phone
                                    </label>
                                </Row>
                                <PhoneInput
                                    phoneType="number"
                                    name="dbaNumber"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={dbaNumber}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('dbaNumber', businessInfo.notRequired)}
                                    valid={getIsValid('dbaNumber', invalidInputs)}
                                    errorMessage={getErrorMessage('dbaNumber', invalidInputs)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('physicalAddress', this.state.currentFocused)} >
                            The physical location of your business (where the business is actually taking place) or your home address if it is home-based. Not a P.O. Box or mail forwarding location.
                        </div>
                        <div className="item-wrapper" id="physicalAddress" style={shouldHideElement('physicalAddress', businessInfo.hide)}>
                            <Row>
                                <label className="label">
                                    Physical Address (No P.O. Box)
                                </label>
                            </Row>
                            <TextInput
                                name="physicalAddress"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleAddressTextBlur()}
                                value={physicalAddress}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('physicalAddress', businessInfo.notRequired)}
                                valid={getIsValid('physicalAddress', invalidInputs)}
                                errorMessage={getErrorMessage('physicalAddress', invalidInputs)}
                                placeHolder={'Physical Address'}
                                handleFocus={this.handleFocus}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('physicalAddressCity', this.state.currentFocused)}>Physical Address City</div>
                        <div className="item-wrapper" id="physicalAddressCity" style={shouldHideElement('physicalAddressCity', businessInfo.hide)}>
                            <div>
                                <label className="label">City</label>
                            </div>
                            <TextInput
                                name="physicalAddressCity"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleAddressTextBlur()}
                                value={physicalAddressCity}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('physicalAddressCity', businessInfo.notRequired)}
                                valid={getIsValid('physicalAddressCity', invalidInputs)}
                                errorMessage={getErrorMessage('physicalAddressCity', invalidInputs)}
                                placeHolder={'City'}
                                handleFocus={this.handleFocus}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="multi-item-wrapper">
                            <div id="physicalAddressState" className="half-width" style={shouldHideElement('physicalAddressState', businessInfo.hide)}>
                                <div>
                                    <label className="label" style={{ marginBottom: '20px' }}>State</label>
                                </div>
                                <Select
                                    name="physicalAddressState"
                                    handleChange={this.handleAddressSelectChange}
                                    value={physicalAddressState}
                                    options={states}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('physicalAddressState', businessInfo.notRequired)}
                                    valid={getIsValid('physicalAddressState', invalidInputs)}
                                    errorMessage={getErrorMessage('physicalAddressState', invalidInputs)}
                                />
                            </div>
                            <div id="physicalAddressZip" className="half-width" style={shouldHideElement('physicalAddressZip', businessInfo.hide)}>
                                <div>
                                    <label className="label" style={{ marginLeft: '10px' }}>Zip</label>
                                </div>
                                <TextInput
                                    type="text"
                                    name="physicalAddressZip"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleAddressTextBlur()}
                                    value={physicalAddressZip}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('physicalAddressZip', businessInfo.notRequired)}
                                    valid={getIsValid('physicalAddressZip', invalidInputs)}
                                    errorMessage={getErrorMessage('physicalAddressZip', invalidInputs)}
                                    placeHolder={'Zip'}
                                    maxLength={10}
                                />
                            </div>
                        </div>
                    </div>
                </Group>
                { this.renderLegalAddress() }
                <Group>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('customerServicePhone', this.state.currentFocused)}>
                            The phone number that will display on your customer’s credit card or bank statement. Will need to match the CS# on your website.
                        </div>
                        <div className="multi-item-wrapper">
                            <div id="customerServicePhone" className="half-width" style={shouldHideElement('customerServicePhone', businessInfo.hide)}>
                                <div>
                                    <label className="label">Customer Service Phone</label>
                                </div>
                                <PhoneInput
                                    phoneType="number"
                                    name="customerServicePhone"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={customerServicePhone}
                                    required={this.state.isUserAnAgent ? false : shouldNotBeRequired('customerServicePhone', businessInfo.notRequired)}
                                    valid={getIsValid('customerServicePhone', invalidInputs)}
                                    errorMessage={getErrorMessage('customerServicePhone', invalidInputs)}
                                />
                            </div>

                            <div id="fax" className="half-width" style={shouldHideElement('fax', businessInfo.hide)}>
                                <label className="label">
                                    Fax <span className="optional-input">{shouldBeRequired('fax', businessInfo.required) ? '' : 'Optional'} </span>
                                </label>
                                <PhoneInput
                                    phoneType="number"
                                    name="fax"
                                    handleChange={this.handleTextChange}
                                    handleBlur={this.handleTextBlur()}
                                    value={fax}
                                    required={false}
                                    valid={getIsValid('fax', invalidInputs)}
                                    errorMessage={getErrorMessage('fax', invalidInputs)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('webAddress', this.state.currentFocused)}>
                            Required for E-commerce accounts, but please provide one if you have one no matter how you are processing.
                        </div>
                        <div className="item-wrapper" id="webAddress" style={shouldHideElement('webAddress', businessInfo.hide)}>
                            <Row>
                                <label className="label">
                                    Website Address
                                </label>
                            </Row>
                            <TextInput
                                icon="website"
                                name="webAddress"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur(isValidWebsite)}
                                value={webAddress}
                                errorMessage={getErrorMessage('webAddress', invalidInputs)}
                                placeHolder={'e.g. http://nexiohub.com'}
                                handleFocus={this.handleFocus}
                                required={false}
                                valid={true}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('contactName', this.state.currentFocused)}>
                            Name of the person who will be the main point of contact for correspondence (but is not necessarily authorized to make any changes to the merchant account without the signer’s permission).
                        </div>
                        <div className="item-wrapper" id="contactName" style={shouldHideElement('contactName', businessInfo.hide)}>
                            <Row>
                                <label className="label">
                                    Contact Name <span className="optional-input">{shouldNotBeRequired('contactName', businessInfo.required) ? '' : 'Optional'} </span>
                                </label>
                            </Row>
                            <TextInput
                                icon="contact"
                                name="contactName"
                                handleChange={this.handleTextChange}
                                handleBlur={(e) => this.handleBlurForBankDataSave(e)}
                                value={contactName}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('contactName', businessInfo.required)}
                                valid={getIsValid('contactName', invalidInputs)}
                                errorMessage={getErrorMessage('contactName', invalidInputs)}
                                placeHolder={'Contact Name'}
                                handleFocus={this.handleFocus}
                            />
                        </div>
                    </div>
                    <div className="form-input-wrapper">
                        <div className="arrow-box" style={shouldShowHelp('email', this.state.currentFocused)}>The contact email address where all correspondence will be sent.</div>
                        <div className="item-wrapper" id="email" style={shouldHideElement('email', businessInfo.hide)}>
                            <Row>
                                <label className="label">
                                    Email (used for Nexio system access)
                                </label>
                            </Row>
                            <TextInput
                                icon="email"
                                type="email"
                                name="email"
                                handleChange={this.handleTextChangeWithTrim}
                                handleBlur={this.handleTextBlur(isEmail)}
                                value={email}
                                required={this.state.isUserAnAgent ? false : shouldNotBeRequired('email', businessInfo.required)}
                                valid={getIsValid('email', invalidInputs)}
                                errorMessage={getErrorMessage('email', invalidInputs)}
                                placeHolder={'Email'}
                                handleFocus={this.handleFocus}
                            />
                        </div>
                    </div>
                </Group>
            </Section>
        );
    }
}

export const mapStateToProps = (state) => ({
    businessInfo: getBusinessInfo(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(BusinessInfo);
