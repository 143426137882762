import React, { Component } from 'react';
import PropTypes from 'prop-types';

import scrollBehavior from '../../../../utils/scrollBehavior';
import Owner from '../Sections/Owner/Owner';
import Fees from '../Sections/Fees/Fees';
import Terms from '../Sections/Terms/Terms';

export class Page3 extends Component{
    static propTypes = {
        invalidInputs: PropTypes.any,
        runValidation: PropTypes.func
    };

    componentDidMount() {
        const application = document.getElementById('merchant-application');
        application.scrollIntoView(scrollBehavior());
    }

    render() {
        const { invalidInputs } = this.props;

        return (
            <div>
                <Owner invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
                <Fees invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
                <Terms invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
            </div>
        );
    }
}

export default Page3;
