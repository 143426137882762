import React from 'react';
import { connect } from 'react-redux';

import './Pricing.scss';

import RateBubble from '../../../../Shared/RateBubble/RateBubble';
import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import { FEES } from '../../../../../actions/sectionsActions';
import {
    shouldBeRequired,
    getErrorMessage,
    getIsValid
} from '../../../../../utils/form';
import _ from 'lodash';
import { getFees, getIsDirectSalesApp, getMerchantAppPartner, getIs1099App } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class Pricing extends BaseSection {
    sectionName = FEES;
    state = {
        qualifiedRate: this.props.fees.qualifiedRate,
        transactionFee: this.props.fees.transactionFee,
        merchantFee: this.props.fees.merchantFee,
        interchangeRate: this.props.fees.interchangeRate,
        transactionFeeIc: this.props.fees.transactionFeeIc,
        merchantFeeIc: this.props.fees.merchantFeeIc,
        merchantFeeTiered: this.props.fees.merchantFeeTiered,
        transactionFeeTiered: this.props.fees.transactionFeeTiered,
        nonQualifiedRate: this.props.fees.nonQualifiedRate,
        midQualifiedRate: this.props.fees.midQualifiedRate,
        qualifiedRateTiered: this.props.fees.qualifiedRateTiered,
        isDirectSalesApp: this.props.isDirectSalesApp,
        is1099App: this.props.is1099App,
        isUserAnAgent: this.props.isUserAnAgent,
        downgradeFee: this.props.fees.downgradeFee,
        commercialNonQualifiedRate: this.props.fees.commercialNonQualifiedRate
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.fees, this.props.fees)) {
            this.setState({
                qualifiedRate: this.props.fees.qualifiedRate,
                transactionFee: this.props.fees.transactionFee,
                merchantFee: this.props.fees.merchantFee,
                interchangeRate: this.props.fees.interchangeRate,
                transactionFeeIc: this.props.fees.transactionFeeIc,
                merchantFeeIc: this.props.fees.merchantFeeIc,
                merchantFeeTiered: this.props.fees.merchantFeeTiered,
                transactionFeeTiered: this.props.fees.transactionFeeTiered,
                nonQualifiedRate: this.props.fees.nonQualifiedRate,
                midQualifiedRate: this.props.fees.midQualifiedRate,
                qualifiedRateTiered: this.props.fees.qualifiedRateTiered,
                isDirectSalesApp: this.props.isDirectSalesApp,
                is1099App: this.props.is1099App,
                downgradeFee: this.props.fees.downgradeFee,
                commercialNonQualifiedRate: this.props.fees.commercialNonQualifiedRate
            });
        }
    }

    renderOption(option) {
        const { invalidInputs, fees } = this.props;
        const {
            qualifiedRate, transactionFee, merchantFee, interchangeRate, transactionFeeIc, merchantFeeIc,
            merchantFeeTiered, transactionFeeTiered, nonQualifiedRate, midQualifiedRate, qualifiedRateTiered,
            downgradeFee, commercialNonQualifiedRate
        } = this.state;

        if (_.toLower(option) === 'interchange') {
            return (
                <div className="rate-bubble-wrapper" id="interchange_option">
                    <Row justify="space-between">
                        <RateBubble label="Interchange Rate">
                            <TextInput
                                type="number"
                                name="interchangeRate"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={interchangeRate}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('interchangeRate', fees.notRequired)}
                                valid={getIsValid('interchangeRate', invalidInputs)}
                                errorMessage={getErrorMessage('interchangeRate', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Authorization Fee">
                            <TextInput
                                type="money"
                                name="transactionFeeIc"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={transactionFeeIc}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('transactionFeeIc', fees.notRequired)}
                                valid={getIsValid('transactionFeeIc', invalidInputs)}
                                errorMessage={getErrorMessage('transactionFeeIc', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Monthly Fee">
                            <TextInput
                                type="money"
                                name="merchantFeeIc"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={ merchantFeeIc }
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('merchantFeeIc', fees.notRequired)}
                                valid={getIsValid('merchantFeeIc', invalidInputs)}
                                errorMessage={getErrorMessage('merchantFeeIc', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                    </Row>
                </div>
            );
        } else if (_.toLower(option) === 'err') {
            return (
                <div className="rate-bubble-wrapper" id="err_options">
                    <Row justify="space-between">
                        <RateBubble label="Qualified Rate">
                            <TextInput
                                type="percent"
                                name="qualifiedRate"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={qualifiedRate}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('qualifiedRate', fees.notRequired)}
                                valid={getIsValid('qualifiedRate', invalidInputs)}
                                errorMessage={getErrorMessage('qualifiedRate', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Non Qualified Rate">
                            <TextInput
                                type="percent"
                                name="downgradeFee"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={downgradeFee}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('downgradeFee', fees.notRequired)}
                                valid={getIsValid('downgradeFee', invalidInputs)}
                                errorMessage={getErrorMessage('downgradeFee', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Authorization Fee">
                            <TextInput
                                type="money"
                                name="transactionFee"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={transactionFee}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('transactionFee', fees.notRequired)}
                                valid={getIsValid('transactionFee', invalidInputs)}
                                errorMessage={getErrorMessage('transactionFee', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Monthly Fee">
                            <TextInput
                                type="money"
                                name="merchantFee"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={ merchantFee }
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('merchantFee', fees.notRequired)}
                                valid={getIsValid('merchantFee', invalidInputs)}
                                errorMessage={getErrorMessage('merchantFee', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                    </Row>
                </div>
            );
        } else if (_.toLower(option) === 'tiered') {
            return (
                <div className="rate-bubble-wrapper" id="tiered_option">
                    <Row justify="space-between">
                        <RateBubble label="Qualified Rate">
                            <TextInput
                                type="percent"
                                name="qualifiedRateTiered"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={qualifiedRateTiered}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('qualifiedRateTiered', fees.notRequired)}
                                valid={getIsValid('qualifiedRateTiered', invalidInputs)}
                                errorMessage={getErrorMessage('qualifiedRateTiered', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Mid-Qualified Rate">
                            <TextInput
                                type="percent"
                                name="midQualifiedRate"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={midQualifiedRate}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('midQualifiedRate', fees.notRequired)}
                                valid={getIsValid('midQualifiedRate', invalidInputs)}
                                errorMessage={getErrorMessage('midQualifiedRate', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Non-Qualified Rate">
                            <TextInput
                                type="percent"
                                name="nonQualifiedRate"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={nonQualifiedRate}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('nonQualifiedRate', fees.notRequired)}
                                valid={getIsValid('nonQualifiedRate', invalidInputs)}
                                errorMessage={getErrorMessage('nonQualifiedRate', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Commercial Non-Qualified Rate">
                            <TextInput
                                type="percent"
                                name="commercialNonQualifiedRate"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={commercialNonQualifiedRate}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('commercialNonQualifiedRate', fees.notRequired)}
                                valid={getIsValid('commercialNonQualifiedRate', invalidInputs)}
                                errorMessage={getErrorMessage('commercialNonQualifiedRate', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Authorization Fee">
                            <TextInput
                                type="money"
                                name="transactionFeeTiered"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={transactionFeeTiered}
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('transactionFeeTiered', fees.notRequired)}
                                valid={getIsValid('transactionFeeTiered', invalidInputs)}
                                errorMessage={getErrorMessage('transactionFeeTiered', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                        <RateBubble label="Monthly Fee">
                            <TextInput
                                type="money"
                                name="merchantFeeTiered"
                                placeHolder="0.00"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={ merchantFeeTiered }
                                required={ this.state.isUserAnAgent ? false : shouldBeRequired('merchantFeeTiered', fees.notRequired)}
                                valid={getIsValid('merchantFeeTiered', invalidInputs)}
                                errorMessage={getErrorMessage('merchantFeeTiered', invalidInputs)}
                                readOnly={!this.state.isUserAnAgent}
                            />
                        </RateBubble>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { merchantAppPartner, is1099App, isDirectSalesApp } = this.props;

        if (is1099App || isDirectSalesApp) {
            return (
                <Section heading="" className="pricing-section" id="nonIsv">
                    {this.renderOption(merchantAppPartner.pricingType)}
                </Section>
            );
        } else {
            return (
                <Section heading="Low Cost Guarantee" className="pricing-section" id="isv">
                    {this.renderOption(merchantAppPartner.pricingType)}
                </Section>
            );
        }
    }
}

export const mapStateToProps = (state) => ({
    fees: getFees(state),
    merchantAppPartner: getMerchantAppPartner(state),
    isUserAnAgent: getIsUserAnAgent(state),
    isDirectSalesApp: getIsDirectSalesApp(state),
    is1099App: getIs1099App(state)
});

export default connect(mapStateToProps)(Pricing);