import { toast } from 'react-toastify';
import { doesPropExistAndHasItChanged } from '@nexio/emvio-util-app';

export function toastIfSuccess(currentProps, nextProps, propName = 'isSuccessful', messageToToast) {
    if (doesPropExistAndHasItChanged(currentProps, nextProps, propName)) {
        toast(messageToToast, { type: 'success' });
    }
}

export function toastIfError(currentProps, nextProps, errorPropName = 'error') {
    if (doesPropExistAndHasItChanged(currentProps, nextProps, errorPropName)) {
        toast(nextProps[errorPropName], { type: 'error' });
    }
}
