import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import { POST_NEXT_SIGNER_FAILURE, POST_NEXT_SIGNER_LOAD, POST_NEXT_SIGNER_SUCCESS } from './nextSignerActions';
import { createSelector } from 'reselect';
import _ from 'lodash';

export default createFetchReducer({
    loadActionType: POST_NEXT_SIGNER_LOAD,
    failureActionType: POST_NEXT_SIGNER_FAILURE,
    successActionType: POST_NEXT_SIGNER_SUCCESS
});

const baseSelectors = createBaseSelectors('nextSignerReducer');

export const getIsPostNextSignerLoading = baseSelectors.getIsLoading;

export const getPostNextSignerError = baseSelectors.getError;

export const getRedirectUrl = createSelector(
    baseSelectors.getData,
    (data) => _.get(data, 'url')
);