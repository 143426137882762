import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mixpanel from '../../../utils/mixpanel';
import { connect } from 'react-redux';
import './PhoneInput.scss';
import { getMerchantAppMerchantHash } from '../../../reducers/sectionsReducer';

export class PhoneInput extends Component {
    constructor(props){
        super(props);
        this.inputAreaCodeFocus = React.createRef();
        this.inputAreaPrefixFocus = React.createRef();
        this.inputAreaNumberFocus = React.createRef();
    }

    state = {
        areaCode: '',
        prefix: '',
        number: '',
        value: this.props.value
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        placeHolder: PropTypes.string,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        type: PropTypes.string,
        required: PropTypes.bool,
        valid: PropTypes.bool,
        errorMessage: PropTypes.string,
        readOnly: PropTypes.bool,
        phoneType: PropTypes.string,
        merchantHash: PropTypes.string,
        dataId: PropTypes.string
    };

    static defaultProps = {
        placeHolder: '',
        disabled: false,
        type: 'text',
        required: false,
        valid: true,
        errorMessage: '',
        dataId: null
    };

    componentDidMount() {
        const { value } = this.props;
        this.setState({
            areaCode: (value && value.length > 2) ? value.substring(0, 3) : '',
            prefix: (value && value.length > 5) ? value.substring(3, 6) : '',
            number: (value && value.length > 9) ? value.substring(6, 10) : ''
        });
    }

    componentDidUpdate(previousProps, previousStates) {
        const { value } = this.props;
        if (value && previousProps.value !== value && previousProps.name === this.props.name){
            this.setState({
                areaCode: (value && value.length > 2) ? value.substring(0, 3) : '',
                prefix: (value && value.length > 5) ? value.substring(3, 6) : '',
                number: (value && value.length > 9) ? value.substring(6, 10) : ''
            });
        }
    }

    isPhoneNumberValid() {
        return (this.state.areaCode && this.state.areaCode.length === 3 &&
            this.state.prefix && this.state.prefix.length === 3 &&
            this.state.number && this.state.number.length === 4)
        || (!this.props.required && this.state.areaCode === '' && this.state.prefix === '' && this.state.number === '');
    }

    changePhone = (event) => {
        event.persist();
        if (event.target.name === 'areaCode' && event.target.value.length === 3){
            this.inputAreaPrefixFocus.current.focus();
        }
        if (event.target.name === 'prefix' && event.target.value.length === 3){
            this.inputAreaNumberFocus.current.focus();
        }
        let setStateOK = true;
        switch (event.target.name){
            case 'areaCode':
            case 'prefix':
                setStateOK = event.target.value.length <= 3;
                break;
            case 'number':
                setStateOK = event.target.value.length <= 4;
                break;
            default:
                //should not happen
                break;
        }
        if (setStateOK){
            this.setState({
                [event.target.name]: event.target.value
            }, () => {
                if (this.isPhoneNumberValid()) {
                    this.props.handleChange({
                        persist: () => {},
                        target: {
                            name: this.props.name,
                            value: `${this.state.areaCode}${this.state.prefix}${this.state.number}`
                        }
                    });
                }
            });
        }
    };

    handlePhoneBlur = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.isPhoneNumberValid()) {
            this.props.handleBlur({
                target: {
                    name: this.props.name,
                    value: `${this.state.areaCode}${this.state.prefix}${this.state.number}`
                }
            });
            mixpanel.track(`${this.props.merchantHash}--${this.props.name}`);
        }
    };

    /** this is to deal with html number fields. number fields allow the
     * character e and - at the beginning and in the middle
     **/
    keyPressed = (e) => {
        if (e.key === 'e' || e.key === '-'){
            e.preventDefault();
        }
    };

    renderInput() {
        const {
            name, value, placeHolder, disabled, phoneType, required, readOnly, dataId
        } = this.props;
        const { areaCode, prefix, number } = this.state;

        if (readOnly) {
            return <div className="read-only-input" name={name}>
                {value}
            </div>;
        } else {
            return <div className="phone-input-wrapper" >
                {/*type=hidden is not validated by the form api*/}
                <input
                    value={`${areaCode}${prefix}${number}`}
                    name={this.props.name}
                    pattern="[0-9]{10}"
                    style={{ display: 'none' }}
                    required={required}
                    onChange={() => {}}
                    data-id={dataId}
                />
                <div className={phoneType}>
                    <span>(</span>
                    <input
                        className="phone-input-areacode"
                        type="number"
                        placeholder={placeHolder}
                        name="areaCode"
                        value={areaCode}
                        onChange={this.changePhone}
                        onBlur={this.handlePhoneBlur}
                        disabled={disabled}
                        required={required}
                        ref={this.inputAreaCodeFocus}
                        onKeyPress={this.keyPressed}
                    />
                    <span>)</span>
                    <input
                        className="phone-input-prefix"
                        type="number"
                        placeholder={placeHolder}
                        name={'prefix'}
                        value={this.state.prefix}
                        onChange={this.changePhone}
                        onBlur={this.handlePhoneBlur}
                        disabled={disabled}
                        required={required}
                        ref={this.inputAreaPrefixFocus}
                        onKeyPress={this.keyPressed}
                    />
                    <span>-</span>
                    <input
                        className="phone-input-number"
                        type="number"
                        placeholder={placeHolder}
                        name={'number'}
                        value={this.state.number}
                        onChange={this.changePhone}
                        onBlur={this.handlePhoneBlur}
                        disabled={disabled}
                        required={required}
                        ref={this.inputAreaNumberFocus}
                        onKeyPress={this.keyPressed}
                    />
                </div>
            </div>;
        }
    }

    render() {
        const { valid, errorMessage } = this.props;
        const className = `text-input-container phone-input-container ${!valid ? 'text-input-error' : ''}`;

        return (
            <span className={className} >
                { this.renderInput() }
                {!valid && <span className="text-input-error-message">{errorMessage}</span>}
            </span>
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantHash: getMerchantAppMerchantHash(state)
});

export default connect(mapStateToProps)(PhoneInput);

