import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import { createSelector } from 'reselect';
import {
    FETCH_ISO_CONFIG_FAILURE,
    FETCH_ISO_CONFIG_LOAD,
    FETCH_ISO_CONFIG_SUCCESS
} from './createISOAppActions';
import _ from 'lodash';

export default createFetchReducer({
    loadActionType: FETCH_ISO_CONFIG_LOAD,
    failureActionType: FETCH_ISO_CONFIG_FAILURE,
    successActionType: FETCH_ISO_CONFIG_SUCCESS
});

const { getData } = createBaseSelectors('createISOAppReducer');

export const getAgents = createSelector(
    getData,
    (data) => _.get(data, 'agents')
);

export const getPartners = createSelector(
    getData,
    (data) => _.get(data, 'partners')
);