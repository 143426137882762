import _ from 'lodash';
import config from '../config/config';

export const FETCH_MERCHANT_APPS_LOAD = 'FETCH_MERCHANT_APPS_LOAD';
export const FETCH_MERCHANT_APPS_FAILURE = 'FETCH_MERCHANT_APPS_FAILURE';
export const FETCH_MERCHANT_APPS_SUCCESS = 'FETCH_MERCHANT_APPS_SUCCESS';

export const FETCH_BANK_DATA_LOAD = 'FETCH_BANK_DATA_LOAD';
export const FETCH_BANK_DATA_FAILURE = 'FETCH_BANK_DATA_FAILURE';
export const FETCH_BANK_DATA_SUCCESS = 'FETCH_BANK_DATA_SUCCESS';

export const fetchAppList = (status, limit, offset, startDate, endDate, searchTerm, sorters) => {
    const sortID = _.isEmpty(sorters) ? 'dateCreated' : sorters[0].id;
    const sortDesc = _.isEmpty(sorters) ? '-' : (sorters[0].desc ? '-' : '');

    let params = `limit=${limit}&offset=${offset}&startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}&sort=${sortDesc}${sortID}`;
    if (status){
        params = `status=${status}&${params}`;
    }

    return {
        loadActionTypeOrCreator: FETCH_MERCHANT_APPS_LOAD,
        failureActionTypeOrCreator: FETCH_MERCHANT_APPS_FAILURE,
        successActionTypeOrCreator: FETCH_MERCHANT_APPS_SUCCESS,
        fetchParams: {
            url: `${config.cforceServiceUrl}/list?${params}`
        }
    };
};

export const fetchBankData = (merchantAppId) => {
    return {
        loadActionTypeOrCreator: FETCH_BANK_DATA_LOAD,
        failureActionTypeOrCreator: FETCH_BANK_DATA_FAILURE,
        successActionTypeOrCreator: FETCH_BANK_DATA_SUCCESS,
        fetchParams: {
            url: `${config.cforceServiceUrl}/bankdata/${merchantAppId}`
        }
    };
};
