import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import mixpanel from '../../../utils/mixpanel';
import './CheckboxContainer.scss';
import { getMerchantAppMerchantHash } from '../../../reducers/sectionsReducer';

export class CheckboxContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: false
        };
        this.requiredRef = React.createRef();
    }

    static propTypes = {
        name: PropTypes.string.isRequired,
        children: PropTypes.array,
        isRequired: PropTypes.bool,
        merchantHash: PropTypes.string,
        isUserAnAgent: PropTypes.bool
    };

    componentDidMount() {
        this.handleValidation();
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        const checkboxes = [..._.get(this.requiredRef, 'current.elements', [])];

        return checkboxes.some((checkbox) => checkbox.checked);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const checkboxes = [..._.get(this.requiredRef, 'current.elements', [])];
        const currentSnapshot =  checkboxes.some((checkbox) => checkbox.checked);

        if (!_.isEqual(_.get(this.props, 'isRequired'), _.get(prevProps, 'isRequired'))) {
            this.isValid(checkboxes);
        }

        if (currentSnapshot !== snapshot) {
            this.setState({ isValid: currentSnapshot });
        }
    }

    handleValidation = () => {
        const checkboxes = [..._.get(this.requiredRef, 'current.elements', [])];
        this.isValid(checkboxes);
        mixpanel.track(`${this.props.merchantHash}--${this.props.name}`);
    };

    isValid(checkboxes) {
        const isValid = checkboxes.some((checkbox) => checkbox.checked);
        this.setState({
            isValid: this.props.isRequired ? isValid : true
        });
    }

    render() {
        return (
            <div className="cforce-checkbox">
                {!this.state.isValid && <span className="text-input-error-message">{!this.props.isUserAnAgent ? 'Must choose one option' : null}</span>}
                <fieldset
                    ref={this.requiredRef}
                    name={this.props.name}
                    data-value={this.state.isValid}
                    onChange={this.handleValidation}
                    onInput={this.handleValidation}
                >
                    {this.props.children}
                </fieldset>
            </div>
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantHash: getMerchantAppMerchantHash(state)
});

export default connect(mapStateToProps)(CheckboxContainer);
