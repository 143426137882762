import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TextArea.scss';
import mixpanel from '../../../utils/mixpanel';
import { connect } from 'react-redux';
import { getMerchantAppMerchantHash } from '../../../reducers/sectionsReducer';

export class TextArea extends Component {
    static propTypes = {
        errorMessage: PropTypes.arrayOf(PropTypes.string),
        visibleRows: PropTypes.number,
        id: PropTypes.string,
        handleChange: PropTypes.func,
        handleBlur: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.string,
        merchantHash: PropTypes.string,
        required: PropTypes.bool,
        valid: PropTypes.bool
    };

    static defaultProps = {
        visibleRows: 4,
        handleChange: () => {},
        handleBlur: () => {},
        required: false
    };

    onBlur = (e) => {
        this.props.handleBlur(e);
        mixpanel.track(`${this.props.merchantHash}--${this.props.name}`);
    };

    render() {
        const { visibleRows, handleChange, value, name, required, valid, errorMessage, id } = this.props;

        return (
            <span>
                <textarea
                    className={`text-area ${!valid ? 'text-area-error' : ''}`}
                    id={id}
                    rows={visibleRows}
                    onChange={handleChange}
                    onBlur={this.onBlur}
                    value={value}
                    name={name}
                    required={value ? true : required}
                />
                {!valid && <span className="text-area-error-message">{errorMessage}</span>}
            </span>
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantHash: getMerchantAppMerchantHash(state)
});

export default connect(mapStateToProps)(TextArea);
