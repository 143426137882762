import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import '../CreateAppModal.scss';
import PropTypes from 'prop-types';

import { getIsoConfigData } from './createISOAppActions';
import { createApp } from '../../../../../actions/createAppActions';
import { pricingTypeOptions, optBlueOptions } from '../../../Application/StaticData/staticData';
import { getPartners, getAgents } from './createISOAppReducer';
import config from '../../../../../config/config';

import Modal from '../../../../Shared/Modal/Modal';
import Select from '../../../../Shared/Select/Select';
import { isChecked } from '../../../../../utils/form';

export class CreateISOAppModal extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        handleCancel: PropTypes.func,
        isoPartners: PropTypes.array,
        isoAgents: PropTypes.array,
        isOpen: PropTypes.bool,
        handleSave: PropTypes.func
    };

    static defaultProps = {
        isoAgents: [],
        isoPartners: []
    };

    state = {
        contact: '',
        isAppCreating: false,
        pricingType: '',
        optBlue: '',
        repCodeID: '',
        checkCredit: true,
        hasPersonalGuarantor: true
    };

    componentDidMount() {
        this.props.dispatch(getIsoConfigData());
    }

    handleSelectChange = (e) => this.setState({
        [e.target.name]: e.target.value
    });

    handleContactChange = (e) => this.setState({
        contact: e.target.value
    });

    handleCheckCreditChange = () => this.setState({
        checkCredit: !this.state.checkCredit
    });

    handleHasPersonalGuarantorChange = () => this.setState({
        hasPersonalGuarantor: !this.state.hasPersonalGuarantor
    });

    handleCancel = () => {
        this.setState({
            contact: '',
            isAppCreating: false,
            pricingType: '',
            optBlue: '',
            repCodeID: '',
            checkCredit: true,
            hasPersonalGuarantor: true
        });
        this.props.handleCancel();
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({
            isAppCreating: true
        });
        const { handleSave } = this.props;
        const { pricingType, optBlue, contact, repCodeID, checkCredit, hasPersonalGuarantor } = this.state;
        const formElements = e.target.elements;
        const elements = [...formElements];
        const isFormValid = elements.every((element) => element.checkValidity());
        if (isFormValid) {
            await this.props.dispatch(createApp({ partnerID: config.triAppPartnerId, isvRep: '', pricingType, optBlue, contact, repCodeID, checkCredit, hasPersonalGuarantor, is1099App: true }));
            handleSave();
            this.setState({
                contact: '',
                isAppCreating: false,
                pricingType: '',
                optBlue: '',
                repCodeID: '',
                checkCredit: true,
                hasPersonalGuarantor: true
            });
        } else {
            toast.error('Please fill out all required fields');
            this.setState({
                isAppCreating: false
            });
        }

    };

    render() {
        const { contact, isAppCreating, pricingType, optBlue, repCodeID, checkCredit, hasPersonalGuarantor } = this.state;
        const { isoAgents, isOpen } = this.props;

        return (
            <Modal
                title="Create New 1099 Application"
                isOpen={isOpen}
                handleClose={this.handleCancel}
                className="create-app-modal"
            >
                <form data-testid="create-iso-app-form" className="options" onSubmit={this.handleSubmit}
                    noValidate>
                    <div className="config-options">
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="pricingType" className="left">
                                    * Pricing Type
                                </label>
                                <Select
                                    id="pricingType"
                                    name="pricingType"
                                    handleChange={this.handleSelectChange}
                                    value={pricingType}
                                    options={pricingTypeOptions}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="optBlue" className="left">
                                    * OptBlue
                                </label>
                                <Select
                                    id="optBlue"
                                    name="optBlue"
                                    handleChange={this.handleSelectChange}
                                    value={optBlue}
                                    options={optBlueOptions}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="repCodeID" className="left">
                                    * Rep Code
                                </label>
                                <Select
                                    id="repCodeID"
                                    name="repCodeID"
                                    handleChange={this.handleSelectChange}
                                    value={repCodeID}
                                    options={
                                        isoAgents
                                            .filter((agent) => agent.AgentId1)
                                            .map((agent) => ({
                                                label: `${agent.AgentId1 ? `${agent.AgentId1} - ` : ''} ${agent.Name}`,
                                                value: agent.ID
                                            }))
                                    }
                                    required={true}
                                />
                            </div>
                            <div className="cell">
                                <label htmlFor="contact" className="left">
                                    * Contact
                                </label>
                                <input
                                    id="contact"
                                    className="contact-select text-input"
                                    type="text"
                                    name="contact"
                                    onChange={this.handleContactChange}
                                    value={contact}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="checkCredit" className="left">
                                    Check Credit
                                </label>
                                <input
                                    type="checkbox"
                                    id="checkCredit"
                                    name="checkCredit"
                                    className="checkbox"
                                    onChange={this.handleCheckCreditChange}
                                    checked={isChecked(checkCredit)}
                                />
                            </div>
                        </div>
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="hasPersonalGuarantor" className="left">
                                    Personal Guarantor
                                </label>
                                <input
                                    type="checkbox"
                                    id="hasPersonalGuarantor"
                                    name="hasPersonalGuarantor"
                                    className="checkbox"
                                    onChange={this.handleHasPersonalGuarantorChange}
                                    checked={isChecked(hasPersonalGuarantor)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="application-container">
                        <button disabled={isAppCreating} className="create-button-show">
                            {isAppCreating ? 'Loading...' : 'Create Application'}
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    isoPartners: getPartners(state),
    isoAgents: getAgents(state)
});

export default connect(mapStateToProps)(CreateISOAppModal);
