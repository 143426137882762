import config from '../config/config';

export const FETCH_ENABLED_PARTNERS_LOAD = 'FETCH_ENABLED_PARTNERS_LOAD';
export const FETCH_ENABLED_PARTNERS_FAILURE = 'FETCH_ENABLED_PARTNERS_FAILURE';
export const FETCH_ENABLED_PARTNERS_SUCCESS = 'FETCH_ENABLED_PARTNERS_SUCCESS';

export const fetchEnabledPartners = () => {
    return {
        fetchParams: { url: `${config.cforceServiceUrl}/allEnabledPartners` },
        loadActionTypeOrCreator: FETCH_ENABLED_PARTNERS_LOAD,
        failureActionTypeOrCreator: FETCH_ENABLED_PARTNERS_FAILURE,
        successActionTypeOrCreator: FETCH_ENABLED_PARTNERS_SUCCESS
    };
};
