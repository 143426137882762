import React, { useEffect } from 'react';
import { Formik } from 'formik';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import TextArea from '../../../../Shared/TextArea/TextArea';
import Section from '../../../../Shared/Section/Section';
import config from '../../../../../config/config';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { sendAppLinkEmail } from './sendAppLinkEmailActions';
import {
    getBusinessInfoEmail,
    getIs1099App, getIsoRepEmail, getIsoRepName, getIsoRepPhoneNumber,
    getMerchantAppId,
    getMerchantAppMerchantHash, getMerchantAppPartnerAgentEmail, getMerchantAppPartnerAgentName,
    getMerchantAppPartnerHash,
    getMerchantAppToken
} from '../../../../../reducers/sectionsReducer';
import PropTypes from 'prop-types';
import { formikValidate, validateEmail, validatePhoneNumber, validateRequired } from '@nexio/ui-validation';
import { getIsSendAppLinkEmailLoading, getSendAppLinkEmailErrorMessage } from './sendAppLinkEmailReducer';
import _ from 'lodash';
import './SendAppLinkEmail.scss';

const SendAppLinkEmail = ({ handleModalClose = () => {} }) => {
    const token = useSelector(getMerchantAppToken);
    const partnerHash = useSelector(getMerchantAppPartnerHash);
    const merchantAppId = useSelector(getMerchantAppId);
    const merchantHash = useSelector(getMerchantAppMerchantHash);
    const businessInfoEmail = useSelector(getBusinessInfoEmail);
    const isoRepEmail = useSelector(getIsoRepEmail);
    const isoRepPhoneNumber = useSelector(getIsoRepPhoneNumber);
    const isoRepName = useSelector(getIsoRepName);
    const is1099App = useSelector(getIs1099App);
    const merchantAppPartnerAgentName = useSelector(getMerchantAppPartnerAgentName);
    const merchantAppPartnerAgentEmail = useSelector(getMerchantAppPartnerAgentEmail);
    const isSendAppLinkEmailLoading = useSelector(getIsSendAppLinkEmailLoading);
    const sendAppLinkEmailErrorMessage = useSelector(getSendAppLinkEmailErrorMessage);
    const link = `${config.cforceDashboardBaseUrl}/par/${partnerHash}/app/${merchantHash}`;
    const dispatch = useDispatch();

    useEffect(() => {
        if (sendAppLinkEmailErrorMessage) {
            toast(sendAppLinkEmailErrorMessage, { type: 'error' });
        }
    }, [sendAppLinkEmailErrorMessage]);

    return (
        <Formik
            initialValues={{
                agentEmail: (is1099App ? merchantAppPartnerAgentEmail : isoRepEmail) || '',
                agentName: (is1099App ? merchantAppPartnerAgentName : isoRepName) || '',
                agentPhone: isoRepPhoneNumber || '',
                emailBody: is1099App
                    ? ''
                    : `Hello, <p> It was great being able to speak with you about payment processing! Please see the link below to access the application referenced during our conversation. 
                        Once you have completed the remaining fields and submit the application, an Approval Specialist will reach out if further documentation is needed to start processing payments. </p>
                        <p>Please do not hesitate to contact us with any questions you have — we are here to help! 
                        Looking forward to your success! </p>
                        Thank you.`,
                emailTo: businessInfoEmail || ''
            }}
            validate={({
                agentEmail,
                agentName,
                agentPhone,
                emailBody,
                emailTo
            }) => {
                const validateResult = {
                    agentEmail: formikValidate(agentEmail, [validateRequired, validateEmail]),
                    agentName: formikValidate(agentName, [validateRequired]),
                    agentPhone: formikValidate(agentPhone, [validateRequired, validatePhoneNumber]),
                    emailBody: formikValidate(emailBody, [validateRequired]),
                    emailTo: formikValidate(emailTo, [validateRequired])
                };

                return _.omitBy(validateResult, _.isNil);
            }}
            onSubmit={async ({
                agentEmail,
                agentName,
                agentPhone,
                emailBody,
                emailTo
            }) => {
                await dispatch(sendAppLinkEmail({
                    agentEmail: _.trim(agentEmail),
                    agentName,
                    agentPhone,
                    emailBody,
                    emailTo: _.trim(emailTo),
                    merchantAppId,
                    link,
                    token
                }));

                handleModalClose();
                toast('Email sent!', { type: 'success' });
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
            }) => (
                <Section className="email-info" heading="Send Application Link via Email">
                    <form onSubmit={handleSubmit}>
                        <Group>
                            <div className="form-input-wrapper">
                                <div className="item-wrapper">
                                    <Row>
                                        <label htmlFor="emailTo" className="label">
                                            To Email
                                        </label>
                                    </Row>
                                    <TextInput
                                        id="emailTo"
                                        type="email"
                                        icon="email"
                                        value={values.emailTo}
                                        name="emailTo"
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        errorMessage={touched.emailTo && errors.emailTo}
                                        valid={!touched.emailTo || !errors.emailTo}
                                    />
                                </div>
                            </div>
                            <div className="form-input-wrapper">
                                <div className="item-wrapper">
                                    <Row>
                                        <label htmlFor="emailBody" className="label" > Email Body </label>
                                    </Row>
                                    <TextArea
                                        id="emailBody"
                                        name="emailBody"
                                        type="text"
                                        value={values.emailBody}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        errorMessage={touched.emailBody && errors.emailBody}
                                        valid={!touched.emailBody || !errors.emailBody}
                                    />
                                </div>
                            </div>
                            <div className="form-input-wrapper">
                                <div className="same-line">
                                    <div>
                                        <Row>
                                            <label className="label">
                                                Link:
                                            </label>
                                        </Row>
                                        <div className="link">
                                            {link}
                                        </div>
                                    </div>
                                    <div>
                                        <Row>
                                            <label className="label">
                                                Token:
                                            </label>
                                        </Row>
                                        <div>
                                            {token}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-input-wrapper">
                                <div className="item-wrapper">
                                    <Row>
                                        <label htmlFor="agentName" className="label">
                                            Agent Name
                                        </label>
                                    </Row>
                                    <TextInput
                                        id="agentName"
                                        name="agentName"
                                        value={values.agentName}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        errorMessage={touched.agentName && errors.agentName}
                                        valid={!touched.agentName || !errors.agentName}

                                    />
                                </div>
                            </div>
                            <div className="form-input-wrapper">
                                <div className="item-wrapper">
                                    <Row>
                                        <label htmlFor="agentPhone" className="label">
                                            Agent Phone
                                        </label>
                                    </Row>
                                    <TextInput
                                        id="agentPhone"
                                        name="agentPhone"
                                        value={values.agentPhone}
                                        handleChange={handleChange}
                                        errorMessage={touched.agentPhone && errors.agentPhone}
                                        valid={!touched.agentPhone || !errors.agentPhone}
                                        handleBlur={handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="form-input-wrapper">
                                <div className="item-wrapper">
                                    <Row>
                                        <label htmlFor="agentEmail" className="label">
                                            Agent Email
                                        </label>
                                    </Row>
                                    <TextInput
                                        id="agentEmail"
                                        type="email"
                                        icon="email"
                                        name="agentEmail"
                                        value={values.agentEmail}
                                        handleChange={handleChange}
                                        errorMessage={touched.agentEmail && errors.agentEmail}
                                        valid={!touched.agentEmail || !errors.agentEmail}
                                        handleBlur={handleBlur}
                                    />
                                </div>
                            </div>
                        </Group>
                        <div className="button-row">
                            <button disabled={isSendAppLinkEmailLoading}
                                type="submit"
                                className={`send${isSendAppLinkEmailLoading ? '-disabled' : ''}`}
                            >
                                {isSendAppLinkEmailLoading ? 'Sending...' : 'Send Email'}
                            </button>
                        </div>
                    </form>
                </Section>
            )}
        </Formik>
    );
};

SendAppLinkEmail.propTypes = {
    handleModalClose: PropTypes.func.isRequired
};

export default SendAppLinkEmail;