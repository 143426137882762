import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './App.scss';
import Routes from './Routes/Routes';

export class App extends Component {
    constructor(props) {
        super(props);
        const env = process.env.NODE_ENV;
        if (env !== 'production') {
            document.title = `Cforce Dashboard ${env}`;
        }
    }

    render() {
        return (
            <div className="emvio-app">
                <Router>
                    <div className="app-container">
                        <Routes />
                        <ToastContainer
                            autoClose={5000}
                            position="top-right"
                        />
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;
