import React from 'react';
import { connect } from 'react-redux';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import './ApplicationFiles.scss';
import FileUpload from './FileUpload';
import _ from 'lodash';
import { APPLICATION_FILES } from '../../../../../actions/sectionsActions';
import {
    getMerchantAppData,
    getApplicationFiles,
    getMerchantProfile
} from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class ApplicationFiles extends BaseSection {
    sectionName = APPLICATION_FILES;

    constructor(props) {
        super(props);

        this.state = {
            proofOfOwnership: _.get(this.props.applicationFiles, 'proofOfOwnership') || '',
            businessLicense: _.get(this.props.applicationFiles, 'businessLicense') || '',
            financials: _.get(this.props.applicationFiles, 'financials') || '',
            ownerId: _.get(this.props.applicationFiles, 'ownerId') || '',
            businessImages: _.get(this.props.applicationFiles, 'businessImages') || '',
            processingStatements: _.get(this.props.applicationFiles, 'processingStatements') || '',
            irsLetter: _.get(this.props.applicationFiles, 'irsLetter') || '',
            compensationPlan: _.get(this.props.applicationFiles, 'compensationPlan') || '',
            distributorAgreement: _.get(this.props.applicationFiles, 'distributorAgreement') || '',
            url: '',
            fileLoading: false,
            fileError: false
        };
    }

    componentDidMount() {
        this.checkRequired();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.fileUploadLink, this.props.fileUploadLink)) {
            this.setState({
                url: this.props.fileUploadLink
            });
        }

        if (!_.isEqual(prevProps.applicationFiles, this.props.applicationFiles)) {

            this.setState({
                proofOfOwnership: _.get(this.props.applicationFiles, 'proofOfOwnership') || '',
                businessLicense: _.get(this.props.applicationFiles, 'businessLicense') || '',
                financials: _.get(this.props.applicationFiles, 'financials') || '',
                ownerId: _.get(this.props.applicationFiles, 'ownerId') || '',
                businessImages: _.get(this.props.applicationFiles, 'businessImages') || '',
                processingStatements: _.get(this.props.applicationFiles, 'processingStatements') || '',
                irsLetter: _.get(this.props.applicationFiles, 'irsLetter') || '',
                compensationPlan: _.get(this.props.applicationFiles, 'compensationPlan') || '',
                distributorAgreement: _.get(this.props.applicationFiles, 'distributorAgreement') || ''
            });
        }
        this.checkRequired();
    }

    onOpenFileDialog = () => {
        this.dropzoneRef.open();
    };

    checkRequired = () => {
        const missingRequired = (_.isEmpty(this.state.compensationPlan || this.state.compensationPlan === '[]')) || (_.isEmpty(this.state.distributorAgreement) || this.state.distributorAgreement === '[]');

        if (!this.props.isUserAnAgent) {
            if (this.props.merchantProfile.isDirectSales) {
                if (missingRequired || this.state.fileLoading) {
                    this.props.runDocUploadValidation(true, true);
                } else {
                    this.props.runDocUploadValidation(true, false);
                }
            }
        }
    }

    fileIsLoading = () => {
        this.setState({ fileLoading: true });
    }

    fileLoadingDone = () => {
        this.setState({ fileLoading: false });
    }

    updateFiles = (input, newFiles) => {
        this.setState({
            [input]: JSON.stringify(newFiles)
        });

        this.handleFileInputChange(input, this.state[input]);
    }

    render() {
        return (
            <Section className="app-files" heading="Document Upload" >
                <p>
                    If you have any of the following optional supplementary documents available, please take the opportunity to attach them as well.<br />
                    (Note: These are not required at this time however Underwriting may require document(s)
                    from the below list after your application has been reviewed. Your Sales Representative will let you know if anything from this list is required at that time.)
                </p>
                <p className="sub-title">Optional Documents</p>
                <Group>
                    <div id="proofOfOwnership" className="file-upload-wrapper">
                        <div className="item-description">
                            <p>Beneficiary Information / Proof of Ownership / Breakdown of Ownership</p>
                        </div>
                        <FileUpload
                            value={this.state.proofOfOwnership}
                            handleFileInputChange={this.handleFileInputChange}
                            category="proofOfOwnership"
                            updateFiles={this.updateFiles}
                            fileIsLoading={this.fileIsLoading}
                            fileLoadingDone={this.fileLoadingDone}
                            fileError={() => this.setState({ fileError: true })}
                        />
                    </div>
                </Group>
                <Group>
                    <div id="businessLicense" className="file-upload-wrapper">
                        <div className="item-description">
                            <p>Business License / Business Document</p>
                        </div>
                        <FileUpload
                            value={this.state.businessLicense}
                            handleFileInputChange={this.handleFileInputChange}
                            category="businessLicense"
                            updateFiles={this.updateFiles}
                            fileIsLoading={this.fileIsLoading}
                            fileLoadingDone={this.fileLoadingDone}
                            fileError={() => this.setState({ fileError: true })}
                        />
                    </div>
                </Group>
                <Group>
                    <div id="financials" className="file-upload-wrapper">
                        <div className="item-description">
                            <p>Business or personal financials (bank statements, tax returns, profit / loss statements & balance sheets)</p>
                        </div>
                        <FileUpload
                            value={this.state.financials}
                            handleFileInputChange={this.handleFileInputChange}
                            category="financials"
                            updateFiles={this.updateFiles}
                            fileIsLoading={this.fileIsLoading}
                            fileLoadingDone={this.fileLoadingDone}
                            fileError={() => this.setState({ fileError: true })}
                        />
                    </div>
                </Group>
                <Group>
                    <div id="ownerId" className="file-upload-wrapper">
                        <div className="item-description">
                            <p>Owner(s) Driver’s License</p>
                        </div>
                        <FileUpload
                            value={this.state.ownerId}
                            handleFileInputChange={this.handleFileInputChange}
                            category="ownerId"
                            updateFiles={this.updateFiles}
                            fileIsLoading={this.fileIsLoading}
                            fileLoadingDone={this.fileLoadingDone}
                            fileError={() => this.setState({ fileError: true })}
                        />
                    </div>
                </Group>
                <Group>
                    <div id="businessImages" className="file-upload-wrapper">
                        <div className="item-description">
                            <p>Pictures of storefront (include interior, exterior, signage, and address)</p>
                        </div>
                        <FileUpload
                            value={this.state.businessImages}
                            handleFileInputChange={this.handleFileInputChange}
                            category="businessImages"
                            updateFiles={this.updateFiles}
                            fileIsLoading={this.fileIsLoading}
                            fileLoadingDone={this.fileLoadingDone}
                            fileError={() => this.setState({ fileError: true })}
                        />
                    </div>
                </Group>
                <Group>
                    <div id="processingStatements" className="file-upload-wrapper">
                        <div className="item-description">
                            <p>Previous Processing Statements</p>
                        </div>
                        <FileUpload
                            value={this.state.processingStatements}
                            handleFileInputChange={this.handleFileInputChange}
                            category="processingStatements"
                            updateFiles={this.updateFiles}
                            fileIsLoading={this.fileIsLoading}
                            fileLoadingDone={this.fileLoadingDone}
                            fileError={() => this.setState({ fileError: true })}
                        />
                    </div>
                </Group>
                <Group>
                    <div id="irsLetter" className="file-upload-wrapper">
                        <div className="item-description">
                            <p>SS-4 IRS Letter</p>
                        </div>
                        <FileUpload
                            value={this.state.irsLetter}
                            handleFileInputChange={this.handleFileInputChange}
                            category="irsLetter"
                            updateFiles={this.updateFiles}
                            fileIsLoading={this.fileIsLoading}
                            fileLoadingDone={this.fileLoadingDone}
                            fileError={() => this.setState({ fileError: true })}
                        />
                    </div>
                </Group>
                {
                    this.props.merchantProfile.isDirectSales ? (
                        <>
                            <p className="sub-title">Required Documents*</p>
                            <p>
                                For MLMs, please submit a copy of your compensation plan and distributor agreement
                            </p>
                            <Group>
                                <div id="compensationPlan" className="file-upload-wrapper">
                                    <div className="item-description">
                                        <p>Compensation Plan</p>
                                        {this.props.showMissingUpload && (_.isEmpty(this.state.compensationPlan || this.state.compensationPlan === '[]')) ? (
                                            <p style={{ color: 'red' }}>Please attach required documents</p>
                                        ) : null}
                                    </div>
                                    <FileUpload
                                        value={this.state.compensationPlan}
                                        handleFileInputChange={this.handleFileInputChange}
                                        category="compensationPlan"
                                        updateFiles={this.updateFiles}
                                        fileIsLoading={this.fileIsLoading}
                                        fileLoadingDone={this.fileLoadingDone}
                                    />
                                </div>
                            </Group>
                            <Group>
                                <div id="distributorAgreement" className="file-upload-wrapper">
                                    <div className="item-description">
                                        <p>Distributor Agreement</p>
                                        {this.props.showMissingUpload && (_.isEmpty(this.state.distributorAgreement) || this.state.distributorAgreement === '[]') ? (
                                            <p style={{ color: 'red' }}>Please attach required documents</p>
                                        ) : null}
                                    </div>
                                    <FileUpload
                                        value={this.state.distributorAgreement}
                                        handleFileInputChange={this.handleFileInputChange}
                                        category="distributorAgreement"
                                        updateFiles={this.updateFiles}
                                        fileIsLoading={this.fileIsLoading}
                                        fileLoadingDone={this.fileLoadingDone}
                                    />
                                </div>
                            </Group>
                        </>
                    ) : null
                }
            </Section >
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantAppData: getMerchantAppData(state),
    isUserAnAgent: getIsUserAnAgent(state),
    applicationFiles: getApplicationFiles(state),
    merchantProfile: getMerchantProfile(state)
});

export default connect(mapStateToProps)(ApplicationFiles);
