import React, { Component } from 'react';
import PropTypes from 'prop-types';

import scrollBehavior from '../../../../utils/scrollBehavior';
import CreditCardInfo from '../Sections/CreditCardInfo/CreditCardInfo';
import CardCompliance from '../Sections/CardCompliance/CardCompliance';
import BankData from '../Sections/BankData/BankData';

export class Page2 extends Component{
    static propTypes = {
        invalidInputs: PropTypes.any,
        runValidation: PropTypes.func
    };

    componentDidMount() {
        const application = document.getElementById('merchant-application');
        application.scrollIntoView(scrollBehavior());
    }

    render() {
        const { invalidInputs } = this.props;

        return (
            <div>
                <CreditCardInfo invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
                <CardCompliance invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
                <BankData invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
            </div>
        );
    }
}

export default Page2;
