import _ from 'lodash';
import moment from 'moment';
import isUrl from 'is-url';

export function isChecked(truthy) {
    return truthy
        ? 'checked'
        : '';
}

export function isRadioChecked(radioValue, savedValue) {
    return radioValue === savedValue
        ? 'checked'
        : '';
}

export function shouldHideElement(id, hiddenElements = []) {
    return hiddenElements.includes(id)
        ? { display: 'none' }
        : {};
}

export function shouldShowHelp(id, currentFocus, index) {
    const show = typeof index !== 'undefined' ? id === `${currentFocus}_${index}` : id === currentFocus;

    return show
        ? { visibility: 'visible' }
        : { visibility: 'hidden' };
}

export function shouldBeRequired(id, requiredElements = []) {
    return requiredElements.includes(id);
}

export function shouldNotBeRequired(id, nonRequiredElements = []) {
    return !nonRequiredElements.includes(id);
}

export function getErrorMessage(id, invalidInputs) {
    return invalidInputs[id]
        ? invalidInputs[id].validationMessage
        : '';
}

export function getIsValid(id, invalidInputs) {
    return !invalidInputs[id];
}

export function isNumber(value) {
    const numberRegex = /[0-9]+/;

    return numberRegex.test(value);
}

export function isDecimalNumber(value) {
    const decRegex = /^\d*(\.\d{0,2})?$/;

    return decRegex.test(value);
}

export function isEmail(value) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return {
        isValid: emailRegex.test(value) || _.isEmpty(value),
        message: 'Must be valid email address'
    };
}

export function dateTime(dateStr) {
    return (dateStr || '').replace(/T00:00:00.000Z/, '');
}

export function isValidDate(date) {
    return {
        isValid: date &&  moment.utc(date, 'YYYY-MM-DD').isValid(),
        message: 'Invalid Date'
    };
}

export function isValidURL(url) {
    return isUrl(url)
        ? { valid: true }
        : { valid: false, message: 'Must be a valid web address' };
}

export function isValidWebsite(url) {
    let isValid = true;

    url.split(',').forEach((item) => {
        if (! /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=*]*)$/.test(item)) {
            isValid = false;
        }
    });

    return isValid
        ? { valid: true }
        : { valid: false, message: 'Must be a valid web address' };
}

export function conditionalShow(value) {
    return value
        ? { display: 'block' }
        : { display: 'none' };
}
