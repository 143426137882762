import {
    STORE_APPLICATION_SECTIONS,
    UPDATE_MERCHANT_PROFILE_DATA,
    UPDATE_MERCHANT_APP_PARTNER_DATA,
    UPDATE_MERCHANT_APP_DATA,
    UPDATE_BUSINESS_INFO_DATA,
    UPDATE_CREDIT_CARD_INFO_DATA,
    UPDATE_BANK_DATA,
    UPDATE_TERM_DATA,
    UPDATE_MERCHANT_SURVEY_DATA,
    UPDATE_CARD_COMPLIANCE_DATA,
    UPDATE_AMEX_DATA,
    UPDATE_FEES_DATA,
    UPDATE_MERCHANT_PROFILE_ANALYSIS_DATA,
    UPDATE_OWNER_INFO_DATA,
    UPDATE_APPLICATION_FILES,
    CLEAR_APPLICATION_SECTIONS,
    ADD_OWNER_SUCCESS,
    REMOVE_OWNER_SUCCESS, UPDATE_AMEX_FEES_DATA, UPDATE_KNOW_YOUR_CUSTOMER_DATA,
    GET_UPLOAD_LINK_LOAD, GET_UPLOAD_LINK_SUCCESS, GET_UPLOAD_LINK_FAILURE,
    DELETE_APP_FILE_LOAD, DELETE_APP_FILE_SUCCESS, DELETE_APP_FILE_FAILURE
} from '../actions/sectionsActions';
import { createSelector } from 'reselect';
import _ from 'lodash';

const initialState = {
    sections: {
        MerchantProfile: {
            id: null,
            merchantAppId: null,
            required: [],
            notRequired: [],
            hide: [],
            ownershipType: null,
            marketTypeRetail: false,
            marketTypeRestaurant: false,
            marketTypeSupermarket: false,
            marketTypeLodging: false,
            marketTypeMOTO: false,
            marketTypeECommerce: false,
            marketTypeEmergingMarket: false,
            marketTypePublicSector: false,
            marketTypeCashAdvance: false,
            marketTypeOther: false,
            businessStartDate: null,
            typesOfGoodsServices: null,
            federalTaxId: null,
            acceptCard: null,
            businessSeasonal: null,
            depositRequired: null,
            highVolumeMonths: null,
            additionalBenefitsOffered: null,
            durationOfBenefitsInWeeks: null,
            recurringTransactions: null,
            acceptPrepayment: null,
            prepaidsalesPercentage: null,
            avgPrepaidSalesPercentage: null,
            isDirectSales: false,
            websiteTestUsername: null,
            websiteTestPassword: null,
            fulfillmentPeriod: null,
            previouslyTerminatedByProcessor: null,
            processorTermReason: null
        },
        BusinessInfo: {
            id: null,
            merchantAppId: null,
            required: [],
            notRequired: [],
            hide: [],
            dbaName: null,
            legalName: null,
            businessPhone: null,
            physicalAddress: null,
            physicalAddressCity: null,
            physicalAddressState: null,
            physicalAddressZip: null,
            legalAddress: null,
            legalAddressCity: null,
            legalAddressState: null,
            legalAddressZip: null,
            dbaNumber: null,
            webAddress: null,
            customerServicePhone: null,
            fax: null,
            email: null,
            contactName: null,
            achPaymentsAccepted: false
        },
        CreditCardInfo: {
            id: null,
            merchantAppId: null,
            required: [],
            notRequired: [],
            hide: [],
            multipleLocations: null,
            cardPresentPercentage: null,
            cardNotPresentMotoPercentage: null,
            cardNotPresentEcommercePercentage: null,
            monthlyVisaDiscoverSales: null,
            averageSaleAmount: null,
            highTicket: null
        },
        Terms: {
            id: null,
            merchantAppId: null,
            fileName: null,
            required: [],
            notRequired: [],
            hide: [],
            termsCheckbox: 0
        },
        MerchantSiteSurvey: {
            id: null,
            merchantAppId: null,
            required: [],
            notRequired: [],
            hide: [],
            zoneCommercial: false,
            zoneIndustrial: false,
            zoneResidence: false,
            nameDisplayWindow: null,
            nameDisplayDoor: null,
            nameDisplayStoreFront: null,
            locationOffice: null,
            locationShoppingArea: null,
            locationHome: null,
            locationMixed: null,
            locationApartment: null,
            locationMall: null,
            locationIsolated: null,
            productInventoryLegitimate: null,
            useFulfillmentHouse: null,
            fulfillmentHouseInspected: null,
            notes: null,
            discountMonthly: null,
            discountDaily: null,
            locationOther: null,
            locationOtherDescription: null,
            reasonForLeaving: null
        },
        CardDataStorageComplianceInfo: {
            id: null,
            merchantAppId: null,
            required: [],
            notRequired: [],
            hide: [],
            accDataCompromise: null,
            dateOfCompromise: null,
            pciDSSCompliance: null,
            dateOfCompliance: null,
            qualifiedSecurityAssessorName: null,
            selfAssessmentQuestionnaire: null,
            dateOfLastScan: null,
            dialUpOrTTC: null,
            dealWithFCN: null,
            cardDataMerchantsLocationOnly: false,
            cardDataOtherServiceProvider: false,
            cardDataPrimaryServiceProvider: false,
            cardDataBothMerchantAndServiceProviders: false,
            cardDataMerchantHeadquarterCorpOfficeOnly: false,
            cardDataAllApply: false,
            posServiceProvider: null,
            serviceProviderName: null,
            softwareVersion: null,
            useOtherServiceProvider: null,
            otherServiceProviderName: null
        },
        Amex: {
            id: null,
            merchantAppId: null,
            required: [],
            notRequired: [],
            hide: [],
            se: null,
            amex: null
        },
        MPAInfo: {
            id: null,
            merchantAppId: null,
            productDetails: null,
            advertising: null,
            inventoryStored: null,
            dropShipping: null,
            chargingCustomerFor: null,
            chargesAdvance: null,
            explainBilling: null,
            cancellationPolicy: null,
            refundPolicy: null,
            motoAccount: null,
            customerRefund: null,
            questionsContactNumber: null,
            creditCardPaymentSystem: null,
            ownerNames: null,
            travelInsurance: null,
            arcNumbers: null,
            thirdPartyVendors: null,
            licensedStates: null,
            travelServicesCompany: null,
            debtCollection: null,
            debtIndustries: null,
            chargeCustomer: null,
            attorneyOnStaff: null,
            discloseLength: null,
            debtConsolidation: null,
            bankruptcyPaperwork: null,
            discloseInterestRates: null,
            loanOriginated: null,
            filingPaperwork: null,
            travelCompany: null,
            offerCollectionServices: null,
            offerCreditRepairServices: null,
            offerLendingServices: null,
            offerDocumentPreparationServices: null,
            version: null,
            telemarketing: null,
            nutraceuticalProducts: null,
            telemarketingSalesRule: null,
            counselingPrograms: null,
            hide: [],
            required: [],
            notRequired: []
        },
        Fees: {
            id: null,
            merchantAppId: null,
            required: [],
            notRequired: [],
            hide: [],
            chargebackFee: null,
            edcAVSFee: null,
            retrievalFee: null,
            voiceAuthFee: null,
            insufFundsFee: null,
            terminalAuthFee: null,
            downgradeFee: null,
            accountSetupFee: null,
            annualMembershipFee: null,
            monthlyStatementFee: null,
            monthlyRegulatoryFee: null,
            onlineReportingAccessFee: null,
            monthlyBreachInsuranceFee: null,
            annualPciComplianceFee: null,
            qualifiedRate: null,
            transactionFee: null,
            merchantFee: null,
            interchangeRate: null,
            transactionFeeIc: null,
            merchantFeeIc: null,
            merchantFeeTiered: null,
            transactionFeeTiered: null,
            nonQualifiedRate: null,
            midQualifiedRate: null,
            pricingType: null,
            authorizationFee: null,
            batchFee: null,
            pinDebitRatePerItem: null,
            pinDebitRate: null,
            errSignatureDebitFee: null,
            tieredSignatureDebitFee: null
        },
        OwnerInfoData: [
            {
                id: null,
                merchantAppId: null,
                name: null,
                ownOrRentHome: null,
                years: null,
                jobTitle: null,
                phone: null,
                homeAddress: null,
                city: null,
                state: null,
                zip: null,
                socialSecurity: null,
                birthday: null,
                percentageOfOwnership: null,
                identifcationType: null,
                identificationNumber: null,
                identificationState: null,
                email: null,
                required: [],
                notRequired: [],
                hide: []
            }
        ],
        MerchantAppData: {
            id: null,
            merchantAppPartnerId: null,
            merchantHash: null,
            socialLastFourDigits: null,
            status: null,
            active: null,
            optBlue: 0,
            isvRep: null,
            creatorName: null,
            creatorId: null,
            contact: null,
            priceMatch: null,
            dataFrom: null,
            termsCheckbox: null,
            dateComplete: null,
            dateSent: null,
            applicationsPartnerId: null,
            is1099App: null,
            isDirectSalesApp: null,
            checkCredit: '1',
            hasPersonalGuarantor: '1',
            hide: [],
            notRequired: [],
            required: []
        },
        MerchantAppPartner: {
            id: null,
            partnerName: null,
            partnerLanding: null,
            gateway: null,
            partnerHash: null,
            showPricing: null,
            pricingType: null,
            qualifiedRate: null,
            qualifiedRateTiered: null,
            commercialNonQualifiedRate: null,
            midQualifiedRate: null,
            nonQualifiedRate: null,
            nonQualifiedRateErr: null,
            transactionFee: null,
            transactionFeeTiered: null,
            transactionFeeIc: null,
            merchantFee: null,
            merchantFeeTiered: null,
            merchantFeeIc: null,
            interchangeRate: null,
            accountSetupFee: null,
            annualPciComplianceFee: null,
            annualMembershipFee: null,
            minimumFee: null,
            monthlyStatementFee: null,
            chargebackFee: null,
            retrievalFee: null,
            monthlyRegulatoryFee: null,
            insufficientFundsFee: null,
            edsAvsFee: null,
            voiceAuthFee: null,
            helpDeskFee: null,
            onlineReportingAccessFee: null,
            monthlyBreachInsuranceFee: null,
            notes: null,
            repCodeID: null,
            AgentID1: null,
            AgentID2: null,
            pricingCategory: null,
            enabled: null,
            processingBankId: null,
            agentName: null,
            agentEmail: null,
            hide: [],
            notRequired: [],
            required: []
        },
        BankData: {
            merchantAppId: null,
            contactName: null,
            phoneNumber: null,
            nameOnCheck: null,
            accountNumber: null,
            routingNumber: null,
            giactResponseCode: null
        },
        isvUserData: {
            ID: 0,
            Name: null,
            Department: null,
            Profile: 0,
            ProfileType: 0,
            UID: null,
            Email: null,
            IP: null,
            DateAdded: null,
            DateUpdated: null,
            LastActivity: null,
            LoginCount: 0,
            isMaster: 0,
            isAdmin: 0,
            Enabled: 0,
            Department_Head: 0,
            Activated: null,
            CF_LoginFailed: 0,
            CF_Locked: 0,
            hide: [],
            notRequired: [],
            required: []
        },
        isoRepData: {
            Address: null,
            Address2: null,
            AgentId1: null,
            AgentId2: null,
            Channel: 0,
            Channel_Secondary: null,
            City: null,
            Company: null,
            Email: null,
            ID: 0,
            Name: null,
            Office: null,
            PartnerID: null,
            PhoneNumber: null,
            SendEmails: 0,
            State: null,
            Website: null,
            ZipCode: null,
            hide: [],
            isPartner: 0,
            notRequired: [],
            required: []
        },
        sendAppLinkEmail: {
            to: null,
            agentPhone: null,
            agentEmail: null,
            agentWebAddress: null
        },
        AmexFees: {
            qualifiedErr: 0,
            nonQualifiedErr: 0,
            transactionFeeErr: 0,
            qualifiedRateTiered: 0,
            midQualifiedRateTiered: 0,
            transactionFeeTiered: 0,
            nonQualifiedRateTiered: 0,
            transactionFeeIC: 0,
            interchangeRateIC: 0
        },
        KnowYourCustomer: {
            id: null,
            merchantAppId: null,
            productsServicesOffered: null,
            advertisementStrategy: null,
            isWorkingWithAttorney: null,
            workingWithAttorneyWho: null,
            howIsFulfillmentHandled: null,
            fulfillmentHouseInfo: null,
            isDsaMember: null,
            hasIndictmentInlast5Years: null,
            indictmentInLast5YearsInfo: null,
            salesGrossRevenuePercentage: null,
            doesPayCommissionOnSales: null,
            recruitingGrossRevenuePercentage: null,
            doesPayCommissionOnRecruiting: null,
            isStartupBeingFinanced: null,
            hasFiledForBankruptcy: null,
            doesMonitorConsumerBoards: null,
            hasPeakPaymentSeason: null,
            peakPaymentSeasonTimes: null,
            doesRecurringBilling: null,
            recurringBillingInfo: null,
            timeFromChargeToShipment: null,
            doesOfferTrial: null,
            trialOfferInfo: null,
            ccCollectionForMotoOrders: null,
            whoHandlesRefunds: null,
            hasRestockingFeesOnRefunds: null,
            whoHandlesProductFulfillment: null,
            doesRequireOngoingDistributorPurchases: null,
            doesProvideDistributorTraining: null,
            canPurchaseProductFromPhysicalLocation: null,
            lengthOfDistributorContract: null,
            howIsDistributorPaperworkProvided: null
        },
        ApplicationFiles: {
            proofOfOwnership: '',
            businessLicense: '',
            financials: '',
            ownerId: '',
            businessImages: '',
            processingStatements: '',
            irsLetter: '',
            compensationPlan: '',
            distributorAgreement: ''
        },
        fileUploadLink: ''
    }
};

export const sectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_APPLICATION_SECTIONS:
            return { ...state, sections: { ...state.sections, ...action.sections } };
        case CLEAR_APPLICATION_SECTIONS:
            return { ...state, ...initialState };
        case UPDATE_MERCHANT_PROFILE_DATA:
            return { ...state, sections: { ...state.sections, MerchantProfile: { ...state.sections.MerchantProfile, ...action.data } } };
        case UPDATE_BUSINESS_INFO_DATA: {
            let updatedState = {
                ...state,
                sections: {
                    ...state.sections,
                    BusinessInfo: {
                        ...state.sections.BusinessInfo,
                        ...action.data
                    }
                }
            };

            if ('webAddress' in action.data && action.data.webAddress === '') {
                updatedState.sections.CreditCardInfo = {
                    ...state.sections.CreditCardInfo,
                    webAddress: ''
                };
            }

            return updatedState;
        }
        case UPDATE_CREDIT_CARD_INFO_DATA:
            return { ...state, sections: { ...state.sections, CreditCardInfo: { ...state.sections.CreditCardInfo, ...action.data } } };
        case UPDATE_BANK_DATA:
            return { ...state, sections: { ...state.sections, BankData: { ...state.sections.BankData, ...action.data } } };
        case UPDATE_APPLICATION_FILES:
            return { ...state, sections: { ...state.sections, ApplicationFiles: { ...state.sections.ApplicationFiles, ...action.data } } };
        case UPDATE_TERM_DATA:
            return { ...state, sections: { ...state.sections, Terms: { ...state.sections.Terms, ...action.data } } };
        case UPDATE_MERCHANT_SURVEY_DATA:
            return { ...state, sections: { ...state.sections, MerchantSiteSurvey: { ...state.sections.MerchantSiteSurvey, ...action.data } } };
        case UPDATE_CARD_COMPLIANCE_DATA:
            return { ...state, sections: { ...state.sections, CardDataStorageComplianceInfo: { ...state.sections.CardDataStorageComplianceInfo, ...action.data } } };
        case UPDATE_AMEX_DATA:
            return { ...state, sections: { ...state.sections, Amex: { ...state.sections.Amex, ...action.data } } };
        case UPDATE_FEES_DATA:
            return { ...state, sections: { ...state.sections, Fees: { ...state.sections.Fees, ...action.data } } };
        case UPDATE_AMEX_FEES_DATA:
            return { ...state, sections: { ...state.sections, AmexFees: { ...state.sections.AmexFees, ...action.data } } };
        case UPDATE_OWNER_INFO_DATA:
            // eslint-disable-next-line no-case-declarations
            const clonedOwnerInfoData = _.cloneDeep(state.sections.OwnerInfoData);
            clonedOwnerInfoData[action.index][action.key] = action.value;

            return { ...state, sections: { ...state.sections, OwnerInfoData: clonedOwnerInfoData } };
        case UPDATE_MERCHANT_PROFILE_ANALYSIS_DATA:
            return { ...state, sections: { ...state.sections, MPAInfo: { ...state.sections.MPAInfo, ...action.data } } };
        case UPDATE_MERCHANT_APP_PARTNER_DATA:
            return { ...state, sections: { ...state.sections, MerchantAppPartner: { ...state.sections.MerchantAppPartner, ...action.data } } };
        case UPDATE_MERCHANT_APP_DATA:
            return { ...state, sections: { ...state.sections, MerchantAppData: { ...state.sections.MerchantAppData, ...action.data } } };
        case UPDATE_KNOW_YOUR_CUSTOMER_DATA:
            return { ...state, sections: { ...state.sections, KnowYourCustomer: { ...state.sections.KnowYourCustomer, ...action.data } } };
        case ADD_OWNER_SUCCESS:
            return { ...state, sections: { ...state.sections, OwnerInfoData: action.data } };
        case GET_UPLOAD_LINK_SUCCESS:
            return { ...state, isGetUploadLinkLoading: false, isGetUploadLinkSuccessful: true };
        case GET_UPLOAD_LINK_LOAD:
            return { ...state, isGetUploadLinkLoading: true, isGetUploadLinkSuccessful: false, getUploadLinkError: null };
        case GET_UPLOAD_LINK_FAILURE:
            return { ...state, isGetUploadLinkLoading: false, getUploadLinkError: action.error };
        case DELETE_APP_FILE_LOAD:
            return { ...state, isDeleteAppFileLoading: true, isDeleteAppFileSuccessful: false, deleteAppFileError: null };
        case DELETE_APP_FILE_SUCCESS:
            return { ...state, isDeleteAppFileLoading: false, isDeleteAppFileSuccessful: true };
        case DELETE_APP_FILE_FAILURE:
            return { ...state, isDeleteAppFileLoading: false, deleteAppFileError: action.error };
        case REMOVE_OWNER_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            const clonedOwners = _.cloneDeep(state.sections.OwnerInfoData);
            // eslint-disable-next-line no-case-declarations
            const updatedOwnerInfoData = clonedOwners.reduce((acc, value, index) => {
                if (action.index !== index) {
                    acc.push(value);
                }

                return acc;
            }, []);

            return { ...state, sections: { ...state.sections, OwnerInfoData: updatedOwnerInfoData } };
        default:
            return state;
    }
};

export const getSectionsState = createSelector(
    (state) => state,
    (state) => _.get(state, 'sectionsReducer')
);

export const getSections = createSelector(
    getSectionsState,
    (sectionsState) => _.get(sectionsState, 'sections')
);

export const getCreditCardInfo = createSelector(
    getSections,
    (sections) => _.get(sections, 'CreditCardInfo')
);

export const getBankData = createSelector(
    getSections,
    (sections) => _.get(sections, 'BankData')
);

export const getApplicationFiles = createSelector(
    getSections,
    (sections) => _.get(sections, 'ApplicationFiles')
);

export const getMerchantProfile = createSelector(
    getSections,
    (sections) => _.get(sections, 'MerchantProfile')
);

export const getMerchantSiteSurvey = createSelector(
    getSections,
    (sections) => _.get(sections, 'MerchantSiteSurvey')
);

export const getIsoRepData = createSelector(
    getSections,
    (sections) => _.get(sections, 'isoRepData')
);

export const getIsoRepPhoneNumber = createSelector(
    getIsoRepData,
    (isoRepData) => _.get(isoRepData, 'PhoneNumber')
);

export const getIsoRepName = createSelector(
    getIsoRepData,
    (isoRepData) => _.get(isoRepData, 'Name')
);

export const getIsoRepEmail = createSelector(
    getIsoRepData,
    (isoRepData) => _.get(isoRepData, 'Email')
);

export const getMPAInfo = createSelector(
    getSections,
    (sections) => _.get(sections, 'MPAInfo')
);

export const getBusinessInfo = createSelector(
    getSections,
    (sections) => _.get(sections, 'BusinessInfo')
);

export const getBusinessInfoEmail = createSelector(
    getBusinessInfo,
    (businessInfo) => _.get(businessInfo, 'email')
);

export const getOwnerInfoData = createSelector(
    getSections,
    (sections) => _.get(sections, 'OwnerInfoData')
);

export const getAmexFees = createSelector(
    getSections,
    (sections) => _.get(sections, 'AmexFees')
);

export const getCardDataStorageComplianceInfo = createSelector(
    getSections,
    (sections) => _.get(sections, 'CardDataStorageComplianceInfo')
);

export const getFees = createSelector(
    getSections,
    (sections) => _.get(sections, 'Fees')
);

export const getMerchantAppData = createSelector(
    getSections,
    (sections) => _.get(sections, 'MerchantAppData')
);

export const getIsDirectSalesApp = createSelector(
    getMerchantAppData,
    (merchantAppData) => _.get(merchantAppData, 'isDirectSalesApp')
);

export const getIs1099App = createSelector(
    getMerchantAppData,
    (merchantAppData) => _.get(merchantAppData, 'is1099App')
);

export const getMerchantAppToken = createSelector(
    getMerchantAppData,
    (merchantAppData) => _.get(merchantAppData, 'token')
);

export const getMerchantAppMerchantHash = createSelector(
    getMerchantAppData,
    (merchantAppData) => _.get(merchantAppData, 'merchantHash')
);

export const getMerchantAppOptBlue = createSelector(
    getMerchantAppData,
    (merchantAppData) => _.get(merchantAppData, 'optBlue')
);

export const getMerchantAppContact = createSelector(
    getMerchantAppData,
    (merchantAppData) => _.get(merchantAppData, 'contact')
);

export const getMerchantAppId = createSelector(
    getMerchantAppData,
    (merchantAppData) => _.get(merchantAppData, 'id')
);

export const getIsvUserData = createSelector(
    getSections,
    (sections) => _.get(sections, 'isvUserData')
);

export const getIsvUserName = createSelector(
    getIsvUserData,
    (isvUserData) => _.get(isvUserData, 'Name')
);

export const getMerchantAppPartner = createSelector(
    getSections,
    (sections) => _.get(sections, 'MerchantAppPartner')
);

export const getMerchantAppPartnerHash = createSelector(
    getMerchantAppPartner,
    (merchantAppPartner) => _.get(merchantAppPartner, 'partnerHash')
);

export const getMerchantAppPartnerName = createSelector(
    getMerchantAppPartner,
    (merchantAppPartner) => _.get(merchantAppPartner, 'partnerName')
);

export const getMerchantAppPricingType = createSelector(
    getMerchantAppPartner,
    (merchantAppPartner) => _.get(merchantAppPartner, 'pricingType')
);

export const getMerchantAppPartnerAgentName = createSelector(
    getMerchantAppPartner,
    (merchantAppPartner) => _.get(merchantAppPartner, 'agentName')
);

export const getMerchantAppPartnerAgentEmail = createSelector(
    getMerchantAppPartner,
    (merchantAppPartner) => _.get(merchantAppPartner, 'agentEmail')
);

export const getTerms = createSelector(
    getSections,
    (sections) => _.get(sections, 'Terms')
);

export const getKnowYourCustomer = createSelector(
    getSections,
    (sections) => _.get(sections, 'KnowYourCustomer')
);

export const getFileUploadLink = createSelector(
    getSections,
    (sections) => _.get(sections, 'fileUploadLink')
);

export const getCardNotPresentMotoPercentage = createSelector(
    getCreditCardInfo,
    (info) => _.get(info, 'cardNotPresentMotoPercentage')
);

export const getCardNotPresentEcommercePercentage = createSelector(
    getCreditCardInfo,
    (info) => _.get(info, 'cardNotPresentEcommercePercentage')
);

export const getIsMarketTypeLodging = createSelector(
    getMerchantProfile,
    (profile) => !!_.get(profile, 'marketTypeLodging')
);

export const isDeleteAppFileError = createSelector(
    getSectionsState,
    (state) => _.get(state, 'deleteAppFileError ')
);

export const getDeleteAppFileErrorMessage = createSelector(
    isDeleteAppFileError,
    (error) => _.get(error, 'message')
);

export const getIsDeleteAppFileLoading = createSelector(
    getSectionsState,
    (state) => _.get(state, 'isDeleteAppFileLoading')
);

export const getIsDeleteAppFileSuccessful = createSelector(
    getSectionsState,
    (state) => _.get(state, 'isDeleteAppFileSuccessful')
);

export const getUploadLinkError = createSelector(
    getSectionsState,
    (state) => _.get(state, 'getUploadLinkError')
);

export const getUploadLinkErrorMessage = createSelector(
    getUploadLinkError,
    (error) => _.get(error, 'message')
);

export const getUploadLinkLoading = createSelector(
    getSectionsState,
    (state) => _.get(state, 'isGetUploadLinkLoading')
);

export const getUploadLinkSuccessful = createSelector(
    getSectionsState,
    (state) => _.get(state, 'isGetUploadLinkSuccessful')
);
