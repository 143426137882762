import mixpanel from 'mixpanel-browser';
import config from '../config/config';
import _ from 'lodash';

const token = _.get(config, 'mixpanel.token', '');

mixpanel.init(token, {
    api_host: 'https://api.mixpanel.com',
    autotrack: false
});

export default mixpanel;
