import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Group.scss';

class Group extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ])
    };

    render() {
        return (
            <div className="section-group">
                {this.props.children}
            </div>
        );
    }
}

export default Group;