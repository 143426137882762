import React, { Component } from 'react';
import PropTypes from 'prop-types';

import scrollBehavior from '../../../../utils/scrollBehavior';
import MerchantProfileAnalysis from '../Sections/MerchantProfileAnalysis/MerchantProfileAnalysis';

export class Page3a extends Component {
    static propTypes = {
        invalidInputs: PropTypes.any,
        runValidation: PropTypes.func
    };

    componentDidMount() {
        const application = document.getElementById('merchant-application');
        application.scrollIntoView(scrollBehavior());
    }

    render() {
        const { invalidInputs } = this.props;

        return (
            <div>
                <MerchantProfileAnalysis invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
            </div>
        );
    }
}

export default Page3a;
