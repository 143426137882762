import { createFetchReducer, createBaseSelectors } from '@nexio/ui-redux';

import {
    FETCH_ENABLED_PARTNERS_LOAD,
    FETCH_ENABLED_PARTNERS_FAILURE,
    FETCH_ENABLED_PARTNERS_SUCCESS
} from '../actions/partnerActions';

export default createFetchReducer({
    loadActionType: FETCH_ENABLED_PARTNERS_LOAD,
    failureActionType: FETCH_ENABLED_PARTNERS_FAILURE,
    successActionType: FETCH_ENABLED_PARTNERS_SUCCESS
});

const { getData, getIsLoading } = createBaseSelectors('partnersReducer');

export const getIsEnabledPartnersLoading = getIsLoading;
export const getEnabledPartners = getData;
