import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Section from '../../../../Shared/Section/Section';
import Row from '../../../../Shared/Row/Row';
import Group from '../../../../Shared/Group/Group';
import TextInput from '../../../../Shared/TextInput/TextInput';
import CheckboxContainer from '../../../../Shared/CheckboxContainer/CheckboxContainer';
import BaseSection from '../BaseSection';

import './CreditCardInfo.scss';

import { CREDIT_CARD_INFO } from '../../../../../actions/sectionsActions';
import {
    getErrorMessage,
    getIsValid,
    isRadioChecked,
    shouldHideElement,
    shouldNotBeRequired,
    shouldBeRequired,
    shouldShowHelp,
    conditionalShow
} from '../../../../../utils/form';
import { getCreditCardInfo, getMerchantAppData, getMerchantProfile } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class CreditCardInfo extends BaseSection {
    sectionName = CREDIT_CARD_INFO;
    state = {
        creditCardTransactionPercentage: 0,
        cardSwipedPercentage: this.props.creditCardInfo.cardSwipedPercentage,
        cardKeyedPercentage: this.props.creditCardInfo.cardKeyedPercentage,
        cardNotPresentMotoPercentage: this.props.creditCardInfo.cardNotPresentMotoPercentage,
        cardNotPresentEcommercePercentage: this.props.creditCardInfo.cardNotPresentEcommercePercentage,
        monthlyVisaDiscoverSales: this.props.creditCardInfo.monthlyVisaDiscoverSales,
        averageSaleAmount: this.props.creditCardInfo.averageSaleAmount,
        amexSe: this.props.creditCardInfo.amexSe,
        isUserAnAgent: this.props.isUserAnAgent,
        highTicket: this.props.creditCardInfo.highTicket
    };

    constructor(props) {
        super(props);

        this.state = {
            creditCardTransactionPercentage: 0,
            cardSwipedPercentage: this.props.creditCardInfo.cardSwipedPercentage,
            cardKeyedPercentage: this.props.creditCardInfo.cardKeyedPercentage,
            cardNotPresentMotoPercentage: this.props.creditCardInfo.cardNotPresentMotoPercentage,
            cardNotPresentEcommercePercentage: this.props.creditCardInfo.cardNotPresentEcommercePercentage,
            monthlyVisaDiscoverSales: this.props.creditCardInfo.monthlyVisaDiscoverSales,
            averageSaleAmount: this.props.creditCardInfo.averageSaleAmount,
            amexSe: this.props.creditCardInfo.amexSe,
            multipleLocations: this.props.creditCardInfo.multipleLocations,
            amex: this.props.creditCardInfo.amex,
            highTicket: this.props.creditCardInfo.highTicket
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.creditCardInfo, this.props.creditCardInfo)) {
            this.setState({
                creditCardTransactionPercentage: 0,
                cardSwipedPercentage: this.props.creditCardInfo.cardSwipedPercentage,
                cardKeyedPercentage: this.props.creditCardInfo.cardKeyedPercentage,
                cardNotPresentMotoPercentage: this.props.creditCardInfo.cardNotPresentMotoPercentage,
                cardNotPresentEcommercePercentage: this.props.creditCardInfo.cardNotPresentEcommercePercentage,
                monthlyVisaDiscoverSales: this.props.creditCardInfo.monthlyVisaDiscoverSales,
                averageSaleAmount: this.props.creditCardInfo.averageSaleAmount,
                amexSe: this.props.creditCardInfo.amexSe,
                multipleLocations: this.props.creditCardInfo.multipleLocations,
                amex: this.props.creditCardInfo.amex,
                highTicket: this.props.creditCardInfo.highTicket
            });
        }
    }

    handleNumberChange = (e) => {
        e.persist();
        this.setState({
            [e.target.name]: _.parseInt(e.target.value) || 0
        });
    };

    render() {
        const { creditCardInfo, invalidInputs, merchantAppData } = this.props;
        const { cardSwipedPercentage, cardKeyedPercentage, cardNotPresentMotoPercentage, cardNotPresentEcommercePercentage, monthlyVisaDiscoverSales, averageSaleAmount, amexSe, highTicket } = this.state;

        if ((creditCardInfo.amex === 0 || creditCardInfo.amex === 1) && amexSe) {
            this.props.creditCardInfo.amexSe = null;
        }

        return (
            <div>
                <Section heading="Credit Card Information" className="creditcard-info">
                    <Group>
                        <div className="ccinfo-header" justify="left">Sales Profile (must = 100%)</div>
                        <div id="cardSwipedPercentage" className="form-input-wrapper" style={shouldHideElement('cardSwipedPercentage', creditCardInfo.hide)}>
                            <div className="arrow-box percentage-box" style={shouldShowHelp('cardSwipedPercentage', this.state.currentFocused)} >
                                Percentage of your credit card transactions physically swiped through a terminal
                            </div>
                            <div className="multi-item-wrapper">
                                <div className="card-percentages" id="cardSwipedPercentage">
                                    <div className="start">
                                        <label className="label">Card Present/Swiped</label>
                                    </div>
                                    <TextInput
                                        name="cardSwipedPercentage"
                                        type="percent"
                                        handleChange={this.handleNumberChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={cardSwipedPercentage}
                                        required={this.state.isUserAnAgent ? false : shouldBeRequired('cardSwipedPercentage', creditCardInfo.required)}
                                        valid={getIsValid('cardSwipedPercentage', invalidInputs)}
                                        errorMessage={getErrorMessage('cardSwipedPercentage', invalidInputs)}
                                        handleFocus={this.handleFocus}
                                    />
                                </div>
                                <div className="card-percentages" id="cardKeyedPercentage" style={shouldHideElement('cardKeyedPercentage', creditCardInfo.hide)}>
                                    <div className="arrow-box percentage-box" style={shouldShowHelp('cardKeyedPercentage', this.state.currentFocused)}>
                                        Percentage of your credit card transactions manually keyed in although the credit card is present
                                    </div>
                                    <div className="start">
                                        <label className="label">Card Present/Keyed</label>
                                    </div>
                                    <TextInput
                                        name="cardKeyedPercentage"
                                        type="percent"
                                        handleChange={this.handleNumberChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={cardKeyedPercentage}
                                        required={this.state.isUserAnAgent ? false : shouldBeRequired('cardKeyedPercentage', creditCardInfo.required)}
                                        valid={getIsValid('cardKeyedPercentage', invalidInputs)}
                                        errorMessage={getErrorMessage('cardKeyedPercentage', invalidInputs)}
                                        handleFocus={this.handleFocus}
                                    />
                                </div>
                                <div className="card-percentages" id="cardNotPresentMotoPercentage" style={shouldHideElement('cardNotPresentMotoPercentage', creditCardInfo.hide)}>
                                    <div className="arrow-box percentage-box" style={shouldShowHelp('cardNotPresentMotoPercentage', this.state.currentFocused)}>
                                        Percentage of your credit card transactions taken over the phone
                                    </div>
                                    <div className="start">
                                        <label className="label">Card Not Present/MOTO</label>
                                    </div>
                                    <TextInput
                                        name="cardNotPresentMotoPercentage"
                                        type="percent"
                                        handleChange={this.handleNumberChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={cardNotPresentMotoPercentage}
                                        required={this.state.isUserAnAgent ? false : shouldBeRequired('cardNotPresentMotoPercentage', creditCardInfo.required)}
                                        valid={getIsValid('cardNotPresentMotoPercentage', invalidInputs)}
                                        errorMessage={getErrorMessage('cardNotPresentMotoPercentage', invalidInputs)}
                                        handleFocus={this.handleFocus}
                                    />
                                </div>
                                <div className="card-percentages" id="cardNotPresentEcommercePercentage" style={shouldHideElement('cardNotPresentEcommercePercentage', creditCardInfo.hide)}>
                                    <div className="arrow-box percentage-box" style={shouldShowHelp('cardNotPresentEcommercePercentage', this.state.currentFocused)}>
                                        Percentage of your credit card transactions taken online
                                    </div>
                                    <div className="start">
                                        <label className="label">Card Not Present/Ecommerce</label>
                                    </div>
                                    <TextInput
                                        name="cardNotPresentEcommercePercentage"
                                        type="percent"
                                        handleChange={this.handleNumberChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={cardNotPresentEcommercePercentage}
                                        required={this.state.isUserAnAgent ? false : shouldBeRequired('cardNotPresentEcommercePercentage', creditCardInfo.required)}
                                        valid={getIsValid('cardNotPresentEcommercePercentage', invalidInputs)}
                                        errorMessage={getErrorMessage('cardNotPresentEcommercePercentage', invalidInputs)}
                                        handleFocus={this.handleFocus}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="totalProcessingPercentage">
                            <Row alignment="baseline">
                                <label className="percentage-label">Current Total:</label>
                                <TextInput
                                    name="totalProcessingPercentage"
                                    type="number"
                                    valid={ _.sum([cardSwipedPercentage, cardKeyedPercentage, cardNotPresentMotoPercentage, cardNotPresentEcommercePercentage]) === 100}
                                    // errorMessage="this must be equal to 100 %"
                                    value={ _.sum([cardSwipedPercentage, cardKeyedPercentage, cardNotPresentMotoPercentage, cardNotPresentEcommercePercentage]).toString() }
                                    required={true}
                                    minimum={100}
                                    maximum={100}
                                    handleChange={() => {}}
                                    handleBlur={() => {}}
                                />
                                <label style={{ marginLeft: '8px' }}>%</label>
                            </Row>
                        </div>
                        <div className="form-input-wrapper" id="monthlyVisaDiscoverSales" style={shouldHideElement('monthlyVisaDiscoverSales', creditCardInfo.hide)}>
                            <div className="multi-item-wrapper">
                                <div className="arrow-box" style={shouldShowHelp('monthlyVisaDiscoverSales', this.state.currentFocused)} >
                                    The amount of monthly volume you are applying for. What is the highest anticipated monthly volume you anticipate processing? You may need to provide processing statements to substantiate what you are applying for.
                                </div>
                                <div className="half-width">
                                    <div className="start">
                                        <label className="label">Monthly Volume</label>
                                    </div>
                                    <TextInput
                                        name="monthlyVisaDiscoverSales"
                                        type="number"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={monthlyVisaDiscoverSales}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('monthlyVisaDiscoverSales', creditCardInfo.notRequired)}
                                        valid={getIsValid('monthlyVisaDiscoverSales', invalidInputs)}
                                        errorMessage={getErrorMessage('monthlyVisaDiscoverSales', invalidInputs)}
                                    />
                                </div>
                                <div className="half-width" id="averageSaleAmount" style={shouldHideElement('averageSaleAmount', creditCardInfo.hide)}>
                                    <div className="arrow-box" style={shouldShowHelp('averageSaleAmount', this.state.currentFocused)}>
                                        Average Sale Amount
                                    </div>
                                    <div className="start">
                                        <label className="label">Average Ticket</label>
                                    </div>
                                    <TextInput
                                        name="averageSaleAmount"
                                        type="number"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={averageSaleAmount}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('averageSaleAmount', creditCardInfo.notRequired)}
                                        valid={getIsValid('averageSaleAmount', invalidInputs)}
                                        errorMessage={getErrorMessage('averageSaleAmount', invalidInputs)}
                                        handleFocus={this.handleFocus}
                                    />
                                </div>
                                <div className="half-width" id="highTicket" style={shouldHideElement('highTicket', creditCardInfo.hide)}>
                                    <div className="arrow-box" style={shouldShowHelp('highTicket', this.state.currentFocused)}>
                                        High Ticket
                                    </div>
                                    <div className="start">
                                        <label className="label">High Ticket</label>
                                    </div>
                                    <TextInput
                                        name="highTicket"
                                        type="number"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={highTicket}
                                        required={this.state.isUserAnAgent ? false : shouldNotBeRequired('highTicket', creditCardInfo.notRequired)}
                                        valid={getIsValid('highTicket', invalidInputs)}
                                        errorMessage={getErrorMessage('highTicket', invalidInputs)}
                                        handleFocus={this.handleFocus}
                                    />
                                </div>
                            </div>
                        </div>
                    </Group>
                </Section>
                <Section heading="American Express Information" className="amex-info" style={conditionalShow(merchantAppData.optBlue === 0)}>
                    <Group>
                        <div className="form-input-wrapper">
                            <div className="item-wrapper">
                                <CheckboxContainer name="amex" isRequired={merchantAppData.optBlue === 0}>
                                    <div className="start">
                                        <label className="label">
                                            American Express Information
                                        </label>
                                    </div>
                                    <div className="onethird-width-div">
                                        <input
                                            type="radio"
                                            name="amex"
                                            value="0"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(0, this.state.amex)}
                                        />
                                        <label>None</label>
                                    </div>
                                    <div className="onethird-width-div">
                                        <input
                                            type="radio"
                                            name="amex"
                                            value="1"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(1, this.state.amex)}
                                        />
                                        <label>New</label>
                                    </div>
                                    <div className="onethird-width-div">
                                        <input
                                            type="radio"
                                            name="amex"
                                            value="2"
                                            onChange={this.handleRadioChange}
                                            checked={isRadioChecked(2, this.state.amex)}
                                        />
                                        <label>Existing</label>
                                    </div>
                                </CheckboxContainer>
                            </div>
                        </div>
                        <div className="form-input-wrapper" id="amexSe" style={conditionalShow(creditCardInfo.amex === 2)}>
                            <div className="item-wrapper">
                                <Row>
                                    <label className="label">SE (Existing Merchant Number)</label>
                                </Row>
                                <Row>
                                    <TextInput
                                        name="amexSe"
                                        handleChange={this.handleTextChange}
                                        handleBlur={this.handleTextBlur()}
                                        value={amexSe}
                                        required={creditCardInfo.amex === 2}
                                        valid={getIsValid('amexSe', invalidInputs)}
                                        errorMessage={getErrorMessage('amexSe', invalidInputs)}
                                    />
                                </Row>
                            </div>
                        </div>
                    </Group>
                </Section >
            </div>
        );
    }
}

export const mapStateToProps = (state) => ({
    creditCardInfo: getCreditCardInfo(state),
    isUserAnAgent: getIsUserAnAgent(state),
    merchantAppData: getMerchantAppData(state),
    merchantProfile: getMerchantProfile(state)
});

export default connect(mapStateToProps)(CreditCardInfo);
