import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import {
    GET_DOCUSIGN_IFRAME_URL_FAILURE,
    GET_DOCUSIGN_IFRAME_URL_LOAD,
    GET_DOCUSIGN_IFRAME_URL_SUCCESS
} from './docuSignIframeActions';
import { createSelector } from 'reselect';
import _ from 'lodash';

export const getDocusignIframeUrlReducer = createFetchReducer({
    loadActionType: GET_DOCUSIGN_IFRAME_URL_LOAD,
    failureActionType: GET_DOCUSIGN_IFRAME_URL_FAILURE,
    successActionType: GET_DOCUSIGN_IFRAME_URL_SUCCESS
});

const baseSelectors = createBaseSelectors('getDocusignIframeUrlReducer');

export const getIsDocusignIframeUrlLoading = baseSelectors.getIsLoading;

export const getDocusignIframeUrl = createSelector(
    baseSelectors.getData,
    (data) => _.get(data, 'docusignURL')
);

export const getDocusignIframeUrlError = baseSelectors.getError;