import React, { useEffect } from 'react';
import {
    EmvioLoadingSpinner as LoadingSpinner
} from '@nexio/emvio-util-ui';
import { postNextSigner } from './nextSignerActions';
import './NextSigner.scss';
import { setLocalStorageItem } from '../../../../../utils/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'qs';
import { getIsPostNextSignerLoading, getPostNextSignerError, getRedirectUrl } from './nextSignerReducer';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

const NextSigner = () => {
    const postNextSignerError = useSelector(getPostNextSignerError);
    const isPostNextSignerLoading = useSelector(getIsPostNextSignerLoading);
    const redirectUrl = useSelector(getRedirectUrl);
    const location = useLocation();
    const dispatch = useDispatch();

    const paramsObj = parse(location.search, { ignoreQueryPrefix: true });

    const token = _.get(paramsObj, 't');

    useEffect(() => {
        if (redirectUrl) {
            window.location = redirectUrl;
        }
    }, [redirectUrl]);

    useEffect(() => {
        const asyncFunction = async () => {
            if (token) {
                await dispatch(postNextSigner(paramsObj.t, location.pathname.includes('underWriterSigner'), location.pathname.includes('agentSigner')));
                setLocalStorageItem('merchant-token', paramsObj.t);
            }
        };

        asyncFunction();
    }, []);

    if (postNextSignerError || !token) {
        return (
            <div className="no-longer-valid">
                This link is no longer valid
            </div>
        );
    }

    return (
        <div className="loading">
            <div className="loading-label">
                {isPostNextSignerLoading ? 'Please wait' : 'Please wait while DocuSign prepares your document for signing'}
            </div>
            <LoadingSpinner size="large" relative />
        </div>
    );
};

export default NextSigner;