import React from 'react';
import { connect } from 'react-redux';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import Row from '../../../../Shared/Row/Row';
import TextInput from '../../../../Shared/TextInput/TextInput';
import TextArea from '../../../../Shared/TextArea/TextArea';

import './MerchantSurvey.scss';
import _ from 'lodash';
import { MERCHANT_SURVEY } from '../../../../../actions/sectionsActions';
import {
    isChecked,
    getIsValid,
    getErrorMessage,
    shouldBeRequired
} from '../../../../../utils/form';
import CheckboxContainer from '../../../../Shared/CheckboxContainer/CheckboxContainer';
import { getIsoRepData, getMerchantSiteSurvey } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class MerchantSurvey extends BaseSection {
    sectionName = MERCHANT_SURVEY;

    state = {
        locationOtherDescription: this.props.merchantSurvey.locationOtherDescription,
        notes: this.props.merchantSurvey.notes,
        isUserAnAgent: this.props.isUserAnAgent
    };

    constructor(props) {
        super(props);

        this.state = {
            ...props.merchantSurvey
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.merchantSurvey, this.props.merchantSurvey)) {
            this.setState({
                ...this.props.merchantSurvey
            });
        }
    }

    render() {
        const { merchantSurvey, invalidInputs } = this.props;
        const { notes, locationOtherDescription } = this.state;
        if (merchantSurvey.useFulfillmentHouse === 0 && merchantSurvey.fulfillmentHouseInspected) {
            this.props.merchantSurvey.fulfillmentHouseInspected = null;
        }

        return (
            <Section className="merchant-site-survey" heading="Merchant Site Survey Report (To Be Completed by Sales Representative)" >
                <Group>
                    <div className="form-input-wrapper">
                        <div className="item-wrapper" id="locations">
                            <Row>
                                <div className="start">
                                    <label className="label">Merchant Location: </label>
                                </div>
                            </Row>
                            <CheckboxContainer name="location" isRequired={true}>
                                <div className="half-width-div">
                                    <span className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            name="locationOffice"
                                            onChange={this.handleCheckboxChange}
                                            checked={isChecked(this.state.locationOffice)}
                                        />
                                    </span>
                                    <span className="checkbox-label-container">
                                        <label>Office Building</label>
                                    </span>
                                </div>
                                <div className="half-width-div">
                                    <span className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            name="locationShoppingArea"
                                            onChange={this.handleCheckboxChange}
                                            checked={isChecked(this.state.locationShoppingArea)}
                                        />
                                    </span>
                                    <span className="checkbox-label-container">
                                        <label>Store Front</label>
                                    </span>
                                </div>
                                <div className="half-width-div">
                                    <span className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            name="locationHome"
                                            onChange={this.handleCheckboxChange}
                                            checked={isChecked(this.state.locationHome)}
                                        />
                                    </span>
                                    <span className="checkbox-label-container">
                                        <label>Residence</label>
                                    </span>
                                </div>
                                <div className="half-width-div">
                                    <span className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            name="locationWarehouse"
                                            onChange={this.handleCheckboxChange}
                                            checked={isChecked(this.state.locationWarehouse)}
                                        />
                                    </span>
                                    <span className="checkbox-label-container">
                                        <label>Warehouse</label>
                                    </span>
                                </div>
                                <div className="full-width-div">
                                    <div>
                                        <span className="checkbox-container">
                                            <input
                                                type="checkbox"
                                                name="locationOther"
                                                onChange={this.handleCheckboxChange}
                                                checked={isChecked(this.state.locationOther)}
                                            />
                                        </span>
                                        <span className="checkbox-label-container">
                                            <label>Other</label>
                                        </span>
                                    </div>
                                    <div className="other-input">
                                        <TextInput
                                            name="locationOtherDescription"
                                            handleChange={this.handleTextChange}
                                            handleBlur={this.handleTextBlur()}
                                            value={locationOtherDescription}
                                            required={false}
                                            valid={getIsValid('locationOtherDescription', invalidInputs)}
                                            errorMessage={getErrorMessage('locationOtherDescription', invalidInputs)}
                                        />
                                    </div>
                                </div>
                            </CheckboxContainer>
                        </div>
                    </div>
                    <div className="form-input-wrapper" id="notes" >
                        <div className="item-wrapper">
                            <Row>
                                <div className="start">
                                    <label className="label">Notes:
                                        <span className="optional-input">{shouldBeRequired('notes', merchantSurvey.hide) ? '' : 'Optional'} </span>
                                    </label>
                                </div>
                            </Row>
                            <TextArea
                                type="text"
                                name="notes"
                                handleChange={this.handleTextChange}
                                handleBlur={this.handleTextBlur()}
                                value={notes}
                                required={false}
                                valid={getIsValid('notes', invalidInputs)}
                                errorMessage={getErrorMessage('notes', invalidInputs)}
                            />
                        </div>
                    </div>
                </Group >
            </Section >
        );
    }
}

export const mapStateToProps = (state) => ({
    merchantSurvey: getMerchantSiteSurvey(state),
    isoRepData: getIsoRepData(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(MerchantSurvey);
