import { getIsMerchantAuthorized } from '../reducers/applicationReducer';

export const tokenMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (typeof action === 'function') {
        const isMerchantAuthorized = getIsMerchantAuthorized(getState());
        const item = isMerchantAuthorized ? 'merchant-token' : 'cforce-token';
        const token = window.localStorage.getItem(item);

        return action({ dispatch, getState, token });
    }

    return next(action);
};
