import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './Terms.scss';

import BaseSection from '../BaseSection';
import Section from '../../../../Shared/Section/Section';
import Group from '../../../../Shared/Group/Group';
import CbCal from '../../../../Shared/Forms/cbCal_Terms_And_Conditions.pdf';
import MerrickPdf from '../../../../Shared/Forms/Merrick_Terms_And_Conditions.pdf';
import ChesapeakePdf from '../../../../Shared/Forms/Chesapeake_Terms_And_Conditions.pdf';
import DeutschePdf from '../../../../Shared/Forms/Deutsche_Terms_And_Conditions.pdf';
import TriAppPdf from '../../../../Shared/Forms/Tri_App_Terms_And_Conditions.pdf';
import CheckboxContainer from '../../../../Shared/CheckboxContainer/CheckboxContainer';

import { TERMS } from '../../../../../actions/sectionsActions';
import {
    isChecked, shouldNotBeRequired
} from '../../../../../utils/form';
import { getTerms } from '../../../../../reducers/sectionsReducer';
import { getIsUserAnAgent } from '../../../../../reducers/applicationReducer';

export class Terms extends BaseSection {
    sectionName = TERMS;

    state = {
        isUserAnAgent: this.props.isUserAnAgent
    };

    static propTypes = {
        terms: PropTypes.shape({
            termsCheckbox: PropTypes.number
        })
    };

    constructor(props) {
        super(props);

        this.state = {
            termsCheckbox: props.terms.termsCheckbox
        };
    }

    render() {
        const { terms } = this.props;
        let termsPdfUrl = '';
        if (terms.fileName) {
            if (terms.fileName.includes('Merrick')) {
                termsPdfUrl = MerrickPdf;
            } else if (terms.fileName.includes('cbCal')) {
                termsPdfUrl = CbCal;
            } else if (terms.fileName.includes('Ches')) {
                termsPdfUrl = ChesapeakePdf;
            } else if (terms.fileName.includes('Deut')) {
                termsPdfUrl = DeutschePdf;
            } else if (terms.fileName.includes('Tri_App')) {
                termsPdfUrl = TriAppPdf;
            }
        }

        return (
            <Section heading="Terms & Agreements">
                <Group>
                    <div id="termsCheckbox">
                        <CheckboxContainer name="termsCheckbox" isRequired={this.state.isUserAnAgent ? false : shouldNotBeRequired('termsCheckbox', terms.notRequired)}>
                            <label>Accept Terms &amp; Conditions</label>
                            <input
                                type="checkbox"
                                name="termsCheckbox"
                                onChange={this.handleCheckboxChange}
                                checked={isChecked(this.state.termsCheckbox)}
                                required={true}
                            />
                        </CheckboxContainer>
                    </div>
                    <label className="label">
                        <a href={termsPdfUrl} target="_blank" rel="noopener noreferrer">
                            Click Here to View the Terms and Conditions
                        </a>
                    </label>
                </Group>
            </Section>
        );
    }
}

export const mapStateToProps = (state) => ({
    terms: getTerms(state),
    isUserAnAgent: getIsUserAnAgent(state)
});

export default connect(mapStateToProps)(Terms);
