import config from '../../../../../config/config';

export const FETCH_CREATE_APP_CONFIG_LOAD = 'FETCH_CREATE_APP_CONFIG_LOAD';
export const FETCH_CREATE_APP_CONFIG_FAILURE = 'FETCH_CREATE_APP_CONFIG_FAILURE';
export const FETCH_CREATE_APP_CONFIG_SUCCESS = 'FETCH_CREATE_APP_CONFIG_SUCCESS';

export const getConfigData = () => {
    return {
        fetchParams: {
            url: `${config.cforceServiceUrl}/config`
        },
        loadActionTypeOrCreator: FETCH_CREATE_APP_CONFIG_LOAD,
        failureActionTypeOrCreator: FETCH_CREATE_APP_CONFIG_FAILURE,
        successActionTypeOrCreator: FETCH_CREATE_APP_CONFIG_SUCCESS
    };
};