import React, { Component } from 'react';
import PropTypes from 'prop-types';

import scrollBehavior from '../../../../utils/scrollBehavior';
import Pricing from '../Sections/Pricing/Pricing';
import AmexFees from '../Sections/AmexFees/AmexFees';
import BusinessInfo from '../Sections/BusinessInfo/BusinessInfo';
import MerchantProfile from '../Sections/MerchantProfile/MerchantProfile';

export class Page1 extends Component{
    static propTypes = {
        invalidInputs: PropTypes.any,
        runValidation: PropTypes.func
    };

    componentDidMount() {
        const application = document.getElementById('merchant-application');
        application.scrollIntoView(scrollBehavior());
    }

    render() {
        const { invalidInputs } = this.props;

        return (
            <div>
                <Pricing invalidInputs={invalidInputs}/>
                <AmexFees invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
                <BusinessInfo invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
                <MerchantProfile invalidInputs={invalidInputs} runValidation={this.props.runValidation}/>
            </div>
        );
    }
}

export default Page1;
