import {
    CREATE_APP_LOAD,
    CREATE_APP_FAILURE,
    CREATE_APP_SUCCESS
} from '../actions/createAppActions';
import { createFetchReducer, createBaseSelectors } from '@nexio/ui-redux';
import _ from 'lodash';
import { createSelector } from 'reselect';

export default createFetchReducer({
    loadActionType: CREATE_APP_LOAD,
    failureActionType: CREATE_APP_FAILURE,
    successActionType: CREATE_APP_SUCCESS
});

const baseSelectors = createBaseSelectors('createAppReducer');

export const getMerchantAppId = createSelector(
    baseSelectors.getData,
    (data) => _.get(data, 'merchantAppId')
);