import config from '../../../../config/config';

export const FETCH_PARTNER_DEFAULTS_LOAD = 'FETCH_PARTNER_DEFAULTS_LOAD';
export const FETCH_PARTNER_DEFAULTS_FAILURE = 'FETCH_PARTNER_DEFAULTS_FAILURE';
export const FETCH_PARTNER_DEFAULTS_SUCCESS = 'FETCH_PARTNER_DEFAULTS_SUCCESS';

export const SAVE_PARTNER_DEFAULTS_LOAD = 'SAVE_PARTNER_DEFAULTS_LOAD';
export const SAVE_PARTNER_DEFAULTS_FAILURE = 'SAVE_PARTNER_DEFAULTS_FAILURE';
export const SAVE_PARTNER_DEFAULTS_SUCCESS = 'SAVE_PARTNER_DEFAULTS_SUCCESS';

export const fetchPartnerDefaults = (partnerId) => {
    return {
        fetchParams: { url: `${config.cforceServiceUrl}/partner/defaults/${partnerId}` },
        loadActionTypeOrCreator: FETCH_PARTNER_DEFAULTS_LOAD,
        failureActionTypeOrCreator: FETCH_PARTNER_DEFAULTS_FAILURE,
        successActionTypeOrCreator: FETCH_PARTNER_DEFAULTS_SUCCESS
    };
};

export const savePartnerDefaults = (partnerDefaults) => {
    return {
        fetchParams: {
            method: 'POST',
            body: partnerDefaults,
            url: `${config.cforceServiceUrl}/partner/defaults`
        },
        loadActionTypeOrCreator: SAVE_PARTNER_DEFAULTS_LOAD,
        failureActionTypeOrCreator: SAVE_PARTNER_DEFAULTS_FAILURE,
        successActionTypeOrCreator: SAVE_PARTNER_DEFAULTS_SUCCESS
    };
};
