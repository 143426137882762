import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Row.scss';

class Row extends Component {
    static propTypes = {
        justify: PropTypes.oneOf([
            'center',
            'start',
            'end',
            'space-between',
            'space-around',
            'space-evenly',
            'half-width'
        ]),
        alignment: PropTypes.oneOf([
            'center',
            'baseline'
        ]),
        children: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
            PropTypes.string
        ])
    };

    static defaultProps = {
        justify: 'start',
        alignment: 'center'
    };

    render() {
        const { justify, alignment } = this.props;
        const classNames = `${justify} ${alignment}`;

        return (
            <div className={classNames}>
                {this.props.children}
            </div>
        );
    }
}

export default Row;