import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import { createSelector } from 'reselect';
import {
    CREATE_PARTNER_APP_LOAD,
    CREATE_PARTNER_APP_FAILURE,
    CREATE_PARTNER_APP_SUCCESS
} from '../actions/createPartnerAppActions';
import _ from 'lodash';

export default createFetchReducer({
    loadActionType: CREATE_PARTNER_APP_LOAD,
    failureActionType: CREATE_PARTNER_APP_FAILURE,
    successActionType: CREATE_PARTNER_APP_SUCCESS
});

const { getData, getErrorMessage, getIsLoading } = createBaseSelectors('createPartnerAppReducer');

export const getMerchantHash = createSelector(
    getData,
    (data) => _.get(data, 'merchantHash')
);
export const getToken = createSelector(
    getData,
    (data) => _.get(data, 'token')
);
export const getPartnerHash = createSelector(
    getData,
    (data) => _.get(data, 'partnerHash')
);
export const getMerchantId = createSelector(
    getData,
    (data) => _.get(data, 'getMerchantId')
);

export const getCreatePartnerAppErrorMessage = getErrorMessage;

export const getCreatePartnerAppIsLoading = getIsLoading;
