import PropTypes from 'prop-types';

export const merchantProfile = {
    hiddenFields: PropTypes.array,
    requiredFields: PropTypes.array,
    ownerShipType: PropTypes.string,
    marketTypeRetail: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypeRestaurant: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypeSupermarket: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypeLodging: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypeMOTO: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypeECommerce: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypeEmergingMarket: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypePublicSector: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypeCashAdvance: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    marketTypeOther: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    businessStartDate: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    typesOfGoodsServices: PropTypes.string,
    federalTaxId: PropTypes.string,
    acceptCard: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    businessSeasonal: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    depositRequired: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    highVolumeMonths: PropTypes.string,
    additionalBenefitsOffered: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    durationOfBenefitsInWeeks: PropTypes.string,
    recurringTransactions: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    acceptPrepayment: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    prepaidsalesPercentage: PropTypes.string,
    avgPrepaidSalesPercentage: PropTypes.string,
    merchantAppData: PropTypes.array
};

export const merchantAppData = {
    merchantAppData: PropTypes.array
};

export const isoRepData = {
    Address: PropTypes.string,
    Address2: PropTypes.string,
    AgentId1: PropTypes.string,
    AgentId2: PropTypes.string,
    Channel: PropTypes.number,
    Channel_Secondary: PropTypes.string,
    City: PropTypes.string,
    Company: PropTypes.string,
    Email: PropTypes.string,
    ID: PropTypes.number,
    Name: PropTypes.string,
    Office: PropTypes.number,
    PartnerID: PropTypes.number,
    PhoneNumber: PropTypes.string,
    SendEmails: PropTypes.number,
    State: PropTypes.string,
    Website: PropTypes.string,
    ZipCode: PropTypes.string,
    hide: PropTypes.array,
    isPartner: PropTypes.number,
    notRequired: PropTypes.array,
    required: PropTypes.array
};

export const merchantAppPartner = {
    id: PropTypes.number,
    partnerName: PropTypes.string,
    partnerLanding: PropTypes.string,
    gateway: PropTypes.string,
    partnerHash: PropTypes.string,
    showPricing: PropTypes.number,
    pricingType: PropTypes.string,
    qualifiedRate: PropTypes.number,
    qualifiedRateTiered: PropTypes.number,
    midQualifiedRate: PropTypes.number,
    nonQualifiedRate: PropTypes.number,
    nonQualifiedRateErr: PropTypes.number,
    transactionFee: PropTypes.number,
    transactionFeeTiered: PropTypes.number,
    transactionFeeIc: PropTypes.number,
    merchantFee: PropTypes.number,
    merchantFeeTiered: PropTypes.number,
    merchantFeeIc: PropTypes.number,
    interchangeRate: PropTypes.string,
    accountSetupFee: PropTypes.string,
    annualPciComplianceFee: PropTypes.number,
    annualMembershipFee: PropTypes.number,
    minimumFee: PropTypes.string,
    monthlyStatementFee: PropTypes.string,
    chargebackFee: PropTypes.string,
    retrievalFee: PropTypes.string,
    monthlyRegulatoryFee: PropTypes.string,
    insufficientFundsFee: PropTypes.string,
    monthlyDebitAccessFee: PropTypes.number,
    edsAvsFee: PropTypes.number,
    voiceAuthFee: PropTypes.number,
    helpDeskFee: PropTypes.number,
    onlineReportingAccessFee: PropTypes.number,
    notes: PropTypes.string,
    repCodeID: PropTypes.number,
    AgentID1: PropTypes.string,
    AgentID2: PropTypes.string,
    pricingCategory: PropTypes.string,
    enabled: PropTypes.boolean,
    hide: PropTypes.array,
    notRequired: PropTypes.array,
    required: PropTypes.array
};

export const businessInfo = {
    hiddenFields: PropTypes.array,
    requiredFields: PropTypes.array,
    dbaName: PropTypes.string,
    legalName: PropTypes.string,
    businessPhone: PropTypes.string,
    physicalAddress: PropTypes.string,
    physicalAddressCity: PropTypes.string,
    physicalAddressState: PropTypes.string,
    physicalAddressZip: PropTypes.string,
    legalAddress: PropTypes.string,
    legalAddressCity: PropTypes.string,
    legalAddressState: PropTypes.string,
    legalAddressZip: PropTypes.string,
    dbaNumber: PropTypes.string,
    webAddress: PropTypes.string,
    customerServicePhone: PropTypes.string,
    fax: PropTypes.string,
    email: PropTypes.string,
    contact: PropTypes.string,
    achPaymentsAccepted: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ])
};
