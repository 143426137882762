import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Sections.scss';

class Section extends Component {
    static propTypes = {
        heading: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
        className: PropTypes.string,
        style: PropTypes.any
    };

    static defaultProps = {
        heading: 'Section Header'
    };

    render() {
        const { children, heading, className, style } = this.props;
        const secClassName = className ? `section ${className}` : 'section';

        return (
            <section className={secClassName} style={style}>
                <div className="heading">
                    <h3>
                        {heading}
                    </h3>
                </div>
                <div className="content">
                    {children}
                </div>
            </section>
        );
    }
}

export default Section;
