import config from '../config/config';
import { createSuccessActionCreator } from '@nexio/ui-redux';
import { storeApplicationSections } from './sectionsActions';

export const FETCH_APPLICATION_DATA_LOAD = 'FETCH_APPLICATION_DATA_LOAD';
export const FETCH_APPLICATION_DATA_FAILURE = 'FETCH_APPLICATION_DATA_FAILURE';
export const FETCH_APPLICATION_DATA_SUCCESS = 'FETCH_APPLICATION_DATA_SUCCESS';

export const fetchApplicationData = (merchantAppId) => {
    return ({ dispatch, getState }) => {
        const isUserAnAgent = getState().applicationReducer.isUserAnAgent;
        const url = `${config.cforceServiceUrl}${isUserAnAgent ? '' : '/merchant'}/${merchantAppId}`;

        return dispatch({
            fetchParams: {
                url
            },
            loadActionTypeOrCreator: FETCH_APPLICATION_DATA_LOAD,
            failureActionTypeOrCreator: FETCH_APPLICATION_DATA_FAILURE,
            successActionTypeOrCreator: fetchApplicationDataSuccessActionCreator
        });
    };
};

export const fetchApplicationDataSuccessActionCreator = (data) => {
    return ({ dispatch }) => {
        dispatch(storeApplicationSections(data.sections));
        dispatch(createSuccessActionCreator(FETCH_APPLICATION_DATA_SUCCESS)());
    };
};