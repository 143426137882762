import _ from 'lodash';
import { createSelector } from 'reselect';

import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';

import {
    FETCH_PARTNER_DEFAULTS_FAILURE,
    FETCH_PARTNER_DEFAULTS_LOAD,
    FETCH_PARTNER_DEFAULTS_SUCCESS
} from './PartnerDefaultsActions';

export default createFetchReducer({
    loadActionType: FETCH_PARTNER_DEFAULTS_LOAD,
    failureActionType: FETCH_PARTNER_DEFAULTS_FAILURE,
    successActionType: FETCH_PARTNER_DEFAULTS_SUCCESS
});

const { getData, getIsLoading } = createBaseSelectors('partnerDefaultsReducer');

export const getIsLoadingPartnerDefaults = getIsLoading;

export const getPartnerDefaults = createSelector(
    getData,
    (data) => _.get(data, 'partnerDefaults')
);
