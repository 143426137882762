import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    EmvioLoadingSpinner as LoadingSpinner
} from '@nexio/emvio-util-ui';
import { getMerchantAppId } from '../../../../../reducers/sectionsReducer';
import { fetchDocusignIframeUrl, markMerchantAppComplete } from './docuSignIframeActions';

import './DocuSignIframe.scss';
import {
    getDocusignIframeUrl,
    getDocusignIframeUrlError,
    getIsDocusignIframeUrlLoading
} from './docuSignIframeReducers';

const DocuSignIframe = ({ className }) => {
    const dispatch = useDispatch();
    const height = window.innerHeight || 800;
    const [isIframeLoading, setIsIframeLoading] = useState(true);
    const merchantAppId = useSelector(getMerchantAppId);
    const isDocusignIframeUrlLoading = useSelector(getIsDocusignIframeUrlLoading);
    const docusignURL = useSelector(getDocusignIframeUrl);
    const error = useSelector(getDocusignIframeUrlError);

    useEffect(() => {
        window.scroll(0, 0);

        dispatch(markMerchantAppComplete(merchantAppId));
        dispatch(fetchDocusignIframeUrl(merchantAppId));
    }, []);

    return (
        <div className={className}>
            <div className="iframe-loading">
                {error ? <div className="loading-label">Something went wrong</div> : ''}
                {(!error && (isIframeLoading || isDocusignIframeUrlLoading))
                    ? (
                        <Fragment>
                            <div className="loading-label">
                                Please wait while DocuSign prepares your document for signing
                            </div>
                            <LoadingSpinner size="large" absolute/>
                        </Fragment>
                    )
                    : ''
                }
            </div>
            <div className="iframe">
                {docusignURL
                    ? <iframe
                        data-testid="docusign-iframe"
                        src={docusignURL}
                        width="100%"
                        height={`${height}px`}
                        onLoad={() => setIsIframeLoading(false)}
                        title="docusign"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                    />
                    : ''
                }
            </div>
        </div>
    );
};

DocuSignIframe.propTypes = {
    merchantAppID: PropTypes.any,
    className: PropTypes.string
};

export default DocuSignIframe;