import React, { Component } from 'react';
import PropTypes from 'prop-types';

import scrollBehavior from '../../../../utils/scrollBehavior';
import ApplicationFiles from '../Sections/ApplicationFiles/ApplicationFiles';

export class DocumentUpload extends Component {

    static propTypes = {
        invalidInputs: PropTypes.any,
        runValidation: PropTypes.func,
        runDocUploadValidation: PropTypes.func,
        showMissingUpload: PropTypes.bool
    };

    componentDidMount() {
        const application = document.getElementById('merchant-application');
        application.scrollIntoView(scrollBehavior());
    }

    render() {
        const { invalidInputs, runDocUploadValidation, showMissingUpload, runValidation } = this.props;

        return (
            <div>
                <ApplicationFiles invalidInputs={invalidInputs} runValidation={runValidation}
                    runDocUploadValidation={runDocUploadValidation}
                    showMissingUpload={showMissingUpload}
                />
            </div>
        );
    }
}

export default DocumentUpload;
