import { combineReducers } from 'redux';
import { appListReducer, bankDataReducer } from './appListReducer';
import { sectionsReducer } from './sectionsReducer';
import { applicationReducer } from './applicationReducer';
import createAppConfigReducer from '../App/OnlineApp/Modals/CreateApp/CreateApp/createAppReducer';
import createDirectSalesAppReducer from '../App/OnlineApp/Modals/CreateApp/DirectSales/createDirectSalesAppReducer';
import createISOAppReducer from '../App/OnlineApp/Modals/CreateApp/ISO/createISOAppReducer';
import createPartnerAppReducer from './createPartnerAppReducer';
import fetchApplicationDataReducer from './fetchApplicationDataReducer';
import {
    postBankDataReducer,
    validateBankDataReducer
} from '../App/OnlineApp/Application/Sections/DocusignCB/docusignCBReducer';
import createAppReducer from './createAppReducer';
import fetchApplicationMerchantReducer from './fetchApplicationMerchantReducer';
import removeOwnerReducer from './removeOwnerReducer';
import addOwnerReducer from './addOwnerReducer';
import nextSignerReducer from '../App/OnlineApp/Application/Sections/NextSigner/nextSignerReducer';
import { sendAppLinkEmailReducer } from '../App/OnlineApp/Application/Sections/SendAppLinkEmail/sendAppLinkEmailReducer';
import { getDocusignIframeUrlReducer } from '../App/OnlineApp/Application/Sections/DocuSign/docuSignIframeReducers';
import partnerDefaultsReducer from '../App/OnlineApp/Modals/PartnerDefaults/PartnerDefaultsReducer';
import partnersReducer from './partnersReducer';
import formFieldsReducer from './formFieldsReducer';

export const rootReducer = combineReducers({
    addOwnerReducer,
    applicationReducer,
    appListReducer,
    bankDataReducer,
    createAppConfigReducer,
    createAppReducer,
    createDirectSalesAppReducer,
    createISOAppReducer,
    createPartnerAppReducer,
    fetchApplicationDataReducer,
    fetchApplicationMerchantReducer,
    formFieldsReducer,
    getDocusignIframeUrlReducer,
    nextSignerReducer,
    partnerDefaultsReducer,
    partnersReducer,
    postBankDataReducer,
    removeOwnerReducer,
    sectionsReducer,
    sendAppLinkEmailReducer,
    validateBankDataReducer
});
