import {
    FETCH_APPLICATION_MERCHANT_LOAD,
    FETCH_APPLICATION_MERCHANT_FAILURE,
    FETCH_APPLICATION_MERCHANT_SUCCESS
} from '../actions/fetchApplicationMerchant';
import { createFetchReducer, createBaseSelectors } from '@nexio/ui-redux';

export default createFetchReducer({
    loadActionType: FETCH_APPLICATION_MERCHANT_LOAD,
    failureActionType: FETCH_APPLICATION_MERCHANT_FAILURE,
    successActionType: FETCH_APPLICATION_MERCHANT_SUCCESS
});

const baseSelectors = createBaseSelectors('fetchApplicationMerchantReducer');

export const getIsFetchApplicationMerchantLoading = baseSelectors.getIsLoading;