import config from '../config/config';
import { createSuccessActionCreator } from '@nexio/ui-redux';
import _ from 'lodash';

import { getMerchantAppId, getOwnerInfoData } from '../reducers/sectionsReducer';
import { getIsMerchantAuthorized, getIsUserAnAgent } from '../reducers/applicationReducer';

// Section names
export const MERCHANT_SURVEY = 'MerchantSiteSurvey';
export const MERCHANT_PROFILE = 'MerchantProfile';
export const BUSINESS_INFO = 'BusinessInfo';
export const CREDIT_CARD_INFO = 'CreditCardInfo';
export const TERMS = 'Terms';
export const CARD_COMPLIANCE = 'CardDataStorageComplianceInfo';
export const AMEX = 'Amex';
export const OWNER = 'OwnerInfoData';
export const FEES = 'Fees';
export const MERCHANT_PROFILE_ANALYSIS = 'MPAInfo';
export const BANK_DATA = 'BankData';
export const MERCHANT_APP_PARTNER = 'MerchantAppPartner';
export const AMEX_FEES = 'AmexFees';
export const KNOW_YOUR_CUSTOMER = 'KnowYourCustomer';
export const MERCHANT_APP = 'MerchantApp';
export const APPLICATION_FILES = 'ApplicationFiles';

// Redux actions
export const UPDATE_MERCHANT_PROFILE_DATA = 'UPDATE_MERCHANT_PROFILE_DATA';
export const UPDATE_BUSINESS_INFO_DATA = 'UPDATE_BUSINESS_INFO_DATA';
export const UPDATE_CREDIT_CARD_INFO_DATA = 'UPDATE_CREDIT_CARD_INFO_DATA';
export const UPDATE_BANK_DATA = 'UPDATE_BANK_DATA';
export const UPDATE_TERM_DATA = 'UPDATE_TERM_DATA';
export const UPDATE_MERCHANT_SURVEY_DATA = 'UPDATE_MERCHANT_SURVEY_DATA';
export const UPDATE_OWNER_INFO_DATA = 'UPDATE_OWNER_INFO_DATA';
export const UPDATE_APPLICATION_FILES = 'UPDATE_APPLICATION_FILES';

export const UPDATE_OWNER_INFO_DATA_SUCCESS = 'UPDATE_OWNER_INFO_DATA_SUCCESS';
export const UPDATE_OWNER_INFO_DATA_FAILURE = 'UPDATE_OWNER_INFO_DATA_FAILURE';

export const UPDATE_CARD_COMPLIANCE_DATA = 'UPDATE_CARD_COMPLIANCE_DATA';
export const UPDATE_AMEX_DATA = 'UPDATE_AMEX_DATA';
export const UPDATE_FEES_DATA = 'UPDATE_FEES_DATA';
export const UPDATE_AMEX_FEES_DATA = 'UPDATE_AMEX_FEES_DATA';
export const UPDATE_MERCHANT_PROFILE_ANALYSIS_DATA = 'UPDATE_MERCHANT_PROFILE_ANALYSIS_DATA';
export const UPDATE_MERCHANT_APP_PARTNER_DATA = 'UPDATE_MERCHANT_APP_PARTNER_DATA';
export const UPDATE_MERCHANT_APP_DATA = 'UPDATE_MERCHANT_APP_DATA';
export const UPDATE_KNOW_YOUR_CUSTOMER_DATA = 'UPDATE_KNOW_YOUR_CUSTOMER_DATA';
export const STORE_APPLICATION_SECTIONS = 'STORE_APPLICATION_SECTIONS';
export const CLEAR_APPLICATION_SECTIONS = 'CLEAR_APPLICATION_SECTIONS';

export const REMOVE_OWNER_SUCCESS = 'REMOVE_OWNER_SUCCESS';
export const REMOVE_OWNER_LOAD = 'REMOVE_OWNER_LOAD';
export const REMOVE_OWNER_FAILURE = 'REMOVE_OWNER_FAILURE';

export const SAVE_APPLICATION_DATA_LOAD = 'SAVE_APPLICATION_DATA_LOAD';
export const SAVE_APPLICATION_DATA_FAILURE = 'SAVE_APPLICATION_DATA_FAILURE';
export const SAVE_APPLICATION_DATA_SUCCESS = 'SAVE_APPLICATION_DATA_SUCCESS';

export const ADD_OWNER_LOAD = 'ADD_OWNER_LOAD';
export const ADD_OWNER_FAILURE = 'ADD_OWNER_FAILURE';
export const ADD_OWNER_SUCCESS = 'ADD_OWNER_SUCCESS';

export const GET_UPLOAD_LINK_LOAD = 'GET_UPLOAD_LINK_LOAD';
export const GET_UPLOAD_LINK_FAILURE = 'GET_UPLOAD_LINK_FAILURE';
export const GET_UPLOAD_LINK_SUCCESS = 'GET_UPLOAD_LINK_SUCCESS';

export const UPLOAD_APP_FILE_LOAD = 'UPLOAD_APP_FILE_LOAD';
export const UPLOAD_APP_FILE_FAILURE = 'UPLOAD_APP_FILE_FAILURE';
export const UPLOAD_APP_FILE_SUCCESS = 'UPLOAD_APP_FILE_SUCCESS';

export const DELETE_APP_FILE_LOAD = 'DELETE_APP_FILE_LOAD';
export const DELETE_APP_FILE_FAILURE = 'DELETE_APP_FILE_FAILURE';
export const DELETE_APP_FILE_SUCCESS = 'DELETE_APP_FILE_SUCCESS';

export const saveApplicationData = (sectionName, data) => {
    return ({ dispatch, getState }) => {
        const state = getState();
        const isMerchantAuthorized = getIsMerchantAuthorized(state);
        const isUserAnAgent = getIsUserAnAgent(state);
        const sectionData = state.sectionsReducer.sections[sectionName];
        const merchantAppId = getMerchantAppId(state);

        if (!shouldSave(sectionName, merchantAppId, isUserAnAgent)) {
            return;
        }

        return dispatch({
            fetchParams: {
                body: {
                    ...sectionData,
                    ...data
                },
                method: 'PUT',
                url: `${config.cforceServiceUrl}/${isMerchantAuthorized ? 'merchant/' : ''}appDetails/${sectionName}/${merchantAppId}`
            },
            loadActionTypeOrCreator: saveApplicationDataLoadActionCreator(sectionName, data),
            failureActionTypeOrCreator: SAVE_APPLICATION_DATA_FAILURE,
            successActionTypeOrCreator: SAVE_APPLICATION_DATA_SUCCESS
        });
    };
};

export function shouldSave(sectionName, merchantAppId, isUserAnAgent) {
    if (!merchantAppId) {
        // only attempt to save a baseSection if we have something to save
        return false;
    } else if (sectionName === FEES && !isUserAnAgent) {
        return false;
    } else if (sectionName === AMEX_FEES && !isUserAnAgent) {
        return false;
    }

    return true;
}

export const saveApplicationDataLoadActionCreator = (sectionName, data) => () => {
    return ({ dispatch }) => {
        const actionMapper = {
            [MERCHANT_PROFILE]: updateMerchantProfileData,
            [BUSINESS_INFO]: updateBusinessInfoData,
            [CREDIT_CARD_INFO]: updateCreditCardInfoData,
            [BANK_DATA]: updateBankData,
            [TERMS]: updateTermData,
            [MERCHANT_SURVEY]: updateMerchantSurveyData,
            [CARD_COMPLIANCE]: updateCardComplianceData,
            [AMEX]: updateAmexData,
            [FEES]: updateFeesData,
            [AMEX_FEES]: updateAmexFeesData,
            [MERCHANT_PROFILE_ANALYSIS]: updateMerchantProfileAnalysisData,
            [MERCHANT_APP_PARTNER]: updateMerchantAppPartnerData,
            [MERCHANT_APP]: updateMerchantAppData,
            [KNOW_YOUR_CUSTOMER]: updateKnowYourCustomerData,
            [APPLICATION_FILES]: updateApplicationFiles
        };
        const actionCreator = actionMapper[sectionName];

        if (actionCreator) {
            dispatch(actionCreator(data));
        }

        dispatch(createSuccessActionCreator(SAVE_APPLICATION_DATA_LOAD)());
    };
};

export const createAdditionalOwnerData = (merchantAppId) => {
    return {
        fetchParams: {
            body: {
                merchantAppId
            },
            method: 'POST',
            url: `${config.cforceServiceUrl}/ownerInfo`
        },
        loadActionTypeOrCreator: ADD_OWNER_LOAD,
        failureActionTypeOrCreator: ADD_OWNER_FAILURE,
        successActionTypeOrCreator: ADD_OWNER_SUCCESS
    };
};

export const saveOwnerData = (index, key, value) => {
    return ({ dispatch, getState }) => {
        const state = getState();
        const isMerchantAuthorized = getIsMerchantAuthorized(state);
        const isUserAnAgent = getIsUserAnAgent(state);
        const sectionData = getOwnerInfoData(getState())[index];
        const merchantAppId = _.get(sectionData, 'merchantAppId');

        if (!shouldSave(OWNER, merchantAppId, isUserAnAgent)) {
            return;
        }

        return dispatch({
            loadActionTypeOrCreator: () => updateOwnerInfoData(index, key, value),
            failureActionTypeOrCreator: UPDATE_OWNER_INFO_DATA_FAILURE,
            successActionTypeOrCreator: UPDATE_OWNER_INFO_DATA_SUCCESS,
            fetchParams: {
                method: 'PUT',
                body: {
                    ...sectionData,
                    [key]: value
                },
                url: `${config.cforceServiceUrl}${isMerchantAuthorized ? '/merchant' : ''}/appDetails/OwnerInfo/${merchantAppId}`
            }
        });
    };
};

export const deleteOwner = ({ merchantAppId, id }, index) => {
    return ({ dispatch, getState }) => {
        return dispatch({
            loadActionTypeOrCreator: REMOVE_OWNER_LOAD,
            failureActionTypeOrCreator: REMOVE_OWNER_FAILURE,
            successActionTypeOrCreator: () => removeOwner(index),
            fetchParams: {
                method: 'DELETE',
                url: `${config.cforceServiceUrl}/${getIsMerchantAuthorized(getState()) ? `merchant/${merchantAppId}/` : ''}ownerInfo/${id}`
            }
        });
    };
};

export const deleteAppAttachment = (merchantAppId, file) => {
    return ({ dispatch, getState }) => {
        const state = getState();
        const isMerchantAuthorized = getIsMerchantAuthorized(state);

        return dispatch({
            loadActionTypeOrCreator: DELETE_APP_FILE_LOAD,
            failureActionTypeOrCreator: DELETE_APP_FILE_FAILURE,
            successActionTypeOrCreator: DELETE_APP_FILE_SUCCESS,
            fetchParams: {
                method: 'DELETE',
                url: `${config.cforceServiceUrl}/${isMerchantAuthorized ? 'merchant/' : ''}deleteApplicationFile/${merchantAppId}`,
                body: file
            }
        });
    };
};

export const getFileUploadLink = (merchantAppId, fileName = '', category = '') => {

    return ({ dispatch, getState }) => {
        const state = getState();
        const isMerchantAuthorized = getIsMerchantAuthorized(state);

        return dispatch({
            loadActionTypeOrCreator: GET_UPLOAD_LINK_LOAD,
            failureActionTypeOrCreator: getFileUploadLinkFailureActionCreator,
            successActionTypeOrCreator: GET_UPLOAD_LINK_SUCCESS,
            fetchParams: {
                method: 'POST',
                url: `${config.cforceServiceUrl}/${isMerchantAuthorized ? 'merchant/' : ''}getFileUploadLink/${merchantAppId}`,
                body: {
                    fileName,
                    category
                }
            }
        });
    };
};

export function getFileUploadLinkFailureActionCreator(error) {
    return {
        error,
        type: GET_UPLOAD_LINK_FAILURE
    };

}

export const storeApplicationSections = (sections) => ({
    type: STORE_APPLICATION_SECTIONS,
    sections
});

export const clearApplicationSections = () => ({
    type: CLEAR_APPLICATION_SECTIONS
});

export const updateMerchantProfileData = (data) => ({
    type: UPDATE_MERCHANT_PROFILE_DATA,
    data
});

export const updateBusinessInfoData = (data) => ({
    type: UPDATE_BUSINESS_INFO_DATA,
    data
});

export const updateCreditCardInfoData = (data) => ({
    type: UPDATE_CREDIT_CARD_INFO_DATA,
    data
});

export const updateBankData = (data) => ({
    type: UPDATE_BANK_DATA,
    data
});

export const updateApplicationFiles = (data) => ({
    type: UPDATE_APPLICATION_FILES,
    data
});

export const updateTermData = (data) => ({
    type: UPDATE_TERM_DATA,
    data
});

export const updateMerchantSurveyData = (data) => ({
    type: UPDATE_MERCHANT_SURVEY_DATA,
    data
});

export const updateOwnerInfoData = (index, key, value) => ({
    type: UPDATE_OWNER_INFO_DATA,
    index,
    key,
    value
});

export const updateCardComplianceData = (data) => ({
    type: UPDATE_CARD_COMPLIANCE_DATA,
    data
});

export const updateAmexData = (data) => ({
    type: UPDATE_AMEX_DATA,
    data
});

export const updateFeesData = (data) => ({
    type: UPDATE_FEES_DATA,
    data
});

export const updateAmexFeesData = (data) => ({
    type: UPDATE_AMEX_FEES_DATA,
    data
});

export const updateMerchantProfileAnalysisData = (data) => ({
    type: UPDATE_MERCHANT_PROFILE_ANALYSIS_DATA,
    data
});

export const updateMerchantAppPartnerData = (data) => ({
    type: UPDATE_MERCHANT_APP_PARTNER_DATA,
    data
});

export const updateMerchantAppData = (data) => ({
    type: UPDATE_MERCHANT_APP_DATA,
    data
});

export const updateKnowYourCustomerData = (data) => ({
    type: UPDATE_KNOW_YOUR_CUSTOMER_DATA,
    data
});

export const removeOwner = (index) => ({
    type: REMOVE_OWNER_SUCCESS,
    index
});
