import { createSelector } from 'reselect';
import _ from 'lodash';

import { createFetchReducer, createBaseSelectors } from '@nexio/ui-redux';

import {
    FETCH_FORM_FIELDS_LOAD,
    FETCH_FORM_FIELDS_FAILURE,
    FETCH_FORM_FIELDS_SUCCESS
} from '../actions/formFieldsActions';

export default createFetchReducer({
    loadActionType: FETCH_FORM_FIELDS_LOAD,
    failureActionType: FETCH_FORM_FIELDS_FAILURE,
    successActionType: FETCH_FORM_FIELDS_SUCCESS
});

const { getData, getIsLoading } = createBaseSelectors('formFieldsReducer');

export const getIsFormFieldsLoading = getIsLoading;
export const getFormFields = createSelector(
    getData,
    (data) => _.get(data, 'formFields') || []
);
export const getFormSections = createSelector(
    getFormFields,
    (formFields) => {

        return formFields.map((section) => ({ id: section.sectionId, name: section.sectionName }));
    }
);

