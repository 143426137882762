import { fetchMiddleware } from '@nexio/ui-redux';
import config from '../config/config';
import _ from 'lodash';

export const fetcher = ({
    body,
    headers,
    method = 'GET',
    url
}) => {
    let idToken = _.get(headers, 'Authorization');

    if (!idToken && url.startsWith(`${config.cforceServiceUrl}/merchant`)) {
        idToken = window.localStorage.getItem('merchant-token');
    } else if (!idToken) {
        idToken = window.localStorage.getItem('cforce-token');
    }

    return fetch(url, {
        body: body ? JSON.stringify(body) : body,
        headers: {
            Authorization: idToken
        },
        method
    });
};

export default fetchMiddleware(fetcher);