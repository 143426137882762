import React, { useEffect } from 'react';
import Modal from '../../Shared/Modal/Modal';
import './ViewAppDetailsModal.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplicationData } from '../../../actions/fetchApplicationData';
import PropTypes from 'prop-types';
import { getIsFetchApplicationDataLoading } from '../../../reducers/fetchApplicationDataReducer';
import {
    EmvioLoadingSpinner as LoadingSpinner
} from '@nexio/emvio-util-ui';

const ViewAppDetailsModal = ({
    children,
    isOpen,
    handleModalClose,
    merchantAppId
}) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(getIsFetchApplicationDataLoading);

    useEffect(() => {
        if (isOpen && !_.isNil(merchantAppId)) {
            dispatch(fetchApplicationData(merchantAppId));
        }
    }, [merchantAppId, isOpen]);

    return (
        <Modal
            className="view-app-detail"
            title="View App Detail"
            isOpen={isOpen}
            handleClose={handleModalClose}
        >
            <div className="application-container">
                {isLoading ? <div className="loading-container"><LoadingSpinner relative /></div> : children}
            </div>
        </Modal>
    );
};

ViewAppDetailsModal.propTypes = {
    isOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    children: PropTypes.array,
    merchantAppId: PropTypes.number
};

export default ViewAppDetailsModal;