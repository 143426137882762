import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import { createSelector } from 'reselect';
import {
    FETCH_CREATE_APP_CONFIG_FAILURE,
    FETCH_CREATE_APP_CONFIG_LOAD,
    FETCH_CREATE_APP_CONFIG_SUCCESS
} from './createAppActions';
import _ from 'lodash';

export default createFetchReducer({
    loadActionType: FETCH_CREATE_APP_CONFIG_LOAD,
    failureActionType: FETCH_CREATE_APP_CONFIG_FAILURE,
    successActionType: FETCH_CREATE_APP_CONFIG_SUCCESS
});

const { getData } = createBaseSelectors('createAppConfigReducer');

export const getIsvUsers = createSelector(
    getData,
    (data) => _.get(data, 'isvUsers')
);

export const getAppPartnersNames = createSelector(
    getData,
    (data) => _.get(data, 'appPartnersNames')
);

export const getAppPartnersRepCodes = createSelector(
    getData,
    (data) => _.get(data, 'appPartnersRepCodes')
);

export const getProcessingBanks = createSelector(
    getData,
    (data) => _.get(data, 'processingBanks')
);