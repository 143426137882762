import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import { ADD_OWNER_FAILURE, ADD_OWNER_LOAD, ADD_OWNER_SUCCESS } from '../actions/sectionsActions';

export default createFetchReducer({
    loadActionType: ADD_OWNER_LOAD,
    failureActionType: ADD_OWNER_FAILURE,
    successActionType: ADD_OWNER_SUCCESS
});

const baseSelectors = createBaseSelectors('addOwnerReducer');

export const getIsAddOwnerLoading = baseSelectors.getIsLoading;