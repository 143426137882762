import config from '../../../../../config/config';

export const POST_NEXT_SIGNER_LOAD = 'POST_NEXT_SIGNER_LOAD';
export const POST_NEXT_SIGNER_SUCCESS = 'POST_NEXT_SIGNER_SUCCESS';
export const POST_NEXT_SIGNER_FAILURE = 'POST_NEXT_SIGNER_FAILURE';

export const postNextSigner = (token, underwriter, agentSigner) => {
    return {
        loadActionTypeOrCreator: POST_NEXT_SIGNER_LOAD,
        failureActionTypeOrCreator: POST_NEXT_SIGNER_FAILURE,
        successActionTypeOrCreator: POST_NEXT_SIGNER_SUCCESS,
        fetchParams: {
            body: {
                t: token,
                underwriter,
                agentSigner
            },
            method: 'POST',
            url: `${config.cforceServiceUrl}/docusign/nextSigner`
        }
    };
};