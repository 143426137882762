import { EmvioButton } from '@nexio/emvio-form-components';
import { EmvioDateRangePicker, EmvioTable, EmvioTabs, mapColumns } from '@nexio/emvio-util-ui';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isUserAnAgent } from '../../../actions/applicationActions';

import { fetchAppList, fetchBankData } from '../../../actions/appListActions';
import { clearApplicationSections } from '../../../actions/sectionsActions';
import config from '../../../config/config';
import { getIsUserAnAgent } from '../../../reducers/applicationReducer';
import {
    getAppList,
    getAppListCount,
    getAppListErrorMessage,
    getBankData,
    getBankDataError,
    getIsAppListLoading,
    getIsBankDataLoading
} from '../../../reducers/appListReducer';
import { getMerchantAppId } from '../../../reducers/createAppReducer';
import { getMerchantAppData, getMerchantAppPartner } from '../../../reducers/sectionsReducer';
import { getDepartmentFromToken } from '../../../utils/decode';
import { getLocalStorageItem, setLocalStorageItem } from '../../../utils/localStorage';
import { toastIfError } from '../../../utils/toast';
import Application from '../Application/Application';
import ConfigSection from '../Application/ConfigSection';
import { tabList } from '../Application/StaticData/staticData';
import BankInfoModal from '../Modals/BankInfoModal';
import CreateAppModal from '../Modals/CreateApp/CreateApp/CreateAppModal';
import CreateDirectSalesAppModal from '../Modals/CreateApp/DirectSales/CreateDirectSalesAppModal';
import PartnerDefaultsModal from '../Modals/PartnerDefaults/PartnerDefaultsModal';
import CreateISOAppModal from '../Modals/CreateApp/ISO/CreateISOAppModal';
import LinkModal from '../Modals/LinkModal';
import ViewAppDetailsModal from '../Modals/ViewAppDetailsModal';
import Actions from './Actions';
import './AppList.scss';

import { cforceDepartments } from '../Application/StaticData/staticData';

const defaultState = {
    currentTab: 0,
    pageSize: 10,
    limit: 10,
    offset: 0,
    contact: '',
    pageNum: 0,
    partner: '',
    pricing: '',
    isPartnerDefaultsOpen: false,
    isLinkOpen: false,
    isCreateOpen: false,
    isCreateISOOpen: false,
    isCreateDirectSalesOpen: false,
    isViewAppDetailOpen: false,
    isViewBankDataOpen: false,
    isTokenReceived: false,
    selectedRow: {},
    searchDateRange: {
        startDate: moment.utc().subtract(6, 'month'),
        endDate: moment.utc()
    },
    searchTerm: '',
    departmentId: null
};

export class AppList extends Component {
    static propTypes = {
        bankDataError: PropTypes.string,
        merchantAppData: PropTypes.array,
        merchantAppId: PropTypes.number,
        row: PropTypes.shape({
            partnerName: PropTypes.string,
            applicationLink: PropTypes.string,
            token: PropTypes.string,
            contact: PropTypes.string
        }),
        count: PropTypes.number,
        departmentId: PropTypes.number,
        error: PropTypes.string,
        dispatch: PropTypes.func,
        isAppListLoading: PropTypes.bool,
        isBankDataLoading: PropTypes.bool,
        newMerchantAppData: PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ]),
            contact: PropTypes.string
        }),
        newMerchantAppPartner: PropTypes.shape({
            partnerName: PropTypes.string,
            pricingType: PropTypes.string,
            processingBankId: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ])
        }),
        bankData: PropTypes.shape({
            accountNumber: PropTypes.string,
            contactName: PropTypes.string,
            id: PropTypes.number,
            merchantAppId: PropTypes.number,
            nameOnCheck: PropTypes.string,
            phoneNumber: PropTypes.string,
            routingNumber: PropTypes.string,
            giactResponseCode: PropTypes.number
        })
    };

    static defaultProps = {
        dispatch: () => { },
        merchantAppData: [],
        count: 0
    };

    state = {
        ...defaultState
    };

    renderBankDataAction = (props) => {
        const { partnerName, dbaName, bankDataID, id } = props.row;
        if (bankDataID) {
            return (
                <Actions
                    handleLinkClick={this.handleBankDataLinkClick(id, partnerName, dbaName)}
                    icon={'icon-home'}
                />
            );
        } else {
            return (<div></div>);
        }

    };

    renderLinkAction = (props) => {
        return (
            <Actions
                handleLinkClick={this.handleLinkClick(props.original)}
                icon={'icon-link'}
            />
        );
    };

    tableColumns = [
        { label: 'Date Created', accessor: 'dateCreated', show: true, sortable: true, width: 150 },
        { label: 'Partner', accessor: 'partnerName', show: true, sortable: true },
        { label: 'Contact', accessor: 'contact', show: true, sortable: true },
        { label: 'Merchant Name', accessor: 'dbaName', show: true, sortable: true },
        { label: 'Pricing', accessor: 'pricingType', show: true, sortable: true, width: 150 },
        { label: 'Rep Code', accessor: 'agentID', show: true, sortable: false, width: 150 },
        { label: 'App Link', accessor: 'applicationLink', show: false, sortable: true },
        { label: 'token', accessor: 'token', show: false, sortable: false },
        { label: '', accessor: 'id', show: false },
        { label: '', accessor: 'optBlue', show: false },
        { label: '', accessor: 'isvRep', show: false },
        { label: 'Actions', accessor: 'action', Cell: this.renderLinkAction, show: true, sortable: false, width: 100 },
        {
            label: 'BankData',
            accessor: 'bankDataID',
            Cell: this.renderBankDataAction,
            show: true,
            sortable: false,
            width: 100
        }
    ];

    componentDidMount() {
        if (process.env.BUILD_ENV === 'local') {
            this.receiveJWT({ data: { token: '' } });
        } else {
            window.addEventListener('message', this.receiveJWT);
            window.parent.postMessage({ getToken: true }, '*');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.receiveJWT);
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentTab } = this.state;
        toastIfError(prevProps, this.props);
        toastIfError(prevProps, this.props, 'bankDataError');
        if (currentTab !== prevState.currentTab) {
            if (process.env.BUILD_ENV === 'local') {
                this.receiveJWT({ data: { token: '' } });
            } else {
                window.parent.postMessage({ refresh: true }, '*');
            }
        }
        if (!prevState.isTokenReceived && this.state.isTokenReceived) {
            const { currentTab, limit, offset, searchDateRange, searchTerm } = this.state;
            this.props.dispatch(fetchAppList(currentTab, limit, offset, moment(searchDateRange.startDate).format('YYYY-MM-DD'), moment(searchDateRange.endDate).add(1, 'day').format('YYYY-MM-DD'), searchTerm));
        }
    }

    receiveJWT = (event) => {
        const { dispatch } = this.props;
        let { token } = event.data;
        if (process.env.BUILD_ENV === 'local') {
            token = config.token;
        }

        if (token) {
            const department = getDepartmentFromToken(token);
            setLocalStorageItem('cforce-token', token);
            dispatch(isUserAnAgent(!!token));
            this.setState({
                departmentId: _.toNumber(department),
                isTokenReceived: true
            });
        }
    };

    tableFetchData = (filters, page, tablePageSize, sorters) => {
        const { searchTerm, pageSize, currentTab, searchDateRange } = this.state;
        const tableOffset = page * pageSize;
        const tableLimit = pageSize;
        this.setState({
            pageNum: page
        });
        const token = getLocalStorageItem('cforce-token');
        if (token) {
            this.fetchData(currentTab, tableLimit, tableOffset, searchDateRange, searchTerm, sorters);
        }
    };

    localFetchData = () => {
        const { searchTerm, pageSize, pageNum, currentTab, searchDateRange } = this.state;
        const tableOffset = pageNum * pageSize;
        const tableLimit = pageSize;
        this.fetchData(currentTab, tableLimit, tableOffset, searchDateRange, searchTerm);
    };

    fetchData = (currentTab, tableLimit, tableOffset, searchDateRange, searchTerm, sorters) => {
        const { dispatch } = this.props;
        let token = getLocalStorageItem('cforce-token');
        dispatch(isUserAnAgent(!!token));
        dispatch(fetchAppList(currentTab, tableLimit, tableOffset, moment(searchDateRange.startDate).format('YYYY-MM-DD'), moment(searchDateRange.endDate).add(1, 'day').format('YYYY-MM-DD'), searchTerm, sorters));
    };

    handleTabSelection = (currentTab) => {
        this.setState({ currentTab });
    };

    handleBankDataLinkClick = (merchantAppId) => async (e) => {
        const { dispatch } = this.props;
        e.stopPropagation();

        await dispatch(fetchBankData(merchantAppId));

        this.setState({
            isViewBankDataOpen: true
        });
    };

    handleLinkClick = (selectedMerchantAppData) => (e) => {
        e.stopPropagation();
        this.setState({
            isLinkOpen: true,
            selectedMerchantAppData
        });
    };

    handleCreateModalClose = (name) => async () => {
        await this.localFetchData();

        const selectedRow = {
            id: this.props.merchantAppId
        };

        this.setState({
            [name]: false,
            isViewAppDetailOpen: true,
            selectedRow: selectedRow
        });
    };

    handleModalOpen = (name) => () => this.setState({ [name]: true });

    handleModalCancel = (name) => () => {
        this.setState({ [name]: false });
        this.props.dispatch(clearApplicationSections());
    };

    handleSelectRow = (row) => this.setState({
        isViewAppDetailOpen: true,
        selectedRow: row
    });

    dateRangeChanged = (dateRange) => this.setState({
        searchDateRange: dateRange
    });

    handleSearching = (e) => {
        e.persist();
        const searchTerm = e.target.value;
        this.setState({
            searchTerm,
            page: 0
        });
        this.delayedSearch();
    };

    resetSearchTerm = () => {
        const { searchDateRange, ...resetValues } = defaultState;
        this.setState({
            ...resetValues
        }, () => this.localFetchData());
    };

    search = () => {
        this.localFetchData();
    };

    delayedSearch = _.debounce(this.search, 500);

    renderModals() {
        const {
            isLinkOpen,
            selectedMerchantAppData,
            isViewAppDetailOpen,
            isViewBankDataOpen,
            isPartnerDefaultsOpen,
            isCreateOpen,
            isCreateISOOpen,
            isCreateDirectSalesOpen,
            selectedRow
        } = this.state;

        if (!this.props.isAppListLoading) {
            return (
                <Fragment>
                    <LinkModal
                        isLinkOpen={isLinkOpen}
                        handleCancel={this.handleModalCancel('isLinkOpen')}
                        merchantAppData={selectedMerchantAppData}
                    />
                    <BankInfoModal
                        isViewBankDataOpen={isViewBankDataOpen}
                        handleCancel={this.handleModalCancel('isViewBankDataOpen')}
                        bankData={this.props.bankData}
                    />
                    <CreateAppModal
                        isOpen={isCreateOpen}
                        handleCancel={this.handleModalCancel('isCreateOpen')}
                        handleSave={this.handleCreateModalClose('isCreateOpen')}
                    />
                    <CreateISOAppModal
                        isOpen={isCreateISOOpen}
                        handleCancel={this.handleModalCancel('isCreateISOOpen')}
                        handleSave={this.handleCreateModalClose('isCreateISOOpen')}
                    />
                    <CreateDirectSalesAppModal
                        isOpen={isCreateDirectSalesOpen}
                        handleCancel={this.handleModalCancel('isCreateDirectSalesOpen')}
                        handleSave={this.handleCreateModalClose('isCreateDirectSalesOpen')}
                    />
                    <PartnerDefaultsModal
                        isOpen={isPartnerDefaultsOpen}
                        handleCancel={this.handleModalCancel('isPartnerDefaultsOpen')}
                        handleSave={this.handleModalCancel('isPartnerDefaultsOpen')}
                    />
                    <ViewAppDetailsModal
                        isOpen={isViewAppDetailOpen}
                        handleModalClose={this.handleModalCancel('isViewAppDetailOpen')}
                        merchantAppId={_.get(selectedRow, 'id')}
                    >
                        <ConfigSection />
                        <Application handleModalClose={this.handleModalCancel('isViewAppDetailOpen')} />
                    </ViewAppDetailsModal>
                </Fragment>
            );
        }
    }

    renderPartnerDefaultsButton = () => {
        const { departmentId } = this.state;

        if (departmentId === cforceDepartments.PRODUCT_DEVELOPMENT_DEPARTMENT
            || departmentId === cforceDepartments.PARTNERSHIPS_DEPARTMENT
            || departmentId === cforceDepartments.UNDERWRITING_DEPARTMENT
            || departmentId === cforceDepartments.ADMIN_DEPARTMENT
        ) {

            return <div className="row-0-right">
                <EmvioButton
                    className="button end"
                    onClick={this.handleModalOpen('isPartnerDefaultsOpen')}
                > Edit Partner Defaults </EmvioButton>
            </div>;
        }
    }

    render() {
        const { merchantAppData, count, isAppListLoading, isBankDataLoading } = this.props;
        const {
            pageNum,
            pageSize,
            searchTerm,
            departmentId,
            searchDateRange
        } = this.state;
        let columnsWithSorters = mapColumns(null, this.tableColumns);

        const newAppButtonClassName = `button ${_.toNumber(departmentId) === cforceDepartments.EXTERNAL_AGENT_DEPARTMENT ? 'dontshow' : ''}`;
        const nonIsvAppButtonClassName = `button ${_.toNumber(departmentId) === cforceDepartments.EXTERNAL_PARTNER_DEPARTMENT ? 'dontshow' : ''}`;

        if (!this.state.isTokenReceived) {
            return null;
        }

        return (
            <div className="container">
                {this.renderModals()}
                <div className="row-0">
                    <div className="row-0-left">
                        <EmvioButton
                            className={newAppButtonClassName}
                            onClick={this.handleModalOpen('isCreateOpen')}
                            disabled={departmentId === cforceDepartments.EXTERNAL_AGENT_DEPARTMENT}
                        >
                            + ISV Application
                        </EmvioButton>
                        <EmvioButton
                            className={nonIsvAppButtonClassName}
                            onClick={this.handleModalOpen('isCreateISOOpen')}
                            disabled={departmentId === cforceDepartments.EXTERNAL_PARTNER_DEPARTMENT}
                        >
                            + 1099 Application
                        </EmvioButton>
                        <EmvioButton
                            className={nonIsvAppButtonClassName}
                            onClick={this.handleModalOpen('isCreateDirectSalesOpen')}
                            disabled={departmentId === cforceDepartments.EXTERNAL_PARTNER_DEPARTMENT}
                        >
                            + Direct Sales Application
                        </EmvioButton>
                    </div>
                    {this.renderPartnerDefaultsButton()}
                </div>

                <div className="row-1">
                    <EmvioTabs
                        tabs={tabList}
                        onSelect={this.handleTabSelection}
                    />
                </div>
                <div className="row-2">
                    <div className="row-2-left">
                        <EmvioDateRangePicker
                            initialStartDate={searchDateRange.startDate}
                            initialEndDate={searchDateRange.endDate}
                            onChange={this.dateRangeChanged}
                        />
                        <span className="button-container">
                            <EmvioButton
                                className="button"
                                onClick={this.localFetchData}
                            >
                                Search By Date
                            </EmvioButton>
                        </span>
                    </div>
                    <div className="row-2-right">
                        <span className="text-block">
                            Search:
                        </span>
                        <input className="search-input" type="text" onChange={this.handleSearching}
                            value={searchTerm} />
                        <span className="icon-close" onClick={this.resetSearchTerm} />
                    </div>
                </div>
                <div>
                    <EmvioTable
                        key={this.state.currentTab}
                        appElement="#root"
                        count={count}
                        columns={columnsWithSorters}
                        defaultColumnsAndProps={columnsWithSorters}
                        data={merchantAppData}
                        hasNoCount={true}
                        isDataLoading={isAppListLoading || isBankDataLoading}
                        isTableLoading={false}
                        csvLoading={false}
                        noDataText=""
                        page={pageNum}
                        pages={Math.ceil(count / pageSize)}
                        pageSize={pageSize}
                        onFetchData={this.tableFetchData}
                        clickShouldExpandRow={true}
                        onGoToItem={this.handleSelectRow}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bankDataError: getBankDataError(state),
    merchantAppData: getAppList(state),
    merchantAppId: getMerchantAppId(state),
    error: getAppListErrorMessage(state),
    count: getAppListCount(state),
    isAppListLoading: getIsAppListLoading(state),
    isBankDataLoading: getIsBankDataLoading(state),
    isUserAnAgent: getIsUserAnAgent(state),
    newMerchantAppData: getMerchantAppData(state),
    newMerchantAppPartner: getMerchantAppPartner(state),
    bankData: getBankData(state)
});

export default connect(mapStateToProps)(AppList);
