import config from '../config/config';

export const CREATE_APP_LOAD = 'CREATE_APP_LOAD';
export const CREATE_APP_FAILURE = 'CREATE_APP_FAILURE';
export const CREATE_APP_SUCCESS = 'CREATE_APP_SUCCESS';

export const createApp = (data) => {
    return {
        failureActionTypeOrCreator: CREATE_APP_FAILURE,
        loadActionTypeOrCreator: CREATE_APP_LOAD,
        fetchParams: {
            body: data,
            method: 'POST',
            url: config.cforceServiceUrl
        },
        successActionTypeOrCreator: CREATE_APP_SUCCESS
    };
};