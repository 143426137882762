import { createBaseSelectors, createFetchReducer } from '@nexio/ui-redux';
import { REMOVE_OWNER_FAILURE, REMOVE_OWNER_LOAD, REMOVE_OWNER_SUCCESS } from '../actions/sectionsActions';

export default createFetchReducer({
    loadActionType: REMOVE_OWNER_LOAD,
    failureActionType: REMOVE_OWNER_FAILURE,
    successActionType: REMOVE_OWNER_SUCCESS
});

const baseSelectors = createBaseSelectors('removeOwnerReducer');

export const getIsRemoveOwnerLoading = baseSelectors.getIsLoading;