import React, { Component } from 'react';
import Modal from '../../Shared/Modal/Modal';
import PropTypes from 'prop-types';
import './BankInfoModal.scss';
import { mappers } from '@nexio/cforce-config';
import _ from 'lodash';

class BankInfoModal extends Component {
    static propTypes = {
        isViewBankDataOpen: PropTypes.bool,
        accountNumber: PropTypes.string,
        routingNumber: PropTypes.string,
        handleCancel: PropTypes.func,
        bankData: PropTypes.shape ({
            accountNumber: PropTypes.string,
            contactName: PropTypes.string,
            id: PropTypes.number,
            merchantAppId: PropTypes.number,
            nameOnCheck: PropTypes.string,
            phoneNumber: PropTypes.string,
            routingNumber: PropTypes.string,
            giactResponseCode: PropTypes.number
        })
    };

    renderGiactResponse = () => {
        const giactResponseCode = _.get(this.props.bankData, 'giactResponseCode');
        const verificationResponse = _.get(mappers, `giactResponse[${giactResponseCode}].verificationResponse`) || '';
        const code = _.get(mappers, `giactResponse[${giactResponseCode}].code`) || '';
        const description = _.get(mappers, `giactResponse[${giactResponseCode}].description`) || '';

        if (giactResponseCode) {
            return <div className="bankinfo-row">
                <label className="label">Giact Response</label>
                <div className="spacer">
                    {verificationResponse} ({code}): {description}
                </div>
            </div>;
        } else {
            return;
        }
    };

    render() {
        const { isViewBankDataOpen, handleCancel, bankData } = this.props;

        if (bankData) {
            return (
                <Modal
                    title="BANK INFO"
                    isOpen={isViewBankDataOpen}
                    handleClose={handleCancel}
                    className="bank-info-modal"
                >

                    <div className="bankdata-modal">
                        <div className="bankinfo-row">
                            <label className="label">Contact Name</label>
                            <div className="spacer">
                                {bankData.contactName}
                            </div>
                        </div>

                        <div className="bankinfo-row">
                            <label className="label">Phone Number</label>
                            <div className="spacer">
                                {bankData.phoneNumber}
                            </div>
                        </div>

                        <div className="bankinfo-row">
                            <label className="label">Legal Business Name (On The Check)</label>
                            <div className="spacer">
                                {bankData.nameOnCheck}
                            </div>
                        </div>

                        <div className="bankinfo-row">
                            <label className="label">Account Number</label>
                            <div className="spacer">
                                {bankData.accountNumber}
                            </div>
                        </div>

                        <div className="bankinfo-row">
                            <label className="label">Routing Number</label>
                            <div className="spacer">
                                {bankData.routingNumber}
                            </div>
                        </div>
                        {this.renderGiactResponse()}
                    </div>

                </Modal>
            );
        } else {
            return (
                <div></div>
            );
        }

    }
}

export default BankInfoModal;