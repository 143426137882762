import config from '../../../../../config/config';

export const POST_BANK_DATA_LOAD = 'POST_BANK_DATA_LOAD';
export const POST_BANK_DATA_FAILURE = 'POST_BANK_DATA_FAILURE';
export const POST_BANK_DATA_SUCCESS = 'POST_BANK_DATA_SUCCESS';

export const postBankData = ({
    contactName,
    phoneNumber,
    accountNumber,
    routingNumber,
    nameOnCheck,
    merchantAppId
}) => {
    return {
        fetchParams: {
            body: {
                contactName,
                phoneNumber,
                accountNumber,
                routingNumber,
                nameOnCheck
            },
            method: 'POST',
            url: `${config.cforceServiceUrl}/merchant/${merchantAppId}/email`
        },
        loadActionTypeOrCreator: POST_BANK_DATA_LOAD,
        failureActionTypeOrCreator: POST_BANK_DATA_FAILURE,
        successActionTypeOrCreator: POST_BANK_DATA_SUCCESS
    };
};

export const VALIDATE_BANK_DATA_LOAD = 'VALIDATE_BANK_DATA_LOAD';
export const VALIDATE_BANK_DATA_FAILURE = 'VALIDATE_BANK_DATA_FAILURE';
export const VALIDATE_BANK_DATA_SUCCESS = 'VALIDATE_BANK_DATA_SUCCESS';

export const validateBankData = ({
    routingNumber,
    accountNumber,
    merchantAppId
}) => {
    return {
        fetchParams: {
            body: {
                accountNumber,
                routingNumber,
                merchantAppId
            },
            method: 'POST',
            url: `${config.cforceServiceUrl}/merchant/validateAccountAndRouting`
        },
        loadActionTypeOrCreator: VALIDATE_BANK_DATA_LOAD,
        failureActionTypeOrCreator: VALIDATE_BANK_DATA_FAILURE,
        successActionTypeOrCreator: VALIDATE_BANK_DATA_SUCCESS
    };
};
