import { createFetchReducer, createBaseSelectors } from '@nexio/ui-redux';
import {
    SEND_APP_LINK_EMAIL_FAILURE,
    SEND_APP_LINK_EMAIL_LOAD,
    SEND_APP_LINK_EMAIL_SUCCESS
} from './sendAppLinkEmailActions';

export const sendAppLinkEmailReducer = createFetchReducer({
    loadActionType: SEND_APP_LINK_EMAIL_LOAD,
    failureActionType: SEND_APP_LINK_EMAIL_FAILURE,
    successActionType: SEND_APP_LINK_EMAIL_SUCCESS
});

const baseSelectors = createBaseSelectors('sendAppLinkEmailReducer');

export const getIsSendAppLinkEmailLoading = baseSelectors.getIsLoading;
export const getSendAppLinkEmailErrorMessage = baseSelectors.getErrorMessage;